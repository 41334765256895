import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { type SubscriptionDocumentType } from '@orus.eu/dimensions'
import { TechnicalError } from '@orus.eu/error'
import { memo, useState } from 'react'
import { colorTokens, Text, type CompoundIconName } from '../../../../foundations'
import { borderRadius } from '../../../../foundations/border-radius-tokens'
import { spacing } from '../../../../foundations/spacing-tokens'
import { Dialog } from '../../../../patterns/dialog'

import { Avatar, Callout } from '../../../../components/index.js'
import { FlexColumn } from '../../../../legacy/atoms'

import { Uploader } from '../../../../legacy/molecules'
import type { UploaderStorageBackend } from '../../../../legacy/molecules/uploader/uploader-file'
import womanRaisingHand from './images/woman-raising-hand.png'
import { subscriptionDocumentsConfiguration, type Example } from './subscription-document-text'

export type SubscriptionDocumentUploadDialogProps = {
  documentType: SubscriptionDocumentType
  storageBackend: UploaderStorageBackend
  onClose: () => void
}

export const SubscriptionDocumentUploadDialog = memo<SubscriptionDocumentUploadDialogProps>(
  function DocumentUploadDialog({ documentType, onClose, storageBackend }) {
    const [isUploadInProgress, setIsUploadInProgress] = useState(false)

    const configuration = subscriptionDocumentsConfiguration[documentType]

    return (
      <Dialog
        onClose={onClose}
        size="large"
        title={configuration.uploadDialogTitle}
        onPrimaryAction={onClose}
        primaryActionLabel="Fermer"
        primaryActionDisabled={isUploadInProgress}
      >
        <FlexColumn
          css={css`
            min-width: 100%;
          `}
        >
          <div
            css={css`
              display: flex;
              flex-direction: column;
              gap: ${spacing[70]};
            `}
          >
            <div
              css={css`
                display: flex;
                flex-direction: column;
                gap: ${spacing[40]};
                align-self: stretch;
              `}
            >
              <div>
                <Text variant="body2Medium">Documents acceptés</Text>
                <DataContainer>
                  {configuration.acceptedDocuments ? (
                    <DataSection>
                      {configuration.acceptedDocuments.map((acceptedDocument, index) => (
                        <Text key={index} variant="body2">
                          {acceptedDocument}
                        </Text>
                      ))}
                    </DataSection>
                  ) : null}
                  {configuration.acceptedAlternativeDocuments ? (
                    <OrSection variant="body2" color={colorTokens['color-text-info-secondary']}>
                      ou
                    </OrSection>
                  ) : null}
                  {configuration.acceptedAlternativeDocuments ? (
                    <DataSection>
                      {configuration.acceptedAlternativeDocuments.map((documents, index) => (
                        <Text key={index} variant="body2">
                          {documents}
                        </Text>
                      ))}
                    </DataSection>
                  ) : null}
                </DataContainer>
              </div>

              <div>
                <Text variant="body2Medium">Conditions requises</Text>
                <DataContainer>
                  <DataSection>
                    {configuration.requiredConditions && configuration.requiredConditions.length > 0 ? (
                      <div>
                        {configuration.requiredConditions.map((condition, index) => (
                          <Text key={index} variant="body2">
                            {condition}
                          </Text>
                        ))}
                      </div>
                    ) : null}
                  </DataSection>
                  {configuration.requiredAlternativeConditions ? (
                    <OrSection variant="body2" color={colorTokens['color-text-info-secondary']}>
                      ou
                    </OrSection>
                  ) : null}
                  <DataSection>
                    {configuration.requiredAlternativeConditions ? (
                      <div>
                        {configuration.requiredAlternativeConditions.map((condition, index) => (
                          <Text key={index} variant="body2">
                            {condition}
                          </Text>
                        ))}
                      </div>
                    ) : null}
                  </DataSection>
                </DataContainer>
              </div>
            </div>
          </div>

          <div
            css={css`
              padding-top: ${spacing[70]};
            `}
          >
            <Uploader size="large" storageBackend={storageBackend} setIsUploadInProgress={setIsUploadInProgress} />
          </div>

          <div>
            {configuration.additionalExplanations && configuration.additionalExplanations.length > 0 ? (
              <div
                css={css`
                  padding-top: ${spacing[70]};
                `}
              >
                <Text variant="body2Medium">Plus d’explications</Text>
                <Text variant="body2">{configuration.additionalExplanations}</Text>
              </div>
            ) : null}

            {configuration.examples && configuration.examples.length > 0 ? (
              <div
                css={css`
                  padding-top: ${spacing[70]};
                  display: flex;
                  justify-content: center;
                `}
              >
                <ExampleImages examples={configuration.examples} width={configuration.examplesWidth ?? '100%'} />
              </div>
            ) : null}

            {configuration.highlights && configuration.highlights.length > 0 ? (
              <div
                css={css`
                  padding-top: ${spacing[70]};
                `}
              >
                <Highlights highlights={configuration.highlights} />
              </div>
            ) : null}

            {configuration.callout ? (
              <div
                css={css`
                  padding-top: ${spacing[70]};
                  padding-bottom: ${spacing[60]};
                `}
              >
                <Callout
                  css={css`
                    background-color: ${colorTokens['color-bg-brand-light']};
                  `}
                  variant="content"
                  title="Bon à savoir"
                  avatar={<Avatar src={womanRaisingHand} />}
                >
                  {configuration.callout}
                </Callout>
              </div>
            ) : null}
          </div>
        </FlexColumn>
      </Dialog>
    )
  },
)

type HighlightsProps = {
  highlights: string[]
}
const Highlights = memo<HighlightsProps>(function Highlights({ highlights }) {
  return (
    <div
      css={css`
        display: flex;
        flex-direction: column;
      `}
    >
      {highlights.map((highlight, index) => (
        <Highlight key={index} highlight={highlight} index={index} />
      ))}
    </div>
  )
})

type HighlightProps = {
  highlight: string
  index: number
}
const Highlight = memo<HighlightProps>(function Highlight({ highlight, index }) {
  return (
    <div
      css={css`
        padding: ${spacing[30]} 0;
        display: flex;
        gap: ${spacing[50]};
        align-items: center;
      `}
    >
      <Avatar icon={getIconForNumber(index + 1)} size="30" />
      <Text>{highlight}</Text>
    </div>
  )
})

type ExampleImagesProps = {
  examples: Example[]
  width: string
}
const ExampleImages = memo<ExampleImagesProps>(function ExampleImages({ examples, width }) {
  return (
    <ExamplesContainer width={width}>
      {examples.map((example, index) => (
        <Example key={index} example={example} />
      ))}
    </ExamplesContainer>
  )
})

const ExamplesContainer = styled.div<{ width: string }>`
  background-color: #f8f8ff;
  display: flex;
  gap: ${spacing[50]};
  padding: ${spacing[50]};
  width: ${({ width }) => width};
`

type ExampleProps = {
  example: Example
}
const Example = memo<ExampleProps>(function Example({ example }) {
  return (
    <ExampleContainer>
      {example.name ? (
        <ExampleTitleContainer>
          <Text variant="body2">{example.name}</Text>
        </ExampleTitleContainer>
      ) : null}

      <img
        css={css`
          width: 100%;
        `}
        //chromatic ignore on flakiness for image (experience) that will be soon removed
        data-chromatic="ignore"
        src={example.imageSrc}
        alt={example.name ?? 'Exemple de document'}
      />
    </ExampleContainer>
  )
})

const ExampleContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${spacing[40]};
`

const ExampleTitleContainer = styled.div`
  background-color: #f1ebfc;
  padding: ${spacing[20]} ${spacing[30]};
  border-radius: ${borderRadius[10]};
`

function getIconForNumber(value: number): CompoundIconName {
  const icon = iconNumbers[value - 1]
  if (icon == undefined) {
    throw new TechnicalError('No icon configured for this value', { context: { value } })
  }
  return icon
}

const iconNumbers: CompoundIconName[] = ['circle-1-regular', 'circle-2-regular', 'circle-3-regular', 'circle-4-regular']

const OrSection = styled(Text)`
  width: 56px;
  height: 20px;
`

const DataSection = styled.div`
  flex: 1;
`

const DataContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: ${spacing[40]};
`
