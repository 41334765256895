import { createFileRoute } from '@tanstack/react-router'

import PlatformPendingSubscriptionHistoryPage from '../../../../../components/pages/backoffice/platform/platform-pending-subscription-history-page'
import { PermissionChecker } from '../../../../../components/templates/permission-checker'

export const Route = createFileRoute('/bak/pending-subscriptions/$subscriptionId/history')({
  component: () => (
    <PermissionChecker requiredPermissions={['subscription.readHistory']}>
      <PlatformPendingSubscriptionHistoryPage />
    </PermissionChecker>
  ),
})
