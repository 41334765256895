import { StringsEnumDimension } from '../strings-enum-dimension.js'

export const rcpaDepartmentDimension = new StringsEnumDimension({
  name: 'rcpaDepartment',
  displayValues: { name: 'Code département' },
  values: [
    '01',
    '02',
    '03',
    '04',
    '05',
    '06',
    '07',
    '08',
    '09',
    '10',
    '11',
    '12',
    '13',
    '14',
    '15',
    '16',
    '17',
    '18',
    '19',
    '20',
    '21',
    '22',
    '23',
    '24',
    '25',
    '26',
    '27',
    '28',
    '29',
    '30',
    '31',
    '32',
    '33',
    '34',
    '35',
    '36',
    '37',
    '38',
    '39',
    '40',
    '41',
    '42',
    '43',
    '44',
    '45',
    '46',
    '47',
    '48',
    '49',
    '50',
    '51',
    '52',
    '53',
    '54',
    '55',
    '56',
    '57',
    '58',
    '59',
    '60',
    '61',
    '62',
    '63',
    '64',
    '65',
    '66',
    '67',
    '68',
    '69',
    '70',
    '71',
    '72',
    '73',
    '74',
    '75',
    '76',
    '77',
    '78',
    '79',
    '80',
    '81',
    '82',
    '83',
    '84',
    '85',
    '86',
    '87',
    '88',
    '89',
    '90',
    '91',
    '92',
    '93',
    '94',
    '95',
    //#region Guadeloupe
    '97100',
    '97139',
    '97142',
    '97121',
    '97122',
    '97123',
    '97125',
    '97130',
    '97140',
    '97126',
    '97127',
    '97190',
    '97113',
    '97128',
    '97112',
    '97129',
    '97111',
    '97160',
    '97170',
    '97131',
    '97110',
    '97116',
    '97117',
    '97120',
    '97118',
    '97134',
    '97180',
    '97115',
    '97136',
    '97137',
    '97114',
    '97141',
    '97119',
    //#endregion
    //#region Martinique
    '97200',
    '97234',
    '97216',
    '97217',
    '97218',
    '97222',
    '97221',
    '97222',
    '97223',
    '97224',
    '97250',
    '97240',
    '97218',
    '97213',
    '97232',
    '97214',
    '97218',
    '97225',
    '97290',
    '97260',
    '97226',
    '97250',
    '97211',
    '97215',
    '97231',
    '97270',
    '97212',
    '97250',
    '97227',
    '97228',
    '97230',
    '97233',
    '97220',
    '97229',
    '97280',
    //#endregion
    //#region Réunion
    '97400',
    '97417',
    '97490',
    '97425',
    '97412',
    '97413',
    '97414',
    '97427',
    '97429',
    '97431',
    '97420',
    '97419',
    '97440',
    '97470',
    '97480',
    '97416',
    '97421',
    '97415',
    '97442',
    '97410',
    '97438',
    '97439',
    '97441',
    '97433',
    '97418',
    '97426',
    //#endregion
  ] as const,
  tags: ['RCPA'],
})
