import { createFileRoute } from '@tanstack/react-router'

import PlatformNewInvoicePageV2 from '../../../../../components/pages/backoffice/platform/platform-new-invoice-page-V2'
import { PermissionChecker } from '../../../../../components/templates/permission-checker'

export const Route = createFileRoute('/bak/contracts/$subscriptionId/new-invoice')({
  component: () => (
    <PermissionChecker requiredPermissions={['invoices.create']}>
      <PlatformNewInvoicePageV2 />
    </PermissionChecker>
  ),
})
