import isPropValid from '@emotion/is-prop-valid'
import styled from '@emotion/styled'
import { mobileMediaQuery } from '../../code-only/hooks'
import { spacing } from '../../foundations/spacing-tokens.js'

/** Container component
 *
 * Exposes a flex container to serve as base, with sane defaults
 *
 * @param gap - space between children
 */
export const FlexContainer = styled('div', {
  shouldForwardProp: (propName) => !['gap', 'margin', 'padding'].includes(propName),
})<{
  gap?: string
  margin?: string
  padding?: string
}>`
  display: flex;
  ${({ gap = '0' }) => `gap: ${gap}`};
  ${({ margin = '0 auto' }) => `margin: ${margin}`};
  ${({ padding = '0' }) => `padding: ${padding}`};
`

export const FlexColumn = styled(FlexContainer)`
  flex-direction: column;
`

export const FlexRow = styled(FlexContainer)`
  flex-direction: row;
`

export const FlexSpacedContainer = styled('div', {
  shouldForwardProp: (propName) => !['gap', 'margin', 'padding'].includes(propName),
})<{
  gap?: string
  margin?: string
  padding?: string
}>`
  display: flex;
  ${({ gap = spacing[70] }) => `gap: ${gap}`};
  ${({ margin = '0 auto' }) => `margin: ${margin}`};
  ${({ padding = `0 ${spacing[60]}` }) => `padding: ${padding}`};
`

export const FlexSpacedColumn = styled(FlexSpacedContainer)`
  flex-direction: column;
`

export const FlexSpacedRow = styled(FlexSpacedContainer)`
  flex-direction: row;
`

/** Screen container component
 *
 * aimed at being the wrapping component for a page's content
 * mimics default behaviour of MUI's container
 *
 * maxWidth in desktop mode is 800px, aligning with design (instead of MUI's 848px)
 * in mobile mode, ensures no padding (instead of MUI's 16px)
 *
 * @param maxWidth - maximum width of the container (defaults to 848px)
 * @param width - width of the container (defaults to 100% of the container)
 */
export const ContentContainerAppClient = styled(FlexSpacedColumn, {
  shouldForwardProp: (propName) => !['maxWidth', 'width', 'marginTop'].includes(propName) || isPropValid(propName),
})<{
  maxWidth?: string
  width?: string
  marginTop?: string
}>`
  max-width: ${({ maxWidth = '800px' }) => maxWidth};
  width: ${({ width = '100%' }) => width};
  margin-top: ${({ marginTop = spacing[70] }) => marginTop};

  ${mobileMediaQuery} {
    margin-top: 0;
    padding-left: 0;
    padding-right: 0;
  }
`

export const ContentContainer = styled(FlexColumn, {
  shouldForwardProp: (propName) => !['maxWidth', 'width'].includes(propName) || isPropValid(propName),
})<{
  maxWidth?: string
  width?: string
}>`
  ${({ maxWidth }) => (maxWidth ? `max-width: ${maxWidth};` : ``)};
  width: ${({ width = '100%' }) => width};

  ${mobileMediaQuery} {
    padding-left: 0;
    padding-right: 0;
  }
`

export const ContentContainerBackoffice = styled(FlexSpacedColumn, {
  shouldForwardProp: (propName) => !['maxWidth', 'marginTop'].includes(propName),
})<{
  maxWidth?: string
  marginTop?: string
}>`
  max-width: ${({ maxWidth = '1200px' }) => maxWidth};
  width: 100%;
  margin-top: ${({ marginTop }) => marginTop};
  margin-bottom: ${spacing[70]};

  ${mobileMediaQuery} {
    padding-left: 16px;
    padding-right: 16px;
  }
`
