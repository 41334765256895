import styled from '@emotion/styled'
import { useSnackbar, type CustomContentProps } from 'notistack'
import { forwardRef, memo, useCallback, type ReactNode, type RefAttributes } from 'react'
import { spacing } from '../../../../foundations/spacing-tokens.js'
import { Avatar } from '../../../avatar/avatar.js'
import { CalloutNotificationTemplate, type ActionButton } from '../../common/callout-notification-template.js'
import { colorsPerVariant, type NotificationVariant } from '../util.js'

export type TemporaryNotificationProps = {
  variant: NotificationVariant
  dark?: boolean
  title?: string
  actionButton?: ActionButton
  showAvatar?: boolean
  children?: ReactNode
  className?: string
  onExitClick: () => void
  // The 'forwardRef' is mandatory to use it as a custom component for notistack 'Snackbar'
} & RefAttributes<HTMLDivElement>

export const TemporaryNotification = memo<TemporaryNotificationProps>(
  forwardRef<HTMLDivElement, TemporaryNotificationProps>(function TemporaryNotification(props, ref) {
    const { variant, title, dark, actionButton, showAvatar = true, children, className, onExitClick } = props

    const colors = colorsPerVariant[variant][dark ? 'dark' : 'light']

    return (
      <CalloutNotificationTemplate
        {...colors}
        leftAvatar={showAvatar ? <Avatar icon="circle-info-solid" /> : undefined}
        leftAvatarSize="30"
        actionButton={actionButton}
        title={title}
        className={className}
        rightAvatar={<Avatar icon="xmark-regular" />}
        onRightAvatarClick={onExitClick}
        ref={ref}
        shadow={true}
        indented={true}
      >
        {children}
      </CalloutNotificationTemplate>
    )
  }),
)

export type TemporaryNotificationAlertOrusProps = {
  orusVariant: NotificationVariant
  extra?: ReactNode | undefined
}

type TemporaryNotificationAlertProps = CustomContentProps & TemporaryNotificationAlertOrusProps

// notistack expects custom alerts to:
// - forward the ref
// - be a single element (no fragment)
export const TemporaryNotificationAlert = memo(
  forwardRef<HTMLDivElement, TemporaryNotificationAlertProps>(function TemporaryNotificationAlert(props, ref) {
    // Props passed by notistack
    const { id, message } = props
    const { orusVariant } = props
    const { closeSnackbar } = useSnackbar()
    const handleCloseSnackbar = useCallback(() => closeSnackbar(id), [closeSnackbar, id])

    return (
      <TemporaryNotification ref={ref} onExitClick={handleCloseSnackbar} variant={orusVariant}>
        <TemporaryNotificationAlertContainer>
          {message}

          {props.extra}
        </TemporaryNotificationAlertContainer>
      </TemporaryNotification>
    )
  }),
)

const TemporaryNotificationAlertContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: ${spacing[40]};
`
