import { css } from '@emotion/react'
import { memo } from 'react'

import styled from '@emotion/styled'

import {
  productAttributeValueToString,
  type ProjectedProductAttributes,
} from '../../../../code-only/hooks/use-product-attributes-projection.js'
import { useLanguage } from '../../../../code-only/localization/language-context.js'
import { colorTokens } from '../../../../foundations/color-tokens.js'
import { spacing } from '../../../../foundations/spacing-tokens.js'
import { Text } from '../../../../foundations/text'
import { SummaryTable } from '../../../../legacy/atoms/summary-table.js'

type DetailLinesProps = {
  projectedProductsAttributes: ProjectedProductAttributes[]
  className?: string
}

export const DetailLines = memo<DetailLinesProps>(function DetailLines(props) {
  const language = useLanguage()
  const { projectedProductsAttributes: attributes, className } = props
  return (
    <SummaryTableWithMargin className={className}>
      {attributes.map(({ productName, attributes }) => (
        // list is static and we have no natural key
        // eslint-disable-next-line react/jsx-key
        <div
          css={css`
            padding-top: ${spacing[50]};
            padding-bottom: ${spacing[50]};
          `}
        >
          <Text
            variant="body2Medium"
            css={css`
              height: ${spacing[60]};
            `}
          >
            {productName}
          </Text>
          {attributes.map((attribute) => (
            // list is static and we have no natural key
            // eslint-disable-next-line react/jsx-key
            <div
              css={css`
                display: flex;
                justify-content: space-between;
                height: ${spacing[60]};
              `}
            >
              <Text variant="body2">{attribute.label}</Text>
              <Text variant="body2Medium" color={colorTokens['color-text-base-link']}>
                {productAttributeValueToString(attribute, language)}
              </Text>
            </div>
          ))}
        </div>
      ))}
    </SummaryTableWithMargin>
  )
})

const SummaryTableWithMargin = styled(SummaryTable)`
  margin-top: ${spacing[50]};
`
