import { createFileRoute } from '@tanstack/react-router'

import PendingSubscriptionPage from '../../../../../components/pages/backoffice/common/PendingSubscriptionPage'
import { PermissionChecker } from '../../../../../components/templates/permission-checker'

export const Route = createFileRoute('/partner/$organization/v2-pending-subscriptions/$subscriptionId')({
  component: () => (
    <PermissionChecker requiredPermissions={['subscription.read']}>
      <PendingSubscriptionPage />
    </PermissionChecker>
  ),
})
