import styled from '@emotion/styled'
import { memo } from 'react'
import { Avatar, Button, Spinner } from '../../../components'
import { borderRadius, colorTokens, shadow, spacing, Text, type CompoundIconName } from '../../../foundations'

export type ButtonCardWithAvatarProps = {
  text: string
  onClick: () => void
  disabled?: boolean
  downloadInProgress?: boolean
  avatarIcon: CompoundIconName
  avatarColor: string
}

export const ButtonCardWithAvatar = memo<ButtonCardWithAvatarProps>(function ButtonCardWithAvatar({
  text,
  onClick,
  disabled,
  downloadInProgress = false,
  avatarColor,
  avatarIcon,
}) {
  return (
    <ButtonContainer>
      {downloadInProgress ? (
        <SpinnerContainer>
          <Spinner size="50" />
        </SpinnerContainer>
      ) : (
        <></>
      )}
      <StyledButton size="large" fullWidth variant="secondary" disabled={disabled} onClick={onClick}>
        <ButtonRow downloadInProgress={downloadInProgress}>
          <Avatar variant="contained" icon={avatarIcon} size="60" containerColor={avatarColor} />
          <ButtonLabel variant="body2Medium">{text}</ButtonLabel>
        </ButtonRow>
      </StyledButton>
    </ButtonContainer>
  )
})

const SpinnerContainer = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`

const ButtonLabel = styled(Text)`
  white-space: normal;
  hyphens: auto;
  word-break: break-word;
  text-align: left;
`

const ButtonContainer = styled.div`
  position: relative;
`

const StyledButton = styled(Button)`
  padding: ${spacing[60]};
  align-items: flex-start;
  justify-content: flex-start;
  box-shadow: ${shadow.bottom[10]};
  border: 1px solid ${colorTokens['color-stroke-base']};
  border-radius: ${borderRadius[30]};
  background-color: ${colorTokens['color-bg-base-normal']};
  overflow: hidden;
  height: fit-content;
`

const ButtonRow = styled.span<{ downloadInProgress: boolean }>`
  display: flex;
  flex-direction: column;
  gap: ${spacing[40]};
  visibility: ${({ downloadInProgress }) => (downloadInProgress ? 'hidden' : 'visible')};
`
