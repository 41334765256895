import type { ActivityInput } from '@orus.eu/activity'
import { BaseDimension, type TypeOfDimension } from '../abstract-dimension'
import { AmountDimension } from '../amount-dimension'
import { BooleanDimension } from '../boolean-dimension'
import { ExclusionDimension } from '../exclusion-dimension'
import { FinancialRateDimension } from '../financial-rate-dimension'
import { InstallmentFeeRateDimension } from '../installment-fee-dimension'
import { IntegerDimension } from '../integer-dimension'
import { StringsWithDataEnumDimension } from '../strings-with-data-enum-dimension'

export * from './rcpa-activities-model/index'
export * from './rcpa-agent-dimension.js'
export * from './rcpa-department-dimension.js'
export * from './rcpa-guarantee-text.js'
export * from './rcpa-offer-dimension.js'
export * from './rcpa-options-dimension.js'
export * from './rcpa-quote/index.js'
export * from './rcpa-subsidiaries-dimension.js'

export const rcpaSelectedDimension = new BooleanDimension({
  name: 'rcpaSelected',
  displayValues: { name: 'RC Pro AIG' },
  tags: ['RCPA'],
} as const)

export const rcpaAvailableDimension = new BooleanDimension({
  name: 'rcpaAvailable',
  displayValues: { name: 'RC Pro AIG disponible' },
  tags: ['RCPA'],
} as const)

export const rcpaHasOptionDirigeantAvailableDimension = new BooleanDimension({
  name: 'rcpaHasOptionDirigeantAvailable',
  displayValues: { name: 'Option Dirigeant disponible' },
  tags: ['RCPA'],
} as const)

export const rcpaOptionDirigeantDimension = new BooleanDimension({
  name: 'rcpaOptionDirigeant',
  displayValues: { name: 'Option Dirigeant' },
  tags: ['RCPA'],
} as const)

export const rcpaHasSubsidiariesDimension = new BooleanDimension({
  name: 'rcpaHasSubsidiaries',
  displayValues: { name: 'Le prospect a des filiales' },
  tags: ['RCPA'],
} as const)

export const rcpaHasAgentsDimension = new BooleanDimension({
  name: 'rcpaHasAgents',
  displayValues: { name: 'Le prospect a des mandataires' },
  tags: ['RCPA'],
} as const)

export const isRcpaCifWorkforceNeededDimension = new BooleanDimension({
  name: 'isRcpaCifWorkforceNeeded',
  displayValues: { name: 'Le nombre de conseillers en Investissement Financier est nécessaire' },
  tags: ['RCPA'],
} as const)

export const rcpaCifWorkforceDimension = new IntegerDimension({
  name: 'rcpaCifWorkforce',
  displayValues: { name: 'Nombre de conseillers en Investissement Financier' },
  tags: ['RCPA'],
} as const)

export const isRcpaPersonaNeededDimension = new BooleanDimension({
  name: 'isRcpaPersonaNeeded',
  displayValues: { name: "Le type d'entreprise est nécessaire" },
  tags: ['RCPA'],
} as const)

export const rcpaPersonaDimension = new StringsWithDataEnumDimension({
  name: 'rcpaPersona',
  displayValues: {
    name: "Type d'entreprise",
  },
  entries: [
    ['physique', { label: '🧑‍💼 Indépendant (vous travaillez seul, sans société)' }],
    ['morale', { label: '🏢 Société (vous représentez une entreprise)' }],
  ],
} as const)

export type RcpaPersona = TypeOfDimension<typeof rcpaPersonaDimension>

export const rcpaExclusionsDimension = new ExclusionDimension({
  name: 'rcpaExclusions',
  displayValues: { name: 'Exclusions spécifiques RC Pro AIG' },
  tags: ['RCPA'],
})

export const rcpaYearlyBasePremiumDimension = new AmountDimension({
  name: 'rcpaYearlyBasePremium',
  displayValues: { name: 'Cotisation annuelle HT' },
  tags: ['RCPA'],
} as const)

export const rcpaYearlyTaxesDimension = new AmountDimension({
  name: 'rcpaYearlyTaxes',
  displayValues: { name: 'Taxes annuelles' },
  tags: ['RCPA'],
} as const)

export const rcpaYearlyTotalPremiumDimension = new AmountDimension({
  name: 'rcpaYearlyTotalPremium',
  displayValues: { name: 'Cotisation annuelle TTC' },
  tags: ['RCPA'],
} as const)

export const rcpaMonthlyTotalPremiumDimension = new AmountDimension({
  name: 'rcpaMonthlyTotalPremium',
  displayValues: { name: 'Cotisation mensuelle TTC' },
  tags: ['RCPA'],
} as const)

export const rcpaYearlyOrusManagementFeeAmountDimension = new AmountDimension({
  name: 'rcpaYearlyOrusManagementFeeAmount',
  displayValues: { name: 'Frais de gestion annuels ORUS' },
  tags: ['RCPA'],
} as const)

export const rcpaYearlyOrusInstallmentFeeAmountDimension = new AmountDimension({
  name: 'rcpaYearlyOrusInstallmentFeeAmount',
  displayValues: { name: 'Frais de fractionnement annuels ORUS' },
  tags: ['RCPA'],
} as const)

export const rcpaYearlyPartnerManagementFeeAmountDimension = new AmountDimension({
  name: 'rcpaYearlyPartnerManagementFeeAmount',
  displayValues: { name: 'Frais de gestion annuels partenaire' },
  tags: ['RCPA'],
} as const)

export const isRcpaOriasRegulatedActivityDimension = new BooleanDimension({
  name: 'isRcpaOriasRegulatedActivity',
  displayValues: { name: 'Activité régulée par ORIAS' },
  tags: ['RCPA'],
} as const)

export const isRcpaAlreadyAigInsuredWithDerogationDimension = new BooleanDimension({
  name: 'isRcpaAlreadyAigInsuredWithDerogation',
  displayValues: { name: 'Déjà assuré par AIG avec dérogation' },
  tags: ['RCPA'],
} as const)

export const rcpaActivitiesDimension = new BaseDimension<'rcpaActivities', ActivityInput[]>({
  name: 'rcpaActivities',
  displayValues: { name: 'Activités couvertes par la RCPA' },
  tags: ['RCPA'],
} as const)

export const rcpaManagementFeeRateDimension = new FinancialRateDimension({
  name: 'rcpaManagementFeeRate',
  displayValues: { name: 'Frais de gestion (Orus) RCPA' },
  tags: ['RCPA'],
} as const)

export const rcpaInstallmentFeeRateDimension = new InstallmentFeeRateDimension({
  name: 'rcpaInstallmentFeeRate',
  displayValues: { name: 'Frais de fractionnement (Orus) RCPA' },
  tags: ['RCPA'],
} as const)

export const rcpaMultiActivityAvailableDimension = new BooleanDimension({
  name: 'rcpaMultiActivityAvailable',
  displayValues: { name: "Multi activités disponible pour l'activité" },
  tags: ['RCPA'],
} as const)

export const rcpaAvailableActivitiesForMultiActivityDimension = new BaseDimension<
  'rcpaAvailableActivitiesForMultiActivity',
  ActivityInput[]
>({
  name: 'rcpaAvailableActivitiesForMultiActivity',
  displayValues: { name: 'Activités disponibles pour la multi activité' },
  tags: ['RCPA'],
} as const)

export const rcpaAvailableAgentsActivitiesDimension = new BaseDimension<
  'rcpaAvailableAgentsActivities',
  ActivityInput[]
>({
  name: 'rcpaAvailableAgentsActivities',
  displayValues: { name: 'Activités disponibles pour les mandataires' },
  tags: ['RCPA'],
} as const)
