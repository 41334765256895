import { createFileRoute } from '@tanstack/react-router'

import HealthInsuranceCpmsPage from '../../../../components/pages/health-insurance/cpms-page'
import { PermissionChecker } from '../../../../components/templates/permission-checker'

export const Route = createFileRoute('/_customer/health-insurance/cpms')({
  component: () => (
    <PermissionChecker requiredPermissions={['contracts.read']}>
      <HealthInsuranceCpmsPage />
    </PermissionChecker>
  ),
})
