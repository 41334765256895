import { Global } from '@emotion/react'
import { CssBaseline, ThemeProvider } from '@mui/material'
import { memo, useMemo, useState } from 'react'

import { PharaohThemeProvider } from '../foundations/pharaoh-theme-provider'

import { TrackButtonClickContext, type ButtonClickContext } from '../wrapper-context'
import { DefaultLanguageProviderWrapper } from './localization'
import { defaultSkin } from './skin/default-skin/index.js'
import { SkinContextProvider } from './skin/hooks.js'
import type { Skin } from './skin/skin.js'
import { theme } from './theme.js'

export type PharaohWrapperProps = {
  children?: React.ReactNode
  clickButtonCallback: (clickContext: ButtonClickContext) => void
}

export const PharaohWrapper = memo<PharaohWrapperProps>(function PharaohWrapper({ children, clickButtonCallback }) {
  const [skin, setSkin] = useState<Skin | null>(null)

  const skinContextData = useMemo(() => ({ skin, setSkin }), [skin, setSkin])

  const globalStyles = useMemo(() => {
    return {
      ':root': (skin ?? defaultSkin).cssVariables,
    }
  }, [skin])

  skin?.additionalStylesheets.forEach(ensureStylesheetAdded)

  return (
    <TrackButtonClickContext.Provider value={clickButtonCallback}>
      <PharaohThemeProvider>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <Global styles={globalStyles} />
          {skin?.additionalStyles ? <Global styles={skin.additionalStyles} /> : null}
          <SkinContextProvider value={skinContextData}>
            <DefaultLanguageProviderWrapper>{children}</DefaultLanguageProviderWrapper>
          </SkinContextProvider>
        </ThemeProvider>
      </PharaohThemeProvider>
    </TrackButtonClickContext.Provider>
  )
})

const addedStylesheets = new Set<string>()
function ensureStylesheetAdded(url: string) {
  if (addedStylesheets.has(url)) return

  const link = document.createElement('link')

  link.type = 'text/css'
  link.rel = 'stylesheet'
  link.href = url

  document.head.appendChild(link)

  addedStylesheets.add(url)
}
