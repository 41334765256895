import { colorTokens } from '../../../foundations'

export type NotificationVariant = 'info' | 'success' | 'warning' | 'danger'

export type Colors = { backgroundColor: string; accentColor: string; textColor: string }

export const colorsPerVariant: {
  [variant in NotificationVariant]: { light: Colors; dark: Colors }
} = {
  info: {
    light: {
      backgroundColor: colorTokens['color-bg-info-inverse'],
      accentColor: colorTokens['color-fg-info'],
      textColor: colorTokens['color-text-base-main'],
    },
    dark: {
      backgroundColor: colorTokens['color-bg-info-primary'],
      accentColor: colorTokens['color-fg-info-primary'],
      textColor: colorTokens['color-text-base-inverse'],
    },
  },
  success: {
    light: {
      backgroundColor: colorTokens['color-bg-success-inverse'],
      accentColor: colorTokens['color-fg-success'],
      textColor: colorTokens['color-text-base-main'],
    },
    dark: {
      backgroundColor: colorTokens['color-bg-success-primary'],
      accentColor: colorTokens['color-fg-success-primary'],
      textColor: colorTokens['color-text-base-inverse'],
    },
  },
  warning: {
    light: {
      backgroundColor: colorTokens['color-bg-warning-inverse'],
      accentColor: colorTokens['color-fg-warning'],
      textColor: colorTokens['color-text-base-main'],
    },
    dark: {
      backgroundColor: colorTokens['color-bg-warning-primary'],
      accentColor: colorTokens['color-fg-warning-primary'],
      textColor: colorTokens['color-text-base-inverse'],
    },
  },
  danger: {
    light: {
      backgroundColor: colorTokens['color-bg-danger-inverse'],
      accentColor: colorTokens['color-fg-danger'],
      textColor: colorTokens['color-text-base-main'],
    },
    dark: {
      backgroundColor: colorTokens['color-bg-danger-primary'],
      accentColor: colorTokens['color-fg-danger-primary'],
      textColor: colorTokens['color-text-base-inverse'],
    },
  },
}
