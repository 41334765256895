import { createFileRoute } from '@tanstack/react-router'

import ReportClaimPage from '../../../components/pages/report-claim/ReportClaimPage'
import { PermissionChecker } from '../../../components/templates/permission-checker'

export const Route = createFileRoute('/_customer/report-claim')({
  component: () => (
    <PermissionChecker requiredPermissions={['contracts.read']}>
      <ReportClaimPage />
    </PermissionChecker>
  ),
})
