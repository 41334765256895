import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { memo, type ReactNode } from 'react'
import { desktopMediaQuery } from '../../code-only/hooks/use-screen-type.js'
import { useUiContext } from '../../code-only/hooks/use-screen-variant.js'
import { borderStroke, colorTokens, shadow, spacing, Text } from '../../foundations/index.js'

export type ContractPanelContent =
  | {
      type: 'row'
      title: string
      values: string[]
      paymentPrecisionLabel?: string
      withBottomSpacing?: boolean
    }
  | {
      type: 'node'
      children: ReactNode
    }

export type ContractPanelProps = {
  title: string
  contractGridRows: ContractPanelContent[]
  buttomButton?: ReactNode
  rightButton?: ReactNode
  children?: ReactNode
  PersistentNotification?: ReactNode
}

export const ContractPanel = memo<ContractPanelProps>(function ContractPanel(props) {
  const { title, contractGridRows, rightButton, buttomButton, children, PersistentNotification } = props
  const uiContext = useUiContext()
  return (
    <>
      <Container>
        <div
          css={css`
            display: flex;
            justify-content: space-between;
            gap: ${spacing[50]};
          `}
        >
          <Text variant="h6" color={colorTokens['color-text-base-main']}>
            {title}
          </Text>
          {rightButton}
        </div>

        <div
          css={css`
            display: grid;
            grid-template-columns: 1fr auto;
            gap: ${spacing[30]};
          `}
        >
          {contractGridRows.map((contractGridRow, index) =>
            contractGridRow.type === 'row' ? (
              <ContractCardGridRow
                key={index}
                title={contractGridRow.title}
                values={contractGridRow.values}
                paymentPrecisionLabel={contractGridRow.paymentPrecisionLabel}
                withBottomSpacing={contractGridRow.withBottomSpacing}
              />
            ) : (
              <>
                <div
                  css={css`
                    grid-column: 1 / span 2;
                  `}
                >
                  {contractGridRow.children}
                </div>
              </>
            ),
          )}
        </div>

        {PersistentNotification}

        {buttomButton ? (
          <div
            css={css`
              justify-content: left;
              text-align: ${uiContext !== 'mobile' ? 'match-parent' : 'center'};
            `}
          >
            {buttomButton}
          </div>
        ) : undefined}
        {children}
      </Container>
    </>
  )
})

const ContractCardGridRow = memo<{
  title: string
  values: string[]
  paymentPrecisionLabel?: string
  withBottomSpacing?: boolean
}>(function ContractCardGridRow({ title, values, paymentPrecisionLabel, withBottomSpacing = false }) {
  const uiContext = useUiContext()

  return (
    <>
      <Text variant="body2" color={colorTokens['color-text-base-basic']}>
        {title}
      </Text>
      <div css={withBottomSpacing ? { marginBottom: spacing[60] } : {}}>
        {values.map((value) => (
          <div
            key={value}
            css={css`
              text-align: right;
            `}
          >
            <Text variant="body2">
              {value}
              {uiContext !== 'mobile' && paymentPrecisionLabel ? ` (${paymentPrecisionLabel})` : ''}
            </Text>
            {uiContext === 'mobile' && paymentPrecisionLabel ? (
              <Text variant="body2">({paymentPrecisionLabel})</Text>
            ) : (
              <></>
            )}
          </div>
        ))}
      </div>
    </>
  )
})

const Container = styled.div`
  background-color: ${colorTokens['color-bg-base-normal']};
  border: ${borderStroke[20]} solid ${colorTokens['color-stroke-base']};
  box-shadow: ${shadow.bottom[20]};
  border-radius: ${spacing[40]};
  padding: ${spacing[60]} ${spacing[70]};
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: ${spacing[60]};

  ${desktopMediaQuery} {
    padding: ${spacing[70]};
  }
`
