import { css } from '@emotion/react'
import { memo, useMemo } from 'react'

import { useUiContext, type UiContext } from '../../../../code-only/hooks/use-screen-variant.js'
import { useLanguage, useTranslate } from '../../../../code-only/localization/language-context.js'
import { useSkin } from '../../../../code-only/skin/hooks.js'
import { Avatar } from '../../../../components/index.js'
import { colorTokens } from '../../../../foundations/color-tokens.js'
import { borderRadius, Text } from '../../../../foundations/index.js'
import { spacing } from '../../../../foundations/spacing-tokens.js'
import { Card } from '../../../../patterns/index.js'
import { PhoneNumber } from '../../../atoms/phone-number.js'
import { OrusFaceAvatar } from '../../universal-subscription/index.js'
import { Block } from '../block/index.js'
import { GuaranteesList } from '../guarantees-list/index.js'
import { OptionGuaranteeList } from '../guarantees-option-list/index.js'
import type { Guarantee, HelpContactDetails, Option } from '../types.js'

export type GuaranteesSection = {
  name: string
  id: string
  guarantees: Guarantee[]
}

export type GuaranteesTableProps = {
  variant: UiContext

  guaranteesSection: GuaranteesSection[]
  includedGuaranteesCount: number
  options: Option[]
  perOptionSubOptions: Record<string, Option[]>

  onOptionAddOrRemoveClick?: (option: Option, type: 'add' | 'remove') => void
  onGuaranteeClick: (guarantee: Guarantee) => void
  onInfoOptionClick: (option: Option) => void
  onContactClick: () => void

  className?: string
  quotePageType?: 'static' | 'dynamic'
  isMuta: boolean

  /** Contact details to display in the help section */
  helpContactDetails: HelpContactDetails
  /** Whether the subscription is managed by Orus, affects help text */
  isManagedByOrus: boolean
  /** Whether to show Orus illustrations set */
  showIllustrations: boolean
}

export const GuaranteesTable = memo<GuaranteesTableProps>(function GuaranteesTable(props) {
  const translate = useTranslate()
  const {
    variant,
    guaranteesSection,
    includedGuaranteesCount,
    options,
    perOptionSubOptions,
    onOptionAddOrRemoveClick,
    onGuaranteeClick,
    onInfoOptionClick,
    className,
    quotePageType,
    isMuta,
    helpContactDetails,
    isManagedByOrus,
    showIllustrations,
  } = props

  const tableType = quotePageType ?? 'dynamic'
  const displayableOptions = tableType === 'dynamic' ? options : options.filter((opt) => opt.selected)

  const skin = useSkin()
  const language = useLanguage()
  const screenVariant = useUiContext()

  const optionsList = useMemo(
    () => (
      <OptionGuaranteeList
        variant={variant}
        options={displayableOptions}
        onOptionAddOrRemoveClick={onOptionAddOrRemoveClick}
        onInfoOptionClick={onInfoOptionClick}
        css={css`
          margin-top: ${variant === 'mobile' ? spacing[60] : spacing[70]};
        `}
        quotePageType={tableType}
        isMuta={isMuta}
      />
    ),
    [variant, displayableOptions, onOptionAddOrRemoveClick, onInfoOptionClick, tableType, isMuta],
  )

  const helpBlock = useMemo(
    () => (
      <Card
        title={translate('subscription_need_help_title')}
        subtitle={
          isManagedByOrus
            ? translate('subscription_need_help_explanation')
            : translate('subscription_need_help_explanation_broker')
        }
        avatar={
          screenVariant === 'mobile' ? (
            showIllustrations ? (
              <Avatar illustration="help_big" size="70" />
            ) : (
              <OrusFaceAvatar
                css={css`
                  margin-bottom: ${spacing[40]};
                `}
                variant="round"
                size="60"
              />
            )
          ) : null
        }
        size="medium"
        variant="decorative"
        backgroundColorDecorative={skin.quoteCardHelperBackgroundColor ?? colorTokens['color-bg-brand']}
        buttons={[
          helpContactDetails?.phone
            ? {
                props: {
                  variant: 'primary',
                  icon: 'phone-regular',
                  avatarPosition: 'left',
                  href: `tel:${helpContactDetails.phone}`,
                  trackingId: 'call_button',
                },
                content: <PhoneNumber>{helpContactDetails.phone.replace(/^\+33 ?/, '0')}</PhoneNumber>,
              }
            : undefined,
          isManagedByOrus
            ? helpContactDetails.whatsappLink
              ? {
                  props: {
                    variant: helpContactDetails?.phone ? 'tertiary' : 'primary',
                    avatarPosition: 'left',
                    icon: 'whatsapp-brands',
                    target: '_blank',
                    href: helpContactDetails.whatsappLink,
                  },
                  content: translate('chat_on_whatsapp'),
                }
              : undefined
            : helpContactDetails.email
              ? {
                  props: {
                    variant: helpContactDetails?.phone ? 'tertiary' : 'primary',
                    avatarPosition: 'left',
                    icon: 'envelope-regular',
                    target: '_blank',
                    href: `email:${helpContactDetails.email}`,
                  },
                  content: translate('send_an_email'),
                }
              : undefined,
        ]}
        rightImageUrl={skin.quoteHelpBannerImageUrl[language]}
      />
    ),
    [
      language,
      skin.quoteHelpBannerImageUrl,
      translate,
      helpContactDetails,
      screenVariant,
      skin.quoteCardHelperBackgroundColor,
      showIllustrations,
      isManagedByOrus,
    ],
  )

  const optionsTitle = useMemo(
    () => (
      <>
        {displayableOptions.length > 1 ? (
          <Text variant="h6" color={colorTokens['color-text-base-main']}>
            Les {displayableOptions.length} options{' '}
            <span
              css={css`
                color: ${colorTokens['color-text-base-main']};
              `}
            >
              {tableType === 'dynamic' ? 'supplémentaires' : 'incluses'}
            </span>
          </Text>
        ) : (
          <Text variant="h6">Option incluse</Text>
        )}
      </>
    ),
    [displayableOptions.length, tableType],
  )

  return (
    <div
      className={className}
      css={css`
        flex: ${variant === 'mobile' || variant === 'backoffice' ? '1' : undefined};
      `}
    >
      <Block
        css={css`
          padding: ${variant === 'mobile' ? 0 : variant === 'backoffice' ? spacing['60'] : undefined};
          box-shadow: ${variant === 'mobile' || variant === 'backoffice' ? 'none' : undefined};
          flex: ${variant === 'mobile' || variant === 'backoffice' ? '1' : undefined};
          border: 1px solid ${colorTokens['color-stroke-base']};
          border-radius: ${borderRadius[30]};
        `}
        variant={variant}
        title={<GuaranteeTableTitle includedGuaranteesCount={includedGuaranteesCount} />}
      >
        {guaranteesSection.map((section) => (
          <div
            id={section.id}
            key={section.id}
            css={css`
              margin-top: ${variant === 'mobile' || variant === 'backoffice' ? spacing[60] : spacing[70]};
            `}
          >
            {guaranteesSection.length > 1 ? <Text variant="subtitle">{section.name}</Text> : <></>}

            <GuaranteesList
              variant={variant}
              guarantees={section.guarantees}
              onGuaranteeClick={onGuaranteeClick}
              css={css`
                margin-top: ${variant === 'mobile' ? 0 : spacing[30]};
              `}
              quotePageType={tableType}
              isMuta={isMuta}
            />
          </div>
        ))}
        {Object.entries(perOptionSubOptions).map(([optionName, subOptions]) => (
          <div
            id={optionName}
            key={optionName}
            css={css`
              margin-top: ${variant === 'mobile' ? spacing[60] : spacing[70]};
            `}
          >
            <Text variant="h6">{translate('option_guarantees_section_title', { optionName })}</Text>

            <OptionGuaranteeList
              variant={variant}
              options={subOptions}
              onOptionAddOrRemoveClick={onOptionAddOrRemoveClick}
              onInfoOptionClick={onInfoOptionClick}
              css={css`
                margin-top: ${variant === 'mobile' ? spacing[60] : spacing[70]};
              `}
              quotePageType={tableType}
              isMuta={isMuta}
            />
          </div>
        ))}

        {variant === 'desktop' ? (
          <div
            css={css`
              margin-top: ${spacing[70]};
            `}
          >
            {' '}
            {helpBlock}
            <>
              {displayableOptions.length > 0 ? (
                <>
                  <Text
                    variant="h6"
                    css={css`
                      margin-top: ${spacing[70]};
                    `}
                  >
                    {optionsTitle}
                  </Text>
                  {optionsList}
                </>
              ) : (
                <></>
              )}
            </>
          </div>
        ) : (
          <></>
        )}
      </Block>

      {variant === 'mobile' ? (
        <>
          <div
            css={css`
              margin-top: ${spacing[70]};
            `}
          >
            {helpBlock}
          </div>
          <>
            {displayableOptions.length > 0 ? (
              <Block
                variant={variant}
                title={optionsTitle}
                css={css`
                  margin-top: ${spacing[70]};
                `}
              >
                {optionsList}
              </Block>
            ) : (
              <></>
            )}
          </>
        </>
      ) : (
        <></>
      )}
    </div>
  )
})

type GuaranteeTableTitleProps = { includedGuaranteesCount: number }
const GuaranteeTableTitle = memo<GuaranteeTableTitleProps>(function GuaranteeTableTitle({ includedGuaranteesCount }) {
  const translate = useTranslate()
  return includedGuaranteesCount === 1 ? (
    <Text variant="h6" color={colorTokens['color-text-base-main']}>
      {translate('the_guarantee_included')}
    </Text>
  ) : (
    <Text variant="h6" color={colorTokens['color-text-base-main']}>
      {translate('the_x_guarantees_included', { count: includedGuaranteesCount })}
    </Text>
  )
})
