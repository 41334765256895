import { createFileRoute } from '@tanstack/react-router'

import TrackingDocumentationPage from '../../../components/pages/backoffice/platform/platform-documentation/tracking-documentation-page'
import { PermissionChecker } from '../../../components/templates/permission-checker'

export const Route = createFileRoute('/bak/tracking-doc')({
  component: () => (
    <PermissionChecker requiredPermissions={['tracking.read']}>
      <TrackingDocumentationPage />
    </PermissionChecker>
  ),
})
