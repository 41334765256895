import styled from '@emotion/styled'
import type { ReactElement } from 'react'
import { Badge, Button, Spinner, type BadgeVariant } from '../../../components'
import { Text } from '../../../foundations'
import { borderRadius } from '../../../foundations/border-radius-tokens'
import { borderStroke } from '../../../foundations/border-stroke-tokens'
import { colorTokens } from '../../../foundations/color-tokens'
import { spacing } from '../../../foundations/spacing-tokens'

export type WidgetBaseProps = {
  title: string
  badgeLabel?: string | number
  badgeVariant?: BadgeVariant
  badgeDark?: boolean
  href?: string
  children?: React.ReactNode
  hasChildren?: boolean
  isHeaderLoading?: boolean
  isBodyLoading?: boolean
}

export const WidgetBase = function WidgetBase(props: WidgetBaseProps): ReactElement {
  const {
    title,
    badgeLabel,
    badgeVariant,
    badgeDark = false,
    href,
    hasChildren = true,
    isHeaderLoading = false,
    isBodyLoading = false,
    children,
  } = props
  return (
    <StyledWrapper>
      <StyledHeader>
        <StyledHeaderLeft>
          <Text variant="body1Medium">{title}</Text>
          {isHeaderLoading ? (
            <Spinner size="30" />
          ) : badgeLabel != null ? (
            <Badge dark={badgeDark} variant={badgeVariant ? badgeVariant : 'neutral'} minWidth={'20px'}>
              {badgeLabel}
            </Badge>
          ) : undefined}
        </StyledHeaderLeft>
        {href ? (
          <Button
            href={href}
            target="_blank"
            variant="secondary"
            style="decorative"
            size="small"
            icon="arrow-up-right-regular"
          />
        ) : undefined}
      </StyledHeader>
      {isBodyLoading ? (
        <StyledEmptyState>
          <Spinner size="40" />
        </StyledEmptyState>
      ) : hasChildren ? (
        children ? (
          children
        ) : (
          <StyledEmptyState>
            <Text variant="body2">Aucune information pour le moment</Text>
          </StyledEmptyState>
        )
      ) : undefined}
    </StyledWrapper>
  )
}

const StyledWrapper = styled.div`
  padding: ${spacing[50]};
  border-radius: ${borderRadius[20]};
  border: ${borderStroke[20]} solid ${colorTokens['color-stroke-base']};
  background-color: ${colorTokens['color-bg-base-normal']};
  display: flex;
  flex-direction: column;
  gap: ${spacing[30]};
`

const StyledHeader = styled.div`
  display: flex;
  align-items: center;
  gap: ${spacing[40]};
  justify-content: space-between;
  width: 100%;
`

const StyledHeaderLeft = styled.div`
  display: flex;
  align-items: center;
  gap: ${spacing[40]};
`

const StyledEmptyState = styled.div`
  padding: ${spacing[60]} ${spacing[80]};
  text-align: center;
`
