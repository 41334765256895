import type { ParameterlessTranslationKey } from '@orus.eu/translations'
import { z } from 'zod'
import type { AbstractDimension } from '../dimension/abstract-dimension.js'
import type { SubscriptionBreadcrumbsPath } from './subscription-breadcrumbs.js'
import type { SubscriptionCondition } from './subscription-condition.js'
import type { SubscriptionFooterUiElement } from './subscription-footer-ui-element.js'
import type { SubscriptionUiElement, SubscriptionUiElementGroup } from './subscription-ui-element.js'

export const allSubscriptionStepIds = [
  'activity-search-main',
  'activity-category-search',
  'complementary-activities-selection',
  'rcph-activities-distribution',
  'general-information',
  'rcph-prequote-form',
  'es-rcph-prequote-form',
  'es-rcph-heavy-single-customer',
  'es-rcph-xp',
  'es-rcph-installs-security-material',
  'muta-personal-data',
  'muta-beneficiary-question',
  'muta-beneficiaries',
  'rcda-company-data',
  'rcda-experience',
  'rcda-previously-insured',
  'revenue',
  'mrpw-claims',
  'mrpw-occupation-status',
  'mrpw-assets-value',
  'mrph-rc-claims',
  'mrph-claims',
  'mrph-occupation-status',
  'mrph-surface',
  'mrph-assets-value',
  'mrph-formula',
  'mrph-option-pe',
  'mrph-siret-search',
  'mrpw-siret-search',
  'mrpw-confirm-address',
  'mrpw-add-address',
  'mrph-confirm-address',
  'mrph-add-address',
  'rcph-cyber-information',
  'mrpw-pizzeria-mgt-xp',
  'rcpa-prequote-form',
  'rcpa-persona-question',
  'rcpa-subsidiaries-question',
  'rcpa-add-subsidiaries',
  'rcpa-finalize-subsidiaries',
  'rcpa-cif-workforce',
  'rcpa-agents-question',
  'rcpa-add-agents',
  'personal-data',
  'quote',
  'coverage',
  'post-quote-rcda-revenue-data',
  'statement',
  'post-quote-siret-search',
  'post-quote-confirm-address',
  'post-quote-add-address',
  'additional-personal-data',
  'muta-bank-account',
  'confirm-documents-locking',
  'locked',
  'checkout',
] as const
export const subscriptionStepIdSchema = z.enum(allSubscriptionStepIds)
export type SubscriptionStepId = z.infer<typeof subscriptionStepIdSchema>

const subscriptionDetailTypes = ['guarantees'] as const

type SubscriptionDetailType = (typeof subscriptionDetailTypes)[number]

export function isSubscriptionDetailType(value: string): value is SubscriptionDetailType {
  return (subscriptionDetailTypes as readonly string[]).includes(value)
}

export type SubscriptionDetail = {
  type: SubscriptionDetailType
  id: string
}

export type SubscriptionUiStep = {
  id: SubscriptionStepId
  /**
   * A list of breadcrums of length 1 or 2 (UI design only supports this)
   */
  breadcrumbs: SubscriptionBreadcrumbsPath
  details?: { [key in SubscriptionDetailType]?: SubscriptionUiElementGroup }
  condition?: SubscriptionCondition
  title?: string
  titleKey?: ParameterlessTranslationKey
  hideInBackoffice?: boolean
  hideForExistingCustomers?: boolean
  hideInSelfOnboarding?: boolean
  hideForPartners?: boolean
  showProgress: boolean
  subtitleElement?: SubscriptionUiElement
  bodyElements: SubscriptionUiElementGroup
  footerElement?: SubscriptionFooterUiElement
  /**
   * A list of side elements.
   * No column will be added if set to undefined.
   * An empty column will be added if set to an empty array
   */
  sideElements?: SubscriptionUiElementGroup
  sideElementsMobileVariant?: 'default' | 'card' | 'hidden'
  /**
   * Optionally customize the next step label (default is "continue")
   */
  nextStepLabelKey?: ParameterlessTranslationKey
  /**
   * Optionnally show a floating go to bottom button
   */
  jumpToBottom?: boolean
  /**
   * Optionally add a tip before the next step label (default is "Continuer")
   */
  nextStepLabelTip?: string
  /**
   * Optionally add an ui element before the next step label
   */
  secondaryNavigationUiElement?: SubscriptionUiElement
  /**
   * These dimensions will not block completion of the step
   */
  partiallyUsedInputDimensions?: AbstractDimension[]
  /**
   * Optionally hide the footer element
   */
  hideFooterElement?: boolean
}
