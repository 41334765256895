import { createFileRoute } from '@tanstack/react-router'

import PlatformEphemeralJobsPage from '../../../components/pages/backoffice/platform/platform-ephemeral-jobs-page/platform-ephemeral-jobs-page'
import { PermissionChecker } from '../../../components/templates/permission-checker'

export const Route = createFileRoute('/bak/ephemeral-jobs')({
  component: () => (
    <PermissionChecker requiredPermissions={['job.runEphemeral']}>
      <PlatformEphemeralJobsPage />
    </PermissionChecker>
  ),
})
