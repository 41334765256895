import { css } from '@emotion/react'
import type { ReactElement, ReactNode } from 'react'
import { useDownloadFile } from '../../code-only/hooks'
import { secondaryColor } from '../../code-only/theme.js'
import { getFileDisplayName } from '../../code-only/util.js'
import { Button, type ButtonProps } from '../../components/button/button.js'
import { Spinner } from '../../components/spinner/index.js'
import { colorTokens } from '../../foundations/color-tokens.js'

import { Avatar } from '../../components'
import type { CompoundIconName } from '../../foundations/icon/names.js'

export type DownloadButtonProps = ButtonProps & {
  displayName?: string
  icon?: CompoundIconName
  iconColor?: string
  children?: ReactNode
  downloadInProgress?: boolean
}

export function DownloadButton(props: DownloadButtonProps): ReactElement {
  const { displayName, icon, iconColor, children, downloadInProgress, onClick, ...passedProps } = props

  const avatar = downloadInProgress ? (
    <Spinner size="30" strokeColor={iconColor ?? secondaryColor} />
  ) : (
    <Avatar icon={icon ?? 'file-lines-regular'} color={iconColor ?? secondaryColor} />
  )

  return (
    <Button
      {...passedProps}
      onClick={onClick}
      avatar={avatar}
      avatarPosition="left"
      css={css`
        color: ${colorTokens['color-text-base-secondary']};
      `}
    >
      {children}
    </Button>
  )
}

export type DownloadButtonWithHrefProps = Omit<ButtonProps, 'onClick' | 'href'> & {
  fileName: string
  displayName?: string
  href: string
  icon?: CompoundIconName
  iconColor?: string
  children?: ReactNode
  iconOnly?: boolean
}

export function DownloadButtonWithHref(props: DownloadButtonWithHrefProps): ReactElement {
  const { fileName, displayName, href, children, iconOnly, ...passedProps } = props

  const { downloadFile, downloadStatus } = useDownloadFile(href, fileName)

  const onClick = !props.disabled && downloadStatus !== 'downloading' && href ? downloadFile : undefined

  const effectiveDisplayName = iconOnly ? undefined : displayName || getFileDisplayName(fileName)

  return (
    <DownloadButton onClick={onClick} downloadInProgress={downloadStatus === 'downloading'} {...passedProps}>
      {children ?? effectiveDisplayName}
    </DownloadButton>
  )
}
