import { memo, type ReactNode } from 'react'

import { colorTokens } from '../../foundations/color-tokens.js'
import { Avatar } from '../avatar/avatar.js'
import { CalloutNotificationTemplate, type ActionButton } from './common/callout-notification-template.js'

export type CalloutVariant = 'content' | 'incentive'

export type CalloutProps = {
  variant: CalloutVariant
  title: string

  avatar: ReactNode
  actionButton?: ActionButton
  /** When provided, an info icon appears on the right */
  onInfoClick?: () => void

  children: ReactNode
  className?: string
}

export const Callout = memo<CalloutProps>(function Callout(props) {
  const { variant, title, avatar, actionButton, onInfoClick, children, className } = props

  const colors = colorsPerVariant[variant]

  return (
    <CalloutNotificationTemplate
      {...colors}
      leftAvatar={avatar}
      leftAvatarSize="40"
      rightAvatar={onInfoClick ? infoAvatar : undefined}
      onRightAvatarClick={onInfoClick}
      actionButton={actionButton}
      title={title}
      className={className}
    >
      {children}
    </CalloutNotificationTemplate>
  )
})

const infoAvatar = <Avatar icon="circle-info-solid" />

const colorsPerVariant: {
  [variant in CalloutVariant]: { backgroundColor: string; accentColor: string; textColor: string }
} = {
  content: {
    backgroundColor: colorTokens['color-bg-brand-light'],
    accentColor: colorTokens['color-fg-decorative'],
    textColor: colorTokens['color-text-decorative-main'],
  },
  incentive: {
    backgroundColor: colorTokens['color-bg-decorative-3'],
    accentColor: colorTokens['color-fg-decorative'],
    textColor: colorTokens['color-text-decorative-main'],
  },
}
