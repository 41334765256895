import styled from '@emotion/styled'
import { memo, useCallback, useMemo, type ReactNode } from 'react'

import { css } from '@emotion/react'
import {
  X0,
  amountToString,
  gt,
  rateGt,
  rateToPercentString,
  zeroAmount,
  type Amount,
  type FinancialRate,
} from '@orus.eu/amount'
import { globalMonthlyCommitmentPenaltyTenPercentRate, type ProductAttributes } from '@orus.eu/dimensions'
import type { OperatingZone } from '@orus.eu/operating-zone'
import { spacing } from '../../../../foundations/spacing-tokens.js'

import { Features, type Feature } from '../../../molecules/features/index.js'

import {
  projectAttributeInQuoteDetailBody,
  projectAttributeInQuoteDetailDialog,
  useProductsAttributesProjection,
} from '../../../../code-only/hooks/use-product-attributes-projection.js'
import { useScreenType } from '../../../../code-only/hooks/use-screen-type.js'
import { useTranslate } from '../../../../code-only/localization/language-context.js'
import { Avatar, AvatarDecorative } from '../../../../components/index.js'
import { Text } from '../../../../foundations/index.js'
import { Dialog } from '../../../../patterns/index.js'
import { TabBar } from '../../../molecules/index.js'
import { DetailLines } from '../detail-lines/index.js'

export type PricingDetailsDialogProps = {
  monthlyPrice: Amount
  yearlyPrice: Amount
  paymentRecurrence: 'monthly' | 'yearly'
  forbiddenMonthlyPayment: boolean
  commitment: 'monthly' | 'yearly'
  yearlyCommitmentHasDiscount: boolean
  /** The Orus discount */
  discount: FinancialRate
  /** The first period price. E.g., when paying monthly, the first month might include terrorism tax */
  firstPeriodPrice?: Amount
  /** The yearly terrorism tax. If it equals 0, it won't be shown */
  yearlyTerrorismTaxPrice?: Amount
  productsAttributes: ProductAttributes[]
  partnerApplicationPrice?: Amount

  onClose: () => void
  /**
   * When provided, a tab bar to select change the payment recurrence is added
   */
  onPaymentRecurrenceChange?: (paymentRecurrence: 'monthly' | 'yearly') => void
  /**
   * An arbitrary react node that can be inserted at the end of the dialog
   */
  footer?: ReactNode
  isPartner?: boolean
  operatingZone: OperatingZone
  onEditQuote?: () => void
  useIllustration: boolean
}

export const PricingDetailsDialog = memo<PricingDetailsDialogProps>(function PricingDetailsDialog(props) {
  const translate = useTranslate()
  const {
    monthlyPrice,
    yearlyPrice,
    paymentRecurrence,
    forbiddenMonthlyPayment,
    commitment,
    yearlyCommitmentHasDiscount,
    discount,
    firstPeriodPrice,
    yearlyTerrorismTaxPrice,
    productsAttributes,
    onClose,
    onPaymentRecurrenceChange,
    footer,
    isPartner,
    partnerApplicationPrice,
    onEditQuote,
    useIllustration,
  } = props

  const screenType = useScreenType()

  const yearlyDiscountEnabled =
    yearlyCommitmentHasDiscount && paymentRecurrence === 'yearly' && !forbiddenMonthlyPayment
  const hasTerrorismTax = yearlyTerrorismTaxPrice && gt(yearlyTerrorismTaxPrice, zeroAmount)
  const formattedCurrentRecurrencePrice = amountToString(paymentRecurrence === 'monthly' ? monthlyPrice : yearlyPrice, {
    addCurrency: true,
  })
  const otherRecurrencePrice = paymentRecurrence === 'monthly' ? yearlyPrice : monthlyPrice
  const otherRecurrence = paymentRecurrence === 'monthly' ? 'yearly' : 'monthly'
  const tabs = useMemo(() => {
    return {
      monthly: translate('monthly'),
      yearly: `${translate('yearly')} ${
        yearlyCommitmentHasDiscount ? ` -${rateToPercentString(globalMonthlyCommitmentPenaltyTenPercentRate, 0)}` : ''
      }`,
    }
  }, [yearlyCommitmentHasDiscount, translate])

  const handleTabChange = useCallback(
    (tabId: string) => {
      if ((tabId !== 'monthly' && tabId !== 'yearly') || !onPaymentRecurrenceChange) return
      onPaymentRecurrenceChange(tabId)
    },
    [onPaymentRecurrenceChange],
  )
  const attributesProjectedInHeader = useProductsAttributesProjection(
    productsAttributes,
    projectAttributeInQuoteDetailDialog,
  )

  const attributesProjectedInBody = useProductsAttributesProjection(
    productsAttributes,
    projectAttributeInQuoteDetailBody,
  )

  const features = useMemo<Feature[]>(() => {
    return [
      {
        title: translate('subscription_funnel_payment_details'),
        content: (
          <>
            {translate(`subscription_funnel_payment_details_recurrence_${paymentRecurrence}`)}
            <br />
            <br />
            {formattedCurrentRecurrencePrice} {translate(`per_time_unit_${paymentRecurrence}`)}
            {!forbiddenMonthlyPayment && (
              <>
                <br />
                {translate(`or_amount_per_time_unit_${otherRecurrence}`, { amount: otherRecurrencePrice })}
              </>
            )}
          </>
        ),
      },
      ...(firstPeriodPrice
        ? [
            {
              title: translate(`subscription_funnel_payment_detail_first_payement_${paymentRecurrence}`),
              content: [
                amountToString(firstPeriodPrice, { addCurrency: true }),
                partnerApplicationPrice && gt(partnerApplicationPrice, zeroAmount)
                  ? translate('subscription_funnel_payment_detail_including_setup_fee', {
                      amount: partnerApplicationPrice,
                    })
                  : undefined,
              ]
                .filter(Boolean)
                .join(', '),
            },
          ]
        : []),
      {
        title: translate('subscription_funnel_payment_detail_commitment'),
        content: translate(
          commitment === 'yearly'
            ? 'subscription_funnel_with_commitment_title'
            : 'subscription_funnel_without_commitment_title',
        ),
      },
      ...(yearlyDiscountEnabled || rateGt(discount, X0)
        ? [
            {
              title: translate('subscription_funnel_payment_detail_advantage'),
              content: [
                rateGt(discount, X0) ? `-${rateToPercentString(discount, 0)} de réduction code promotion` : undefined,
                yearlyDiscountEnabled
                  ? translate('subscription_funnel_payment_detail_advantage_discount', {
                      discountPercent: rateToPercentString(globalMonthlyCommitmentPenaltyTenPercentRate, 0),
                    })
                  : undefined,
              ]
                .filter(Boolean)
                .join(', '),
            },
          ]
        : []),
      {
        title: translate('subscription_funnel_payment_detail_taxes'),
        content: [
          ...attributesProjectedInBody.flatMap(
            ({ productName, attributes }) =>
              `${attributes.map((attribute) => attribute.label)}${
                attributesProjectedInBody.length > 1 ? `(${productName})` : ''
              }`,
          ),
          props.operatingZone === 'fr' ? translate('subscription_funnel_payment_detail_no_vat') : undefined,
          hasTerrorismTax
            ? `taxe attentat annuelle de ${amountToString(yearlyTerrorismTaxPrice, { addCurrency: true })}`
            : undefined,
        ]
          .filter(Boolean)
          .join(', '),
      },
    ]
  }, [
    paymentRecurrence,
    formattedCurrentRecurrencePrice,
    forbiddenMonthlyPayment,
    otherRecurrencePrice,
    firstPeriodPrice,
    commitment,
    yearlyDiscountEnabled,
    discount,
    partnerApplicationPrice,
    hasTerrorismTax,
    yearlyTerrorismTaxPrice,
    translate,
    otherRecurrence,
    attributesProjectedInBody,
    props.operatingZone,
  ])

  return (
    <Dialog
      onClose={onClose}
      title={translate('subscription_funnel_quote_summary')}
      avatar={
        useIllustration ? (
          <Avatar illustration="calculator_big" size="60" />
        ) : (
          <AvatarDecorative icon="list-check-regular" backgroundColor="sky" size="60" />
        )
      }
      style="base"
      size="large"
      primaryActionLabel={footer ? translate('subscription_funnel_edit_quote_cta') : undefined}
      onPrimaryAction={footer ? onEditQuote : undefined}
      secondaryActionLabel={footer || screenType === 'mobile' ? translate('close') : undefined}
      onSecondaryAction={onClose}
    >
      <Container>
        <div>
          <Text variant="subtitle">
            {translate(isPartner ? 'subscription_funnel_the_contribution' : 'subscription_funnel_your_contribution')}
          </Text>
          <ContainerWithTopMargin>
            {onPaymentRecurrenceChange ? (
              <TabBar
                fullWidth={screenType === 'mobile'}
                size="large"
                tabs={tabs}
                selectedTabId={paymentRecurrence}
                onTabChange={handleTabChange}
                css={css`
                  margin-bottom: ${spacing[30]};
                `}
              />
            ) : (
              <></>
            )}

            <Features features={features} />
          </ContainerWithTopMargin>
        </div>
        <div>
          {attributesProjectedInHeader.length > 0 ? (
            <ContainerWithBottomMargin>
              <Text variant="subtitle">{translate(isPartner ? 'the_formula' : 'your_formula')}</Text>
              <DetailLines projectedProductsAttributes={attributesProjectedInHeader} />
            </ContainerWithBottomMargin>
          ) : (
            <></>
          )}
        </div>

        <div>{footer}</div>
      </Container>
    </Dialog>
  )
})

const ContainerWithTopMargin = styled.div`
  margin-top: ${spacing[50]};
`

const ContainerWithBottomMargin = styled.div`
  margin-bottom: ${spacing[50]};
`

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${spacing[70]};
  width: 100%;
  margin-bottom: ${spacing[50]};
`
