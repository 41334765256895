import styled from '@emotion/styled'
import { memo, type ReactElement, type ReactNode } from 'react'
import type { Select, Switch, TextField } from '../components'
import { spacing } from '../foundations/spacing-tokens'

export type FormInput = ReactElement<typeof TextField> | ReactElement<typeof Switch> | ReactElement<typeof Select>

export type FormRowProps = {
  children: ReactNode
  isSingleMini?: boolean
  alignItems?: string
}

export const FormRow = memo<FormRowProps>(function FormRow(props: FormRowProps) {
  const { children, isSingleMini = false, alignItems } = props

  return (
    <FormRowContainer isSingleMini={isSingleMini} alignItems={alignItems}>
      {children}
    </FormRowContainer>
  )
})

const FormRowContainer = styled.div<{ isSingleMini?: boolean; alignItems?: string }>`
  --gap: ${spacing[50]};
  --element-min-width: 176px;

  display: flex;
  flex-flow: row wrap;
  align-items: ${({ alignItems }) => alignItems ?? 'flex-start'};
  gap: var(--gap);
  flex-grow: 1;

  & > * {
    min-width: var(--element-min-width);
    max-width: ${({ isSingleMini }) => (isSingleMini ? '50%' : '100%')};
    padding-inline-end: ${({ isSingleMini }) => (isSingleMini ? 'var(--gap)' : '0')};
    flex: 1 1 var(--element-min-width);
  }
`
