import { createFileRoute } from '@tanstack/react-router'

import PaymentMethodUpdate from '../../../../components/pages/payment-method-update'
import { PermissionChecker } from '../../../../components/templates/permission-checker'

export const Route = createFileRoute('/_customer/payment-methods/update')({
  component: () => (
    <PermissionChecker requiredPermissions={['paymentAccount.update']}>
      <PaymentMethodUpdate />
    </PermissionChecker>
  ),
})
