export type IconSize =
  | '5'
  | '10'
  | '20'
  | '30'
  | '40'
  | '50'
  | '60'
  | '70'
  | '80'
  | '90'
  | '100'
  | '110'
  | '120'
  | '130'
  | '140'

export const PIXELS_PER_ICON_SIZE: Record<IconSize, string> = {
  '5': '8px',
  '10': '12px',
  '20': '14px',
  '30': '16px',
  '40': '24px',
  '50': '32px',
  '60': '48px',
  '70': '64px',
  '80': '80px',
  '90': '96px',
  '100': '112px',
  '110': '128px',
  '120': '144px',
  '130': '160px',
  '140': '208px',
}
