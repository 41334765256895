import { css } from '@emotion/react'
import { amountToString, newAmount } from '@orus.eu/amount'
import { useCallback, useState } from 'react'
import { useScreenType } from '../../../../code-only/hooks/use-screen-type.js'
import { useTranslate } from '../../../../code-only/localization/language-context.js'
import { typedMemo } from '../../../../code-only/util.js'
import { Avatar, Tooltip } from '../../../../components/index.js'
import { spacing } from '../../../../foundations/spacing-tokens.js'
import { Text } from '../../../../foundations/text'
import { Slider } from '../../../../legacy/atoms/index.js'

type LoiSliderBlockProps<LoiValue extends number> = {
  initialGuaranteeLimit: LoiValue
  allowedValues: readonly LoiValue[]
  onChange: (value: LoiValue) => void
  className?: string
}

export const LoiSliderBlock = typedMemo(function LoiSliderBlock<LoiValue extends number>({
  initialGuaranteeLimit,
  allowedValues,
  onChange,
  className,
}: LoiSliderBlockProps<LoiValue>) {
  const translate = useTranslate()
  const screenType = useScreenType()

  const [guaranteeLimit, setGuaranteeLimit] = useState(initialGuaranteeLimit)

  const handleSliderChange = useCallback(
    (guaranteeLimitIndex: number) => {
      const guaranteeLimit = allowedValues[guaranteeLimitIndex]
      if (guaranteeLimit != undefined) {
        setGuaranteeLimit(guaranteeLimit)
      }
    },
    [allowedValues],
  )

  const handleSliderCommitedChange = useCallback(
    (guaranteeLimitIndex: number) => {
      const guaranteeLimit = allowedValues[guaranteeLimitIndex]
      if (guaranteeLimit != undefined) {
        onChange(guaranteeLimit)
      }
    },
    [allowedValues, onChange],
  )

  const fieldName = translate('limit_of_indemnity_alternative_wording')
  return (
    <div
      css={css`
        display: flex;
        flex-direction: column;
        gap: ${spacing[50]};
      `}
      className={className}
    >
      <div
        css={css`
          display: flex;
          flex-direction: row;
          gap: ${spacing[30]};
          justify-content: space-between;
          align-items: center;
        `}
      >
        <div>
          <Text variant={'body1Medium'}>{fieldName}</Text>
          <Text variant={'body2'}>{translate('limit_of_indemnity_input_purpose')}</Text>
        </div>
        <Tooltip title={translate('limit_of_indemnity_tooltip')}>
          <Avatar variant="full" icon="circle-info-regular" size="30" />
        </Tooltip>
      </div>
      <div
        css={css`
          display: flex;
          flex-direction: column;
          align-items: center;
        `}
      >
        <Slider
          min={0}
          max={allowedValues.length - 1}
          step={1}
          value={allowedValues.indexOf(guaranteeLimit)}
          onChange={handleSliderChange}
          onChangeCommitted={handleSliderCommitedChange}
          marks
          aria-label={fieldName}
          css={css`
            width: ${screenType === 'desktop' ? '400px' : '100%'};
          `}
        />

        <Text
          variant="h3"
          css={css`
            margin-top: ${spacing[50]};
            text-align: center;
          `}
        >
          {amountToString(newAmount(guaranteeLimit.toString()), { displayDecimals: false, addCurrency: true })}
        </Text>
      </div>
    </div>
  )
})
