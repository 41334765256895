import {
  financialRateToNumber,
  financialRateZodSchema,
  newFinancialRate,
  ratesEqual,
  type FinancialRate,
} from '@orus.eu/amount'
import { DOCUMENTATION_LANGUAGE, m, type FieldSpecification, type ValueDocumentation } from '@orus.eu/message'
import { success, type Result } from '@orus.eu/result'
import { z } from 'zod'
import {
  AbstractDimension,
  dimensionValidationFailure,
  getParmeterlessKeyTranslations,
  type DimensionTrackingMessageFieldAdapter,
  type DimensionValidationProblem,
} from './abstract-dimension'
import { BooleanDimension } from './boolean-dimension'

// the default value is not a valid rate, it's just a way to indicate that the installment fee rate is set by default
export const installmentFeeRateCodes = ['discounted', 'standard', 'advanced'] as const

export type InstallmentFeeRateCode = (typeof installmentFeeRateCodes)[number]
export const installmentFeeRateCodeSchema = z.enum(installmentFeeRateCodes)

export const installmentFeeRateZodSchema = z.object({
  code: installmentFeeRateCodeSchema,
  rate: financialRateZodSchema,
})

const DISCOUNTED_RATE = newFinancialRate('0.05')
const STANDARD_RATE = newFinancialRate('0.1')
const ADVANCED_RATE = newFinancialRate('0.15')

export const installmentFeeRate: Record<InstallmentFeeRateCode, { rate: FinancialRate }> = {
  discounted: { rate: DISCOUNTED_RATE },
  standard: { rate: STANDARD_RATE },
  advanced: { rate: ADVANCED_RATE },
}

export const installmentFeeLabels: { [key in InstallmentFeeRateCode]: string } = {
  discounted: '+ 5%',
  standard: '+ 10%',
  advanced: '+ 15%',
}

export const installmentFeeRateValues = Object.keys(installmentFeeRate) as string[]

export type InstallmentFeeRates = z.infer<typeof installmentFeeRateZodSchema>

export const INSTALLMENT_FEES_DISCOUNTED = {
  code: 'discounted',
  rate: DISCOUNTED_RATE,
} as const

export const INSTALLMENT_FEES_STANDARD = {
  code: 'standard',
  rate: STANDARD_RATE,
} as const

export const INSTALLMENT_FEES_ADVANCED = {
  code: 'advanced',
  rate: ADVANCED_RATE,
} as const

export const allInstallmentFeeRate: readonly InstallmentFeeRates[] = Object.entries(installmentFeeRate).map(
  ([code, def]) => ({
    code,
    rate: def.rate,
  }),
) as readonly InstallmentFeeRates[]

export function getInstallmentFeeRateByCode(code: string): InstallmentFeeRates | undefined {
  return allInstallmentFeeRate.find((installmentFeeRate) => installmentFeeRate.code === code) ?? undefined
}

export function getInstallmentFeeRateByRate(rate: FinancialRate): InstallmentFeeRates | undefined {
  return allInstallmentFeeRate.find((installmentFeeRate) => ratesEqual(installmentFeeRate.rate, rate)) ?? undefined
}

export abstract class BaseInstallmentFeeRateDimension<
  NAME extends string,
  VALUE extends InstallmentFeeRates | 'none' | 'default',
> extends AbstractDimension<NAME, VALUE> {
  override readonly placeholders = getParmeterlessKeyTranslations('placeholder_no_fee')

  override validateData(value: unknown): Result<VALUE, DimensionValidationProblem> {
    if (value === 'none' || value === 'default') return success(value as VALUE)

    const parsedResult = installmentFeeRateZodSchema.safeParse(value)

    if (!parsedResult.success) {
      return dimensionValidationFailure(`Field ${this.name} is not a valid installment fees rate`)
    }

    const installmentFeeRateResult = parsedResult.data

    const { code, rate } = installmentFeeRateResult

    const installmentFeeRateDefinition = installmentFeeRate[code as InstallmentFeeRateCode]

    if (!installmentFeeRateDefinition || !ratesEqual(installmentFeeRateDefinition.rate, rate)) {
      return dimensionValidationFailure(`Field ${this.name} is not a valid discount`)
    }

    return success(installmentFeeRateResult as VALUE)
  }

  override readonly trackingMessageFieldAdapter: DimensionTrackingMessageFieldAdapter = {
    getTrackingFieldSpecification: (): FieldSpecification => {
      const valueDocumentation: ValueDocumentation<number> = {
        title: this.displayNames[DOCUMENTATION_LANGUAGE],
      }
      if (this.hints) {
        valueDocumentation.detailedExplanationMd = this.hints[DOCUMENTATION_LANGUAGE]
      }
      return m.nullable(m.number(valueDocumentation))
    },
    convertStateValueToTrackingValue: (stateValue: VALUE | null | undefined): number | null => {
      if (!stateValue || stateValue === 'none' || stateValue === 'default') return null
      return financialRateToNumber(stateValue.rate)
    },
  }
}

export class InstallmentFeeRateWithDefaultDimension<NAME extends string> extends BaseInstallmentFeeRateDimension<
  NAME,
  InstallmentFeeRates | 'none' | 'default'
> {}

export class InstallmentFeeRateDimension<NAME extends string> extends BaseInstallmentFeeRateDimension<
  NAME,
  InstallmentFeeRates | 'none'
> {
  override validateData(value: unknown): Result<InstallmentFeeRates | 'none', DimensionValidationProblem> {
    if (value === 'default')
      return dimensionValidationFailure(
        `"default" is used for retroactive subscriptions. Field ${this.name} is not a valid installment fees rate`,
      )

    return super.validateData(value)
  }
}

export const installmentFeeRateDimension = new InstallmentFeeRateDimension({
  name: 'installmentFeeRate',
  displayValues: { name: 'Frais de fractionnement RCDA' },
  tags: ['RCDA'],
} as const)

export const defaultInstallmentFeeRateDimension = new InstallmentFeeRateDimension({
  name: 'defaultInstallmentFeeRate',
  displayValues: { name: 'Frais de fractionnement RCDA par défaut' },
  tags: ['RCDA'],
} as const)

export const manuallySetInstallmentFeeRateDimension = new InstallmentFeeRateWithDefaultDimension({
  name: 'manuallySetInstallmentFeeRate',
  displayValues: { name: 'Frais de fractionnement RCDA saisis' },
  tags: ['RCDA'],
} as const)

export const isInstallmentFeeRateEditableDimension = new BooleanDimension({
  name: 'isInstallmentFeeRateEditable',
  displayValues: { name: 'Frais de fractionnement RCDA saisissable' },
  tags: ['RCDA'],
} as const)

export const rcphInstallmentFeeRateDimension = new InstallmentFeeRateDimension({
  name: 'rcphInstallmentFeeRate',
  displayValues: { name: 'Frais de fractionnement RCPH' },
  tags: ['RCPH'],
} as const)
