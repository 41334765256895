import FaviconDark from '../code-only/assets/logo/Favicon-Dark.svg'
import FaviconLight from '../code-only/assets/logo/Favicon-Light.svg'
import SubmarkDark from '../code-only/assets/logo/Logo-Submark-Dark.png'
import SubmarkWhite from '../code-only/assets/logo/Logo-Submark-White.png'
import WordmarkDark from '../code-only/assets/logo/Logo-Wordmark-Dark.png'
import WordmarkWhite from '../code-only/assets/logo/Logo-Wordmark-White.png'

export const logo = {
  SubmarkDark,
  SubmarkWhite,
  WordmarkDark,
  WordmarkWhite,
  FaviconLight,
  FaviconDark,
} as const
