export type UseUrlFileBytesOptions = {
  autoLoad?: boolean
}

import { ensureError } from '@orus.eu/error'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useCrash } from '../../../../code-only/hooks'
import { downloadFromOrus } from '../../../../code-only/lib'

/**
 * Allows lazily accessing data at URL as an Uint8Array
 *
 * The data is not downloaded until load() is called, and after it's been called, it will be updated if the url changes.
 *
 */

export function useUrlFileBytes(
  url: string,
  options: UseUrlFileBytesOptions = {},
): {
  /**
   * The data obtained from the url, or undefined if load() has not yet been called or if we are still
   * downloading the data
   */
  data: Uint8Array | undefined
  /**
   * Triggers the initial loading, and does nothing upon successive calls
   */
  load: () => void
} {
  const crash = useCrash()
  const [state, setState] = useState<'idle' | 'loading' | { data: Uint8Array }>(options.autoLoad ? 'loading' : 'idle')

  const idle = state === 'idle'

  const load = useCallback(() => {
    if (!idle) return

    setState('loading')
  }, [idle])

  const urlToLoad = state === 'idle' ? undefined : url

  useEffect(() => {
    if (!urlToLoad) return

    let cancelled = false
    getFileBytesArray(urlToLoad).then(
      (arrayBuffer) => {
        if (cancelled) return

        setState({ data: new Uint8Array(arrayBuffer) })
      },
      (err: unknown) => {
        if (cancelled) return

        crash(ensureError(err))
      },
    )

    return () => {
      cancelled = true
    }
  }, [crash, urlToLoad])

  const data = state === 'idle' || state === 'loading' ? undefined : state.data

  return useMemo(() => ({ load, data }), [load, data])
}

export async function getFileBytesArray(url: string): Promise<ArrayBuffer> {
  const blob = await downloadFromOrus(url)
  return blob.arrayBuffer()
}
