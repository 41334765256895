import { css } from '@emotion/react'
import { memo } from 'react'
import { defaultProgressBarHeight } from '../../components/progressbar/ProgressBar.util'
import { colorTokens } from '../../foundations'

export const HeaderSeparator = memo(function HeaderSeparator() {
  return (
    <hr
      css={css`
        height: ${separatorHeight}px;
        border-top: ${lineThickness}px solid ${colorTokens['color-stroke-base']};
        border-bottom: none;
        border-right: none;
        border-left: none;
        margin: 0;
      `}
    />
  )
})

const lineThickness = 1
const separatorHeight = defaultProgressBarHeight
