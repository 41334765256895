import { createFileRoute } from '@tanstack/react-router'

import PlatformTroubleshootingSessionPage from '../../../../../components/pages/backoffice/platform/platform-troubleshooting-session'
import { PermissionChecker } from '../../../../../components/templates/permission-checker'

export const Route = createFileRoute('/bak/troubleshooting/session/$sessionId')({
  component: () => (
    <PermissionChecker requiredPermissions={['tech.configurations']}>
      <PlatformTroubleshootingSessionPage />
    </PermissionChecker>
  ),
})
