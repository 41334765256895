import { createFileRoute } from '@tanstack/react-router'

import PartnerSearchPage from '../../../../components/pages/backoffice/partners/partners-search-page'
import { PermissionChecker } from '../../../../components/templates/permission-checker'

export const Route = createFileRoute('/partner/$organization/search')({
  component: () => (
    <PermissionChecker requiredPermissions={['search']}>
      <PartnerSearchPage />
    </PermissionChecker>
  ),
})
