import { type } from 'arktype'
import { AbstractDimension, BaseDimension } from './abstract-dimension.js'

export class StaticFilesDimension<NAME extends string> extends AbstractDimension<NAME, StaticFileDescription[]> {}

/**
 * Name of the files publicly exposed as static files. Enumerating them explicitly is a bit annoying but better
 * than a potential security flaw
 */

export const staticFileIds = [
  'restaurants-general-terms',
  'restaurants-ipid',
  'restaurants-v2-general-terms',
  'restaurants-v2-ipid',
  'rc-pro-shared-hiscox-broker-information',
  'rc-pro-shared-hiscox-data-policy',
  'rc-pro-shared-risk-carrier-information',
  'rc-pro-mac-ipid',
  'rc-pro-man-ipid',
  'rc-pro-media-ipid',
  'rc-pro-misc-ipid',
  'rc-pro-miscbie-ipid',
  'rc-pro-tech-ipid',
  'rc-pro-mac-general-terms',
  'rc-pro-man-general-terms',
  'rc-pro-media-general-terms',
  'rc-pro-misc-general-terms',
  'rc-pro-miscbie-general-terms',
  'rc-pro-tech-general-terms',
  'mrph-general-terms',
  'mrph-ipid',
  'mrpw-general-terms',
  'mrpw-ipid',
  'mrpw-fiche-info-distributueur',
  'rcph-mac-general-terms',
  'rcph-man-general-terms',
  'rcph-media-general-terms',
  'rcph-misc-general-terms',
  'rcph-secu-general-terms',
  'rcph-secu-ipid',
  'rcph-tag-general-terms',
  'rcph-tag-ipid',
  'rcph-ecom-general-terms',
  'rcph-ecom-ipid',
  'rcph-tech-general-terms',
  'rcph-mrp-ipid',
  'rcph-mrp-general-terms',
  'muta-ipid',
  'muta-esse-general-terms',
  'muta-priv-general-terms',
  'muta-statuts-asso',
  'muta-ESSE-example',
  'muta-PRIV-1-example',
  'muta-PRIV-2-example',
  'muta-PRIV-3-example',
  'muta-PRIV-4-example',
  'muta-PRIV-5-example',
  'muta-PRIV-6-example',
  'muta-PRIV-7-example',
  'muta-PRIV-8-example',
  'muta-ESSE-1-example',
  'muta-ESSE-2-example',
  'muta-ESSE-3-example',
  'muta-ESSE-4-example',
  'rcda-activity-nomenclature',
  'rcda-activity-nomenclature-v2',
  'rcda-general-terms',
  'rcda-ipid',
  'rc-pro-mac-general-terms-v3',
  'rc-pro-ecom-general-terms-v3',
  'rc-pro-man-general-terms-v3',
  'rc-pro-media-general-terms-v3',
  'rc-pro-misc-general-terms-v3',
  'rc-pro-tech-general-terms-v3',
  'rc-pro-secu-general-terms-v3',
  'rc-pro-tag-general-terms-v3',

  'es-rcph-tech-general-terms',
  'es-rcph-mac-general-terms',
  'es-rcph-man-general-terms',
  'es-rcph-media-general-terms',
  'es-rcph-misc-general-terms',
  'es-rcph-etg-eco-eno-general-terms',
  'es-rcph-gest-general-terms',

  'es-rcph-mac-no-gl-general-terms',
  'es-rcph-man-no-gl-general-terms',
  'es-rcph-media-no-gl-general-terms',
  'es-rcph-misc-no-gl-general-terms',

  'es-rcph-etg-eco-eno-ipid-liability',

  'es-rcph-mac-ipid',
  'es-rcph-man-ipid',
  'es-rcph-media-ipid',
  'es-rcph-misc-ipid',
  'es-rcph-gest-ipid',

  'es-rcph-tech-ipid-liability',
  'es-rcph-mac-ipid-liability',
  'es-rcph-man-ipid-liability',
  'es-rcph-media-ipid-liability',
  'es-rcph-misc-ipid-liability',
  'es-rcph-gest-ipid-liability',
  'es-rcph-nota-informativa-mediadior',
  'rcpa-agent-co-immo-general-terms',
  'rcpa-agent-co-immo-ipid',
  'rcpa-agent-sportif-general-terms',
  'rcpa-agent-sportif-ipid',
  'rcpa-finance-general-terms',
  'rcpa-finance-ipid',
] as const

const StaticFileId = type.enumerated(...staticFileIds)

export const staticFileIdsCategory: { [key in StaticFileId]: StaticFileCategoryId } = {
  'mrph-general-terms': 'general-terms',
  'mrph-ipid': 'ipid',
  'mrpw-general-terms': 'general-terms',
  'mrpw-ipid': 'ipid',
  'mrpw-fiche-info-distributueur': 'risk-carrier-document',
  'rc-pro-mac-general-terms': 'general-terms',
  'rc-pro-mac-ipid': 'ipid',
  'rc-pro-man-general-terms': 'general-terms',
  'rc-pro-man-ipid': 'ipid',
  'rc-pro-media-general-terms': 'general-terms',
  'rc-pro-media-ipid': 'ipid',
  'rc-pro-misc-general-terms': 'general-terms',
  'rc-pro-misc-ipid': 'ipid',
  'rc-pro-miscbie-general-terms': 'general-terms',
  'rc-pro-miscbie-ipid': 'ipid',
  'rc-pro-tech-general-terms': 'general-terms',
  'rc-pro-tech-ipid': 'ipid',
  'rcph-ecom-general-terms': 'general-terms',
  'rcph-ecom-ipid': 'ipid',
  'rcph-mrp-ipid': 'ipid',
  'rcph-mac-general-terms': 'general-terms',
  'rcph-media-general-terms': 'general-terms',
  'rcph-man-general-terms': 'general-terms',
  'rcph-misc-general-terms': 'general-terms',
  'rcph-secu-general-terms': 'general-terms',
  'rcph-secu-ipid': 'ipid',
  'rcph-tag-general-terms': 'general-terms',
  'rcph-tag-ipid': 'ipid',
  'rcph-tech-general-terms': 'general-terms',
  'restaurants-general-terms': 'general-terms',
  'restaurants-ipid': 'ipid',
  'restaurants-v2-general-terms': 'general-terms',
  'restaurants-v2-ipid': 'ipid',
  'rc-pro-shared-hiscox-broker-information': 'risk-carrier-document',
  'rc-pro-shared-hiscox-data-policy': 'risk-carrier-document',
  'rc-pro-shared-risk-carrier-information': 'risk-carrier-document',
  'muta-ipid': 'ipid',
  'muta-esse-general-terms': 'general-terms',
  'muta-priv-general-terms': 'general-terms',
  'muta-statuts-asso': 'risk-carrier-document',
  'muta-ESSE-example': 'risk-carrier-document',
  'muta-PRIV-1-example': 'risk-carrier-document',
  'muta-PRIV-2-example': 'risk-carrier-document',
  'muta-PRIV-3-example': 'risk-carrier-document',
  'muta-PRIV-4-example': 'risk-carrier-document',
  'muta-PRIV-5-example': 'risk-carrier-document',
  'muta-PRIV-6-example': 'risk-carrier-document',
  'muta-PRIV-7-example': 'risk-carrier-document',
  'muta-PRIV-8-example': 'risk-carrier-document',
  'muta-ESSE-1-example': 'risk-carrier-document',
  'muta-ESSE-2-example': 'risk-carrier-document',
  'muta-ESSE-3-example': 'risk-carrier-document',
  'muta-ESSE-4-example': 'risk-carrier-document',
  'rcda-activity-nomenclature': 'risk-carrier-document',
  'rcda-activity-nomenclature-v2': 'risk-carrier-document',
  'rcda-general-terms': 'general-terms',
  'rcda-ipid': 'ipid',
  'rcph-mrp-general-terms': 'general-terms',
  'rc-pro-mac-general-terms-v3': 'general-terms',
  'rc-pro-ecom-general-terms-v3': 'general-terms',
  'rc-pro-man-general-terms-v3': 'general-terms',
  'rc-pro-media-general-terms-v3': 'general-terms',
  'rc-pro-misc-general-terms-v3': 'general-terms',
  'rc-pro-tech-general-terms-v3': 'general-terms',
  'rc-pro-secu-general-terms-v3': 'general-terms',
  'rc-pro-tag-general-terms-v3': 'general-terms',

  'es-rcph-tech-general-terms': 'general-terms',
  'es-rcph-mac-general-terms': 'general-terms',
  'es-rcph-man-general-terms': 'general-terms',
  'es-rcph-media-general-terms': 'general-terms',
  'es-rcph-misc-general-terms': 'general-terms',
  'es-rcph-etg-eco-eno-general-terms': 'general-terms',
  'es-rcph-gest-general-terms': 'general-terms',

  'es-rcph-mac-no-gl-general-terms': 'general-terms',
  'es-rcph-man-no-gl-general-terms': 'general-terms',
  'es-rcph-media-no-gl-general-terms': 'general-terms',
  'es-rcph-misc-no-gl-general-terms': 'general-terms',

  'es-rcph-etg-eco-eno-ipid-liability': 'ipid-liability',

  'es-rcph-mac-ipid': 'ipid',
  'es-rcph-man-ipid': 'ipid',
  'es-rcph-media-ipid': 'ipid',
  'es-rcph-misc-ipid': 'ipid',
  'es-rcph-gest-ipid': 'ipid',

  'es-rcph-tech-ipid-liability': 'ipid-liability',
  'es-rcph-mac-ipid-liability': 'ipid-liability',
  'es-rcph-man-ipid-liability': 'ipid-liability',
  'es-rcph-media-ipid-liability': 'ipid-liability',
  'es-rcph-misc-ipid-liability': 'ipid-liability',
  'es-rcph-gest-ipid-liability': 'ipid-liability',
  'es-rcph-nota-informativa-mediadior': 'risk-carrier-document',
  'rcpa-agent-co-immo-general-terms': 'general-terms',
  'rcpa-agent-co-immo-ipid': 'ipid',
  'rcpa-agent-sportif-general-terms': 'general-terms',
  'rcpa-agent-sportif-ipid': 'ipid',
  'rcpa-finance-general-terms': 'general-terms',
  'rcpa-finance-ipid': 'ipid',
}

export const staticFileCategoryIds = ['general-terms', 'ipid', 'ipid-liability', 'risk-carrier-document'] as const

export type StaticFileCategoryId = (typeof staticFileCategoryIds)[number]

export type StaticFileId = (typeof staticFileIds)[number]

export function isStaticFileId(value: unknown): value is StaticFileId {
  return (staticFileIds as readonly unknown[]).includes(value)
}

export const StaticFileDataSchema = type({
  type: '"static"',
  staticFileId: StaticFileId,
})

export type StaticFileData = {
  type: 'static'
  readonly staticFileId: StaticFileId
}

// add other types as needed

export type StaticFileDescription = {
  data: StaticFileData
  fileName: string
  mimeType: string
}

export type StaticFilesGroup = {
  id: StaticFileCategoryId
  hint: string
  documents: StaticFileDescription[]
}

export const generalTermsGroupsDimension = new BaseDimension<'generalTermsGroups', StaticFilesGroup[]>({
  name: 'generalTermsGroups',
  displayValues: { name: 'Conditions générales par catégories' },
} as const)
