import { createFileRoute } from '@tanstack/react-router'
import DetailedSheetPage from '../../../../../components/pages/backoffice/detailed-sheet-page/DetailedSheetPage'
import { PermissionChecker } from '../../../../../components/templates/permission-checker'

export const Route = createFileRoute('/bak/v2-pending-subscriptions/$subscriptionId/detailed-sheet')({
  component: () => (
    <PermissionChecker requiredPermissions={['subscription.read']}>
      <DetailedSheetPage />
    </PermissionChecker>
  ),
})
