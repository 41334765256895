import { type Amount, newAmount } from '@orus.eu/amount'
import { type Result, failure, isSuccess, success } from '@orus.eu/result'
export const ALL_PI_PRODUCTS = ['MAC', 'MAN', 'MEDIA', 'MISC', 'TECH', 'GEST'] as const
export type PiProduct = (typeof ALL_PI_PRODUCTS)[number]
export function isPiProduct(product: string): product is PiProduct {
  return (ALL_PI_PRODUCTS as readonly string[]).includes(product)
}

export const ALL_PI_DEDUCTIBLES = [300, 500, 600, 1_000, 1_500]
export type PiDeductible = (typeof ALL_PI_DEDUCTIBLES)[number]

export const ALL_PI_SPECIAL_ACTIVITIES = ['travelAgencies', 'securityCompanies'] as const satisfies string[]
export type PiSpecialActivity = (typeof ALL_PI_SPECIAL_ACTIVITIES)[number]

const ALL_PI_LIMITS_OF_INDEMNITY = [
  50_000, 100_000, 150_000, 300_000, 600_000, 1_000_000, 1_500_000, 2_000_000, 3_000_000,
] as const satisfies number[]
export type PiLimitOfIndemnity = (typeof ALL_PI_LIMITS_OF_INDEMNITY)[number]

export function isPiLimitOfIndemnity(candidate: number): candidate is PiLimitOfIndemnity {
  return ALL_PI_LIMITS_OF_INDEMNITY.includes(candidate as PiLimitOfIndemnity)
}

const MISC_MAN_MAC_MEDIA_PREMIUM_PER_LIMIT_OF_INDEMNITY_PER_REVENUE_LEVEL: Record<
  PiLimitOfIndemnity,
  [number, number | undefined][] | undefined
> = {
  50_000: [
    [0, 160],
    [60_001, 220],
    [150_001, undefined],
  ],
  100_000: [
    [0, 180],
    [60_001, 240],
    [150_001, 330],
    [300_001, undefined],
  ],
  150_000: [
    [0, 200],
    [60_001, 270],
    [150_001, 365],
    [300_001, 492],
    [450_001, 640],
    [600_001, 800],
    [750_001, 920],
    [1_000_001, 1_057],
    [1_500_001, 1_216],
    [2_000_001, 1_399],
    [2_500_001, 1_608],
    [3_000_001, undefined],
  ],
  300_000: [
    [0, 224],
    [60_001, 309],
    [150_001, 408],
    [300_001, 542],
    [450_001, 749],
    [600_001, 936],
    [750_001, 1_076],
    [1_000_001, 1_238],
    [1_500_001, 1_423],
    [2_000_001, 1_637],
    [2_500_001, 1_882],
    [3_000_001, undefined],
  ],
  600_000: [
    [0, 280],
    [60_001, 379],
    [150_001, 538],
    [300_001, 770],
    [450_001, 948],
    [600_001, 1_185],
    [750_001, 1_362],
    [1_000_001, 1_567],
    [1_500_001, 1_802],
    [2_000_001, 2_072],
    [2_500_001, 2_383],
    [3_000_001, undefined],
  ],
  1_000_000: [
    [0, 352],
    [60_001, 448],
    [150_001, 614],
    [300_001, 841],
    [450_001, 1_114],
    [600_001, 1_392],
    [750_001, 1_601],
    [1_000_001, 1_841],
    [1_500_001, 2_117],
    [2_000_001, 2_435],
    [2_500_001, 2_800],
    [3_000_001, undefined],
  ],
  1_500_000: [
    [0, 403],
    [60_001, 504],
    [150_001, 665],
    [300_001, 878],
    [450_001, 1_162],
    [600_001, 1_452],
    [750_001, 1_670],
    [1_000_001, 1_920],
    [1_500_001, 2_208],
    [2_000_001, 2_540],
    [2_500_001, 2_921],
    [3_000_001, undefined],
  ],
  2_000_000: [
    [0, 483],
    [60_001, 587],
    [150_001, 757],
    [300_001, 977],
    [450_001, 1_244],
    [600_001, 1_517],
    [750_001, 1_744],
    [1_000_001, 2_006],
    [1_500_001, 2_307],
    [2_000_001, 2_653],
    [2_500_001, 3_051],
    [3_000_001, undefined],
  ],
  3_000_000: undefined,
}

export const PI_BASE_PREMIUM_PER_PRODUCT_OR_SPECIAL_ACTIVITY_PER_LIMIT_OF_INDEMNITY_PER_REVENUE_LEVEL: Record<
  PiProduct | PiSpecialActivity,
  Record<PiLimitOfIndemnity, [number, number | undefined][] | undefined>
> = {
  MISC: MISC_MAN_MAC_MEDIA_PREMIUM_PER_LIMIT_OF_INDEMNITY_PER_REVENUE_LEVEL,
  MAN: MISC_MAN_MAC_MEDIA_PREMIUM_PER_LIMIT_OF_INDEMNITY_PER_REVENUE_LEVEL,
  MAC: MISC_MAN_MAC_MEDIA_PREMIUM_PER_LIMIT_OF_INDEMNITY_PER_REVENUE_LEVEL,
  MEDIA: MISC_MAN_MAC_MEDIA_PREMIUM_PER_LIMIT_OF_INDEMNITY_PER_REVENUE_LEVEL,
  TECH: {
    50_000: [
      [0, 160],
      [60_001, 220],
      [150_001, undefined],
    ],
    100_000: [
      [0, 180],
      [60_001, 240],
      [150_001, 330],
      [300_001, undefined],
    ],
    150_000: [
      [0, 220],
      [60_001, 297],
      [150_001, 401],
      [300_001, 541],
      [450_001, 625],
      [600_001, 644],
      [1_000_001, 711],
      [1_500_001, 936],
      [2_000_001, 1_148],
      [2_500_001, 1_352],
      [3_000_001, undefined],
    ],
    300_000: [
      [0, 246],
      [60_001, 340],
      [150_001, 448],
      [300_001, 596],
      [450_001, 665],
      [600_001, 770],
      [1_000_001, 875],
      [1_500_001, 1_153],
      [2_000_001, 1_416],
      [2_500_001, 1_670],
      [3_000_001, 1_913],
      [3_500_001, 2_154],
      [4_000_001, 2_385],
      [4_500_001, 2_613],
      [5_000_001, undefined],
    ],
    600_000: [
      [0, 308],
      [60_001, 417],
      [150_001, 592],
      [300_001, 847],
      [450_001, 889],
      [600_001, 924],
      [1_000_001, 1_209],
      [1_500_001, 1_594],
      [2_000_001, 1_958],
      [2_500_001, 2_308],
      [3_000_001, 2_646],
      [3_500_001, 2_979],
      [4_000_001, 3_300],
      [4_500_001, 3_616],
      [5_000_001, undefined],
    ],
    1_000_000: [
      [0, 385],
      [60_001, 493],
      [150_001, 675],
      [300_001, 925],
      [450_001, 972],
      [600_001, 1_068],
      [1_000_001, 1_376],
      [1_500_001, 1_815],
      [2_000_001, 2_230],
      [2_500_001, 2_629],
      [3_000_001, 3_014],
      [3_500_001, 3_391],
      [4_000_001, 3_754],
      [4_500_001, 4_117],
      [5_000_001, undefined],
    ],
    1_500_000: [
      [0, 443],
      [60_001, 555],
      [150_001, 732],
      [300_001, 966],
      [450_001, 1_027],
      [600_001, 1_228],
      [1_000_001, 1_419],
      [1_500_001, 1_869],
      [2_000_001, 2_327],
      [2_500_001, 2_743],
      [3_000_001, 3_146],
      [3_500_001, 3_535],
      [4_000_001, 3_918],
      [4_500_001, 4_290],
      [5_000_001, undefined],
    ],
    2_000_000: [
      [0, 531],
      [60_001, 646],
      [150_001, 833],
      [300_001, 1_075],
      [450_001, 1_116],
      [600_001, 1_335],
      [1_000_001, 2_283],
      [1_500_001, 2_283],
      [2_000_001, 2_327],
      [2_500_001, 2_743],
      [3_000_001, 3_146],
      [3_500_001, 3_535],
      [4_000_001, 3_918],
      [4_500_001, 4_290],
      [5_000_001, undefined],
    ],
    3_000_000: [
      [0, undefined],
      [450_001, 3_075],
      [600_001, 3_137],
      [1_000_001, 3_199],
      [1_500_001, 3_263],
      [2_000_001, 3_328],
      [2_500_001, 3_395],
      [3_000_001, 3_486],
      [3_500_001, 3_907],
      [4_000_001, 4_322],
      [4_500_001, 4_724],
      [5_000_001, undefined],
    ],
  },
  GEST: {
    50_000: undefined,
    100_000: undefined,
    150_000: [
      [0, 811],
      [60_001, 1_095],
      [150_001, 1_519],
      [300_001, 2_460],
      [450_001, 2_665],
      [600_001, 2_888],
      [750_001, 2_937],
      [1_000_001, 3_048],
      [1_500_001, 3_378],
      [2_000_001, 3_783],
      [2_500_001, 4_162],
      [3_000_001, undefined],
    ],
    300_000: [
      [0, 889],
      [60_001, 1_226],
      [150_001, 1_663],
      [300_001, 2_654],
      [450_001, 3_053],
      [600_001, 3_308],
      [750_001, 3_365],
      [1_000_001, 3_533],
      [1_500_001, 3_870],
      [2_000_001, 4_334],
      [2_500_001, 4_767],
      [3_000_001, undefined],
    ],
    600_000: [
      [0, 1_360],
      [60_001, 1_840],
      [150_001, 2_686],
      [300_001, 4_609],
      [450_001, 4_729],
      [600_001, 5_123],
      [750_001, 5_212],
      [1_000_001, 5_473],
      [1_500_001, 5_994],
      [2_000_001, 6_713],
      [2_500_001, 7_384],
      [3_000_001, undefined],
    ],
    1_000_000: [
      [0, 1_916],
      [60_001, 2_453],
      [150_001, 3_453],
      [300_001, 5_677],
      [450_001, 6_263],
      [600_001, 6_785],
      [750_001, 6_902],
      [1_000_001, 7_247],
      [1_500_001, 7_938],
      [2_000_001, 8_890],
      [2_500_001, 9_779],
      [3_000_001, undefined],
    ],
    1_500_000: [
      [0, 2_203],
      [60_001, 2_759],
      [150_001, 3_741],
      [300_001, 5_926],
      [450_001, 6_531],
      [600_001, 7_075],
      [750_001, 7_198],
      [1_000_001, 7_558],
      [1_500_001, 8_227],
      [2_000_001, 9_271],
      [2_500_001, 10_198],
      [3_000_001, undefined],
    ],
    2_000_000: [
      [0, 2_521],
      [60_001, 3_066],
      [150_001, 4_061],
      [300_001, 6_286],
      [450_001, 6_671],
      [600_001, 7_358],
      [750_001, 7_486],
      [1_000_001, 7_860],
      [1_500_001, 8_691],
      [2_000_001, 10_012],
      [2_500_001, 11_218],
      [3_000_001, undefined],
    ],
    3_000_000: undefined,
  },
  travelAgencies: {
    50_000: undefined,
    100_000: undefined,
    150_000: undefined,
    300_000: undefined,
    600_000: [
      [0, 924],
      [350_001, 1_320],
      [600_001, undefined],
    ],
    1_000_000: undefined,
    1_500_000: undefined,
    2_000_000: undefined,
    3_000_000: undefined,
  },
  securityCompanies: {
    50_000: undefined,
    100_000: undefined,
    150_000: undefined,
    300_000: [
      [0, 660],
      [350_001, 1_100],
      [600_001, undefined],
    ],
    600_000: [
      [0, 924],
      [350_001, 1_518],
      [600_001, undefined],
    ],
    1_000_000: [
      [0, 1_199],
      [350_001, 1_969],
      [600_001, undefined],
    ],
    1_500_000: undefined,
    2_000_000: undefined,
    3_000_000: undefined,
  },
}

export const MAX_PI_REVENUE_PER_PRODUCT_OR_SPECIAL_ACTIVITY: Record<PiProduct | PiSpecialActivity, Amount> =
  Object.fromEntries(
    Object.entries(PI_BASE_PREMIUM_PER_PRODUCT_OR_SPECIAL_ACTIVITY_PER_LIMIT_OF_INDEMNITY_PER_REVENUE_LEVEL).map(
      ([productOrActivity, revenueLevelsByLoi]) => {
        const revenueLevelGroups = Object.values(revenueLevelsByLoi).filter(
          (group): group is [number, number | undefined][] => group != undefined,
        )

        const groupMaxRevenueLevels = revenueLevelGroups.map((group) => {
          const lastCloseRangeIndex = group.findLastIndex(([_, premium]) => premium != undefined)
          const openEndedRange = group.at(lastCloseRangeIndex + 1)
          if (!openEndedRange) {
            throw new Error('Multiple open-ended ranges found')
          }

          return openEndedRange[0] - 1 // remove the additional euro (first bound is the beginning of the range)
        })

        return [productOrActivity, newAmount(Math.max(...groupMaxRevenueLevels))]
      },
    ),
  ) as Record<PiProduct | PiSpecialActivity, Amount>

export function computePiBasePremium(params: {
  productOrActivity: PiProduct | PiSpecialActivity
  limitOfIndemnity: PiLimitOfIndemnity
  revenue: number
}): Result<number, 'unavailable-loi' | 'unavailable-revenue'> {
  const { productOrActivity, limitOfIndemnity, revenue } = params

  const premiumPerRevenueLevel =
    PI_BASE_PREMIUM_PER_PRODUCT_OR_SPECIAL_ACTIVITY_PER_LIMIT_OF_INDEMNITY_PER_REVENUE_LEVEL[productOrActivity][
      limitOfIndemnity
    ]

  if (!premiumPerRevenueLevel) {
    return failure('unavailable-loi')
  }

  let lastMatching: number | undefined

  for (const [revenueLevel, premium] of premiumPerRevenueLevel) {
    if (revenueLevel > revenue) {
      break
    }

    lastMatching = premium
  }

  return lastMatching ? success(lastMatching) : failure('unavailable-revenue')
}

export function computePiAllowedLimitOfIndemnity({
  productOrActivity,
  revenue,
}: {
  productOrActivity: PiProduct | PiSpecialActivity
  revenue: number
}): PiLimitOfIndemnity[] {
  const allowedLimitOfIndemnity: PiLimitOfIndemnity[] = []

  ALL_PI_LIMITS_OF_INDEMNITY.forEach((loi) => {
    const basePremiumResult = computePiBasePremium({
      productOrActivity,
      revenue,
      limitOfIndemnity: loi,
    })

    if (isSuccess(basePremiumResult)) {
      allowedLimitOfIndemnity.push(loi)
    }
  })

  return allowedLimitOfIndemnity
}

export const PI_ALLOWED_DEDUCTIBLES_PER_PRODUCT_OR_SPECIAL_ACTIVITY: Record<
  PiProduct | PiSpecialActivity,
  Set<PiDeductible>
> = {
  MISC: new Set([300, 600]),
  MAN: new Set([300, 600]),
  MAC: new Set([300, 600]),
  MEDIA: new Set([300, 600]),
  TECH: new Set([300, 600]),
  GEST: new Set([1_500]),
  travelAgencies: new Set([500]),
  securityCompanies: new Set([500]),
}
