import type { Activity } from '@orus.eu/activity'
import { BaseDimension } from '../../abstract-dimension'
import type { RcpaOffer } from '../rcpa-offer-dimension'
import { rcpaGuarantees } from './rcpa-insurance.js'

export type RcpaDeductibleContent = {
  loi: string
  deductible: string
  guaranteeTableFormat?: {
    loiLongFormat: string
    loiShortFormat: string
    deductibleLongFormat: string
    deductibleShortFormat: string
  }
}

export type RcpaDeductible = Partial<Record<(typeof rcpaGuarantees)[number], RcpaDeductibleContent>>

export type RcpaDeductiblePerActivity = Partial<Record<Activity, RcpaDeductible>>

export type RcpaDeductiblePerActivityPerOffer = Partial<Record<RcpaOffer, RcpaDeductiblePerActivity>>

export const rcpaDeductiblePerActivityPerOfferDimension = new BaseDimension<
  'rcpaDeductiblePerActivityPerOffer',
  RcpaDeductiblePerActivityPerOffer
>({
  name: 'rcpaDeductiblePerActivityPerOffer',
  displayValues: { name: 'Franchise par activité par offre' },
  tags: ['RCPA'],
} as const)
