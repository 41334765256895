import { css } from '@emotion/react'
import styled from '@emotion/styled'
import {
  Autocomplete,
  Button,
  InputAdornment,
  Popper,
  type AutocompleteFreeSoloValueMapping,
  type AutocompleteRenderOptionState,
  type ButtonProps,
  type FilterOptionsState,
  type UseAutocompleteProps,
} from '@mui/material'
import { type Key, type ReactElement } from 'react'
import { useUiContext, type UiContext } from '../../../code-only/hooks/use-screen-variant.js'
import { borderStroke, colorTokens, shadow, spacing, Text } from '../../../foundations'
import { Icon } from '../../../foundations/icon/icon.js'
import { cssPropsPerScreenVariantPerTextVariant, type TextCssProps } from '../../../foundations/text/css-props.js'
import { Spinner } from '../../spinner/spinner'

import { TextField } from '../text-field/text-field'

type SearchBarSize = 'small' | 'large'

type SearchBarProps<Value, FreeSolo extends boolean | undefined> = {
  options: Value[]
  size: SearchBarSize
  inputValue: string
  value?: Value | null
  variant?: UiContext
  placeholder?: string
  fullWidth?: boolean
  freeSolo?: FreeSolo
  isLoading?: boolean
  hasCustomEndAdornment?: boolean
  error?: boolean
  disabled?: boolean
  hidePopper?: boolean
  blurOnSelect?: boolean
  helperText?: string

  onChange?: UseAutocompleteProps<Value, false, false, FreeSolo>['onChange']
  onKeyDown?: (event: React.KeyboardEvent) => void
  getOptionLabel?: (option: Value | AutocompleteFreeSoloValueMapping<FreeSolo>) => string
  renderOption?: (
    props: React.HTMLAttributes<HTMLLIElement> & { key: Key },
    option: Value,
    state: AutocompleteRenderOptionState,
  ) => React.ReactNode
  filterOptions?: (options: Value[], state: FilterOptionsState<Value>) => Value[]
  onInputChange?: (event: React.SyntheticEvent, value: string) => void
  clearInputValue?: () => void
}

type PopperProps = {
  size: SearchBarSize
  typography: TextCssProps
}

export function SearchBar<Value, FreeSolo extends boolean | undefined>(
  props: SearchBarProps<Value, FreeSolo>,
): ReactElement {
  const {
    options,
    size,
    variant,
    placeholder,
    fullWidth = false,
    inputValue,
    value,
    freeSolo,
    isLoading,
    error,
    disabled,
    hidePopper,
    blurOnSelect,
    onChange,
    getOptionLabel,
    renderOption,
    onInputChange,
    clearInputValue,
    onKeyDown,
    filterOptions,
    helperText,
    hasCustomEndAdornment = false,
  } = props
  const screenVariant = useUiContext()

  const typography = cssPropsPerScreenVariantPerTextVariant['body2'][variant ?? screenVariant]

  return (
    <>
      <Autocomplete
        freeSolo={freeSolo}
        clearOnBlur={false}
        css={SearchBarCss}
        options={options}
        fullWidth={fullWidth}
        inputValue={inputValue}
        disabled={disabled}
        value={value}
        onChange={onChange}
        blurOnSelect={blurOnSelect}
        getOptionLabel={getOptionLabel}
        filterOptions={filterOptions}
        onInputChange={onInputChange}
        onKeyDown={onKeyDown}
        renderOption={renderOption}
        renderInput={(params) => (
          <TextField
            {...params}
            size={size}
            placeholder={placeholder}
            error={error}
            InputProps={{
              ...params.InputProps,
              startAdornment: (
                <>
                  <InputAdornment position="start">
                    <Icon icon="magnifying-glass-regular" size="30" />
                  </InputAdornment>
                </>
              ),
              ...(hasCustomEndAdornment
                ? {
                    endAdornment: (
                      <>
                        {inputValue !== '' && isLoading ? <Spinner size="30" /> : undefined}
                        {inputValue !== '' && !isLoading ? (
                          <InputAdornment position="end">
                            <UnstyledButton onClick={clearInputValue}>
                              <Icon icon="xmark-regular" />
                            </UnstyledButton>
                          </InputAdornment>
                        ) : undefined}
                      </>
                    ),
                  }
                : {}),
            }}
          />
        )}
        noOptionsText={
          <div>
            <Text variant="body2" color={colorTokens['color-fg-base-disable']}>
              Aucun résultat
            </Text>
          </div>
        }
        slots={{
          popper: (popperComponentProps) => (
            <StyledPopper
              {...popperComponentProps}
              size={size}
              typography={typography}
              sx={{
                ...(hidePopper && { display: 'none' }),
              }}
            />
          ),
        }}
      />
      {helperText ? (
        <Text
          variant="caption"
          color={colorTokens['color-text-base-basic']}
          css={css`
            padding-top: ${spacing[20]};
          `}
        >
          {helperText}
        </Text>
      ) : undefined}
    </>
  )
}

const SearchBarCss = css`
  & .MuiOutlinedInput-root,
  & .MuiOutlinedInput-root.MuiInputBase-sizeSmall {
    padding: 0 ${spacing[40]};
    box-shadow: ${shadow.bottom['05']};
  }

  & .MuiOutlinedInput-root .MuiAutocomplete-input,
  & .MuiOutlinedInput-root.MuiInputBase-sizeSmall .MuiAutocomplete-input {
    padding-left: ${spacing[30]};
  }

  & .MuiAutocomplete-clearIndicator {
    visibility: visible;

    &:hover {
      background: none;
    }
  }

  & .MuiInputAdornment-root {
    margin-right: 0;
  }

  & .MuiAutocomplete-inputRoot .MuiInputBase-input {
    box-shadow: none;
  }
`

const StyledPopper = styled(Popper)<PopperProps>`
  & .MuiAutocomplete-paper {
    margin-top: ${spacing['30']};
    border-radius: ${spacing['30']};
    border: ${borderStroke['20']} solid ${colorTokens['color-stroke-base']};
    box-shadow: ${shadow.bottom[30]};
  }

  & .MuiAutocomplete-listbox {
    padding: 0;
  }

  & .MuiAutocomplete-option {
    padding: ${({ size }) => (size === 'small' ? `${spacing['30']} ${spacing['50']}` : spacing['50'])};
    ${({ typography }) => typography};
  }

  & .MuiAutocomplete-option.Mui-focused.Mui-focusVisible {
    background-color: ${colorTokens['color-bg-base-secondary-hover']};
  }

  & .MuiAutocomplete-listbox .MuiAutocomplete-option.Mui-focused {
    background-color: ${colorTokens['color-bg-base-secondary-hover']};
  }
`

function UnstyledButton({ children, ...props }: ButtonProps): ReactElement {
  return (
    <Button
      sx={{
        border: 'none',
        padding: 0,
        background: 'none',
        color: 'inherit',
        fontSize: 'inherit',
        lineHeight: 'inherit',
        margin: 0,
        minWidth: 'auto',
        '&:hover': {
          background: 'none',
        },
      }}
      {...props}
    >
      {children}
    </Button>
  )
}
