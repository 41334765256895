import { createFileRoute } from '@tanstack/react-router'

import PlatformActivitiesPage from '../../../components/pages/backoffice/platform/platform-activities-page/platform-activities-page'
import { PermissionChecker } from '../../../components/templates/permission-checker'

export const Route = createFileRoute('/bak/activities')({
  component: () => (
    <PermissionChecker requiredPermissions={['activities.read']}>
      <PlatformActivitiesPage />
    </PermissionChecker>
  ),
})
