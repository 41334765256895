import { useOpenChat } from '@orus.eu/pharaoh'
import type { ReactElement } from 'react'
import { GenericProblemMessage } from './generic-problem-message'

export default function TemporaryFailureScreen(): ReactElement {
  const openChat = useOpenChat()
  return (
    <GenericProblemMessage
      title="Problème technique temporaire"
      principalMessage="Désolé, un problème technique nous empêche de continuer."
      firstSubText="Ce problème est temporaire, il peut être résolu en réessayant plus tard, ou en rafraichissant la page."
      secondSubText="Vous pouvez également nous contacter pour toute question."
      buttonText="Contacter le support"
      onButtonClick={openChat}
      secondaryButtonText="Réessayer"
      onSecondaryButtonClick={() => window.location.reload()}
    />
  )
}
