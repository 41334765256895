import { createFileRoute } from '@tanstack/react-router'
import { RenewalPage } from '../../../../../components/pages/renewal/RenewalPage'
import { PermissionChecker } from '../../../../../components/templates/permission-checker'

export const Route = createFileRoute('/_customer/s/r/$token')({
  component: () => (
    <PermissionChecker requiredPermissions={['contracts.sign']}>
      <RenewalPage />
    </PermissionChecker>
  ),
})
