import styled from '@emotion/styled'
import { memo } from 'react'

import { useUiContext } from '../../code-only/hooks'
import type { Color } from '../../colors'
import { Button, ButtonGroup, type ButtonProps } from '../../components'
import { borderStroke, colorTokens, spacing } from '../../foundations'
import { Text, TextContainer } from '../../foundations/text'

export type ButtonPropsAndContent = {
  props: ButtonProps
  content: React.ReactNode
}

export type CardProps = {
  title: React.ReactNode
  subtitle: React.ReactNode
  avatar: React.ReactNode
  rightImageUrl?: string
  withBorder?: boolean
  titleColor?: Color
  subtitleColor?: Color
  size: 'small' | 'medium'
  variant: 'basic' | 'decorative'
  backgroundColorDecorative?: Color
  buttons?: (ButtonPropsAndContent | undefined)[]
  fullWidth?: boolean
  children?: React.ReactNode
}

export const Card = memo<CardProps>(function Card(props: CardProps) {
  const screenVariant = useUiContext()

  const {
    title,
    subtitle,
    avatar,
    rightImageUrl,
    backgroundColorDecorative,
    titleColor,
    subtitleColor,
    size,
    variant,
    children,
    buttons,
    fullWidth,
  } = props

  return (
    <StyledCardContainer
      backgroundColor={variant === 'decorative' ? backgroundColorDecorative : undefined}
      withBorder={variant === 'basic' ? true : false}
      size={size}
      fullWidth={fullWidth}
    >
      <StyledCardContainerLeft>
        <StyledCardBody>
          {avatar ? avatar : null}
          <Text
            variant={size === 'small' && screenVariant === 'mobile' ? 'h6' : 'h5'}
            color={
              titleColor ??
              (variant === 'decorative'
                ? colorTokens['color-text-decorative-main']
                : colorTokens['color-text-base-main'])
            }
          >
            {title}
          </Text>
          <Text
            variant="body2"
            color={
              subtitleColor ??
              (variant === 'decorative'
                ? colorTokens['color-text-decorative-basic']
                : colorTokens['color-text-base-basic'])
            }
          >
            {subtitle}
          </Text>
          {children}
        </StyledCardBody>
        {buttons?.some((button) => button) ? (
          <StyledCardFooter
            bodyMarginBottom={size === 'small' && screenVariant !== 'mobile' ? spacing[90] : spacing[70]}
          >
            <ButtonGroup orientation={size === 'small' || screenVariant === 'mobile' ? 'column' : 'row'}>
              {buttons.map((buttonPropsAndContent, index) =>
                buttonPropsAndContent ? (
                  <Button
                    key={index}
                    {...buttonPropsAndContent.props}
                    style={variant === 'basic' ? 'base' : 'decorative'}
                    fullWidth={size === 'small' || screenVariant === 'mobile'}
                  >
                    {buttonPropsAndContent.content}
                  </Button>
                ) : null,
              )}
            </ButtonGroup>
          </StyledCardFooter>
        ) : null}
      </StyledCardContainerLeft>
      {rightImageUrl && screenVariant === 'desktop' && size === 'medium' ? (
        <StyledCardContainerRight>
          <StyledHelpBlockRightImage src={rightImageUrl} alt="" />
        </StyledCardContainerRight>
      ) : null}
    </StyledCardContainer>
  )
})

const StyledCardContainer = styled.div<{
  backgroundColor?: Color
  withBorder: boolean
  size: 'small' | 'medium'
  fullWidth?: boolean
}>`
  display: flex;
  flex-direction: row;
  padding: ${spacing['70']};
  gap: ${spacing['70']};
  border-radius: ${spacing['40']};
  border: ${(props) =>
    `${borderStroke[20]} solid ${props.withBorder ? colorTokens['color-stroke-base'] : 'transparent'}`};
  background: ${(props) => props.backgroundColor ?? colorTokens['color-bg-base-normal']};
  width: ${(props) => (props.size === 'medium' || props.fullWidth ? '100%' : '320px')};
  container-type: inline-size;
`

const StyledCardBody = styled(TextContainer)`
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  gap: ${spacing['30']};
`

const StyledCardFooter = styled.div<{ bodyMarginBottom: string }>`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  gap: ${spacing['40']};
  margin-top: ${(props) => props.bodyMarginBottom};
`

const StyledCardContainerLeft = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`
const containerMaxWidth = `calc(580px - 2*${spacing['70']})`

const StyledCardContainerRight = styled.div`
  flex: 1;

  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  overflow: hidden;
  margin-bottom: -${spacing['70']};

  @container (max-width: ${containerMaxWidth}) {
    display: none;
  }
`

const StyledHelpBlockRightImage = styled.img`
  width: 258px;
  position: absolute;

  @container (max-width: 258px) {
    align-self: flex-start;
  }
`
