import { colorTokens } from '../../foundations'

export const AVATAR_COLORS = [
  colorTokens['color-bg-decorative-1'],
  colorTokens['color-bg-decorative-2'],
  colorTokens['color-bg-decorative-3'],
  colorTokens['color-bg-decorative-4'],
  colorTokens['color-bg-decorative-5'],
]

export function generateHexaColorFromName(name: string): string {
  let hash = 0
  for (let i = 0; i < name.length; i++) {
    hash = name.charCodeAt(i) + ((hash << 5) - hash)
  }

  const index = Math.abs(hash) % AVATAR_COLORS.length
  return AVATAR_COLORS[index]!
}
