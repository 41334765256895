import { z } from 'zod'
import type { AbstractDimension } from '../dimension'
import type { SubscriptionCondition, SubscriptionUiElement, SubscriptionUiElementGroup } from '../subscription-ui'

export const allQuoteEditorSectionIds = [
  'contact-data',
  'operating-zone',
  'activity',
  'complementary-activities-selection',
  'product-selection',
  'empty-state-quote',
  'invoicing-configuration',
  'discount',
  'mrph-siret-search',
  'mrpw-siret-search',
  'mrpw-add-address',
  'mrph-add-postcode',
  'prequote-subsidiaries',
  'prequote-form',
  'prequote-revenue',
  'prequote-experience',
  'prequote-insurance',
  'prequote-family-member',
  'prequote-variable',
  'prequote-options',
  'installment-fee',
  'rcda-management-fee',
  'rcph-installment-fee',
  'rcph-management-fee',
  'rcpa-installment-fee',
  'rcpa-management-fee',
  'partner-application-fee',
  'partner-management-fees',
  'partner-fees-card',
  'quote',
  'rcph-cyber-information',
  'post-quote-data-policy-acceptance',
  'post-quote-siret-search',
  'post-quote-add-address',
  'start-date',
  'contractualization',
  'statement',
  'post-quote-agents',
  'additional-personal-data',
  'lock-documents',
  'validate-documents',
  'rcda-global-note',
  'terms',
  'documents',
  'operator',
  'terms',
  'custom-clauses',
  'overcharges',
  'detailed-sheet',
  'detailed-sheet-overview',
  'overcharges-rcda',
  'manual-exclusion',
  'pricing-version',
  'conversation',
] as const

export const quoteEditorSectionIdSchema = z.enum(allQuoteEditorSectionIds)
export type QuoteEditorSectionId = z.infer<typeof quoteEditorSectionIdSchema>

export const ALL_QUOTE_EDITOR_TABS = {
  pricing: { title: 'Tarificateur', hasWritableInputs: true, isFullBleed: false },
  conversation: { title: 'Conversation', hasWritableInputs: false, isFullBleed: true },
  'legal-terms': { title: 'Conditions légales', hasWritableInputs: false, isFullBleed: false },
  management: { title: 'Gestion', hasWritableInputs: true, isFullBleed: false },
  information: { title: 'Informations', hasWritableInputs: true, isFullBleed: false },
  'supporting-documents': { title: 'Pièces justificatives', hasWritableInputs: true, isFullBleed: false },
  'contractual-documents': { title: 'Documents contractuels', hasWritableInputs: false, isFullBleed: false },
} as const satisfies Record<
  string,
  {
    title: string
    /**
     * For now, we dont know when a dimension is used to write or read
     * so we create this record to know if a tab is for informative purpose (no writable input) or not
     * hence we can trigger missing inputs error on the right tab
     */
    hasWritableInputs: boolean
    isFullBleed: boolean
  }
>

export type QuoteEditorTabId = keyof typeof ALL_QUOTE_EDITOR_TABS

export type QuoteEditorSection = {
  id: QuoteEditorSectionId
  backofficeUi: {
    tab: QuoteEditorTabId
    breadcrumb: string
    /**
     * Title of the step in the BO
     */
    title?: string
    /**
     * Subtitle of the step in the BO
     */
    subtitle?: string
  }
  condition?: SubscriptionCondition
  permission?: string
  subtitleElement?: SubscriptionUiElement
  bodyElements: SubscriptionUiElementGroup
  hideForPartners?: true
  onlyForBrokersSubscription?: true
  onlyForPartnersSubscription?: true
  /**
   * Hide the section for ORUS subscriptions (to prevent confusing sales)
   */
  onlyForNonOrusSubscription?: true
  whenContractSigned?: true
  whenContractNotSigned?: true
  partiallyUsedInputDimensions?: AbstractDimension[]
  hideOnProductionEnv?: true
  /**
   * Hide the section for partners with the property canEditPartnerFees of the organization set to false
   */
  hideWhenCannotEditFees?: true
}
