import styled from '@emotion/styled'
import { memo, type PropsWithChildren } from 'react'
import { TimeAgo } from '../../code-only/lib'
import { borderRadius, colorTokens, Icon, spacing, Text, type CompoundIconName } from '../../foundations'
import { Dropdown } from '../../patterns/dropdown'
import { Avatar } from '../avatar'
import { Button } from '../button/button'
import { RowContainer } from '../rows'

export type TimelineEventVariant = 'neutral' | 'info' | 'success' | 'warning' | 'danger'
export type TimelineEventState = 'enable' | 'hover' | 'focus' | 'disable'
export type TimelineEventTheme = {
  [style in TimelineEventVariant]: {
    [state in TimelineEventState]: {
      backgroundColor: string
      borderColor: string
      textColor?: string
      authorColor?: string
      iconColor?: string
    }
  }
}

export type TimelineEventProps = {
  type: 'event' | 'comment'
  size: 'large' | 'small'
  variant?: TimelineEventVariant
  isDisabled?: boolean
  icon?: CompoundIconName
  iconColor?: string
  author?: string
  hasAvatar?: boolean
  date: Date
  onReplyOnThread?: () => void
  isDeleted?: boolean
  onDelete?: () => void
  attachment?: {
    name: string
    onClick: (() => void) | null
  } | null
  answer: {
    authorEmails: string[]
    answersCount: number
    lastAnswerDate: Date
  } | null
}

export const TimelineEvent = memo<PropsWithChildren<TimelineEventProps>>(function TimelineEvent(props) {
  const {
    type,
    size,
    variant = 'neutral',
    isDisabled = false,
    icon,
    iconColor,
    author,
    hasAvatar = true,
    date,
    onReplyOnThread,
    isDeleted = false,
    onDelete,
    answer,
    children,
    attachment,
  } = props

  const colors = colorsPerTheme[variant]
  const defaultIcon = iconPerVariant[variant]

  return (
    <StyledTimelineEvent
      colors={colors}
      isDisabled={isDisabled || isDeleted}
      size={size}
      type={type}
      hasAnswer={!!answer}
    >
      <StyledTimelineEventHeader>
        {type === 'event' ? (
          <Icon icon={icon ? icon : defaultIcon} size="10" color={iconColor ? iconColor : colors.enable.iconColor} />
        ) : null}
        {hasAvatar ? (
          author ? (
            <Avatar variant="contained" size="40" name={author} />
          ) : (
            <Avatar
              variant="contained"
              size="40"
              icon="circle-user-regular"
              color={colorTokens['color-bg-decorative-primary-hover']}
              containerColor={colorTokens['color-bg-base-tertiary']}
            />
          )
        ) : null}
        <StyledTimelineEventHeaderContent>
          <Text variant="body2Medium" color={colors.enable.authorColor} noWrap>
            {author ? author : 'Prospect'}
          </Text>
          {type === 'event' ? (
            <StyledDate variant="caption">
              {children}&nbsp;
              <TimeAgo date={date} />
            </StyledDate>
          ) : (
            <Text variant="caption" color={colors.enable.textColor}>
              <TimeAgo date={date} />
            </Text>
          )}
        </StyledTimelineEventHeaderContent>
        <StyledButtonGroup>
          {onReplyOnThread ? (
            <Button
              variant="tertiary"
              style="decorative"
              size="small"
              icon="comment-lines-regular"
              onClick={onReplyOnThread}
            />
          ) : null}
          {type === 'comment' && onDelete ? (
            <Dropdown
              placement="bottom-end"
              trigger={
                <Button
                  variant="tertiary"
                  style="decorative"
                  size="small"
                  icon="ellipsis-regular"
                  disabled={isDisabled}
                />
              }
            >
              <RowContainer variant="border" size="small">
                <Button variant="tertiary" style="danger" icon="trash-can-regular" onClick={onDelete}>
                  Supprimer
                </Button>
              </RowContainer>
            </Dropdown>
          ) : null}
        </StyledButtonGroup>
      </StyledTimelineEventHeader>
      {type === 'comment' ? (
        <StyledComment>
          <StyledCommentContent
            variant="body2"
            color={
              isDeleted
                ? colorTokens['color-text-base-disable']
                : variant === 'neutral'
                  ? colorTokens['color-text-base-basic']
                  : colors.enable.textColor
            }
            italic={isDeleted}
            element="pre"
          >
            {isDeleted ? (
              <>
                <Icon icon="trash-can-light" />
                Commentaire supprimé
              </>
            ) : (
              children
            )}
          </StyledCommentContent>
        </StyledComment>
      ) : null}
      {type === 'event' && attachment ? (
        <StyledAttachment>
          <StyledAttachmentButton
            variant="secondary"
            size="small"
            disabled={!attachment.onClick}
            onClick={attachment.onClick ?? undefined}
            avatarPosition="left"
            icon="paperclip-regular"
          >
            {attachment.name}
          </StyledAttachmentButton>
        </StyledAttachment>
      ) : null}
      {answer ? (
        <StyledAnswer>
          <AvatarGroup>
            {answer.authorEmails.slice(0, 3).map((email) => {
              return <Avatar key={email} variant="contained" size="40" name={email} />
            })}
          </AvatarGroup>
          <Button variant="text" size="small" className="answer-link" onClick={onReplyOnThread}>
            {answer.answersCount} {answer.answersCount === 1 ? 'réponse' : 'réponses'}
          </Button>
          <StyledDate variant="caption" color={colors.enable.textColor}>
            dernière réponse&nbsp;
            <TimeAgo date={answer.lastAnswerDate} />
          </StyledDate>
        </StyledAnswer>
      ) : null}
    </StyledTimelineEvent>
  )
})

const colorsPerTheme: TimelineEventTheme = {
  neutral: {
    enable: {
      backgroundColor: colorTokens['color-bg-base-normal'],
      authorColor: colorTokens['color-text-base-main'],
      textColor: colorTokens['color-text-base-disable'],
      iconColor: colorTokens['color-fg-base'],
      borderColor: colorTokens['color-stroke-base'],
    },
    hover: {
      backgroundColor: colorTokens['color-bg-base-hover'],
      borderColor: colorTokens['color-stroke-base-hover'],
    },
    focus: {
      backgroundColor: colorTokens['color-bg-base-focus'],
      borderColor: colorTokens['color-stroke-base-focus'],
    },
    disable: {
      backgroundColor: colorTokens['color-bg-base-disable'],
      textColor: colorTokens['color-text-base-disable'],
      borderColor: colorTokens['color-stroke-base-disable'],
    },
  },
  info: {
    enable: {
      backgroundColor: colorTokens['color-bg-info-tertiary'],
      authorColor: colorTokens['color-text-info-primary'],
      textColor: colorTokens['color-text-info-primary'],
      iconColor: colorTokens['color-fg-info'],
      borderColor: colorTokens['color-bg-info-tertiary'],
    },
    hover: {
      backgroundColor: colorTokens['color-bg-info-tertiary-hover'],
      borderColor: colorTokens['color-bg-info-tertiary-hover'],
    },
    focus: {
      backgroundColor: colorTokens['color-bg-info-tertiary-active'],
      borderColor: colorTokens['color-bg-info-tertiary-active'],
    },
    disable: {
      backgroundColor: colorTokens['color-bg-base-disable'],
      textColor: colorTokens['color-text-base-disable'],
      borderColor: colorTokens['color-stroke-base-disable'],
    },
  },
  success: {
    enable: {
      backgroundColor: colorTokens['color-bg-success-tertiary'],
      authorColor: colorTokens['color-text-success-primary'],
      textColor: colorTokens['color-text-success-primary'],
      iconColor: colorTokens['color-fg-success'],
      borderColor: colorTokens['color-bg-success-tertiary'],
    },
    hover: {
      backgroundColor: colorTokens['color-bg-success-tertiary-hover'],
      borderColor: colorTokens['color-bg-success-tertiary-hover'],
    },
    focus: {
      backgroundColor: colorTokens['color-bg-success-tertiary-active'],
      borderColor: colorTokens['color-bg-success-tertiary-active'],
    },
    disable: {
      backgroundColor: colorTokens['color-bg-base-disable'],
      textColor: colorTokens['color-text-base-disable'],
      borderColor: colorTokens['color-stroke-base-disable'],
    },
  },
  warning: {
    enable: {
      backgroundColor: colorTokens['color-bg-warning-tertiary'],
      authorColor: colorTokens['color-text-warning-primary'],
      textColor: colorTokens['color-text-warning-primary'],
      iconColor: colorTokens['color-fg-warning'],
      borderColor: colorTokens['color-bg-warning-tertiary'],
    },
    hover: {
      backgroundColor: colorTokens['color-bg-warning-tertiary-hover'],
      borderColor: colorTokens['color-bg-warning-tertiary-hover'],
    },
    focus: {
      backgroundColor: colorTokens['color-bg-warning-tertiary-active'],
      borderColor: colorTokens['color-bg-warning-tertiary-active'],
    },
    disable: {
      backgroundColor: colorTokens['color-bg-base-disable'],
      textColor: colorTokens['color-text-base-disable'],
      borderColor: colorTokens['color-stroke-base-disable'],
    },
  },
  danger: {
    enable: {
      backgroundColor: colorTokens['color-bg-danger-tertiary'],
      authorColor: colorTokens['color-text-danger-primary'],
      textColor: colorTokens['color-text-danger-primary'],
      iconColor: colorTokens['color-fg-danger'],
      borderColor: colorTokens['color-bg-danger-tertiary'],
    },
    hover: {
      backgroundColor: colorTokens['color-bg-danger-tertiary-hover'],
      borderColor: colorTokens['color-bg-danger-tertiary-hover'],
    },
    focus: {
      backgroundColor: colorTokens['color-bg-danger-tertiary-active'],
      borderColor: colorTokens['color-bg-danger-tertiary-active'],
    },
    disable: {
      backgroundColor: colorTokens['color-bg-base-disable'],
      textColor: colorTokens['color-text-base-disable'],
      borderColor: colorTokens['color-stroke-base-disable'],
    },
  },
}

const iconPerVariant: {
  [variant in TimelineEventVariant]: CompoundIconName
} = {
  neutral: 'clock-three-regular',
  info: 'circle-info-solid',
  success: 'circle-check-solid',
  warning: 'triangle-exclamation-solid',
  danger: 'diamond-exclamation-solid',
}

const AvatarGroup = styled.div`
  display: flex;

  > * {
    margin-left: -6px;
    border: 2px solid ${colorTokens['color-bg-base-normal']};
    box-sizing: content-box;
  }

  > :first-of-type {
    margin-left: 0;
  }
`

const StyledButtonGroup = styled.div`
  opacity: 0;
  visibility: hidden;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0;
  height: 32px;
`

const StyledTimelineEvent = styled.div<{
  colors: TimelineEventTheme[TimelineEventVariant]
  isDisabled: boolean
  size: 'large' | 'small'
  type: 'event' | 'comment'
  hasAnswer: boolean
}>`
  display: flex;
  width: 100%;
  padding: ${({ size, hasAnswer }) =>
    size === 'small'
      ? '0'
      : hasAnswer
        ? `${spacing['30']} ${spacing['40']}`
        : `${spacing['30']} ${spacing['40']} ${spacing['40']}`};
  flex-direction: column;
  align-items: flex-start;
  border-radius: ${borderRadius['20']};
  border: ${({ colors, size, type }) =>
    size === 'large' && type === 'comment' && `1px solid ${colors.enable.borderColor}`};
  color: ${({ colors, isDisabled }) => (isDisabled ? colors.disable.textColor : colors.enable.textColor)};
  background-color: ${({ colors }) => colors.enable.backgroundColor};

  ${AvatarGroup} > * {
    border-color: ${({ colors }) => colors.enable.backgroundColor};
  }

  &:hover {
    ${({ size }) => size === 'small' && 'border: none;'}
    border-color: ${({ colors }) => colors.hover.borderColor};
    background: ${({ colors }) => colors.hover.backgroundColor};

    ${AvatarGroup} > * {
      border-color: ${({ colors }) => colors.hover.backgroundColor};
    }

    ${StyledButtonGroup} {
      ${({ isDisabled }) => !isDisabled && 'opacity: 1; visibility: visible;'}
    }
  }

  &:active,
  &:focus {
    ${({ size }) => size === 'small' && 'border: none;'}
    border-color: ${({ colors }) => colors.focus.borderColor};
    background: ${({ colors }) => colors.focus.backgroundColor};
  }

  ${({ isDisabled, colors, size }) =>
    isDisabled &&
    `
      ${size === 'small' && 'border: none;'}
      border-color: ${colors.disable.borderColor};
      background: ${colors.disable.backgroundColor};

      &:hover,
      &:active,
      &:focus {
        ${size === 'small' && 'border: none;'}
        border-color: ${colors.disable.borderColor};
        background: ${colors.disable.backgroundColor};

        ${AvatarGroup} > * {
          border-color: ${colors.disable.backgroundColor};
        }
      }

      ${AvatarGroup} > * {
        border-color: ${colors.disable.backgroundColor};
      }
    `}
`

const StyledTimelineEventHeader = styled.div`
  display: flex;
  align-items: center;
  padding: ${spacing['20']} 0;
  gap: ${spacing['30']};
  align-self: stretch;

  > :last-child {
    margin-left: auto;
  }
`

const StyledTimelineEventHeaderContent = styled.div`
  display: flex;
  padding: ${spacing['10']} 0;
  align-items: center;
  align-content: center;
  gap: 0 ${spacing['30']};
  flex: 1 0 0;
  flex-wrap: wrap;
  min-width: 0;
  width: 100%;
`

const StyledAttachment = styled.div`
  max-width: 100%;
  padding-left: 52px;
`

const StyledAttachmentButton = styled(Button)`
  max-width: 100%;
`

const StyledComment = styled.div`
  display: flex;
  padding-left: 32px;
  flex-direction: column;
  align-items: flex-start;
  gap: ${spacing['40']};
  align-self: stretch;
`

const StyledCommentContent = styled(Text)<{ italic: boolean }>`
  display: inline-flex;
  width: 100%;
  overflow-wrap: break-word;
  white-space: pre-wrap;
  word-wrap: break-word;
  word-break: break-word;
  hyphens: auto;
  ${({ italic }) => italic && 'font-style: italic;'}
  align-items: center;
  gap: ${spacing['20']};
`

const StyledAnswer = styled.div`
  display: flex;
  padding: ${spacing['20']} 0 ${spacing['20']} 32px;
  align-items: center;
  gap: ${spacing['30']};
  align-self: stretch;

  .answer-link {
    color: ${colorTokens['color-text-base-link']};

    &:hover {
      color: ${colorTokens['color-text-decorative-tertiary']};
    }
  }
`

const StyledDate = styled(Text)`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
`
