import { success, type Result } from '@orus.eu/result'
import { type } from 'arktype'
import {
  AbstractDimension,
  dimensionValidationFailure,
  type DimensionValidationProblem,
  type LooselyTypedValue,
} from './abstract-dimension'

export const oriasImmatriculationRegex = /^[0-9]{8}$/

export const oriasImmatriculationType = type({
  type: "'provided'",
  oriasId: oriasImmatriculationRegex,
}).or({
  type: "'later'",
})

export function parseOriasImmatriculationValue(input: string): string | null {
  const trimmedInput = input.replace(/\s/g, '').trim()
  if (oriasImmatriculationRegex.test(trimmedInput)) {
    return trimmedInput
  }
  return null
}

export type OriasImmatriculation = typeof oriasImmatriculationType.infer

export class OriasImmatriculationDimension<NAME extends string> extends AbstractDimension<NAME, OriasImmatriculation> {
  override validateData(value: LooselyTypedValue): Result<OriasImmatriculation, DimensionValidationProblem> {
    const result = oriasImmatriculationType(value)
    if (result instanceof type.errors) {
      return dimensionValidationFailure(`Field ${this.name} is not valid`)
    }
    return success(result)
  }
}
