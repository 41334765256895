import { memo } from 'react'

import { colorTokens } from '../../foundations/color-tokens.js'
import type { CompoundIconName } from '../../foundations/icon/names.js'
import type { IconSize } from '../../foundations/icon/sizes.js'
import type { IllustrationName } from '../../foundations/illustration/names.js'
import { Avatar } from './avatar.js'

type AvatarDecorativeBaseProps = { size: IconSize; className?: string }

export type AvatarDecorativeWithIconProps = AvatarDecorativeBaseProps & {
  icon: CompoundIconName
  backgroundColor: string
}
export type AvatarDecorativeWithIllustrationProps = AvatarDecorativeBaseProps & { illustration: IllustrationName }

export type AvatarDecorativeProps = AvatarDecorativeWithIconProps | AvatarDecorativeWithIllustrationProps

export const AvatarDecorative = memo<AvatarDecorativeProps>(function AvatarDecorative(props) {
  return 'illustration' in props ? (
    <Avatar illustration={props.illustration} size={props.size} className={props.className} />
  ) : (
    <Avatar
      icon={props?.icon}
      size={props.size}
      color={colorTokens['color-fg-decorative']}
      className={props.className}
      variant="contained"
      containerColor={colorTokens['color-bg-brand-light']}
    />
  )
})
