import styled from '@emotion/styled'
import { memo } from 'react'
import {
  type ProjectedProductAttributeDescription,
  productAttributeValueToString,
} from '../../code-only/hooks/use-product-attributes-projection'
import { useLanguage } from '../../code-only/localization'
import { Tooltip } from '../../components/'
import { colorTokens, spacing } from '../../foundations'
import { Icon } from '../../foundations/icon/icon.js'
import { Text } from '../../foundations/text/text.js'

type ProductCardAttributeProps = { productAttribute: ProjectedProductAttributeDescription }

export const ProductCardAttribute = memo<ProductCardAttributeProps>(function ProductCardAttribute(props) {
  const language = useLanguage()
  const { productAttribute } = props

  const isIncluded = productAttribute.type === 'included' && productAttribute.value
  const value = productAttributeValueToString(productAttribute, language)

  return (
    <StyledProductCardAttribute>
      <Text
        variant="body2Medium"
        color={isIncluded ? colorTokens['color-text-base-link'] : colorTokens['color-text-base-main']}
      >
        {value}
      </Text>
      {productAttribute.tooltip ? (
        <Tooltip title={productAttribute.tooltip}>
          <Icon icon="circle-info-regular" size="30" color={colorTokens['color-fg-base']} />
        </Tooltip>
      ) : null}
    </StyledProductCardAttribute>
  )
})

const StyledProductCardAttribute = styled.div`
  display: flex;
  gap: ${spacing[50]};
  align-items: center;
`
