import { css } from '@emotion/react'
import { memo } from 'react'
import { mobileMediaQuery, useScreenType } from '../../../../code-only/hooks/use-screen-type.js'
import { ButtonLink, PersistentNotification } from '../../../../components/index.js'
import { spacing } from '../../../../foundations/spacing-tokens.js'
import { Text } from '../../../../foundations/text'

type HealthInsuranceCPMSPage = {
  text: string
  src: string
  href: string
  persistentNotificationText: string
  buttonText: string
}

export const HealthInsuranceCPMSPage = memo<HealthInsuranceCPMSPage>(function HealthInsuranceCPMSPage(props) {
  const screenType = useScreenType()
  const { text, src, href, persistentNotificationText, buttonText } = props

  return (
    <div
      css={css`
        display: flex;
        flex-direction: column;
        gap: ${spacing[60]};
        margin: ${spacing[60]} 0;
        ${mobileMediaQuery} {
          margin: ${spacing[60]} ${spacing[50]};
        }
        width: 800px;
      `}
    >
      <div
        css={css`
          display: flex;
          flex-direction: column;
          gap: ${spacing[70]};
        `}
      >
        <Text variant="body2">{text}</Text>
        <div
          css={css`
            margin: auto;
          `}
        >
          <img src={src} width={screenType === 'mobile' ? '327px' : '800px'} height="328px" />
        </div>
        <PersistentNotification variant="info">{persistentNotificationText}</PersistentNotification>
        <div
          css={css`
            margin-left: ${screenType === 'mobile' ? '' : 'auto'};
          `}
        >
          <ButtonLink size={screenType === 'mobile' ? 'large' : 'medium'} icon="arrow-up-right-regular" to={href}>
            {buttonText}
          </ButtonLink>
        </div>
      </div>
    </div>
  )
})
