import { Button, useEnqueueTemporaryNotificationAlert, useTranslate } from '@orus.eu/pharaoh'
import { memo, useEffect, useMemo, useRef, type ReactNode } from 'react'

export const UpdateHandler = memo<{ children: ReactNode }>(function UpdateHandler({ children }) {
  const { enqueueTemporaryNotificationAlert } = useEnqueueTemporaryNotificationAlert()
  const translate = useTranslate()

  const updateToastRef = useRef<() => void | undefined>(undefined)

  const notificationDisplayedRef = useRef(false)

  const updateButton = useMemo(
    () => (
      <Button
        onClick={() => {
          window.location.reload()
        }}
        variant="secondary"
      >
        {translate('force_update_label')}
      </Button>
    ),
    [translate],
  )

  useEffect(() => {
    const handleUpdateSuggested = () => {
      if (!notificationDisplayedRef.current) {
        updateToastRef.current = enqueueTemporaryNotificationAlert(translate('new_webapp_version_available'), {
          variant: 'info',
          extra: updateButton,
        })
        notificationDisplayedRef.current = true
      }
    }

    window.addEventListener('update-webapp-suggested', handleUpdateSuggested)

    return () => {
      window.removeEventListener('update-webapp-suggested', handleUpdateSuggested)
    }
  }, [enqueueTemporaryNotificationAlert, updateButton, translate, notificationDisplayedRef])

  return children
})
