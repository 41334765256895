import { css } from '@emotion/react'
import { memo } from 'react'
import { useUiContext } from '../../../../code-only/hooks'
import { DatePicker, type DatePickerProps } from '../../../../components/index.js'
import { TextInputLabelWrapper, type TextInputLabelWrapperProps } from '../../input-label-wrapper/index.js'

export type DatepickerFormFieldProps = DatePickerProps & Omit<TextInputLabelWrapperProps, 'children'>

export const DatepickerFormField = memo<DatepickerFormFieldProps>(function DatepickerFormField(props) {
  const screenVariant = useUiContext()
  const { fullWidth } = props
  return (
    <TextInputLabelWrapper
      variant={props.variant}
      label={props.label}
      caption={props.caption}
      infoTooltip={props.infoTooltip}
    >
      <DatePicker
        {...props}
        css={css`
          ${screenVariant !== 'mobile' && !fullWidth ? 'max-width: 400px;' : ''}
        `}
      />
    </TextInputLabelWrapper>
  )
})
