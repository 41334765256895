import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { memo, type ReactNode } from 'react'
import { useScreenType } from '../../../code-only/hooks'
import { useUiContext, type UiContext } from '../../../code-only/hooks/use-screen-variant.js'
import { Avatar, Tooltip } from '../../../components/index.js'
import { colorTokens } from '../../../foundations/color-tokens.js'
import { spacing } from '../../../foundations/spacing-tokens.js'
import { cssPropsPerScreenVariantPerTextVariant, type TextCssProps } from '../../../foundations/text/css-props.js'
import { Text } from '../../../foundations/text/text.js'
import { type TextVariant } from '../../../foundations/text/variant.js'

export type Variant = 'mobile' | 'desktop' | 'backoffice'

export type TextInputLabelWrapperProps = {
  badge?: ReactNode
  label?: string
  caption?: string
  children: ReactNode
  labelTextVariant?: SupportedTextVariant
  captionTextVariant?: TextVariant
  variant?: UiContext
  className?: string
  infoTooltip?: string
  labelColor?: string
  captionColor?: string
  required?: boolean
}

export const TextInputLabelWrapper = memo<TextInputLabelWrapperProps>(function TextInputLabelWrapper(props) {
  const {
    badge,
    label,
    caption,
    children,
    variant,
    labelTextVariant = 'body2Medium',
    captionTextVariant = 'caption',
    labelColor,
    captionColor,
    className,
    infoTooltip,
    required,
  } = props

  const screenVariant = useUiContext()
  const labelTypography = cssPropsPerScreenVariantPerTextVariant[labelTextVariant][variant ?? screenVariant]

  return (
    <div className={className}>
      {label ? (
        <LabelContainer variant={'desktop'}>
          {badge}
          <Label typography={labelTypography} labelColor={labelColor}>
            {label}

            {required ? (
              <span
                css={css`
                  color: ${colorTokens['color-text-danger-secondary']};
                `}
              >
                {' '}
                *
              </span>
            ) : null}
          </Label>
          {infoTooltip ? (
            <Tooltip title={infoTooltip}>
              <Avatar
                icon="circle-info-regular"
                size="10"
                css={css`
                  flex-shrink: 0;
                `}
              />
            </Tooltip>
          ) : null}
        </LabelContainer>
      ) : null}

      <div
        css={css`
          margin: ${spacing[20]} 0;
        `}
      >
        {children}
      </div>

      {caption ? (
        <Caption variant={captionTextVariant} color={captionColor ?? colorTokens['color-text-base-secondary']}>
          {caption}
        </Caption>
      ) : null}
    </div>
  )
})

const LabelContainer = styled.label<{ variant?: UiContext }>`
  display: flex;
  align-items: center;
  gap: ${spacing[30]};
  justify-content: ${({ variant }) => (variant === 'mobile' ? 'space-between' : 'flex-start')};
`

const Label = styled.div<{ typography: TextCssProps; labelColor?: string }>`
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  ${({ typography }) => typography}
  color: ${({ labelColor }) => (labelColor ? labelColor : colorTokens['color-text-base-main'])};
`

const Caption = styled(Text)`
  height: ${spacing[60]};
  display: flex;
  align-items: center;
`

export type ButtonInputLabelWrapperProps = {
  label?: string
  labelVariant?: TextVariant
  value: ReactNode
  leftComponent?: ReactNode
  rightComponent?: ReactNode
  variant?: Variant
  className?: string
  infoTooltip?: string
  rowGap?: string
}

export const ButtonInputLabelWrapper = memo<ButtonInputLabelWrapperProps>(function ButtonInputLabelWrapper(props) {
  const screenType = useScreenType()
  const {
    label,
    leftComponent,
    rightComponent,
    value,
    variant = screenType,
    className,
    infoTooltip,
    rowGap,
    labelVariant,
  } = props

  const body2Typo = cssPropsPerScreenVariantPerTextVariant['body2'][variant]
  const labelTypography = labelVariant ? cssPropsPerScreenVariantPerTextVariant[labelVariant][variant] : body2Typo

  return (
    <div
      className={className}
      css={css`
        display: grid;
        grid-template-columns: auto 1fr auto;
        grid-template-rows: auto auto;
        align-items: center;
        grid-row-gap: ${rowGap ?? ''};
      `}
    >
      <div
        css={css`
          grid-column: span 3;
          font-size: ${labelTypography.fontSize};
          font-weight: ${labelTypography.fontWeight};
          line-height: ${labelTypography.lineHeight};
          flex-grow: ${variant === 'mobile' ? 1 : 0};
        `}
      >
        {label}
      </div>
      <div>{leftComponent}</div>
      <div
        css={css`
          display: flex;
          align-items: center;
          gap: ${spacing[30]};
        `}
      >
        <div
          css={css`
            font-size: ${labelTypography.fontSize};
            font-weight: ${labelTypography.fontWeight};
            line-height: ${labelTypography.lineHeight};
            flex-grow: ${variant === 'mobile' ? 1 : 0};
          `}
        >
          {value}
        </div>

        {infoTooltip ? (
          <Tooltip title={infoTooltip}>
            <Avatar icon="circle-info-regular" size="10" />
          </Tooltip>
        ) : (
          <></>
        )}
      </div>
      <div>{rightComponent}</div>
    </div>
  )
})

/**
 * Sometimes labels used for form fields take differents sizes. Add types as you need.
 */
export type SupportedTextVariant = 'subtitle' | 'body2Medium'
