import { activityInputType } from '@orus.eu/activity'
import { companyIdNumberType } from '@orus.eu/company-id-number'
import { success, type Result } from '@orus.eu/result'
import { type } from 'arktype'
import {
  AbstractDimension,
  dimensionValidationFailure,
  type DimensionValidationProblem,
  type LooselyTypedValue,
} from '../abstract-dimension'
import { BooleanDimension } from '../boolean-dimension'
import { oriasImmatriculationType } from '../orias-dimension'

export class RcpaSubsidiariesDimension<NAME extends string> extends AbstractDimension<NAME, RcpaSubsidiary[]> {
  override validateData(value: LooselyTypedValue): Result<RcpaSubsidiary[], DimensionValidationProblem> {
    const result = rcpaSubsidiaryType.array()(value)
    if (result instanceof type.errors) {
      return dimensionValidationFailure(`Field ${this.name} is not valid : ${result.message}`)
    }
    return success(result)
  }
}

export const rcpaSubsidiariesDimension = new RcpaSubsidiariesDimension({
  name: 'rcpaSubsidiaries',
  displayValues: { name: 'Filiales' },
  tags: ['RCPA'],
})

export const isRcpaSubsidiariesAvailableDimension = new BooleanDimension({
  name: 'isRcpaSubsidiariesAvailable',
  displayValues: { name: 'Peut avoir des filiales' },
  tags: ['RCPA'],
})

export const isRcpaSubsidiariesCompletedDimension = new BooleanDimension({
  name: 'isRcpaSubsidiariesCompleted',
  displayValues: { name: 'Filiales complétées' },
  tags: ['RCPA'],
})

export const rcpaSubsidiaryType = type({
  id: 'string',
  name: 'string|undefined|null',
  oriasNumber: oriasImmatriculationType.optional().or('undefined').or('null'),
  siren: companyIdNumberType.optional().or('undefined').or('null'),
  activities: activityInputType.array(),
  address: type({
    street: 'string|undefined|null',
    postCode: 'string|undefined|null',
    city: 'string|undefined|null',
    country: 'string|undefined|null',
  })
    .optional()
    .or('undefined')
    .or('null'),
})

export type RcpaSubsidiary = typeof rcpaSubsidiaryType.infer
