import { type } from 'arktype'
import { z } from 'zod'

/**
 * Parses a NIF, CIF, or NIE value.
 *
 * No authoritative reference found
 * Rules inferred from the examples given here https://testingdatagenerator.com/doi.html
 * (link provided by the Hiscox team)
 *
 * @param input - The input string to parse.
 * @returns The parsed and normalized value or null if the input is invalid.
 */
export function parseNifValue(input: string): string | null {
  const normalizedInput = input.replace(/[\s.-]/g, '').toUpperCase()
  if (nifRegex.test(normalizedInput) || nieRegex.test(normalizedInput)) {
    return normalizedInput
  }
  return null
}

export const nifValueType = type('string').narrow((s) => parseNifValue(s) !== null)

export const nifValueZodSchema = z.string().refine(parseNifValue, {
  message: 'Invalid NIF',
})

const nifRegex = /^[0-9]{8}[A-Z]$/
const nieRegex = /^[A-Z]{1}[0-9]{7}[A-Z]$/
