import styled from '@emotion/styled'
import { desktopMediaQuery } from '../../code-only/hooks'
import { spacing } from '../../foundations'

export type NavbarVariant = 'backoffice' | 'clientapp' | 'partner'

export type NavbarProps = {
  variant: NavbarVariant
}

export const StyledBackofficeNavbar = styled.nav`
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  gap: ${spacing[70]};
`

export const StyledClientAppNavbar = styled.nav`
  height: 100%;
  display: flex;
  flex-direction: column;
  width: auto;

  ${desktopMediaQuery} {
    width: 320px;
  }

  gap: ${spacing[70]};
`
