import { Slider as MuiSlider, styled } from '@mui/material'
import { memo, useCallback, type SyntheticEvent } from 'react'
import { colorTokens } from '../../foundations'

export type SliderMark = {
  value: number
}

type SliderProps = {
  min: number
  max: number
  marks?: boolean | SliderMark[]
  step?: number | null
  disabled?: boolean
  value: number
  onChange: (value: number) => void
  onChangeCommitted?: (value: number) => void
  className?: string
  'aria-label'?: string
}

export const Slider = memo<SliderProps>(function Slider(props) {
  const {
    min,
    max,
    step,
    value,
    marks,
    onChange,
    onChangeCommitted,
    className,
    'aria-label': ariaLabel,
    disabled,
  } = props

  const handleChange = useCallback(
    (_event: Event, value: number | number[]) => {
      if (typeof value === 'number') onChange(value)
    },
    [onChange],
  )

  const handleCommittedChange = useCallback(
    (_event: Event | SyntheticEvent<Element, Event>, value: number | number[]) => {
      if (typeof value === 'number') onChangeCommitted?.(value)
    },
    [onChangeCommitted],
  )

  return (
    <StyledSlider
      min={min}
      max={max}
      step={step}
      disabled={disabled}
      value={value}
      onChange={handleChange}
      onChangeCommitted={handleCommittedChange}
      marks={marks}
      className={className}
      aria-label={ariaLabel}
    />
  )
})

const StyledSlider = styled(MuiSlider)(() => ({
  '& .MuiSlider-rail': {
    backgroundColor: colorTokens['color-bg-base-tertiary'],
  },
  '& .MuiSlider-track': {
    backgroundColor: colorTokens['color-bg-base-active'],
  },
}))
