import { createFileRoute } from '@tanstack/react-router'

import PlatformUsersPage from '../../../components/pages/backoffice/platform/platform-users-page'
import { PermissionChecker } from '../../../components/templates/permission-checker'

export const Route = createFileRoute('/bak/users/')({
  component: () => (
    <PermissionChecker requiredPermissions={['users.read']}>
      <PlatformUsersPage />
    </PermissionChecker>
  ),
})
