import { createFileRoute } from '@tanstack/react-router'

import PartnerPendingSubscriptionsPage from '../../../../components/pages/backoffice/partners/partners-pending-subscriptions-page'
import { PermissionChecker } from '../../../../components/templates/permission-checker'

export const Route = createFileRoute('/partner/$organization/pending-subscriptions/')({
  component: () => (
    <PermissionChecker requiredPermissions={['subscription.read']}>
      <PartnerPendingSubscriptionsPage />
    </PermissionChecker>
  ),
})
