import {
  type CanonicalCompanyIdNumberType,
  type CompanyIdNumber,
  companyIdNumberType,
} from '@orus.eu/company-id-number'
import type { OperatingZone } from '@orus.eu/operating-zone'
import { type Result, success } from '@orus.eu/result'
import { type } from 'arktype'
import {
  AbstractDimension,
  type DimensionValidationProblem,
  type LooselyTypedValue,
  dimensionValidationFailure,
} from './abstract-dimension'

export class CompanyIdNumberDimension<NAME extends string> extends AbstractDimension<NAME, CompanyIdNumber> {
  override validateData(value: LooselyTypedValue): Result<CompanyIdNumber, DimensionValidationProblem> {
    const result = companyIdNumberType(value)
    if (result instanceof type.errors) {
      return dimensionValidationFailure(`Field ${this.name} is not valid : ${result.message}`)
    }
    return success(result)
  }
}

export const allowedCompanyIdNumberTypesPerZone: Record<OperatingZone, Set<CanonicalCompanyIdNumberType>> = {
  fr: new Set(['siren', 'later']),
  es: new Set(['cif', 'nif', 'later']),
}
