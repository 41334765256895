import { createFileRoute } from '@tanstack/react-router'

import InvoicesPage from '../../../components/pages/invoices-and-payments/invoices-page'
import { PermissionChecker } from '../../../components/templates/permission-checker'

export const Route = createFileRoute('/_customer/invoices')({
  component: () => (
    <PermissionChecker requiredPermissions={['invoices.read']}>
      <InvoicesPage />
    </PermissionChecker>
  ),
})
