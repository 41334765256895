import { createFileRoute } from '@tanstack/react-router'

import PlatformChangeContractStatusPage from '../../../../../components/pages/backoffice/platform/platform-change-contract-status-page'
import { PermissionChecker } from '../../../../../components/templates/permission-checker'

export const Route = createFileRoute('/bak/contracts/$subscriptionId/status')({
  component: () => (
    <PermissionChecker requiredPermissions={['contracts.update']}>
      <PlatformChangeContractStatusPage />
    </PermissionChecker>
  ),
})
