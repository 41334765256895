import { createFileRoute } from '@tanstack/react-router'

import InsuranceCertificateGeneratorRealEstateSalesAgentsPage from '../../../../components/pages/insurance-certificate-generator-real-estate-sales-agents-page'
import { PermissionChecker } from '../../../../components/templates/permission-checker'

export const Route = createFileRoute(
  '/_customer/insurance-certificate-generator-real-estate-sales-agents/$subscriptionId',
)({
  component: () => (
    <PermissionChecker requiredPermissions={['contracts.read']}>
      <InsuranceCertificateGeneratorRealEstateSalesAgentsPage />
    </PermissionChecker>
  ),
})
