import { createFileRoute } from '@tanstack/react-router'

import PlatformAdminCpmsPage from '../../../../components/pages/backoffice/platform/platform-admin-cpms-page'
import { PermissionChecker } from '../../../../components/templates/permission-checker'

export const Route = createFileRoute('/bak/admin/cpms')({
  component: () => (
    <PermissionChecker requiredPermissions={['tech.configurations']}>
      <PlatformAdminCpmsPage />
    </PermissionChecker>
  ),
})
