import { css } from '@emotion/react'
import { memo, useCallback, useState } from 'react'
import { problemColor, secondaryColor } from '../../../../code-only/theme'
import { Avatar, Button } from '../../../../components'
import { colorTokens, spacing, Text, type CompoundIconName } from '../../../../foundations'
import { Markdown } from '../../../atoms'
import { RowContainerV2 } from '../../../molecules'

type Variant = 'mainActivities' | 'secondaryActivities' | 'forbiddenActivities'

type RelatedActivitiesListProps = {
  activities: string[]
  variant: Variant
}

export const RelatedActivitiesList = memo<RelatedActivitiesListProps>(function RelatedActivitiesList(props) {
  const { activities, variant } = props

  const [displayedActivities, setDisplayedActivities] = useState(8)

  const activitiesToDisplay = activities.slice(0, displayedActivities)

  const handleShowAllActitivities = useCallback(() => {
    setDisplayedActivities(activities.length)
  }, [activities.length])

  const config = CONFIG_PER_VARIANT[variant]

  return (
    <RowContainerV2
      css={css`
        padding: ${spacing[60]};
        background-color: ${config.backgroundColor};
        margin-top: ${spacing[70]};
      `}
    >
      <div
        css={css`
          margin-bottom: ${spacing[50]};
        `}
      >
        <Text variant="body1Medium">{config.title}</Text>
        <Text variant="caption">Les principaux cas</Text>
      </div>
      {activitiesToDisplay.map((activity) => (
        <div
          key={activity}
          css={css`
            display: flex;
            gap: ${spacing[50]};
            align-items: center;
            margin-bottom: ${spacing[30]};
            margin-top: ${spacing[30]};
          `}
        >
          <Avatar icon={config.itemIcon.name} size="30" color={config.itemIcon.color} />
          <Markdown markdown={activity} />
        </div>
      ))}
      {activities.length > 8 && displayedActivities <= 8 ? (
        <div
          css={css`
            margin: auto;
          `}
        >
          <Button
            variant="secondary"
            onClick={handleShowAllActitivities}
            css={css`
              margin-top: ${spacing[50]};
            `}
            avatar={<Avatar icon="angle-down-solid" size="30" />}
          >
            Voir toute la liste
          </Button>
        </div>
      ) : (
        <></>
      )}
    </RowContainerV2>
  )
})

const CONFIG_PER_VARIANT: Record<
  Variant,
  {
    backgroundColor: string
    title: string
    itemIcon: {
      name: CompoundIconName
      color: string
    }
  }
> = {
  mainActivities: {
    backgroundColor: colorTokens['color-bg-base-focus'],
    title: 'Les prestations principales',
    itemIcon: {
      name: 'check-solid',
      color: secondaryColor,
    },
  },
  secondaryActivities: {
    backgroundColor: colorTokens['color-bg-base-focus'],
    title: 'Les prestations secondaires autorisées',
    itemIcon: {
      name: 'check-solid',
      color: secondaryColor,
    },
  },
  forbiddenActivities: {
    backgroundColor: colorTokens['color-bg-danger-inverse'],
    title: 'Les exclusions',
    itemIcon: {
      name: 'xmark-solid',
      color: problemColor,
    },
  },
}
