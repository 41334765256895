import type { ProductInformationId } from '@orus.eu/dimensions'
import type { AvatarDecorativeWithIconProps, AvatarDecorativeWithIllustrationProps } from '../../../components'

export const productsAvatarConfigurations: Record<
  ProductInformationId,
  {
    avatar: Required<Pick<AvatarDecorativeWithIconProps, 'icon' | 'backgroundColor'>>
    illustration: Required<Pick<AvatarDecorativeWithIllustrationProps, 'illustration'>>
  }
> = {
  mrph: {
    avatar: { icon: 'warehouse-light', backgroundColor: 'jasmine' },
    illustration: { illustration: 'mrp_big' },
  },
  mrpw: {
    avatar: { icon: 'shop-light', backgroundColor: 'jasmine' },
    illustration: { illustration: 'mrp_big' },
  },
  restaurant: {
    avatar: { icon: 'user-chef-light', backgroundColor: 'jasmine' },
    illustration: { illustration: 'mrp_big' },
  },
  'rcph-pj': {
    avatar: { icon: 'scale-balanced-light', backgroundColor: 'peach' },
    illustration: { illustration: 'pj_big' },
  },
  'rcph-cyber': {
    avatar: { icon: 'shield-halved-light', backgroundColor: 'mindaro' },
    illustration: { illustration: 'cyber_small' },
  },
  'rcph-tdmi': {
    avatar: { icon: 'laptop-mobile-light', backgroundColor: 'periwinkle' },
    illustration: { illustration: 'tdmi_small' },
  },
  'mrp-small-office': {
    avatar: { icon: 'house-light', backgroundColor: 'mindaro' },
    illustration: { illustration: 'mrp_big' },
  },
  'rc-pro': {
    avatar: { icon: 'book-open-cover-light', backgroundColor: 'sky' },
    illustration: { illustration: 'rcp_big' },
  },
  muta: {
    avatar: { icon: 'stethoscope-light', backgroundColor: 'peach' },
    illustration: { illustration: 'mutuelle_big' },
  },
  rcda: {
    avatar: { icon: 'shovel-light', backgroundColor: 'periwinkle' },
    illustration: { illustration: 'rcd_big' },
  },
  'rcda-rc-pro': {
    avatar: { icon: 'book-open-cover-light', backgroundColor: 'sky' },
    illustration: { illustration: 'rcp_big' },
  },
  'es-rcph-pro': {
    avatar: { icon: 'book-open-cover-light', backgroundColor: 'sky' },
    illustration: { illustration: 'rcp_big' },
  },
  'es-rcph-general': {
    avatar: { icon: 'book-open-cover-light', backgroundColor: 'sky' },
    illustration: { illustration: 'rcp_big' },
  },
  rcpa: {
    avatar: { icon: 'book-open-cover-light', backgroundColor: 'sky' },
    illustration: { illustration: 'rcpr_big' },
  },
}
