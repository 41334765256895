import {
  AbstractDimension,
  activitiesDimension,
  activitiesDistributionDimension,
  activityAddressCityDimension,
  activityAddressDimension,
  activityAddressPostCodeDimension,
  activityAddressStreetDimension,
  activityDimension,
  activitySpecificAnswersDimension,
  activitySpecificQuestionsDimension,
  availableRiskCarrierProductsDimension,
  birthCityDimension,
  birthCountryDimension,
  birthDateDimension,
  brokerDiscountDimension,
  civilLiabilitiesFiveYearsDimension,
  commitmentDimension,
  companyCreationDateDimension,
  companyCreationMaxDateDimension,
  companyIdNumberDimension,
  companyInCreationAllowedDimension,
  companyNameDimension,
  complementaryActivitiesDimension,
  customCoverDimension,
  customMrpActivityAddressDimension,
  customMrpActivityDimension,
  customMrpSummaryDimension,
  customMutaSummaryDimension,
  customRcdaActivityDimension,
  customRcdaSummaryDimension,
  customRcpaActivityDimension,
  customRcpaSummaryDimension,
  customRcphActivityDimension,
  customRcphSummaryDimension,
  customSignatureDimension,
  defaultInstallmentFeeRateDimension,
  detailedSheetAcagSynthesisDimension,
  detailedSheetActivitySynthesisDimension,
  detailedSheetCompleteStatusDimension,
  detailedSheetExperienceStatusDimension,
  detailedSheetExperienceSynthesisDimension,
  detailedSheetFirstLevelResponsibleDimension,
  detailedSheetGlobalSynthesisDimension,
  detailedSheetHistorySynthesisDimension,
  detailedSheetSecondLevelResponsibleDimension,
  detailedSheetThirdLevelResponsibleDimension,
  discountDimension,
  documentsLockConfirmedDimension,
  documentsValidatedDimension,
  emailDimension,
  esRcphCanadaOrUsaRevenuePercentageDimension,
  esRcphCanadaOrUsaRevenuePercentageRequiredDimension,
  esRcphCustomActivityDimension,
  esRcphCustomSummaryDimension,
  esRcphHeadcountDimension,
  esRcphHeadcountRequiredDimension,
  esRcphHeavySingleCustomerDimension,
  esRcphHeavySingleCustomerRelevantDimension,
  esRcphIsSecuDimension,
  esRcphMoreThanTwoYearsOfXpDimension,
  esRcphProductDimension,
  esRcphSelectedDimension,
  esRcphVehicleCountDimension,
  esRcphVehicleCountRequiredDimension,
  estimatedRevenueDimension,
  firstNameDimension,
  generalTermsGroupsDimension,
  globalDocumentNoteDimension,
  hasAcceptedCommercialMessagesDimension,
  hasAcceptedDimension,
  hasMultipleActivitiesDimension,
  hiddenQuoteDimension,
  installmentFeeRateDimension,
  isInstallmentFeeRateEditableDimension,
  isRcpaCifWorkforceNeededDimension,
  isRcpaOriasRegulatedActivityDimension,
  isRcpaPersonaNeededDimension,
  lastNameDimension,
  manuallyExcludedDimension,
  manuallySetInstallmentFeeRateDimension,
  maxActivitiesCountDimension,
  maxStartDateDimension,
  maxStartDateExplanationDimension,
  minStartDateDimension,
  mrphAssetsClaimsThreeYearsDimension,
  mrphAssetsValueDimension,
  mrphDeductibleDaysDimension,
  mrphFormulaDimension,
  mrphHiscoxActivitiesNamesDimension,
  mrphIndemnityDurationMonthsDimension,
  mrphItAssetsValueDimension,
  mrphOccupationStatusDimension,
  mrphQuoteV2Dimension,
  mrphSelectedDimension,
  mrphSurfaceDimension,
  mrpwActivityAddressAllowedWakamCitiesDimension,
  mrpwAssetsClaimsThreeYearsDimension,
  mrpwAssetsValueDimension,
  mrpwHasManagementExperienceInPizzeriaDimension,
  mrpwManagementExperienceInPizzeriaRequiredDimension,
  mrpwOccupationStatusDimension,
  mrpwOptionBoostDimension,
  mrpwOptionRcTravauxDimension,
  mrpwPremiumOverchargesDmgDimension,
  mrpwPremiumOverchargesIncPePvvDimension,
  mrpwPremiumOverchargesRcDimension,
  mrpwPricingVersionDimension,
  mrpwQuoteDimension,
  mrpwRcTravauxEndDimension,
  mrpwRcTravauxStartDimension,
  mrpwSelectedDimension,
  mrpwSurfaceDimension,
  mrpwWakamMainActivityDimension,
  mutaBeneficiariesDimension,
  mutaClientRecommendedFormulaDimension,
  mutaFormulaDimension,
  mutaHasBeneficiariesDimension,
  mutaPossibleFormulasDimension,
  mutaQuoteDimension,
  mutaReimbursementBankAccountDimension,
  mutaSelectedDimension,
  mutaSocialSecurityNumberDimension,
  mutaSocialSecurityOrganismNumberDimension,
  mutaSocialSecurityRegimeDimension,
  neededSubscriptionDocumentsDimension,
  nomenclatureVersionDimension,
  offerInformationDimension,
  operatingZoneDimension,
  organizationCanTerminateContractsDimension,
  oriasImmatriculationDimension,
  partnerApplicationFeeDimension,
  partnerManagementFeeRateDimension,
  paymentRecurrenceDimension,
  personalAddressCityDimension,
  personalAddressIsNotActivityAddress,
  personalAddressPostCodeDimension,
  personalAddressStreetDimension,
  phoneDimension,
  placeNameDimension,
  placeSearchQueryDimension,
  placeSearchResultsDimension,
  possibleOffersDimension,
  preferredPaymentDayDimension,
  quoteDimension,
  rcdaAxeriaActivityNamesDimension,
  rcdaBossExperienceDimension,
  rcdaClaimsCountDimension,
  rcdaClaimsTotalValueDimension,
  rcdaGlobalOverchargeRateDimension,
  rcdaHasClaimsDimension,
  rcdaHasProfessionalQualificationDimension,
  rcdaHasRevenueLimitExceededDimension,
  rcdaInsuranceInformationDimension,
  rcdaInsurancePeriodStartDateDimension,
  rcdaManagementFeeRateDimension,
  rcdaMaxInsurancePeriodStartDateDimension,
  rcdaPercentageSubcontractedDimension,
  rcdaPreviousInsurersNamesDimension,
  rcdaPreviouslyInsuredDimension,
  rcdaQuoteDimension,
  rcdaSelectedDimension,
  rcpaAgentsDimension,
  rcpaAvailableActivitiesForMultiActivityDimension,
  rcpaAvailableAgentsActivitiesDimension,
  rcpaAvailableDimension,
  rcpaCifWorkforceDimension,
  rcpaHasAgentsDimension,
  rcpaHasSubsidiariesDimension,
  rcpaInstallmentFeeRateDimension,
  rcpaManagementFeeRateDimension,
  rcpaMultiActivityAvailableDimension,
  rcpaPersonaDimension,
  rcpaSelectedDimension,
  rcpaSubsidiariesDimension,
  rcphAvailableDimension,
  rcphCanadaOrUsaRevenuePercentageDimension,
  rcphCyberHighOnlineRevenueDimension,
  rcphCyberManyCreditCardPaymentsDimension,
  rcphHiscoxActivitiesNamesDimension,
  rcphInstallmentFeeRateDimension,
  rcphManagementFeeRateDimension,
  rcphOptionCyberDimension,
  rcphOptionMrpSmallOfficeDimension,
  rcphOptionTdmiDimension,
  rcphPremiumOverchargeDimension,
  rcphProductDimension,
  rcphProductLabelDimension,
  rcphQuoteV2Dimension,
  rcphSelectedDimension,
  riskCarrierProductsDimension,
  siretDimension,
  siretRequiredDimension,
  startDateDimension,
  statementDimension,
  statementWarningDimension,
  terminatePreviousContractDimension,
  terminatePreviousContractRelevantDimension,
  workforceDimension,
  yearlyCommitmentHasDiscountDimension,
} from '../dimension'
import {
  getElementDimensions,
  type SubscriptionCondition,
  type SubscriptionStepId,
  type SubscriptionUiElement,
} from '../subscription-ui'
import { quoteDimensions } from '../subscription-ui/quote-element-dimensions'
import { siretSearchSubscriptionBodyElement } from '../subscription-ui/siret-search-ui-element'
import type { QuoteEditorSection } from './quote-editor-ui-section'

export const quoteEditorSections: QuoteEditorSection[] = [
  {
    id: 'custom-clauses',
    backofficeUi: {
      tab: 'pricing',
      breadcrumb: 'Documents',
      title: 'Clauses personnalisables',
      subtitle: 'Les clauses personnalisables seront ajoutées dans l’attestation et les CP.',
    },
    bodyElements: [
      {
        type: 'custom-clauses',
        dimensions: {
          customerCover: customCoverDimension,
          customRcphActivity: customRcphActivityDimension,
          customSignature: customSignatureDimension,
          customRcphSummary: customRcphSummaryDimension,
          customMrpActivityAddress: customMrpActivityAddressDimension,
          customMrpSummary: customMrpSummaryDimension,
          customMrpActivity: customMrpActivityDimension,
          customMutaSummary: customMutaSummaryDimension,
          customRcdaSummary: customRcdaSummaryDimension,
          customRcdaActivity: customRcdaActivityDimension,
          mrpwSelected: mrpwSelectedDimension,
          mutaSelected: mutaSelectedDimension,
          rcdaSelected: rcdaSelectedDimension,
          rcphSelected: rcphSelectedDimension,
          mrphSelected: mrphSelectedDimension,
          esRcphSelected: esRcphSelectedDimension,
          esRcphCustomActivity: esRcphCustomActivityDimension,
          esRcphCustomSummary: esRcphCustomSummaryDimension,
          rcpaSelected: rcpaSelectedDimension,
          customRcpaActivity: customRcpaActivityDimension,
          customRcpaSummary: customRcpaSummaryDimension,
        },
      },
    ],
    hideForPartners: true,
    whenContractSigned: true,
  },
  {
    id: 'contact-data',
    backofficeUi: {
      tab: 'pricing',
      breadcrumb: 'Coordonnées client',
      title: 'Informations de contact',
      subtitle: 'Coordonnées du contact.',
    },
    bodyElements: [
      { type: 'email', dimension: emailDimension, layout: { width: 'narrow' } },
      { type: 'phone', dimension: phoneDimension, layout: { width: 'narrow' } },
      { type: 'email-availablitiy-checker', dimension: emailDimension, goToNewBackofficeLine: true },
      { type: 'quote-priority-notification', dimension: emailDimension, goToNewBackofficeLine: true },
    ],
  },
  {
    id: 'operating-zone',
    backofficeUi: {
      tab: 'pricing',
      breadcrumb: 'Activité',
      title: 'Zone de souscription',
    },
    bodyElements: [
      {
        type: 'choice',
        variant: 'select',
        dimension: operatingZoneDimension,
        isSingleLineElement: { isSingleMini: true },
        hideLabel: true,
      },
    ],
    hideForPartners: true,
  },
  {
    id: 'activity',
    backofficeUi: {
      tab: 'pricing',
      breadcrumb: 'Activité',
      title: "Recherche d'activité",
      subtitle: 'Détermine les produits d’assurances et activités secondaires éligibles.',
    },
    bodyElements: [
      {
        type: 'activity-bo-search',
        dimensions: {
          activity: activityDimension,
          complementaryActivities: complementaryActivitiesDimension,
          rcphProduct: rcphProductDimension,
          possibleOffers: possibleOffersDimension,
          operatingZone: operatingZoneDimension,
          mrphHiscoxActivitiesNames: mrphHiscoxActivitiesNamesDimension,
          rcphHiscoxActivitiesNames: rcphHiscoxActivitiesNamesDimension,
          rcphProductLabel: rcphProductLabelDimension,
          mrpwWakamMainActivity: mrpwWakamMainActivityDimension,
          rcdaAxeriaActivityNames: rcdaAxeriaActivityNamesDimension,
          maxActivitiesCount: maxActivitiesCountDimension,
          rcphSelected: rcphSelectedDimension,
          mrphSelected: mrphSelectedDimension,
          mutaSelected: mutaSelectedDimension,
          rcdaSelected: rcdaSelectedDimension,
          mrpwSelected: mrpwSelectedDimension,
          esRcphSelected: esRcphSelectedDimension,
          availableRiskCarrierProducts: availableRiskCarrierProductsDimension,
          rcpaSelected: rcpaSelectedDimension,
        },
        isSingleLineElement: { isSingleMini: false },
      },
      {
        type: 'activity-specific-questions',
        dimensions: {
          activity: activityDimension,
          activitySpecificQuestions: activitySpecificQuestionsDimension,
          answers: activitySpecificAnswersDimension,
        },
        condition: { notEmpty: activitySpecificQuestionsDimension },
      },
      {
        type: 'activity-selected-info',
        dimensions: {
          activity: activityDimension,
          mrphHiscoxActivitiesNames: mrphHiscoxActivitiesNamesDimension,
          rcphHiscoxActivitiesNames: rcphHiscoxActivitiesNamesDimension,
          rcphProductLabel: rcphProductLabelDimension,
          mrpwWakamMainActivity: mrpwWakamMainActivityDimension,
          rcdaAxeriaActivityNames: rcdaAxeriaActivityNamesDimension,
        },
        condition: {
          and: [{ not: { or: [rcphSelectedDimension, rcdaSelectedDimension] } }, { defined: possibleOffersDimension }], // is possibleOffersDimension ?
        },
        isSingleLineElement: { isSingleMini: false },
      },
    ],
  },
  {
    id: 'complementary-activities-selection',
    condition: {
      and: [
        {
          or: [
            rcphSelectedDimension,
            rcdaSelectedDimension,
            { and: [rcpaAvailableDimension, rcpaMultiActivityAvailableDimension] },
          ],
        },
        { defined: possibleOffersDimension },
      ],
    },
    backofficeUi: {
      tab: 'pricing',
      breadcrumb: 'Activité',
      title: 'Activités (5 maximum)',
      subtitle: 'Élargi la couverture sur les prestations de toutes les activités listées ici.',
    },
    bodyElements: [
      {
        type: 'activities-selector',
        dimensions: {
          operatingZone: operatingZoneDimension,
          activity: activityDimension,
          complementaryActivities: complementaryActivitiesDimension,
          maxActivitiesCount: maxActivitiesCountDimension,
          possibleOffers: possibleOffersDimension,
          rcphProduct: rcphProductDimension,
          mrphHiscoxActivitiesNames: mrphHiscoxActivitiesNamesDimension,
          rcphHiscoxActivitiesNames: rcphHiscoxActivitiesNamesDimension,
          rcphProductLabel: rcphProductLabelDimension,
          mrpwWakamMainActivity: mrpwWakamMainActivityDimension,
          rcdaAxeriaActivityNames: rcdaAxeriaActivityNamesDimension,
          nomenclatureVersion: nomenclatureVersionDimension,
        },
      },
    ],
  },
  {
    id: 'product-selection',
    backofficeUi: {
      tab: 'pricing',
      breadcrumb: 'Assurance',
      title: "Produit d'assurance",
      subtitle: 'Sélecteur de produit d’assurance.',
    },
    condition: { defined: activityDimension },
    bodyElements: [
      {
        type: 'product-selection-bo',
        dimensions: {
          mrphSelected: mrphSelectedDimension,
          mrpwSelected: mrpwSelectedDimension,
          mutaSelected: mutaSelectedDimension,
          rcdaSelected: rcdaSelectedDimension,
          rcphSelected: rcphSelectedDimension,
          rcpaSelected: rcpaSelectedDimension,
          esRcphSelected: esRcphSelectedDimension,
          availableRiskCarrierProducts: availableRiskCarrierProductsDimension,
        },
        isSingleLineElement: { isSingleMini: false },
      },
    ],
  },
  {
    id: 'empty-state-quote',
    backofficeUi: {
      tab: 'pricing',
      title: '',
      subtitle: '',
      breadcrumb: '',
    },
    condition: { not: { defined: activityDimension } },
    bodyElements: [
      {
        type: 'empty-state-quote',
      },
    ],
  },
  {
    id: 'mrph-siret-search',
    condition: {
      and: [mrphSelectedDimension, { defined: placeSearchQueryDimension }],
    },
    backofficeUi: {
      tab: 'pricing',
      breadcrumb: 'Fiche Entreprise',
      title: "Recherche d'entreprise",
    },
    bodyElements: [siretSearchSubscriptionBodyElement],
  },
  //#region MRPW siret steps
  {
    id: 'mrpw-siret-search',
    condition: {
      and: [mrpwSelectedDimension, { defined: placeSearchQueryDimension }],
    },
    backofficeUi: {
      tab: 'pricing',
      breadcrumb: 'Fiche Entreprise',
      title: "Recherche d'entreprise",
    },
    bodyElements: [siretSearchSubscriptionBodyElement],
  },
  {
    id: 'mrpw-add-address',
    condition: mrpwSelectedDimension,
    backofficeUi: {
      tab: 'pricing',
      breadcrumb: 'Fiche Entreprise',
      title: 'Informations d’entreprise',
      subtitle: 'Toutes les informations essentielles sur l’entreprise ou l’établissement.',
    },
    bodyElements: [
      {
        type: 'address-map',
        dimension: activityAddressDimension,
        hideInSelfOnboarding: true,
        isSingleLineElement: { isSingleMini: false },
      },
      {
        type: 'product-indicator',
        product: 'mrpw',
        hideInBackoffice: true,
        dimensions: {
          possibleOffersDimension,
          mrpwSelectedDimension,
          mrphSelectedDimension,
          rcphSelectedDimension,
          mutaSelectedDimension,
          rcdaSelectedDimension,
          rcphOptionCyberDimension,
          rcphOptionTdmiDimension,
          riskCarrierProductsDimension,
          esRcphSelectedDimension,
          esRcphProductDimension,
          rcphOptionMrpSmallOfficeDimension,
          rcpaSelectedDimension,
        },
        condition: { defined: activityAddressDimension },
      },
      {
        type: 'siret',
        dimensions: {
          siret: siretDimension,
          companyIdNumber: companyIdNumberDimension,
          companyInCreationAllowed: companyInCreationAllowedDimension,
        },
        layout: { width: 'narrow' },
        isSingleLineElement: { isSingleMini: true },
      },
      {
        type: 'string',
        dimension: placeNameDimension,
        layout: { width: 'narrow' },
      },
      {
        type: 'string',
        dimension: companyNameDimension,
        layout: { width: 'narrow' },
        goToNewBackofficeLine: true,
      },
      {
        type: 'calendar-date',
        dimensions: { value: companyCreationDateDimension },
        layout: { width: 'narrow' },
      },
      {
        type: 'string',
        dimension: activityAddressStreetDimension,
        goToNewBackofficeLine: true,
      },
      {
        type: 'postcode',
        dimension: activityAddressPostCodeDimension,
        layout: { width: 'narrow' },
        goToNewBackofficeLine: true,
      },
      {
        type: 'choice',
        variant: 'select',
        dimensions: {
          value: activityAddressCityDimension,
          allowedValuesResult: mrpwActivityAddressAllowedWakamCitiesDimension,
        },
        layout: { width: 'narrow' },
      },
      {
        type: 'readonly-current-country',
        dimensions: { operatingZone: operatingZoneDimension },
      },
    ],
    partiallyUsedInputDimensions: [companyIdNumberDimension],
  },
  //#endregion MRPW siret steps
  {
    id: 'mrph-add-postcode',
    condition: mrphSelectedDimension,
    backofficeUi: {
      tab: 'pricing',
      breadcrumb: 'Fiche Entreprise',
      title: 'Localisation',
      subtitle: 'Code postal de l’entreprise ou l’établissement.',
    },
    bodyElements: [
      {
        type: 'postcode',
        dimension: activityAddressPostCodeDimension,
        layout: { width: 'narrow' },
        goToNewBackofficeLine: true,
      },
    ],
    partiallyUsedInputDimensions: [companyIdNumberDimension],
  },
  {
    id: 'prequote-subsidiaries',
    condition: rcpaMultiActivityAvailableDimension,
    backofficeUi: {
      tab: 'pricing',
      breadcrumb: 'Parcours de souscription',
      title: 'Filiales',
      subtitle: 'Filiales à assurer par le contrat',
    },
    bodyElements: [
      {
        type: 'boolean',
        dimension: rcpaHasSubsidiariesDimension,
        variant: 'yes-no-buttons',
        isSingleLineElement: { isSingleMini: true },
      },
      {
        type: 'add-subsidiaries',
        dimensions: {
          rcpaSubsidiaries: rcpaSubsidiariesDimension,
          rcpaAvailableActivitiesForMultiActivity: rcpaAvailableActivitiesForMultiActivityDimension,
        },
        condition: rcpaHasSubsidiariesDimension,
      },
    ],
  },
  {
    id: 'prequote-form',
    backofficeUi: {
      tab: 'pricing',
      breadcrumb: 'Parcours de souscription',
      title: 'Tarification',
      subtitle: "Informations principales et tarifiantes de l'offre.",
    },
    bodyElements: [
      //#region RCPH
      {
        type: 'postcode',
        dimension: activityAddressPostCodeDimension,
        displayValues: {
          hint: 'Domiciliation de votre établissement uniquement en France métropolitaine',
        },
        layout: { width: 'narrow' },
        condition: rcphSelectedDimension,
      },
      {
        type: 'amount',
        displayValues: {
          label: 'CA HT estimé des 12 prochains mois',
          hint: 'Prévisionnel HT sur les 12 prochains mois, montant révisable en cours de contrat 🙂. Cela nous permet de vous proposer le tarif le plus adapté',
        },
        positiveOnly: true,
        dimension: estimatedRevenueDimension,
        layout: { width: 'narrow' },
        condition: rcphSelectedDimension,
      },
      {
        type: 'financial-rate',
        variant: 'boolean',
        label: 'Vous faites plus de 30 % de votre chiffre d’affaires aux États-unis ou au Canada.',
        dimension: rcphCanadaOrUsaRevenuePercentageDimension,
        condition: rcphSelectedDimension,
        goToNewBackofficeLine: true,
      },
      {
        type: 'choice',
        dimension: civilLiabilitiesFiveYearsDimension,
        label: 'Vous avez déjà été mis en cause lors d’un sinistre en RC Pro ces 5 dernières années.',
        variant: 'checkbox',
        defaultValue: '0',
        isSingleLineElement: { isSingleMini: true },
        condition: rcphSelectedDimension,
      },
      //#endregion
      //#region MRPH
      {
        type: 'choice',
        variant: 'one-click-submit-rows',
        dimension: civilLiabilitiesFiveYearsDimension,
        condition: mrphSelectedDimension,
      },
      {
        type: 'choice',
        variant: 'one-click-submit-rows',
        hint: 'Le nombre de fois où vos locaux ou leur contenu ont subi des dommages que votre assureur vous a remboursés : dégâts des eaux, bris de glace, incendies, etc.',
        dimension: mrphAssetsClaimsThreeYearsDimension,
        condition: mrphSelectedDimension,
      },
      {
        type: 'choice',
        // make a more generic variant if a second grid appears
        variant: 'one-click-occupation-status-grid',
        dimension: mrphOccupationStatusDimension,
        condition: mrphSelectedDimension,
      },
      {
        type: 'surface',
        dimension: mrphSurfaceDimension,
        condition: mrphSelectedDimension,
      },
      {
        type: 'amount',
        displayValues: {
          label: 'Valeur du contenu (€)',
        },
        dimension: mrphAssetsValueDimension,
        condition: mrphSelectedDimension,
      },
      {
        type: 'amount',
        displayValues: {
          label: 'Valeur du matériel informatique (€)',
        },
        dimension: mrphItAssetsValueDimension,
        condition: mrphSelectedDimension,
      },
      {
        type: 'amount',
        displayValues: {
          label: 'CA HT estimé des 12 prochains mois',
        },
        dimension: estimatedRevenueDimension,
        condition: mrphSelectedDimension,
      },
      //#endregion
      //#region MRPW
      {
        type: 'choice',
        // make a more generic variant if a second grid appears
        variant: 'one-click-occupation-status-grid',
        dimension: mrpwOccupationStatusDimension,
        condition: mrpwSelectedDimension,
      },
      {
        type: 'surface',
        dimension: mrpwSurfaceDimension,
        layout: { width: 'narrow' },
        condition: mrpwSelectedDimension,
      },
      {
        type: 'amount',
        displayValues: {
          label: 'Valeur du contenu du local',
        },
        dimension: mrpwAssetsValueDimension,
        layout: { width: 'narrow' },
        condition: mrpwSelectedDimension,
      },
      {
        type: 'amount',
        displayValues: {
          label: 'CA HT estimé des 12 prochains mois',
        },
        dimension: estimatedRevenueDimension,
        condition: mrpwSelectedDimension,
      },
      {
        type: 'choice',
        variant: 'radio-group-table-with-title',
        label: 'Nombre de sinistres aux biens',
        dimension: mrpwAssetsClaimsThreeYearsDimension,
        condition: mrpwSelectedDimension,
        isSingleLineElement: { isSingleMini: false },
      },
      //#endregion
      //#region MUTA
      {
        type: 'calendar-date',
        dimensions: { value: birthDateDimension },
        layout: { width: 'narrow' },
        condition: mutaSelectedDimension,
      },
      {
        type: 'postcode',
        dimension: personalAddressPostCodeDimension,
        displayValues: {
          label: 'Code postal de résidence',
        },
        layout: { width: 'narrow' },
        condition: mutaSelectedDimension,
      },
      {
        type: 'choice',
        variant: 'radio-group-table',
        dimension: mutaSocialSecurityRegimeDimension,
        condition: mutaSelectedDimension,
        isSingleLineElement: { isSingleMini: true },
      },
      //#endregion
      //#region RCDA
      {
        type: 'postcode',
        dimension: activityAddressPostCodeDimension,
        layout: { width: 'narrow' },
        condition: rcdaSelectedDimension,
      },
      {
        type: 'integer',
        dimension: workforceDimension,
        layout: { width: 'narrow' },
        condition: rcdaSelectedDimension,
      },
      {
        type: 'calendar-date',
        dimensions: { value: companyCreationDateDimension, maxValue: companyCreationMaxDateDimension },
        layout: { width: 'narrow' },
        condition: rcdaSelectedDimension,
      },
      //#endregion
      //#region ES-RCPH
      {
        type: 'amount',
        dimension: estimatedRevenueDimension,
        condition: esRcphSelectedDimension,
      },
      {
        type: 'financial-rate',
        dimension: esRcphCanadaOrUsaRevenuePercentageDimension,
        variant: 'direct',
        condition: { and: [esRcphSelectedDimension, esRcphCanadaOrUsaRevenuePercentageRequiredDimension] },
      },
      {
        type: 'boolean',
        variant: 'yes-no-buttons',
        dimension: esRcphHeavySingleCustomerDimension,
        condition: { and: [esRcphSelectedDimension, esRcphHeavySingleCustomerRelevantDimension] },
        goToNewBackofficeLine: true,
      },
      {
        type: 'boolean',
        variant: 'yes-no-buttons',
        dimension: esRcphMoreThanTwoYearsOfXpDimension,
        condition: { and: [esRcphSelectedDimension, esRcphIsSecuDimension] },
      },
      {
        type: 'positive-integer',
        dimension: esRcphHeadcountDimension,
        condition: { and: [esRcphSelectedDimension, esRcphHeadcountRequiredDimension] },
        goToNewBackofficeLine: true,
      },
      {
        type: 'integer',
        dimension: esRcphVehicleCountDimension,
        condition: { and: [esRcphSelectedDimension, esRcphVehicleCountRequiredDimension] },
        goToNewBackofficeLine: true,
      },
      //#endregion
      //#region RCPA
      {
        type: 'postcode',
        dimension: activityAddressPostCodeDimension,
        displayValues: {
          hint: 'Domiciliation de votre établissement uniquement en France métropolitaine, Guadeloupe, Martinique et à la Réunion',
        },
        layout: { width: 'narrow' },
        condition: rcpaSelectedDimension,
      },
      {
        type: 'amount',
        displayValues: {
          label: 'CA HT estimé des 12 prochains mois',
          hint: 'Prévisionnel HT sur les 12 prochains mois, montant révisable en cours de contrat 🙂. Cela nous permet de vous proposer le tarif le plus adapté',
        },
        positiveOnly: true,
        dimension: estimatedRevenueDimension,
        layout: { width: 'narrow' },
        condition: rcpaSelectedDimension,
      },
      {
        type: 'choice',
        variant: 'select',
        dimension: rcpaPersonaDimension,
        condition: { and: [rcpaSelectedDimension, isRcpaPersonaNeededDimension] },
        isSingleLineElement: { isSingleMini: false },
      },
      {
        type: 'integer',
        dimension: rcpaCifWorkforceDimension,
        condition: isRcpaCifWorkforceNeededDimension,
      },
      //#endregion
    ],
  },
  {
    id: 'prequote-revenue',
    condition: {
      and: [rcdaSelectedDimension, { defined: activitiesDimension }, { defined: activitiesDistributionDimension }],
    },
    backofficeUi: {
      tab: 'pricing',
      breadcrumb: 'Parcours de souscription',
      title: 'Chiffre d’affaires',
      subtitle: 'Informations financières de l’entreprise et des seuils de répartitions.',
    },
    bodyElements: [
      {
        type: 'amount',
        displayValues: {
          label: 'CA HT estimé des 12 prochains mois',
        },
        dimension: estimatedRevenueDimension,
      },
      {
        type: 'financial-rate',
        variant: 'direct',
        dimension: rcdaPercentageSubcontractedDimension,
      },
      {
        type: 'boolean',
        variant: 'checkbox',
        initializeValueWhenDisplayed: true,
        dimension: rcdaHasRevenueLimitExceededDimension,
        isSingleLineElement: { isSingleMini: false },
      },
      {
        type: 'rcda-revenue-limit',
        dimensions: {
          activities: activitiesDimension,
          activitiesDistribution: activitiesDistributionDimension,
          estimatedRevenue: estimatedRevenueDimension,
          nomenclatureVersion: nomenclatureVersionDimension,
        },
        condition: { defined: estimatedRevenueDimension },
      },
    ],
  },
  {
    id: 'prequote-experience',
    condition: rcdaSelectedDimension,
    backofficeUi: {
      tab: 'pricing',
      breadcrumb: 'Parcours de souscription',
      title: 'Expérience et qualification',
      subtitle: 'Informations sur l’expérience et la formation au métier du BTP.',
    },
    bodyElements: [
      {
        type: 'boolean',
        variant: 'yes-no-buttons',
        dimension: rcdaHasProfessionalQualificationDimension,
        hideLabel: true,
        isSingleLineElement: { isSingleMini: true },
      },
      {
        type: 'choice',
        variant: 'one-click-submit-rows',
        dimension: rcdaBossExperienceDimension,
        isSingleLineElement: { isSingleMini: true },
      },
    ],
  },
  {
    id: 'prequote-insurance',
    condition: { or: [mrpwManagementExperienceInPizzeriaRequiredDimension, rcdaSelectedDimension] },
    backofficeUi: {
      tab: 'pricing',
      breadcrumb: 'Parcours de souscription',
      title: 'Historique d’assurance',
      subtitle: 'Informations et antécédents d’assurance de l’entreprise.',
    },
    bodyElements: [
      {
        type: 'boolean',
        variant: 'yes-no-buttons',
        disableSubmit: true,
        hideLabel: true,
        dimension: rcdaPreviouslyInsuredDimension,
        isSingleLineElement: { isSingleMini: false },
        condition: rcdaSelectedDimension,
      },
      {
        type: 'integer',
        dimension: rcdaClaimsCountDimension,
        layout: { width: 'narrow' },
        condition: { and: [rcdaSelectedDimension, rcdaPreviouslyInsuredDimension] },
      },
      {
        type: 'amount',
        dimension: rcdaClaimsTotalValueDimension,
        condition: { and: [rcdaSelectedDimension, rcdaHasClaimsDimension, rcdaPreviouslyInsuredDimension] },
        layout: { width: 'narrow' },
      },
      {
        type: 'calendar-date',
        dimensions: {
          value: rcdaInsurancePeriodStartDateDimension,
          maxValue: rcdaMaxInsurancePeriodStartDateDimension,
        },
        layout: { width: 'narrow' },
        condition: { and: [rcdaSelectedDimension, rcdaPreviouslyInsuredDimension] },
      },
      {
        type: 'choice',
        variant: 'select',
        dimension: rcdaInsuranceInformationDimension,
        layout: { width: 'narrow' },
        condition: { and: [rcdaSelectedDimension, rcdaPreviouslyInsuredDimension] },
      },
      {
        type: 'string',
        dimension: rcdaPreviousInsurersNamesDimension,
        condition: { and: [rcdaSelectedDimension, rcdaPreviouslyInsuredDimension] },
      },
      {
        type: 'boolean',
        variant: 'yes-no-buttons',
        displayValues: {
          label: "Le prospect a un an ou plus d'expérience en tant que gérant d'une pizzeria",
        },
        dimension: mrpwHasManagementExperienceInPizzeriaDimension,
        condition: mrpwManagementExperienceInPizzeriaRequiredDimension,
      },
    ],
  },
  {
    id: 'prequote-family-member',
    condition: mutaSelectedDimension,
    backofficeUi: {
      tab: 'pricing',
      breadcrumb: 'Parcours de souscription',
      title: 'Membres de la famille',
      subtitle: 'Peuvent être ajoutés les conjoints, enfants rattachés et personnes à charge.',
    },
    bodyElements: [
      {
        type: 'boolean',
        dimension: mutaHasBeneficiariesDimension,
        variant: 'checkbox-card',
        initializeValueWhenDisplayed: true,
        isSingleLineElement: { isSingleMini: true },
      },
      {
        type: 'add-full-beneficiaries-bo',
        condition: mutaHasBeneficiariesDimension,
        dimensions: {
          mutaBeneficiaries: mutaBeneficiariesDimension,
          mutaSocialSecurityNumber: mutaSocialSecurityNumberDimension,
        },
      },
    ],
  },
  {
    id: 'prequote-variable',
    backofficeUi: {
      tab: 'pricing',
      breadcrumb: 'Parcours de souscription',
      title: 'Variable de couverture',
      subtitle: 'Valeurs ajustables pour plus de flexibilité sur la couverture et le tarif.',
    },
    bodyElements: [
      //#region RCPH
      {
        type: 'activities-distribution',
        dimensions: {
          activities: activitiesDimension,
          activitiesDistribution: activitiesDistributionDimension,
          estimatedRevenue: estimatedRevenueDimension,
        },
        condition: {
          and: [
            rcphSelectedDimension,
            rcphAvailableDimension,
            hasMultipleActivitiesDimension,
            { defined: estimatedRevenueDimension },
          ],
        },
        isSingleLineElement: { isSingleMini: false },
      },
      //#endregion
      //#region MRPH
      {
        type: 'choice',
        variant: 'radio-button-cards',
        dimension: mrphFormulaDimension,
        condition: mrphSelectedDimension,
      },
      {
        type: 'choice',
        variant: 'select',
        dimension: mrphDeductibleDaysDimension,
        condition: mrphSelectedDimension,
      },
      {
        type: 'choice',
        variant: 'select',
        dimension: mrphIndemnityDurationMonthsDimension,
        condition: mrphSelectedDimension,
      },
      //#endregion
      //#region MUTA
      {
        type: 'choose-muta-formula',
        dimensions: {
          formula: mutaFormulaDimension,
          possibleFormulas: mutaPossibleFormulasDimension,
          clientRecommendedFormula: mutaClientRecommendedFormulaDimension,
        },
        condition: mutaSelectedDimension,
      },
      //#endregion
      //#region RCDA
      {
        type: 'activities-distribution',
        dimensions: {
          activities: activitiesDimension,
          activitiesDistribution: activitiesDistributionDimension,
          estimatedRevenue: estimatedRevenueDimension,
        },
        condition: {
          and: [rcdaSelectedDimension, hasMultipleActivitiesDimension, { defined: estimatedRevenueDimension }],
        },
        isSingleLineElement: { isSingleMini: false },
      },
      //#endregion
    ],
  },
  {
    id: 'prequote-options',
    backofficeUi: {
      tab: 'pricing',
      breadcrumb: 'Parcours de souscription',
      title: 'Options non visibles par le client',
    },
    bodyElements: [
      //#region MRPW
      {
        type: 'boolean',
        variant: 'checkbox-card',
        displayValues: {
          label: 'Option boost',
          hint: 'Augmente le plafond de remboursement des garanties et contenu suivants : - Bris de matériel - Dommages éléctriques - Mobilier extérieur - Aménagements immobiliers intérieurs et extérieurs',
        },
        dimension: mrpwOptionBoostDimension,
        condition: mrpwSelectedDimension,
      },
      {
        type: 'boolean',
        variant: 'checkbox-card',
        displayValues: {
          label: 'Option RC travaux',
          hint: 'Couvre les dommages causés pendant ou à la suite de travaux réalisés dans l’établissement',
        },
        dimension: mrpwOptionRcTravauxDimension,
        condition: mrpwSelectedDimension,
      },
      {
        type: 'calendar-date',
        dimensions: { value: mrpwRcTravauxStartDimension },
        condition: { and: [mrpwOptionRcTravauxDimension, mrpwSelectedDimension] },
        layout: { width: 'narrow' },
      },
      {
        type: 'calendar-date',
        dimensions: { value: mrpwRcTravauxEndDimension },
        condition: { and: [mrpwOptionRcTravauxDimension, mrpwSelectedDimension] },
        layout: { width: 'narrow' },
      },
      //#endregion
      //#region

      //#endregion
    ],
  },
  {
    id: 'quote',
    condition: {
      and: [
        { defined: quoteDimension },
        { defined: offerInformationDimension },
        { defined: paymentRecurrenceDimension },
        { defined: commitmentDimension },
        { defined: discountDimension },
        { defined: yearlyCommitmentHasDiscountDimension },
        { defined: activityDimension },
        { defined: riskCarrierProductsDimension },
      ],
    },
    backofficeUi: {
      tab: 'pricing',
      breadcrumb: 'Offre personnalisée',
      title: 'Devis d’assurance',
      subtitle: 'Récapitulatif du produit d’assurance et des informations principales.',
    },
    subtitleElement: {
      type: 'quote-page-subtitle',
      dimensions: { activity: activityDimension, complementaryActivities: complementaryActivitiesDimension },
    },
    bodyElements: [
      {
        type: 'quote-page-body',
        dimensions: quoteDimensions,
        sendTrackingEvents: true,
        tracking: {
          dimensions: {
            activity: activityDimension,
            riskCarrierProducts: riskCarrierProductsDimension,
            hiddenQuote: hiddenQuoteDimension,
          },
        },
        isSingleLineElement: { isSingleMini: false },
      },
    ],
  },
  {
    id: 'rcph-cyber-information',
    condition: { and: [rcphSelectedDimension, rcphOptionCyberDimension] },
    backofficeUi: {
      tab: 'pricing',
      breadcrumb: 'Offre personnalisée',
      title: 'Précisions : Cyber',
      subtitle: 'Informations supplémentaires pour cette option.',
    },
    bodyElements: [
      {
        type: 'boolean',
        variant: 'checkbox',
        displayValues: { label: 'fait plus de 25 % de son chiffre d’affaires en ligne' },
        dimension: rcphCyberHighOnlineRevenueDimension,
        isSingleLineElement: { isSingleMini: true },
      },
      {
        type: 'boolean',
        variant: 'checkbox',
        displayValues: { label: 'réalise plus de 100 000 paiements par CB en ligne' },
        dimension: rcphCyberManyCreditCardPaymentsDimension,
        isSingleLineElement: { isSingleMini: true },
      },
    ],
  },
  //#region Company data when not done earlier
  {
    id: 'post-quote-data-policy-acceptance',
    backofficeUi: {
      tab: 'information',
      breadcrumb: 'Acceptation des CGU',
      title: 'Finaliser la souscription',
    },
    bodyElements: [
      {
        type: 'data-policy-acceptance',
        dimensions: {
          value: hasAcceptedDimension,
          hasAcceptedCommercialMessages: hasAcceptedCommercialMessagesDimension,
          operatingZone: operatingZoneDimension,
        },
        hideInSelfOnboarding: true,
        isSingleLineElement: { isSingleMini: false },
      },
    ],
  },
  {
    id: 'post-quote-siret-search',
    condition: {
      and: [
        { not: { or: [mrpwSelectedDimension, mrphSelectedDimension, esRcphSelectedDimension] } },
        { defined: placeSearchQueryDimension },
      ],
    },
    backofficeUi: {
      tab: 'information',
      breadcrumb: 'Fiche Entreprise',
      title: "Recherche d'entreprise",
    },
    bodyElements: [
      {
        type: 'siret-search',
        cityFormat: 'wakam',
        searchText: "Recherche d'entreprise (nom, SIREN, SIRET)",
        cantFindText: 'Entreprise en cours de création / je ne trouve pas mon entreprise',
        dimensions: {
          placeSearchQuery: placeSearchQueryDimension,
          placeSearchResults: placeSearchResultsDimension,
          siret: siretDimension,
          companyIdNumber: companyIdNumberDimension,
          placeName: placeNameDimension,
          activityAddressStreet: activityAddressStreetDimension,
          activityAddressPostCode: activityAddressPostCodeDimension,
          activityAddressCity: activityAddressCityDimension,
          companyName: companyNameDimension,
          companyCreationDate: companyCreationDateDimension,
        },
        isSingleLineElement: { isSingleMini: false },
      },
    ],
  },
  {
    id: 'post-quote-add-address',
    condition: {
      and: [{ not: mrpwSelectedDimension }],
    },
    backofficeUi: {
      tab: 'information',
      breadcrumb: 'Fiche Entreprise',
      title: 'Informations d’entreprise',
      subtitle: 'Toutes les informations essentielles sur l’entreprise ou l’établissement.',
    },
    bodyElements: [
      {
        type: 'address-map',
        dimension: activityAddressDimension,
        hideInSelfOnboarding: true,
        isSingleLineElement: { isSingleMini: false },
      },
      {
        type: 'siret',
        dimensions: {
          siret: siretDimension,
          companyIdNumber: companyIdNumberDimension,
          companyInCreationAllowed: companyInCreationAllowedDimension,
        },
        layout: { width: 'narrow' },
        isSingleLineElement: { isSingleMini: true },
        condition: {
          or: [mrphSelectedDimension, siretRequiredDimension],
        },
      },
      {
        type: 'string',
        dimension: placeNameDimension,
        layout: { width: 'narrow' },
        condition: mrphSelectedDimension,
      },
      {
        type: 'siren',
        dimensions: {
          companyIdNumber: companyIdNumberDimension,
          companyInCreationAllowed: companyInCreationAllowedDimension,
          operatingZone: operatingZoneDimension,
        },
        condition: {
          and: [{ not: mrphSelectedDimension }, { not: siretRequiredDimension }],
        },
        isSingleLineElement: { isSingleMini: true },
      },
      {
        type: 'orias',
        dimensions: {
          oriasImmatriculation: oriasImmatriculationDimension,
        },
        condition: isRcpaOriasRegulatedActivityDimension,
      },
      {
        type: 'string',
        dimension: companyNameDimension,
        layout: { width: 'narrow' },
        goToNewBackofficeLine: true,
      },
      {
        type: 'calendar-date',
        dimensions: { value: companyCreationDateDimension, maxValue: companyCreationMaxDateDimension },
        layout: { width: 'narrow' },
      },
      {
        type: 'string',
        dimension: activityAddressStreetDimension,
        goToNewBackofficeLine: true,
      },
      {
        type: 'postcode',
        dimension: activityAddressPostCodeDimension,
        layout: { width: 'narrow' },
        goToNewBackofficeLine: true,
      },
      {
        type: 'string',
        dimension: activityAddressCityDimension,
        layout: { width: 'narrow' },
      },
      {
        type: 'readonly-current-country',
        dimensions: { operatingZone: operatingZoneDimension },
      },
    ],
  },
  //#endregion
  {
    id: 'start-date',
    backofficeUi: {
      tab: 'information',
      breadcrumb: 'Période de couverture',
      title: 'Début de contrat',
      subtitle: 'Date de démarrage du contrat',
    },
    whenContractNotSigned: true,
    bodyElements: [
      {
        type: 'calendar-date',
        dimensions: {
          value: startDateDimension,
          minValue: minStartDateDimension,
          maxValue: maxStartDateDimension,
          maxValueExplanation: maxStartDateExplanationDimension,
        },
        size: 'normal',
      },
    ],
  },
  {
    id: 'contractualization',
    condition: { and: [organizationCanTerminateContractsDimension, terminatePreviousContractRelevantDimension] },
    backofficeUi: {
      tab: 'information',
      breadcrumb: 'Période de couverture',
      title: 'Résiliation ancien contract',
      subtitle: "Démarches sur le contrat d'assurance déjà existant auprès d'une autre compagnie.",
    },
    bodyElements: [
      {
        type: 'boolean',
        dimension: terminatePreviousContractDimension,
        variant: 'checkbox',
      },
    ],
  },
  {
    id: 'conversation',
    permission: 'timeline.read',
    backofficeUi: {
      tab: 'conversation',
      breadcrumb: 'Conversation',
    },
    bodyElements: [
      {
        type: 'subscription-timeline-element',
      },
    ],
  },
  {
    id: 'statement',
    condition: { and: [{ defined: statementDimension }, { defined: statementWarningDimension }] },
    backofficeUi: {
      tab: 'legal-terms',
      breadcrumb: 'Conditions légales',
      title: 'Exclusions',
      subtitle: "Restriction du champ d'application du contrat d'assurance.",
    },
    bodyElements: [
      {
        type: 'statement',
        dimensions: {
          statementCards: statementDimension,
          statementWarning: statementWarningDimension,
        },
      },
    ],
  },
  {
    id: 'post-quote-agents',
    condition: rcpaMultiActivityAvailableDimension,
    backofficeUi: {
      tab: 'information',
      breadcrumb: 'Informations du client',
      title: 'Mandataires',
    },
    bodyElements: [
      {
        type: 'boolean',
        dimension: rcpaHasAgentsDimension,
        variant: 'yes-no-buttons',
      },
      {
        type: 'add-agents',
        dimensions: {
          rcpaAgents: rcpaAgentsDimension,
          rcpaAvailableAgentsActivities: rcpaAvailableAgentsActivitiesDimension,
        },
        condition: rcpaHasAgentsDimension,
      },
    ],
  },
  {
    id: 'additional-personal-data',
    backofficeUi: {
      tab: 'information',
      breadcrumb: 'Informations du client',
      title: 'Informations personnelles',
    },
    bodyElements: [
      { type: 'string', dimension: firstNameDimension, layout: { width: 'narrow' } },
      { type: 'string', dimension: lastNameDimension, layout: { width: 'narrow' } },
      { type: 'email', dimension: emailDimension, layout: { width: 'narrow' }, goToNewBackofficeLine: true },
      { type: 'phone', dimension: phoneDimension, layout: { width: 'narrow' } },
      { type: 'email-availablitiy-checker', dimension: emailDimension },
      { type: 'quote-priority-notification', dimension: emailDimension },
      {
        type: 'boolean',
        dimension: personalAddressIsNotActivityAddress,
        variant: 'checkbox',
        isSingleLineElement: { isSingleMini: true },
        displayValues: { label: 'Mon adresse personnelle est différente de celle de mon entreprise' },
        condition: { not: mutaSelectedDimension },
      },
      {
        type: 'string',
        dimension: personalAddressStreetDimension,
        condition: personalAddressIsNotActivityAddress,
        goToNewBackofficeLine: true,
      },
      {
        type: 'postcode',
        condition: personalAddressIsNotActivityAddress,
        dimension: personalAddressPostCodeDimension,
        layout: { width: 'narrow' },
      },
      {
        type: 'string',
        dimension: personalAddressCityDimension,
        layout: { width: 'narrow' },
        condition: personalAddressIsNotActivityAddress,
      },
      {
        type: 'readonly-current-country',
        dimensions: { operatingZone: operatingZoneDimension },
        layout: { width: 'narrow' },
        condition: personalAddressIsNotActivityAddress,
        isSingleLineElement: { isSingleMini: true },
      },
      {
        type: 'string',
        condition: mutaSelectedDimension,
        dimension: birthCityDimension,
        layout: { width: 'narrow' },
        goToNewBackofficeLine: true,
      },
      {
        type: 'country',
        condition: mutaSelectedDimension,
        dimension: birthCountryDimension,
        layout: { width: 'narrow' },
      },
      {
        type: 'social-security-organism-number',
        condition: mutaSelectedDimension,
        dimensions: {
          mutaSocialSecurityOrganismNumber: mutaSocialSecurityOrganismNumberDimension,
          mutaRegime: mutaSocialSecurityRegimeDimension,
        },
        layout: { width: 'narrow' },
        goToNewBackofficeLine: true,
      },
      {
        type: 'social-security-number',
        condition: mutaSelectedDimension,
        dimensions: { mutaSocialSecurityNumber: mutaSocialSecurityNumberDimension, birthDate: birthDateDimension },
        layout: { width: 'narrow' },
      },
      {
        type: 'bank-account',
        dimension: mutaReimbursementBankAccountDimension,
        condition: mutaSelectedDimension,
        isSingleLineElement: { isSingleMini: false },
      },
    ],
  },
  {
    id: 'lock-documents',
    condition: {
      and: [{ notEmpty: neededSubscriptionDocumentsDimension }, { defined: neededSubscriptionDocumentsDimension }],
    },
    backofficeUi: {
      tab: 'supporting-documents',
      breadcrumb: 'Dossier',
      title: 'Verrouillage dossier',
      subtitle: 'Parcours de souscription client bloqué pour validation des pièces.',
    },
    bodyElements: [
      {
        type: 'boolean',
        variant: 'checkbox',
        initializeValueWhenDisplayed: true,
        dimension: documentsLockConfirmedDimension,
        disabledIfSubscriptionDimensionsMissingInBackofficeContext: true,
        isSingleLineElement: { isSingleMini: true },
      },
    ],
  },

  {
    id: 'validate-documents',
    condition: {
      and: [{ notEmpty: neededSubscriptionDocumentsDimension }, { defined: neededSubscriptionDocumentsDimension }],
    },
    permission: 'rcda.validateDocuments',
    backofficeUi: {
      tab: 'supporting-documents',
      breadcrumb: 'Dossier',
      title: 'Validation dossier',
      subtitle: "Validation du dossier par l'équipe assurance.",
    },
    bodyElements: [
      {
        type: 'boolean',
        variant: 'checkbox',
        initializeValueWhenDisplayed: true,
        dimension: documentsValidatedDimension,
        disabledIfSubscriptionDimensionsMissingInBackofficeContext: true,
        isSingleLineElement: { isSingleMini: true },
      },
    ],
  },
  {
    id: 'rcda-global-note',
    condition: {
      and: [{ notEmpty: neededSubscriptionDocumentsDimension }, { defined: neededSubscriptionDocumentsDimension }],
    },
    backofficeUi: {
      tab: 'supporting-documents',
      breadcrumb: 'Dossier',
    },
    bodyElements: [
      {
        type: 'string',
        layout: { width: 'large' },
        dimension: globalDocumentNoteDimension,
      },
    ],
  },
  {
    id: 'detailed-sheet',
    hideForPartners: true,
    condition: {
      and: [
        rcdaSelectedDimension,
        { notEmpty: neededSubscriptionDocumentsDimension },
        { defined: neededSubscriptionDocumentsDimension },
      ],
    },
    backofficeUi: {
      tab: 'supporting-documents',
      breadcrumb: 'Dossier',
      title: 'Fiche détaillée',
      subtitle: 'Récapitulatif des informations et pièces de l‘entreprise.',
    },
    bodyElements: [
      {
        type: 'detailed-sheet',
        dimensions: {
          detailedSheetExperienceStatus: detailedSheetExperienceStatusDimension,
          detailedSheetCompleteStatus: detailedSheetCompleteStatusDimension,
        },
      },
    ],
  },
  {
    id: 'detailed-sheet-overview',
    hideForPartners: true,
    hideOnProductionEnv: true,
    condition: {
      and: [
        rcdaSelectedDimension,
        { notEmpty: neededSubscriptionDocumentsDimension },
        { defined: neededSubscriptionDocumentsDimension },
      ],
    },
    backofficeUi: {
      tab: 'supporting-documents',
      breadcrumb: 'Dossier',
    },
    bodyElements: [
      {
        type: 'detailed-sheet-overview',
        layout: { width: 'large' },
        dimensions: {
          firstName: firstNameDimension,
          lastName: lastNameDimension,
          siret: siretDimension,
          companyIdNumber: companyIdNumberDimension,
          companyName: companyNameDimension,
          companyCreationDate: companyCreationDateDimension,
          estimatedRevenue: estimatedRevenueDimension,
          workforce: workforceDimension,
          rcdaBossExperience: rcdaBossExperienceDimension,
          activitiesDistribution: activitiesDistributionDimension,
          activities: activitiesDimension,
          detailedSheetActivitySynthesis: detailedSheetActivitySynthesisDimension,
          detailedSheetAcagSynthesis: detailedSheetAcagSynthesisDimension,
          detailedSheetGlobalSynthesis: detailedSheetGlobalSynthesisDimension,
          detailedSheetFirstLevelResponsible: detailedSheetFirstLevelResponsibleDimension,
          detailedSheetSecondLevelResponsible: detailedSheetSecondLevelResponsibleDimension,
          detailedSheetThirdLevelResponsible: detailedSheetThirdLevelResponsibleDimension,
          detailedSheetExperienceSynthesis: detailedSheetExperienceSynthesisDimension,
          neededSubscriptionDocuments: neededSubscriptionDocumentsDimension,
          detailedSheetExperienceStatus: detailedSheetExperienceStatusDimension,
          detailedSheetHistorySynthesis: detailedSheetHistorySynthesisDimension,
        },
      },
    ],
  },
  {
    id: 'documents',
    condition: {
      and: [{ notEmpty: neededSubscriptionDocumentsDimension }, { defined: neededSubscriptionDocumentsDimension }],
    },
    backofficeUi: {
      tab: 'supporting-documents',
      breadcrumb: 'Dossier',
    },
    bodyElements: [
      {
        type: 'subscription-documents-element',
        dimensions: {
          neededSubscriptionDocuments: neededSubscriptionDocumentsDimension,
          globalOverviewNote: globalDocumentNoteDimension,
        },
      },
    ],
  },
  {
    id: 'terms',
    backofficeUi: {
      tab: 'contractual-documents',
      breadcrumb: 'Documents',
      title: 'Éditique',
      subtitle: 'L’ensemble des documents contractuels ainsi que les autres pièces informelles.',
    },
    whenContractNotSigned: true,
    bodyElements: [
      {
        type: 'terms',
        dimensions: {
          email: emailDimension,
          generalTermsGroups: generalTermsGroupsDimension,
        },
      },
    ],
  },
  {
    id: 'operator',
    backofficeUi: {
      tab: 'management',
      breadcrumb: "Apporteur d'affaires",
      title: 'Responsable du contrat',
      subtitle: 'Fiche du commercial, dont courtier, en charge du contrat client.',
    },
    bodyElements: [
      {
        type: 'operator',
      },
    ],
  },
  {
    id: 'invoicing-configuration',
    backofficeUi: {
      tab: 'management',
      breadcrumb: 'Facturation',
      title: 'Facturation automatique',
      subtitle: 'Élargi la couverture sur les prestations de toutes les activités listées ici.',
    },
    bodyElements: [
      {
        type: 'invoicing-configuration',
      },
      {
        type: 'choice',
        variant: 'select',
        dimension: preferredPaymentDayDimension,
      },
    ],
    hideForPartners: true,
  },
  {
    id: 'installment-fee',
    hideForPartners: true,
    condition: rcdaSelectedDimension,
    backofficeUi: {
      tab: 'management',
      breadcrumb: 'Frais Orus',
      title: 'Frais de fractionnement RCDA',
    },
    bodyElements: [
      {
        type: 'installment-fee-slider',
        dimensions: {
          installmentFee: installmentFeeRateDimension,
          manuallySetInstallmentFeeRate: manuallySetInstallmentFeeRateDimension,
          defaultInstallmentFeeRate: defaultInstallmentFeeRateDimension,
          isInstallmentFeeRateEditable: isInstallmentFeeRateEditableDimension,
        },
      },
    ],
  },
  {
    id: 'rcda-management-fee',
    condition: rcdaSelectedDimension,
    hideForPartners: true,
    backofficeUi: {
      tab: 'management',
      breadcrumb: 'Frais Orus',
      title: 'Frais de gestion RCDA',
    },
    bodyElements: [
      {
        type: 'fee-rate',
        dimension: rcdaManagementFeeRateDimension,
      },
    ],
  },
  {
    id: 'rcph-installment-fee',
    condition: rcphSelectedDimension,
    hideForPartners: true,
    backofficeUi: {
      tab: 'management',
      breadcrumb: 'Frais Orus',
      title: 'Frais de fractionnement RCPH',
    },
    bodyElements: [
      {
        type: 'installment-fee',
        dimensions: {
          installmentFee: rcphInstallmentFeeRateDimension,
        },
      },
    ],
  },
  {
    id: 'rcph-management-fee',
    condition: rcphSelectedDimension,
    hideForPartners: true,
    backofficeUi: {
      tab: 'management',
      breadcrumb: 'Frais Orus',
      title: 'Frais de gestion RCPH',
    },
    bodyElements: [
      {
        type: 'fee-rate',
        dimension: rcphManagementFeeRateDimension,
      },
    ],
  },
  {
    id: 'rcpa-installment-fee',
    condition: rcpaSelectedDimension,
    hideForPartners: true,
    backofficeUi: {
      tab: 'management',
      breadcrumb: 'Frais Orus',
      title: 'Frais de fractionnement RCPA',
    },
    bodyElements: [
      {
        type: 'installment-fee',
        dimensions: {
          installmentFee: rcpaInstallmentFeeRateDimension,
        },
      },
    ],
  },
  {
    id: 'rcpa-management-fee',
    condition: rcphSelectedDimension,
    hideForPartners: true,
    backofficeUi: {
      tab: 'management',
      breadcrumb: 'Frais Orus',
      title: 'Frais de gestion RCPA',
    },
    bodyElements: [
      {
        type: 'fee-rate',
        dimension: rcpaManagementFeeRateDimension,
      },
    ],
  },
  {
    id: 'discount',
    condition: { not: esRcphSelectedDimension },
    backofficeUi: {
      tab: 'management',
      breadcrumb: 'Frais Orus',
      title: 'Discount',
      subtitle: 'Remise valable sur la première année du contrat, décomptée de votre commission.',
    },
    bodyElements: [{ type: 'discount', dimension: discountDimension }],
    hideForPartners: true,
  },
  {
    id: 'partner-fees-card',
    backofficeUi: {
      tab: 'management',
      breadcrumb: 'Rémunération courtier',
    },
    bodyElements: [
      {
        type: 'partner-fees-card',
        dimensions: {
          quote: quoteDimension,
          brokerDiscount: brokerDiscountDimension,
          mutaQuote: mutaQuoteDimension,
          mrphQuoteV2: mrphQuoteV2Dimension,
          rcphQuoteV2: rcphQuoteV2Dimension,
          rcdaQuote: rcdaQuoteDimension,
          mrpwQuote: mrpwQuoteDimension,
        },
      },
    ],
    condition: { defined: quoteDimension },
    hideWhenCannotEditFees: true,
    onlyForNonOrusSubscription: true,
  },
  {
    id: 'partner-application-fee',
    backofficeUi: {
      tab: 'management',
      breadcrumb: 'Rémunération courtier',
      title: 'Frais de dossier',
      subtitle: 'Montant ponctuel à la signature du contrat.',
    },
    bodyElements: [
      {
        type: 'partner-application-fee',
        dimension: partnerApplicationFeeDimension,
        isSingleLineElement: { isSingleMini: true },
      },
    ],
    hideWhenCannotEditFees: true,
    onlyForNonOrusSubscription: true,
  },
  {
    id: 'partner-management-fees',
    backofficeUi: {
      tab: 'management',
      breadcrumb: 'Rémunération courtier',
      title: 'Frais de gestion additionnels',
      subtitle: 'Montant récurrent en plus de votre commission sur la durée du contrat.',
    },
    bodyElements: [
      {
        type: 'fee-rate',
        dimension: partnerManagementFeeRateDimension,
        isSingleLineElement: { isSingleMini: true },
        ariaLabel: 'Frais de gestion (courtier)',
      },
    ],
    hideWhenCannotEditFees: true,
    onlyForNonOrusSubscription: true,
  },
  {
    id: 'custom-clauses',
    backofficeUi: {
      tab: 'contractual-documents',
      breadcrumb: 'Documents',
      title: 'Clauses personnalisables',
      subtitle: 'Les clauses personnalisables seront ajoutées dans l’attestation et les CP.',
    },
    bodyElements: [
      {
        type: 'custom-clauses',
        dimensions: {
          customerCover: customCoverDimension,
          customRcphActivity: customRcphActivityDimension,
          customSignature: customSignatureDimension,
          customRcphSummary: customRcphSummaryDimension,
          customMrpActivityAddress: customMrpActivityAddressDimension,
          customMrpSummary: customMrpSummaryDimension,
          customMrpActivity: customMrpActivityDimension,
          customMutaSummary: customMutaSummaryDimension,
          customRcdaSummary: customRcdaSummaryDimension,
          customRcdaActivity: customRcdaActivityDimension,
          mrpwSelected: mrpwSelectedDimension,
          mutaSelected: mutaSelectedDimension,
          rcdaSelected: rcdaSelectedDimension,
          rcphSelected: rcphSelectedDimension,
          mrphSelected: mrphSelectedDimension,
          esRcphSelected: esRcphSelectedDimension,
          esRcphCustomActivity: esRcphCustomActivityDimension,
          esRcphCustomSummary: esRcphCustomSummaryDimension,
          rcpaSelected: rcpaSelectedDimension,
          customRcpaActivity: customRcpaActivityDimension,
          customRcpaSummary: customRcpaSummaryDimension,
        },
      },
    ],
    hideForPartners: true,
    whenContractNotSigned: true,
  },
  {
    id: 'overcharges',
    condition: { or: [mrpwSelectedDimension, rcphSelectedDimension, rcdaSelectedDimension] },
    hideForPartners: true,
    backofficeUi: {
      tab: 'management',
      breadcrumb: 'Surprimes',
      title: 'Surprimes applicables au contrat',
    },
    bodyElements: [
      {
        type: 'financial-rate',
        variant: 'yes-no',
        dimension: mrpwPremiumOverchargesRcDimension,
        layout: { width: 'narrow' },
        condition: mrpwSelectedDimension,
      },
      {
        type: 'financial-rate',
        variant: 'yes-no',
        dimension: mrpwPremiumOverchargesDmgDimension,
        layout: { width: 'narrow' },
        condition: mrpwSelectedDimension,
      },
      {
        type: 'financial-rate',
        variant: 'yes-no',
        dimension: mrpwPremiumOverchargesIncPePvvDimension,
        layout: { width: 'narrow' },
        condition: mrpwSelectedDimension,
      },
      {
        type: 'financial-rate',
        variant: 'yes-no',
        dimension: rcphPremiumOverchargeDimension,
        layout: { width: 'narrow' },
        condition: rcphSelectedDimension,
        isSingleLineElement: { isSingleMini: true },
      },
    ],
  },
  {
    id: 'overcharges-rcda',
    condition: rcdaSelectedDimension,
    hideForPartners: true,
    permission: 'rcda.updateOvercharges',
    backofficeUi: {
      tab: 'management',
      breadcrumb: 'Surprimes RCDA',
      title: 'Surprimes applicables au contrat décénnale',
    },
    bodyElements: [
      {
        type: 'financial-rate',
        variant: 'yes-no',
        dimension: rcdaGlobalOverchargeRateDimension,
        layout: { width: 'narrow' },
        condition: rcdaSelectedDimension,
        isSingleLineElement: { isSingleMini: true },
      },
    ],
  },
  {
    id: 'manual-exclusion',
    backofficeUi: {
      tab: 'management',
      breadcrumb: 'Divers',
      title: 'Exclusion manuelle',
    },
    bodyElements: [
      {
        type: 'boolean',
        dimension: manuallyExcludedDimension,
        variant: 'checkbox',
      },
    ],
    hideForPartners: true,
  },
  {
    id: 'pricing-version',
    backofficeUi: {
      tab: 'management',
      breadcrumb: 'Divers',
      title: 'Version de tarification',
    },
    bodyElements: [
      {
        type: 'choice',
        variant: 'select',
        dimension: mrpwPricingVersionDimension,
        condition: mrpwSelectedDimension,
      },
    ],
    hideForPartners: true,
  },
]

export function* iterateQuoteEditorSectionUiElements(
  section: QuoteEditorSection,
): IterableIterator<SubscriptionUiElement> {
  if ('subtitleElement' in section) yield section.subtitleElement
  yield* section.bodyElements
}

export const quoteEditorSectionsDimensionsBySectionId = Object.fromEntries(
  quoteEditorSections.map((section) => {
    const dimensions: {
      dimension: AbstractDimension<string, unknown>
      condition?: SubscriptionCondition | undefined
    }[] = []

    for (const element of iterateQuoteEditorSectionUiElements(section)) {
      for (const elementDimension of getElementDimensions(element)) {
        if (!dimensions.some((seenDimension) => seenDimension.dimension.name === elementDimension.name)) {
          dimensions.push({ dimension: elementDimension, condition: element.condition })
        }
      }
    }
    return [section.id, dimensions]
  }),
) as unknown as Record<
  SubscriptionStepId,
  { dimension: AbstractDimension; condition?: SubscriptionCondition | undefined }[]
>
