import styled from '@emotion/styled'
import { forwardRef, memo } from 'react'
import { PIXELS_PER_ICON_SIZE, type IconSize } from '../icon'
import { type IllustrationName } from './names'
import spriteData from './sprite.json'
import spriteSheetUrl from './sprite.svg'

export type IllustrationProps = {
  name: IllustrationName

  /**
   * Defaults to {@link DEFAULT_SIZE}
   */
  size?: IconSize
}

export const Illustration = memo(
  forwardRef<HTMLDivElement, IllustrationProps>(function Illustration({ name, size = DEFAULT_SIZE }, ref) {
    return <StyledIllustration name={name} size={size} ref={ref} />
  }),
)

const getNumericValue = (value: string): number => {
  return parseInt(value, 10)
}

const StyledIllustration = styled.div<{ name: IllustrationName; size: IconSize }>`
  width: ${(props) => PIXELS_PER_ICON_SIZE[props.size]};
  height: ${(props) => PIXELS_PER_ICON_SIZE[props.size]};

  background-image: url(${spriteSheetUrl});
  background-repeat: no-repeat;

  background-size: ${(props) => {
    const originalWidth = spriteData.metadata.width
    const scaleFactor = getNumericValue(PIXELS_PER_ICON_SIZE[props.size]) / spriteData[props.name].width
    return `${originalWidth * scaleFactor}px auto`
  }};

  background-position: ${(props) => {
    const sprite = spriteData[props.name]
    const scaleFactor = getNumericValue(PIXELS_PER_ICON_SIZE[props.size]) / sprite.width
    return sprite ? `-${sprite.x * scaleFactor}px -${sprite.y * scaleFactor}px` : '0 0'
  }};
`

const DEFAULT_SIZE: IconSize = '130'
