import { memo, useState, type ComponentProps } from 'react'

import { css } from '@emotion/react'
import styled from '@emotion/styled'

import { mobileMediaQuery } from '../../../../code-only/hooks/use-screen-type.js'
import { useTranslate } from '../../../../code-only/localization/language-context.js'
import { Avatar, Button, Callout } from '../../../../components/index.js'
import { colorTokens, emoji, Text } from '../../../../foundations/index.js'
import { spacing } from '../../../../foundations/spacing-tokens.js'
import { Dialog } from '../../../../patterns/index.js'
import { LegacyDialog } from '../../../templates/index.js'

export type Coverage = Array<{
  name?: string
  options: Array<{ icon: string; description: string; ceiling?: string; deductible?: string }>
}>

export const GuaranteeAndOptionsList = memo<{
  isMobile: boolean
  coverage: Coverage
}>(function GuaranteeAndOptionsList({ coverage, isMobile }) {
  const translate = useTranslate()
  const [showFullCoverage, setShowFullCoverage] = useState<boolean>(false)
  const hasMoreThanThreeOptions = coverage.flatMap((c) => c.options).length > 3

  return (
    <WarrantyList>
      <Text variant="body1Medium">{translate('subscription_funnel_product_details_modal_guarantees_and_options')}</Text>
      <Card showFullCoverage={showFullCoverage} isMobile={isMobile}>
        {coverage.map(({ name, options }, coverageIndex) => (
          <ContainerWarranty key={coverageIndex}>
            {name ? (
              <GuaranteeTitle>
                <Text variant="subtitle">{name}</Text>
              </GuaranteeTitle>
            ) : null}

            <RowContainer>
              {options?.map(({ icon, description, ceiling, deductible }, optionIndex) => (
                <TextRow key={optionIndex}>
                  <img src={icon} width="24px" />
                  <RowContent>
                    <Text variant="body2">{description}</Text>
                    {ceiling || deductible ? (
                      <RowInfoContainer>
                        {ceiling ? (
                          <RowInfoData>
                            <Text variant="body2Medium">{ceiling}</Text>
                            <Text variant="body2" color={colorTokens['color-text-base-basic']}>
                              {translate('limit')}
                            </Text>
                          </RowInfoData>
                        ) : null}
                        {deductible ? (
                          <RowInfoData>
                            <Text variant="body2Medium">{deductible}</Text>
                            <Text variant="body2" color={colorTokens['color-text-base-basic']}>
                              {translate('deductible')}
                            </Text>
                          </RowInfoData>
                        ) : null}
                      </RowInfoContainer>
                    ) : null}
                  </RowContent>
                </TextRow>
              ))}
            </RowContainer>
          </ContainerWarranty>
        ))}
      </Card>

      {hasMoreThanThreeOptions ? (
        <BoxCenter>
          <Button
            variant="secondary"
            avatarPosition="right"
            avatar={<Avatar icon={showFullCoverage ? 'angle-up-solid' : 'angle-down-solid'} />}
            onClick={() => {
              setShowFullCoverage((prev) => !prev)
            }}
            size={isMobile ? 'large' : undefined}
            fullWidth={isMobile}
          >
            {showFullCoverage
              ? translate('subscription_funnel_product_details_modal_show_less_guarantees')
              : translate('subscription_funnel_product_details_modal_show_all_guarantees')}
          </Button>
        </BoxCenter>
      ) : null}
    </WarrantyList>
  )
})

export const ProductDetailsDialog = memo<{
  productId?: string
  details: {
    name: string
    description: string
    coverage: ComponentProps<typeof GuaranteeAndOptionsList>['coverage'] | undefined
    included: Array<string>
    excluded: Array<string>
  }
  fullWidth: boolean
  hide: ComponentProps<typeof LegacyDialog>['onClose']
  avatar: React.ReactNode
  showGuaranteeAndOptions?: boolean
}>(function ProductDetailsDialog({
  details: { name, description, coverage, included, excluded },
  avatar,
  fullWidth,
  hide,
  showGuaranteeAndOptions = false,
}) {
  const translate = useTranslate()
  return (
    <Dialog
      onClose={hide}
      size={'large'}
      avatar={avatar}
      title={name}
      secondaryActionLabel={translate('close')}
      onSecondaryAction={hide}
    >
      <div
        css={css`
          display: flex;
          flex-direction: column;
          gap: ${spacing[70]};
        `}
      >
        <Text variant="body2">{description}</Text>

        <div
          css={css`
            display: flex;
            flex-direction: column;
            gap: ${spacing[70]};
          `}
        >
          <Callout
            avatar={<Avatar src={emoji.lightBulb} />}
            variant="content"
            title={translate('subscription_funnel_product_details_modal_callout_title')}
          >
            {translate('subscription_funnel_product_details_modal_callout_content')}
          </Callout>
          {showGuaranteeAndOptions && coverage ? (
            <GuaranteeAndOptionsList coverage={coverage} isMobile={fullWidth} />
          ) : null}

          <div
            css={css`
              display: flex;
              flex-direction: ${fullWidth ? 'column' : 'row'};
              gap: ${spacing[70]};
              margin-bottom: ${spacing[70]};
            `}
          >
            <div
              css={css`
                display: flex;
                flex-direction: column;
                gap: ${spacing[50]};
                flex: 1;
              `}
            >
              <div
                css={css`
                  display: flex;
                  flex-direction: column;
                  gap: ${spacing[10]};
                `}
              >
                <Text variant="body1Medium">
                  {translate('subscription_funnel_product_details_modal_included_title')}
                </Text>
                <Text variant="caption" color={colorTokens['color-text-base-basic']}>
                  {translate('subscription_funnel_product_details_modal_included_subtitle')}
                </Text>
              </div>

              <div
                css={css`
                  display: flex;
                  flex-direction: column;
                  gap: ${spacing[30]};
                `}
              >
                {included.map((paragraph, includedIndex) => (
                  <Text key={includedIndex} variant="body2">
                    {paragraph}
                  </Text>
                ))}
              </div>
            </div>

            <div
              css={css`
                display: flex;
                flex-direction: column;
                gap: ${spacing[50]};
                flex: 1;
              `}
            >
              <div
                css={css`
                  display: flex;
                  flex-direction: column;
                  gap: ${spacing[10]};
                `}
              >
                <Text variant="body1Medium">
                  {translate('subscription_funnel_product_details_modal_excluded_title')}
                </Text>
                <Text variant="caption" color={colorTokens['color-text-base-basic']}>
                  {translate('subscription_funnel_product_details_modal_excluded_subtitle')}
                </Text>
              </div>

              <div
                css={css`
                  display: flex;
                  flex-direction: column;
                  gap: ${spacing[30]};
                `}
              >
                {' '}
                {excluded.map((paragraph, excludedIndex) => (
                  <Text key={excludedIndex} variant="body2">
                    {paragraph}
                  </Text>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </Dialog>
  )
})

const ContainerWarranty = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  align-self: stretch;
  gap: ${spacing[60]};
`

const RowContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  align-self: stretch;
`

const GuaranteeTitle = styled.div`
  padding: ${spacing[30]} 0;
  align-items: center;
  align-self: stretch;
`

const WarrantyList = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: flex-start;
  gap: ${spacing[50]};
`

const Card = styled.div<{ showFullCoverage: boolean; isMobile: boolean }>`
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: ${spacing[70]};
  align-self: stretch;
  ${({ isMobile }) =>
    isMobile
      ? `
    padding: ${spacing[50]} 0;
`
      : `
    padding: ${spacing[50]} ${spacing[60]};
    border-radius: ${spacing[40]};
    border: 1px solid ${colorTokens['color-stroke-base']};
    background: ${colorTokens['color-bg-base-normal']};
  `}
  ${({ showFullCoverage }) =>
    !showFullCoverage
      ? `
      max-height: 14.5rem;
      overflow: hidden;
      `
      : ``}
`

const TextRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: ${spacing[50]};
  padding: ${spacing[50]} 0 0 0;
  width: 100%;

  &:not(:last-child) {
    border-bottom: 1px ${colorTokens['color-stroke-base']} solid;
    padding: ${spacing[40]} 0 ${spacing[40]} 0;
  }

  &:first-child {
    padding: 0 0 ${spacing[50]} 0;
  }
`

const RowInfoContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin-left: auto;
  gap: ${spacing[50]};

  ${mobileMediaQuery} {
    width: 100%;
    margin-left: 0;
    justify-content: space-between;
  }
`

const RowInfoData = styled.div`
  display: flex;
  flex-direction: column;
  width: 120px;
`

const RowContent = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  flex: 1;

  ${mobileMediaQuery} {
    align-items: flex-start;
    flex-direction: column;

    gap: ${spacing[50]};
  }
`

const BoxCenter = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
`
