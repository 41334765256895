import { createFileRoute } from '@tanstack/react-router'

import PlatformUserPage from '../../../../components/pages/backoffice/platform/platform-user-page'
import { PermissionChecker } from '../../../../components/templates/permission-checker'

export const Route = createFileRoute('/bak/users/$userId')({
  component: () => (
    <PermissionChecker requiredPermissions={['users.read']}>
      <PlatformUserPage />
    </PermissionChecker>
  ),
})
