import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { memo, type ChangeEvent, type ReactNode } from 'react'
import { useUiContext, type UiContext } from '../../code-only/hooks'
import { ProcessingCheckbox, Radio } from '../../components'
import { borderStroke, colorTokens, shadow, spacing } from '../../foundations'
import { Text } from '../../foundations/text/text.js'

export type SelectableCardProps = {
  title: string
  isActive: boolean
  subtitle?: string
  avatar: ReactNode
  chip?: ReactNode
  button?: ReactNode
  detailDialog?: ReactNode
  dataTestId?: string

  onCheckboxChange?: (newValue: boolean) => boolean
  radioConfig?:
    | {
        value: string
        onRadioChange: (event: ChangeEvent<HTMLInputElement>, value: string) => void
      }
    | undefined
}

export const SelectableCard = memo<SelectableCardProps>(function SelectableCard(props: SelectableCardProps) {
  const screenVariant = useUiContext()

  const { avatar, title, subtitle, chip, isActive, button, onCheckboxChange, detailDialog, dataTestId, radioConfig } =
    props

  return (
    <Label data-testid={dataTestId} isActive={isActive} screenVariant={screenVariant}>
      <>
        <div
          css={css`
            display: flex;
            flex: 1;
            gap: ${spacing[50]};
            align-items: ${screenVariant === 'mobile' ? 'start' : 'center'};
            flex-wrap: wrap;
          `}
        >
          <div
            css={css`
              display: flex;
              flex-direction: ${screenVariant === 'mobile' ? 'column' : 'row'};
              align-items: ${screenVariant === 'mobile' ? 'stretch' : 'center'};
              flex: 1;
              gap: ${spacing[50]};
            `}
          >
            {avatar}
            <div
              css={css`
                flex: 1 1;
                display: flex;
                flex-direction: column;
                gap: ${spacing[20]};
              `}
            >
              <Text variant="body1Medium" color={colorTokens['color-text-base-main']}>
                {title}
              </Text>

              {subtitle ? (
                <Text variant="body2" color={colorTokens['color-text-base-basic']}>
                  {subtitle}
                </Text>
              ) : (
                <></>
              )}
            </div>
          </div>

          {chip}
        </div>

        {onCheckboxChange && (
          <div
            css={css`
              display: flex;
              flex-direction: row-reverse;

              /* In order to make clicks on the whole option card act as clicks on the checkbox, we
          * do this :
          *   - The whole cards is a label
          *   - We make sure that the checkbox is the first interactive element in the label
          * This is why we have row-reverse, instead of the normal flow and ordering the
          * items in the expected way
          */

              gap: ${spacing[50]};
              align-items: center;
              justify-content: space-between;
            `}
          >
            <ProcessingCheckbox value={isActive} onChange={onCheckboxChange} />

            {button}
          </div>
        )}

        {radioConfig && (
          <div
            css={css`
              display: flex;
              gap: ${spacing[50]};
              align-items: center;
              justify-content: space-between;
            `}
          >
            {button}
            <Radio size="medium" value={radioConfig.value} />
          </div>
        )}
      </>
      {detailDialog}
    </Label>
  )
})

const Label = styled.label<{ isActive: boolean; screenVariant: UiContext }>`
  outline: ${({ isActive }) => (isActive ? borderStroke['30'] : borderStroke['20'])} solid;
  outline-offset: ${({ isActive }) => (isActive ? `-${borderStroke['30']}` : `-${borderStroke['20']}`)};
  outline-color: ${({ isActive }) =>
    isActive ? colorTokens['color-stroke-base-selected'] : colorTokens['color-stroke-base']};
  border-radius: ${spacing[30]};
  padding: ${({ screenVariant }) => (screenVariant === 'mobile' ? `${spacing['50']} ${spacing['60']}` : spacing[60])};
  display: flex;
  gap: ${spacing[60]};
  flex-flow: ${({ screenVariant }) => (screenVariant === 'mobile' ? 'column' : 'row')} wrap;
  justify-content: flex-end;
  align-items: ${({ screenVariant }) => (screenVariant === 'mobile' ? 'stretch' : 'center')};
  transition: all 0.3s ease-out;

  background: ${colorTokens['color-bg-base-normal']};
  box-shadow: ${shadow.bottom[10]};

  &:hover {
    cursor: pointer;
    outline-color: ${({ isActive }) =>
      isActive ? colorTokens['color-stroke-base-selected'] : colorTokens['color-stroke-base-hover']};
  }

  &:focus {
    outline-color: ${({ isActive }) =>
      isActive ? colorTokens['color-stroke-base-selected'] : colorTokens['color-stroke-base-focus']};
  }
`
