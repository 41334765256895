import typescaleTokensThemed from './typescale-tokens.json'

export const typescaleTokens = Object.keys(typescaleTokensThemed.typescaleTokens['light']).reduce(
  (styles, skinName) => ({
    ...styles,
    [skinName]: Object.keys(typescaleTokensThemed.typescaleTokens['light']['bo']).reduce(
      (innerStyles, variant) => ({
        ...innerStyles,
        [variant]: `var(--${skinName}-${variant})`,
      }),
      {},
    ),
  }),
  {} as Record<
    keyof (typeof typescaleTokensThemed.typescaleTokens)['light'],
    Record<keyof (typeof typescaleTokensThemed.typescaleTokens)['light']['bo'], string>
  >,
)
