import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { memo, useCallback, useState, type ChangeEvent, type DragEvent } from 'react'
import { Avatar } from '../../../components/index.js'
import { colorTokens } from '../../../foundations/color-tokens.js'
import { spacing } from '../../../foundations/spacing-tokens.js'
import { Text } from '../../../foundations/text'
import { FlexColumn } from '../../atoms/index.js'
import type { LoadedFile } from './uploader-file.js'

export type UploaderInputProps = {
  /**
   * Called when an acceptable file was successfully loaded using the input
   */
  onFileSelected: (file: LoadedFile) => void
  disabled?: boolean
  size: 'small' | 'large'
}

export const UploaderInput = memo<UploaderInputProps>(function UploaderInput({
  onFileSelected,
  disabled = false,
  size,
}) {
  /**
   * Tracks the number of drag enter events to determine if the user is currently dragging a file over the input.
   * Incremented on drag enter, decremented on drag leave.
   * We need to proceed this way because the drag leave event is triggered when the user moves the mouse over a child element.
   */
  const [dragEnterCount, setDragEnterCount] = useState(0)

  const isDraggedOver = !disabled && dragEnterCount > 0

  const handleFileSelected = useCallback(
    (file: File) => {
      if (onFileSelected) onFileSelected({ type: 'loaded', file, name: file.name, size: file.size })
    },
    [onFileSelected],
  )

  const handleChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      const files = event.target.files
      if (!files) return

      for (const file of files) handleFileSelected(file)
    },
    [handleFileSelected],
  )

  const handleDrop = useCallback(
    (event: DragEvent<HTMLLabelElement>) => {
      event.preventDefault()
      setDragEnterCount(0)

      if (!event.dataTransfer || disabled) return

      getFilesFromDataTransfer(event.dataTransfer).forEach(handleFileSelected)
    },
    [disabled, handleFileSelected],
  )

  const handleDragEnter = useCallback((event: DragEvent<HTMLLabelElement>) => {
    event.preventDefault()

    setDragEnterCount((value) => value + 1)
  }, [])

  const handleDragOver = useCallback((event: DragEvent<HTMLLabelElement>) => {
    event.preventDefault()
  }, [])

  const handleDragLeave = useCallback((event: DragEvent<HTMLLabelElement>) => {
    event.preventDefault()

    setDragEnterCount((value) => value - 1)
  }, [])

  return (
    <form onSubmit={(event) => event.preventDefault()}>
      <DropTargetLabel
        onDrop={handleDrop}
        onDragOver={handleDragOver}
        onDragEnter={handleDragEnter}
        onDragLeave={handleDragLeave}
        isDraggedOver={isDraggedOver}
        disabled={disabled}
        size={size}
      >
        <Avatar
          size={size === 'large' ? '40' : '30'}
          icon="file-arrow-up-light"
          color={
            colorTokens[
              disabled ? 'color-fg-base-disable' : isDraggedOver ? 'color-fg-base-active-inverse' : 'color-fg-success'
            ]
          }
        />
        {size === 'large' ? (
          <FlexColumn
            css={css`
              align-items: center;
            `}
          >
            <Text
              variant="body2"
              color={
                colorTokens[
                  disabled
                    ? 'color-text-base-disable'
                    : isDraggedOver
                      ? 'color-fg-base-active-inverse'
                      : 'color-text-base-main'
                ]
              }
            >
              Glisser-déposer ou importer
            </Text>
            <Text variant="body2" color={colorTokens['color-text-base-disable']}>
              formats acceptés : image et document
            </Text>
          </FlexColumn>
        ) : null}
        <input
          css={css`
            position: absolute; /* Hide the input element without display none, so that it can still be focused */
            width: 0;
            height: 0;
            overflow: hidden;
          `}
          type="file"
          multiple
          onChange={handleChange}
          disabled={disabled}
        />
      </DropTargetLabel>
    </form>
  )
})

const DropTargetLabel = styled.label<{ isDraggedOver: boolean; disabled: boolean; size: 'small' | 'large' }>`
  display: flex;
  height: ${({ size }) => (size === 'large' ? '160px' : 'auto')};
  padding: ${({ size }) => (size === 'large' ? spacing['60'] : spacing['20'])};
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: ${spacing['30']};
  align-self: stretch;
  border-radius: ${spacing['30']};
  border: ${({ isDraggedOver, disabled }) =>
    disabled
      ? `1px dashed ${colorTokens['color-stroke-base-disable']}`
      : isDraggedOver
        ? `2px solid ${colorTokens['color-stroke-base-selected']}`
        : `1px dashed ${colorTokens['color-stroke-base-active']}`};
  background: ${({ isDraggedOver, disabled }) =>
    colorTokens[disabled ? 'color-bg-base-disable' : isDraggedOver ? 'color-bg-base-hover' : 'color-bg-base-normal']};
  cursor: ${({ isDraggedOver }) => (isDraggedOver ? 'copy' : 'pointer')};

  &:hover {
    border-color: ${colorTokens['color-stroke-base-focus']};
    background-color: ${colorTokens['color-bg-base-hover']};
  }

  &:focus-within {
    border-color: ${({ isDraggedOver }) =>
      isDraggedOver ? colorTokens['color-stroke-base-selected'] : colorTokens['color-stroke-base-focus']};
    background-color: ${colorTokens['color-bg-base-hover']};
  }
  pointer-events: ${({ disabled }) => (disabled ? 'none' : undefined)};
`

function getFilesFromDataTransfer(dataTransfer: DataTransfer): File[] {
  // insipred by https://developer.mozilla.org/en-US/docs/Web/API/HTML_Drag_and_Drop_API/File_drag_and_drop

  return dataTransfer.items
    ? [...dataTransfer.items]
        .filter((item) => item.kind === 'file')
        .map((item) => item.getAsFile())
        .filter((file): file is File => file !== null)
    : [...dataTransfer.files]
}
