import { memo, useCallback, useState, type ComponentProps, type MouseEvent, type ReactNode } from 'react'

import styled from '@emotion/styled'
import { ListItemIcon, ListItemText, Menu, MenuItem } from '@mui/material'

import { useDialogVisibility } from '../../../code-only/hooks/use-dialog-visibility.js'
import { useScreenType } from '../../../code-only/hooks/use-screen-type.js'
import { Avatar, Button } from '../../../components/index.js'
import { colorTokens } from '../../../foundations/color-tokens.js'
import { Text } from '../../../foundations/index.js'
import { shadow } from '../../../foundations/shadow-tokens.js'
import { spacing } from '../../../foundations/spacing-tokens.js'
import { LegacyDialog } from '../../templates'

const MenuButton = styled(Button)<{ maxWidth?: string }>`
  ${({ maxWidth = undefined }) => (maxWidth ? `max-width: ${maxWidth};` : ``)}
  overflow: hidden;
  padding: 0;

  & > div > span {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: ${spacing[40]};
    padding: ${spacing[40]};
    max-width: 240px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }
`

export const DropdownMenu = memo<{
  menuItems: ComponentProps<typeof FloatingMenuList>['menuItems']
  buttonAccessoryLeft?: ReactNode
  buttonAccessoryRight?: ReactNode
  buttonLabel?: string
  buttonMaxWidth?: string
  onChange: (value: string | null) => void
  forceOpen?: boolean
}>(function UserDropdown({
  buttonAccessoryLeft,
  buttonAccessoryRight,
  buttonLabel,
  buttonMaxWidth,
  menuItems,
  forceOpen = false,
  onChange,
}) {
  const isMobile = useScreenType() === 'mobile'
  const { show, hide, visible } = useDialogVisibility('topbar-menu')

  const open = forceOpen || visible

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)

  const handleClick = useCallback(
    (event: MouseEvent<HTMLElement>) => {
      setAnchorEl(event.currentTarget)
      show()
    },
    [setAnchorEl, show],
  )
  const handleClose = useCallback(() => {
    hide()
  }, [hide])

  return (
    <>
      <MenuButton
        variant={isMobile ? 'text' : 'secondary'}
        aria-controls={open ? 'basic-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
        maxWidth={buttonMaxWidth}
      >
        {buttonAccessoryLeft}
        {isMobile ? (
          <></>
        ) : (
          <>
            {buttonLabel}
            {buttonAccessoryRight}
          </>
        )}
      </MenuButton>

      {isMobile ? (
        <DialogMenuList
          menuItems={menuItems}
          anchorElement={anchorEl}
          open={open}
          handleClose={handleClose}
          onChange={onChange}
        />
      ) : (
        <FloatingMenuList
          menuItems={menuItems}
          anchorElement={anchorEl}
          open={open}
          handleClose={handleClose}
          onChange={onChange}
        />
      )}
    </>
  )
})

const StyledMenu = styled(Menu)`
  .MuiPaper-root {
    margin-top: ${spacing[30]};
    border-radius: ${spacing[30]};
    border: 1px solid ${colorTokens['color-stroke-base']};
    background: ${colorTokens['color-bg-base-normal']};

    box-shadow: ${shadow.bottom['30']};
  }
`

const FloatingMenuList = memo<{
  menuItems: Array<{
    icon?: ComponentProps<typeof Avatar>['icon']
    label: string
    value: string
  }>
  handleClose: () => void
  open: boolean
  onChange: (value: string) => void
  anchorElement: HTMLElement | null
}>(function MenuList({ menuItems, anchorElement, open, handleClose, onChange }) {
  return (
    <StyledMenu
      aria-labelledby="navigation-menu"
      anchorEl={anchorElement}
      open={open}
      onClose={handleClose}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
    >
      {menuItems.map(({ icon, label, value }, idx) => (
        <MenuItem key={idx}>
          <ListItemIcon>
            <Avatar icon={icon} size="30" />
          </ListItemIcon>
          <ListItemText onClick={() => onChange(value)}>
            <Text>{label}</Text>
          </ListItemText>
        </MenuItem>
      ))}
    </StyledMenu>
  )
})

const DialogMenuList = memo<{
  menuItems: Array<{
    icon?: ComponentProps<typeof Avatar>['icon']
    label: string
    value: string
  }>
  handleClose: () => void
  open: boolean
  onChange: (value: string) => void
  anchorElement: HTMLElement | null
}>(function MenuList({ menuItems, open, handleClose, onChange }) {
  if (!open) return
  return (
    <LegacyDialog onClose={handleClose} fullWidth>
      {menuItems.map(({ icon, label, value }, idx) => (
        <MenuItem key={idx}>
          <ListItemIcon>
            <Avatar icon={icon} size="30" />
          </ListItemIcon>
          <ListItemText onClick={() => onChange(value)}>
            <Text>{label}</Text>
          </ListItemText>
        </MenuItem>
      ))}
    </LegacyDialog>
  )
})
