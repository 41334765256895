import { DOCUMENTATION_LANGUAGE, m, type FieldSpecification, type ValueDocumentation } from '@orus.eu/message'
import { success, type Result } from '@orus.eu/result'
import {
  AbstractDimension,
  dimensionValidationFailure,
  type DimensionTrackingMessageFieldAdapter,
  type DimensionValidationProblem,
  type LooselyTypedValue,
} from './abstract-dimension.js'

export abstract class AbstractNumberDimension<NAME extends string, VALUE = number> extends AbstractDimension<
  NAME,
  VALUE
> {
  override validateData(value: LooselyTypedValue): Result<VALUE, DimensionValidationProblem> {
    return typeof value === 'number'
      ? this.validateNumber(value)
      : dimensionValidationFailure(`Field ${this.name} is not a number`)
  }

  override readonly trackingMessageFieldAdapter: DimensionTrackingMessageFieldAdapter = {
    getTrackingFieldSpecification: (): FieldSpecification => {
      const valueDocumentation: ValueDocumentation<number> = {
        title: this.displayNames[DOCUMENTATION_LANGUAGE],
      }
      if (this.hints) {
        valueDocumentation.detailedExplanationMd = this.hints[DOCUMENTATION_LANGUAGE]
      }
      return m.nullable(m.number(valueDocumentation))
    },
    convertStateValueToTrackingValue: (stateValue: number | null | undefined): number | null => {
      if (stateValue == undefined) return null
      return stateValue
    },
  }

  abstract validateNumber(value: number): Result<VALUE, DimensionValidationProblem>
}

/**
 * A variant of the abstract number dimension that allows the "default" string as a valid value,
 * to express the fact that the dimension can be set to a default value, determined somewhere else.
 *
 * Intended usecase :
 * - A dimension "defaultXxxx" that is dynamic
 * - An input dimension "preferredXxxx" that is an input dimension set bu the user
 * - A dimension "xxxx" dimension that is the end result
 */
export abstract class AbstractNumberDimensionWithDefaultOption<
  NAME extends string,
  VALUE = number,
> extends AbstractDimension<NAME, VALUE | 'default'> {
  override validateData(value: LooselyTypedValue): Result<VALUE | 'default', DimensionValidationProblem> {
    if (value === 'default') return success(value)
    return typeof value === 'number'
      ? this.validateNumber(value)
      : dimensionValidationFailure(`Field ${this.name} is not a number`)
  }

  override readonly trackingMessageFieldAdapter: DimensionTrackingMessageFieldAdapter = {
    getTrackingFieldSpecification: (): FieldSpecification => {
      const valueDocumentation: ValueDocumentation<number> = {
        title: this.displayNames[DOCUMENTATION_LANGUAGE],
      }
      if (this.hints) {
        valueDocumentation.detailedExplanationMd = this.hints[DOCUMENTATION_LANGUAGE]
      }
      return m.nullable(m.number(valueDocumentation))
    },
    convertStateValueToTrackingValue: (stateValue: number | 'default' | null | undefined): number | null => {
      if (stateValue == undefined || stateValue === 'default') return null
      return stateValue
    },
  }

  abstract validateNumber(value: number): Result<VALUE, DimensionValidationProblem>
}
