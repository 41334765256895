import { memo, useMemo } from 'react'
import { GuaranteesWithDeductibleList } from '../guarantees-deductible-list/index.js'

import { type UiContext } from '../../../../code-only/hooks/use-screen-variant.js'
import { PassthroughIcon } from '../passthrough-icon.js'
import type { Guarantee } from '../types.js'

type OnClick = (guarantee: Guarantee) => void

export type GuaranteeListProps = {
  guarantees: Guarantee[]
  variant: UiContext

  onGuaranteeClick: OnClick

  className?: string
  quotePageType?: 'static' | 'dynamic'
  isMuta: boolean
}

export const GuaranteesList = memo<GuaranteeListProps>(function GuaranteesList(props) {
  const { guarantees, variant, onGuaranteeClick, className, quotePageType, isMuta } = props

  const listType = quotePageType ?? 'dynamic'

  const guaranteesWithButton = useMemo(
    () =>
      guarantees.map((guarantee) => ({
        ...guarantee,
        onClick: () => {
          onGuaranteeClick(guarantee)
        },
        button: <PassthroughIcon icon="circle-info-solid" />,
      })),
    [guarantees, onGuaranteeClick],
  )

  return (
    <GuaranteesWithDeductibleList
      guarantees={guaranteesWithButton}
      variant={variant}
      className={className}
      quotePageType={listType}
      isMuta={isMuta}
    />
  )
})
