import { css } from '@emotion/react'
import { Divider } from '@mui/material'
import { memo, useCallback, useState } from 'react'
import { useDialogVisibility, useScreenType } from '../../../../code-only/hooks'
import { secondaryColor } from '../../../../code-only/theme.js'
import { getFileDisplayName } from '../../../../code-only/util.js'
import { Avatar, Button, Tooltip } from '../../../../components/index.js'
import type { CompoundIconName } from '../../../../foundations'
import { spacing } from '../../../../foundations/spacing-tokens.js'
import { PdfDocumentDialog } from '../pdf-document-dialog/index.js'
import { useUrlFileBytes } from './download-util.js'

export type DocumentsProps = {
  groups: DocumentGroup[]
}

export type DocumentGroup = {
  id: string
  hint: string
  documents: Document[]
}

export type Document = {
  fileName: string
  url: string
}

export const Documents = memo<DocumentsProps>(function PdfDocumentDialog({ groups }) {
  const screenType = useScreenType()
  const [expanded, setExpanded] = useState(false)

  const displayAllDocs = expanded || screenType !== 'mobile'

  const collapse = useCallback(() => setExpanded(false), [])
  const expand = useCallback(() => setExpanded(true), [])

  /**
   * Tracks rendered documents count during the rendering to later decide if
   * we display the "see less / see more" button
   *
   * Note : in order to display animated transitions, all documents are rendered whether
   * they are visible or not. Display / hide is done via CSS.
   */
  let renderedDocumentsCount = 0
  return (
    <>
      {groups.map((group, groupIndex) => {
        const groupVisible = displayAllDocs || groupIndex < 2
        return (
          <div key={group.id}>
            {groupVisible && groupIndex > 0 ? (
              <div css={visibleCss}>
                <Divider
                  css={css`
                    margin: ${spacing[50]} 0;
                  `}
                />
              </div>
            ) : (
              <></>
            )}

            <div
              key={group.id}
              css={css`
                display: flex;
                justify-content: space-between;
                align-items: center;
              `}
            >
              <div>
                {group.documents.map((document, documentIndex) => {
                  const displayCurrentDocument =
                    displayAllDocs ||
                    // there are multiple groups, so we display the first document of each group
                    (groupVisible && groups.length > 1 && documentIndex < 1) ||
                    // there is a single group, so we display the first two documents
                    (groupVisible && groups.length === 1 && documentIndex < 2)

                  const collapseStateCss = displayCurrentDocument ? visibleCss : hiddenCss
                  renderedDocumentsCount++
                  return <FileButton key={document.url} document={document} css={collapseStateCss} />
                })}
              </div>

              <div css={groupVisible ? visibleCss : hiddenCss}>
                <Tooltip title={group.hint}>
                  <Avatar icon="circle-info-regular" size="30" />
                </Tooltip>
              </div>
            </div>
          </div>
        )
      })}

      {screenType === 'mobile' && renderedDocumentsCount > 2 ? (
        <MoreLessButton onCollapse={collapse} onExpand={expand} expanded={expanded} />
      ) : (
        <></>
      )}
    </>
  )
})

type FileButtonProps = {
  document: Document
  className?: string
}

const FileButton = memo<FileButtonProps>(function FileButton({ document, className }) {
  const { show: showDialog, hide: hideDialog, visible: dialogVisible } = useDialogVisibility(document.url)
  const { data, load } = useUrlFileBytes(document.url)

  const handleClick = useCallback(() => {
    load()
    showDialog()
  }, [load, showDialog])

  return (
    <div>
      <Button
        className={className}
        css={css`
          justify-content: left;
        `}
        fullWidth
        variant="text"
        size="medium"
        avatarPosition="left"
        avatar={<Avatar icon="file-lines-regular" color={secondaryColor} />}
        onClick={handleClick}
      >
        {getFileDisplayName(document.fileName)}
      </Button>
      {dialogVisible ? (
        <PdfDocumentDialog
          fileName={document.fileName}
          onClose={hideDialog}
          pdfBytes={data}
          downloadablePdfBytes={data}
        />
      ) : (
        <></>
      )}
    </div>
  )
})

type MoreLessButtonProps = {
  onCollapse: () => void
  onExpand: () => void
  expanded: boolean
}

const MoreLessButton = memo<MoreLessButtonProps>(function MoreLessButton({ expanded, onCollapse, onExpand }) {
  const { onClick, icon, text }: { onClick: () => void; icon: CompoundIconName; text: string } = expanded
    ? { onClick: onCollapse, icon: 'angle-up-solid', text: 'Voir moins' }
    : { onClick: onExpand, icon: 'angle-down-solid', text: 'Voir plus' }

  return (
    <Button
      css={css`
        margin-top: ${spacing[50]};
      `}
      variant="tertiary"
      fullWidth
      onClick={onClick}
      icon={icon}
      size="large"
    >
      {text}
    </Button>
  )
})

const hiddenCss = css`
  max-height: 0;
  opacity: 0;
  overflow: hidden;
  transition: all 0.3s;
`
const visibleCss = css`
  max-height: 32px;
  opacity: 1;
  overflow: hidden;
  transition: all 0.3s;
`
