import { createFileRoute } from '@tanstack/react-router'

import PlatformOrganizationsPage from '../../../components/pages/backoffice/platform/platform-organization-page/platform-organizations-page'
import { PermissionChecker } from '../../../components/templates/permission-checker'

export const Route = createFileRoute('/bak/organizations')({
  component: () => (
    <PermissionChecker requiredPermissions={['organization.read']}>
      <PlatformOrganizationsPage />
    </PermissionChecker>
  ),
})
