import { css } from '@emotion/react'
import { Container } from '@mui/material'
import {
  Avatar,
  Button,
  FlexSpacedColumn,
  FlexSpacedRow,
  Illustration,
  Text,
  TextContainer,
  colorTokens,
  spacing,
  useScreenType,
  type ScreenType,
} from '@orus.eu/pharaoh'
import { memo, type FunctionComponent } from 'react'
import { SmoothScrollBar } from '../atoms/smooth-scrollbar'

type GenericProblemMessageProps = {
  buttonText?: string
  onButtonClick?: () => void
  secondaryButtonText?: string
  onSecondaryButtonClick?: () => void
  title: string
  principalMessage: string
  firstSubText: string
  secondSubText?: string
  trackingId?: string
  secondaryButtonTrackingId?: string
}

export const GenericProblemMessage: FunctionComponent<GenericProblemMessageProps> = memo(
  function GenericProblemMessage(props) {
    const screenType = useScreenType()
    const Version = versionByScreenType[screenType]
    return <Version {...props} />
  },
)

const DesktopVersion: FunctionComponent<GenericProblemMessageProps> = memo(function DesktopVersion({
  onButtonClick,
  buttonText,
  trackingId,
  onSecondaryButtonClick,
  secondaryButtonText,
  secondaryButtonTrackingId,
  title,
  principalMessage,
  firstSubText,
  secondSubText,
}) {
  return (
    <Container
      maxWidth="sm"
      css={css`
        padding: ${spacing[70]} ${spacing[60]};
        display: flex;
        gap: ${spacing[80]};
        align-items: center;
        min-height: 80vh; /* not centered, but slighly offset to the top, for a prettier result */
      `}
    >
      <div
        css={css`
          flex: 1;
        `}
      >
        <MessageBody
          title={title}
          principalMessage={principalMessage}
          firstSubText={firstSubText}
          secondSubText={secondSubText}
        />
        <FlexSpacedRow margin="0" padding="0" gap={spacing[50]}>
          {onSecondaryButtonClick && secondaryButtonText ? (
            <Button
              css={css`
                margin-top: ${spacing[70]};
              `}
              onClick={onSecondaryButtonClick}
              trackingId={secondaryButtonTrackingId}
              variant="secondary"
            >
              {secondaryButtonText}
            </Button>
          ) : null}
          {onButtonClick && buttonText ? (
            <Button
              css={css`
                margin-top: ${spacing[70]};
              `}
              onClick={onButtonClick}
              trackingId={trackingId}
            >
              {buttonText}
            </Button>
          ) : null}
        </FlexSpacedRow>
      </div>

      <Illustration
        name="map_big"
        size="140"
        css={css`
          display: block;
          margin-left: auto;
          margin-right: auto;
          margin-bottom: ${spacing[50]};
        `}
      />
    </Container>
  )
})

const MobileVersion: FunctionComponent<GenericProblemMessageProps> = memo(function MobileVersion({
  onButtonClick,
  buttonText,
  trackingId,
  onSecondaryButtonClick,
  secondaryButtonText,
  secondaryButtonTrackingId,
  title,
  principalMessage,
  firstSubText,
  secondSubText,
}) {
  return (
    <SmoothScrollBar style={{ width: '100%', height: '100%' }}>
      <div
        css={css`
          padding: ${spacing[70]} ${spacing[60]};
          display: flex;
          flex-direction: column;
          gap: ${spacing[80]};
          justify-content: space-between;
          min-height: 100vh;
        `}
      >
        <div>
          <Illustration
            name="map_big"
            size="140"
            css={css`
              display: block;
              margin-left: auto;
              margin-right: auto;
              margin-bottom: ${spacing[50]};
            `}
          />
          <MessageBody
            title={title}
            principalMessage={principalMessage}
            firstSubText={firstSubText}
            secondSubText={secondSubText}
          />
        </div>
        <FlexSpacedColumn margin="0" padding="0" gap={spacing[50]}>
          {onButtonClick && buttonText ? (
            <Button size="large" fullWidth onClick={onButtonClick} trackingId={trackingId}>
              {buttonText}
            </Button>
          ) : null}
          {onSecondaryButtonClick && secondaryButtonText ? (
            <Button
              size="large"
              fullWidth
              onClick={onButtonClick}
              trackingId={secondaryButtonTrackingId}
              variant="secondary"
            >
              {secondaryButtonText}
            </Button>
          ) : null}
        </FlexSpacedColumn>
      </div>
    </SmoothScrollBar>
  )
})

const versionByScreenType: { [key in ScreenType]: FunctionComponent<GenericProblemMessageProps> } = {
  mobile: MobileVersion,
  desktop: DesktopVersion,
}

type MessageBodyProps = {
  title: string
  principalMessage: string
  firstSubText: string
  secondSubText?: string
}

const MessageBody: FunctionComponent<MessageBodyProps> = memo(function MessageBody({
  title,
  principalMessage,
  firstSubText,
  secondSubText,
}) {
  return (
    <>
      <Text variant="h1">{title}</Text>

      <Avatar
        css={css`
          margin-top: ${spacing[70]};
        `}
        icon="link-duotone"
        size="50"
        color={colorTokens['color-fg-base-active-inverse']}
        secondaryColor={colorTokens['color-fg-base-active-inverse']}
      />

      <Text
        css={css`
          margin-top: ${spacing[30]};
        `}
        variant="body1Medium"
      >
        {principalMessage}
      </Text>

      <TextContainer
        css={css`
          margin-top: ${spacing[30]};
        `}
        variant="body2"
        color={colorTokens['color-text-base-basic']}
      >
        <Text>{firstSubText}</Text>
        {secondSubText ? <Text>{secondSubText}</Text> : <></>}
      </TextContainer>
    </>
  )
})
