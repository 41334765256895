import { css } from '@emotion/react'
import { assert } from '@orus.eu/error'
import { memo, useCallback, useEffect, useMemo, useState, type ReactNode } from 'react'

import styled from '@emotion/styled'
import { mobileMediaQuery, useScreenType } from '../../../../code-only/hooks/use-screen-type.js'
import { useTranslate } from '../../../../code-only/localization/language-context.js'
import { Avatar, Button, Callout, Chip } from '../../../../components/index.js'
import { spacing } from '../../../../foundations/spacing-tokens.js'
import { Features } from '../../../molecules/features/index.js'

import type { IllustrationName } from '../../../../foundations/illustration/names.js'
import { Text, TextContainer } from '../../../../foundations/text/text.js'
import { Dialog } from '../../../../patterns/dialog'
import { FlexColumn, FlexRow } from '../../../atoms/container.js'
import { TabBar } from '../../../molecules/index.js'
import {
  GuaranteeAndOptionsList,
  type Coverage,
} from '../../universal-subscription/product-selector/product-details-modal.js'
import womanRaisingHandPng from './woman-raising-hand.png'

type Variant = 'mobile' | 'desktop'

export type InfoDialogSection = {
  name: string
  description: ReactNode
} & (
  | {
      type: 'info'
      info: Array<{ title: string; content: ReactNode }>
      example: ReactNode
    }
  | {
      type: 'subguarantees'
      coverage: Coverage
    }
)

export type InfoDialogProps = {
  variant: Variant

  title: string
  recommended?: boolean
  sections: InfoDialogSection[]

  button?: ReactNode
  useIllustrations: boolean
  avatar: string
  illustration?: IllustrationName

  onClose: () => void
}

export const InfoDialog = memo<InfoDialogProps>(function InfoList(props) {
  const translate = useTranslate()
  const { variant, title, recommended, sections, button, onClose, useIllustrations } = props
  const [firstSection] = sections
  assert(firstSection != null, 'InfoDialog must have at least one section')

  const hasSelector = sections.length > 1

  const screenType = useScreenType()
  const [selectedSection, setSelectedSection] = useState<InfoDialogSection>(firstSection)
  const tabs = useMemo(() => Object.fromEntries(sections.map((section) => [section.name, section.name])), [sections])

  const handleTabChange = useCallback(
    (tabId: string) => {
      const section = sections.find((section) => section.name === tabId)
      if (section) {
        setSelectedSection(section)
      }
    },
    [sections],
  )

  useEffect(() => {
    setSelectedSection(firstSection)
  }, [firstSection])

  return (
    <Dialog
      onClose={onClose}
      avatar={
        useIllustrations ? (
          <Avatar size="60" illustration={props.illustration} />
        ) : (
          <Avatar src={props.avatar} size="50" />
        )
      }
      title={title}
      size="large"
    >
      <FlexColumn gap="32px">
        {recommended ? (
          <div
            css={css`
              display: flex;
              align-items: center;
              gap: ${spacing[30]};
            `}
          >
            <Chip size="small" variant="warning">
              Recommandé
            </Chip>
            <Text variant="captionMedium">Notre devoir de conseil pour votre activité</Text>
          </div>
        ) : (
          <></>
        )}
        {hasSelector ? (
          <div>
            <TabBar
              fullWidth={variant === 'mobile'}
              size="large"
              tabs={tabs}
              selectedTabId={selectedSection.name}
              onTabChange={handleTabChange}
            />
          </div>
        ) : (
          <></>
        )}

        <TextContainer variant="body2">{selectedSection.description}</TextContainer>

        {selectedSection.type === 'info' ? (
          <>
            <Features features={selectedSection.info} />

            <Callout
              variant="content"
              title={translate('an_example_of_concrete_case')}
              avatar={<Avatar src={womanRaisingHandPng} />}
            >
              {selectedSection.example}
            </Callout>
          </>
        ) : null}

        {selectedSection.type === 'subguarantees' ? (
          <>
            <GuaranteeAndOptionsList isMobile={screenType === 'mobile'} coverage={selectedSection.coverage} />
          </>
        ) : null}

        {button || variant === 'mobile' ? (
          <ButtonRow>
            {button ?? <></>}
            {variant === 'mobile' ? (
              <Button variant="secondary" size="large" onClick={onClose}>
                Fermer
              </Button>
            ) : (
              <></>
            )}
          </ButtonRow>
        ) : (
          <></>
        )}
      </FlexColumn>
    </Dialog>
  )
})

const ButtonRow = styled(FlexRow)`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  gap: ${spacing[50]};
  ${mobileMediaQuery} {
    flex-direction: column;
    width: 100%;
  }
`
