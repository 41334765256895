import { createFileRoute, Outlet } from '@tanstack/react-router'
import BackofficePage from '../components/templates/platform-page'

export const Route = createFileRoute('/bak')({
  component: RouteComponent,
})

function RouteComponent() {
  return (
    <BackofficePage>
      <Outlet />
    </BackofficePage>
  )
}
