import styled from '@emotion/styled'
import type { ReactElement } from 'react'
import { useOpenChat } from '../../code-only/lib'
import { Avatar, Button } from '../../components'
import { colorTokens } from '../../foundations'

export function CompactHeaderContactButton(): ReactElement {
  const openChat = useOpenChat()
  return (
    <NoPaddingButton
      avatar={<Avatar icon="comment-regular" color={colorTokens['color-fg-base-active-inverse']} />}
      variant="text"
      avatarSize="40"
      onClick={openChat}
    />
  )
}

const NoPaddingButton = styled(Button)({
  padding: 0,
})
