import styled from '@emotion/styled'
import { colorTokens } from '../../foundations/color-tokens.js'
import { spacing } from '../../foundations/spacing-tokens.js'
import { FlexColumn } from './container.js'

export const SummaryTable = styled(FlexColumn)<{ withMarginTop?: boolean }>`
  display: flex;
  flex-direction: column;

  width: 100%;

  padding: 0 ${spacing[50]} 0 ${spacing[50]};

  border: 1px solid ${colorTokens['color-stroke-base']};
  border-radius: 8px;

  & > * {
    border-bottom: 1px solid ${colorTokens['color-stroke-base']};

    &:last-child {
      border-bottom: none;
    }
  }
`
