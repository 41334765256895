import { createContext, useContext, type RefObject } from 'react'

import { type DocViewerRef, type IDocument } from '@cyntler/react-doc-viewer'
import type { ReactZoomPanPinchRef } from 'react-zoom-pan-pinch'

type DocViewerContextType = {
  /** ref for the DocViewer component */
  docViewerRef: React.RefObject<DocViewerRef | null> | null
  /** ref for the image element */
  imageRef: RefObject<HTMLImageElement | null> | null
  /** ref for the zoomPanPinch component */
  zoomPanPinchRef: React.RefObject<ReactZoomPanPinchRef | null> | null
  /** ref for the div container */
  containerRef: RefObject<HTMLDivElement | null> | null
  /** The list of documents to preview */
  documents: IDocument[]
  /** The currently active document */
  activeDocument: IDocument | undefined
  /** Called when the active document changes from within the docviewer, so it can be managed externally from the docviewer */
  onActiveDocumentChange: (document: IDocument) => void
  /** API for manipulating the document viewer */
  rotateClockwise: () => void
  rotateCounterClockwise: () => void
  downloadDocument: () => void
  nextDocument: () => void
  previousDocument: () => void
  getScaleToFit: () => number | undefined
}

// create a react context for the docViewerRef
export const DocViewerContext = createContext<DocViewerContextType>({
  docViewerRef: null,
  imageRef: null,
  zoomPanPinchRef: null,
  containerRef: null,
  documents: [],
  activeDocument: undefined,
  onActiveDocumentChange: () => {},
  rotateClockwise: () => {},
  rotateCounterClockwise: () => {},
  downloadDocument: () => {},
  nextDocument: () => {},
  previousDocument: () => {},
  getScaleToFit: () => undefined,
})

export const useDocumentViewer = (): DocViewerContextType => useContext(DocViewerContext)
