import { typescaleTokens } from '../typescale-tokens'
import type { TextVariant } from './variant'

export const interFontFamily = 'Inter'

export type TextCssProps = {
  fontSize: string
  fontWeight: string
  fontFamily: string
  lineHeight: string
  paragraphSpacing?: string
  textTransform?: 'uppercase'
  letterSpacing?: string
}

/**
 * Back Office font must remain configured to Inter family
 * Do not modify or replace by a skin font token
 */
export const cssPropsPerScreenVariantPerTextVariant: {
  [typoVariant in TextVariant]: { desktop: TextCssProps; mobile: TextCssProps; backoffice: TextCssProps }
} = {
  h1: {
    desktop: {
      fontSize: typescaleTokens['desktop']['size-100'],
      fontWeight: typescaleTokens['desktop']['weight-600'],
      lineHeight: typescaleTokens['desktop']['line-height-110'],
      letterSpacing: typescaleTokens['desktop']['letter-30'],
      fontFamily: typescaleTokens['desktop']['Heading'],
    },
    mobile: {
      fontSize: typescaleTokens['mobile']['size-100'],
      fontWeight: typescaleTokens['mobile']['weight-600'],
      lineHeight: typescaleTokens['mobile']['line-height-110'],
      letterSpacing: typescaleTokens['mobile']['letter-30'],
      fontFamily: typescaleTokens['mobile']['Heading'],
    },
    backoffice: {
      fontSize: typescaleTokens['bo']['size-100'],
      fontWeight: typescaleTokens['bo']['weight-600'],
      lineHeight: typescaleTokens['bo']['line-height-110'],
      letterSpacing: typescaleTokens['bo']['letter-30'],
      fontFamily: typescaleTokens['bo']['Heading'],
    },
  },
  h2: {
    desktop: {
      fontSize: typescaleTokens['desktop']['size-90'],
      fontWeight: typescaleTokens['desktop']['weight-600'],
      lineHeight: typescaleTokens['desktop']['line-height-100'],
      letterSpacing: typescaleTokens['desktop']['letter-30'],
      fontFamily: typescaleTokens['desktop']['Heading'],
    },
    mobile: {
      fontSize: typescaleTokens['mobile']['size-90'],
      fontWeight: typescaleTokens['mobile']['weight-600'],
      lineHeight: typescaleTokens['mobile']['line-height-100'],
      letterSpacing: typescaleTokens['mobile']['letter-30'],
      fontFamily: typescaleTokens['mobile']['Heading'],
    },
    backoffice: {
      fontSize: typescaleTokens['bo']['size-90'],
      fontWeight: typescaleTokens['bo']['weight-600'],
      lineHeight: typescaleTokens['bo']['line-height-100'],
      letterSpacing: typescaleTokens['bo']['letter-30'],
      fontFamily: typescaleTokens['bo']['Heading'],
    },
  },
  h3: {
    desktop: {
      fontSize: typescaleTokens['desktop']['size-80'],
      fontWeight: typescaleTokens['desktop']['weight-600'],
      lineHeight: typescaleTokens['desktop']['line-height-80'],
      letterSpacing: typescaleTokens['desktop']['letter-20'],
      fontFamily: typescaleTokens['desktop']['Heading'],
    },
    mobile: {
      fontSize: typescaleTokens['mobile']['size-80'],
      fontWeight: typescaleTokens['mobile']['weight-600'],
      lineHeight: typescaleTokens['mobile']['line-height-80'],
      letterSpacing: typescaleTokens['mobile']['letter-20'],
      fontFamily: typescaleTokens['mobile']['Heading'],
    },
    backoffice: {
      fontSize: typescaleTokens['bo']['size-80'],
      fontWeight: typescaleTokens['bo']['weight-600'],
      lineHeight: typescaleTokens['bo']['line-height-80'],
      letterSpacing: typescaleTokens['bo']['letter-20'],
      fontFamily: typescaleTokens['bo']['Heading'],
    },
  },
  h4: {
    desktop: {
      fontSize: typescaleTokens['desktop']['size-70'],
      fontWeight: typescaleTokens['desktop']['weight-600'],
      lineHeight: typescaleTokens['desktop']['line-height-70'],
      letterSpacing: typescaleTokens['desktop']['letter-20'],
      fontFamily: typescaleTokens['desktop']['Heading'],
    },
    mobile: {
      fontSize: typescaleTokens['mobile']['size-70'],
      fontWeight: typescaleTokens['mobile']['weight-600'],
      lineHeight: typescaleTokens['mobile']['line-height-70'],
      letterSpacing: typescaleTokens['mobile']['letter-20'],
      fontFamily: typescaleTokens['mobile']['Heading'],
    },
    backoffice: {
      fontSize: typescaleTokens['bo']['size-70'],
      fontWeight: typescaleTokens['bo']['weight-600'],
      lineHeight: typescaleTokens['bo']['line-height-70'],
      letterSpacing: typescaleTokens['bo']['letter-20'],
      fontFamily: typescaleTokens['bo']['Heading'],
    },
  },
  h5: {
    desktop: {
      fontSize: typescaleTokens['desktop']['size-60'],
      fontWeight: typescaleTokens['desktop']['weight-600'],
      lineHeight: typescaleTokens['desktop']['line-height-60'],
      letterSpacing: typescaleTokens['desktop']['letter-10'],
      fontFamily: typescaleTokens['desktop']['Heading'],
    },
    mobile: {
      fontSize: typescaleTokens['mobile']['size-60'],
      fontWeight: typescaleTokens['mobile']['weight-600'],
      lineHeight: typescaleTokens['mobile']['line-height-60'],
      letterSpacing: typescaleTokens['mobile']['letter-10'],
      fontFamily: typescaleTokens['mobile']['Heading'],
    },
    backoffice: {
      fontSize: typescaleTokens['bo']['size-60'],
      fontWeight: typescaleTokens['bo']['weight-600'],
      lineHeight: typescaleTokens['bo']['line-height-60'],
      letterSpacing: typescaleTokens['bo']['letter-10'],
      fontFamily: typescaleTokens['bo']['Heading'],
    },
  },
  h6: {
    desktop: {
      fontSize: typescaleTokens['desktop']['size-50'],
      fontWeight: typescaleTokens['desktop']['weight-600'],
      lineHeight: typescaleTokens['desktop']['line-height-50'],
      letterSpacing: typescaleTokens['desktop']['letter-10'],
      fontFamily: typescaleTokens['desktop']['Heading'],
    },
    mobile: {
      fontSize: typescaleTokens['mobile']['size-50'],
      fontWeight: typescaleTokens['mobile']['weight-600'],
      lineHeight: typescaleTokens['mobile']['line-height-50'],
      letterSpacing: typescaleTokens['mobile']['letter-10'],
      fontFamily: typescaleTokens['mobile']['Heading'],
    },
    backoffice: {
      fontSize: typescaleTokens['bo']['size-50'],
      fontWeight: typescaleTokens['bo']['weight-600'],
      lineHeight: typescaleTokens['bo']['line-height-50'],
      letterSpacing: typescaleTokens['bo']['letter-10'],
      fontFamily: typescaleTokens['bo']['Heading'],
    },
  },
  subtitle: {
    desktop: {
      fontSize: typescaleTokens['desktop']['size-40'],
      fontWeight: typescaleTokens['desktop']['weight-500'],
      lineHeight: typescaleTokens['desktop']['line-height-50'],
      letterSpacing: typescaleTokens['desktop']['letter-10'],
      fontFamily: typescaleTokens['desktop']['Body'],
    },
    mobile: {
      fontSize: typescaleTokens['mobile']['size-40'],
      fontWeight: typescaleTokens['mobile']['weight-500'],
      lineHeight: typescaleTokens['mobile']['line-height-50'],
      letterSpacing: typescaleTokens['mobile']['letter-10'],
      fontFamily: typescaleTokens['mobile']['Body'],
    },
    backoffice: {
      fontSize: typescaleTokens['bo']['size-40'],
      fontWeight: typescaleTokens['bo']['weight-500'],
      lineHeight: typescaleTokens['bo']['line-height-50'],
      letterSpacing: typescaleTokens['bo']['letter-10'],
      fontFamily: typescaleTokens['bo']['Body'],
    },
  },
  body1: {
    desktop: {
      fontSize: typescaleTokens['desktop']['size-30'],
      fontWeight: typescaleTokens['desktop']['weight-400'],
      lineHeight: typescaleTokens['desktop']['line-height-40'],
      letterSpacing: typescaleTokens['desktop']['letter-10'],
      fontFamily: typescaleTokens['desktop']['Body'],
    },
    mobile: {
      fontSize: typescaleTokens['mobile']['size-30'],
      fontWeight: typescaleTokens['mobile']['weight-400'],
      lineHeight: typescaleTokens['mobile']['line-height-40'],
      letterSpacing: typescaleTokens['mobile']['letter-10'],
      fontFamily: typescaleTokens['mobile']['Body'],
    },
    backoffice: {
      fontSize: typescaleTokens['bo']['size-30'],
      fontWeight: typescaleTokens['bo']['weight-400'],
      lineHeight: typescaleTokens['bo']['line-height-40'],
      letterSpacing: typescaleTokens['bo']['letter-10'],
      fontFamily: typescaleTokens['bo']['Body'],
    },
  },
  body1Medium: {
    desktop: {
      fontSize: typescaleTokens['desktop']['size-30'],
      fontWeight: typescaleTokens['desktop']['weight-500'],
      lineHeight: typescaleTokens['desktop']['line-height-40'],
      letterSpacing: typescaleTokens['desktop']['letter-10'],
      fontFamily: typescaleTokens['desktop']['Body'],
    },
    mobile: {
      fontSize: typescaleTokens['mobile']['size-30'],
      fontWeight: typescaleTokens['mobile']['weight-500'],
      lineHeight: typescaleTokens['mobile']['line-height-40'],
      letterSpacing: typescaleTokens['mobile']['letter-10'],
      fontFamily: typescaleTokens['mobile']['Body'],
    },
    backoffice: {
      fontSize: typescaleTokens['bo']['size-30'],
      fontWeight: typescaleTokens['bo']['weight-500'],
      lineHeight: typescaleTokens['bo']['line-height-40'],
      letterSpacing: typescaleTokens['bo']['letter-10'],
      fontFamily: typescaleTokens['bo']['Body'],
    },
  },
  body2: {
    desktop: {
      fontSize: typescaleTokens['desktop']['size-20'],
      fontWeight: typescaleTokens['desktop']['weight-400'],
      lineHeight: typescaleTokens['desktop']['line-height-30'],
      letterSpacing: typescaleTokens['desktop']['letter-10'],
      fontFamily: typescaleTokens['desktop']['Body'],
    },
    mobile: {
      fontSize: typescaleTokens['mobile']['size-20'],
      fontWeight: typescaleTokens['mobile']['weight-400'],
      lineHeight: typescaleTokens['mobile']['line-height-30'],
      letterSpacing: typescaleTokens['mobile']['letter-10'],
      fontFamily: typescaleTokens['mobile']['Body'],
    },
    backoffice: {
      fontSize: typescaleTokens['bo']['size-20'],
      fontWeight: typescaleTokens['bo']['weight-400'],
      lineHeight: typescaleTokens['bo']['line-height-30'],
      letterSpacing: typescaleTokens['bo']['letter-10'],
      fontFamily: typescaleTokens['bo']['Body'],
    },
  },
  body2Medium: {
    desktop: {
      fontSize: typescaleTokens['desktop']['size-20'],
      fontWeight: typescaleTokens['desktop']['weight-500'],
      lineHeight: typescaleTokens['desktop']['line-height-30'],
      letterSpacing: typescaleTokens['desktop']['letter-10'],
      fontFamily: typescaleTokens['desktop']['Body'],
    },
    mobile: {
      fontSize: typescaleTokens['mobile']['size-20'],
      fontWeight: typescaleTokens['mobile']['weight-500'],
      lineHeight: typescaleTokens['mobile']['line-height-30'],
      letterSpacing: typescaleTokens['mobile']['letter-10'],
      fontFamily: typescaleTokens['mobile']['Body'],
    },
    backoffice: {
      fontSize: typescaleTokens['bo']['size-20'],
      fontWeight: typescaleTokens['bo']['weight-500'],
      lineHeight: typescaleTokens['bo']['line-height-30'],
      letterSpacing: typescaleTokens['bo']['letter-10'],
      fontFamily: typescaleTokens['bo']['Body'],
    },
  },
  caption: {
    desktop: {
      fontSize: typescaleTokens['desktop']['size-10'],
      fontWeight: typescaleTokens['desktop']['weight-400'],
      lineHeight: typescaleTokens['desktop']['line-height-20'],
      letterSpacing: typescaleTokens['desktop']['letter-10'],
      fontFamily: typescaleTokens['desktop']['Body'],
    },
    mobile: {
      fontSize: typescaleTokens['mobile']['size-10'],
      fontWeight: typescaleTokens['mobile']['weight-400'],
      lineHeight: typescaleTokens['mobile']['line-height-20'],
      letterSpacing: typescaleTokens['mobile']['letter-10'],
      fontFamily: typescaleTokens['mobile']['Body'],
    },
    backoffice: {
      fontSize: typescaleTokens['bo']['size-10'],
      fontWeight: typescaleTokens['bo']['weight-400'],
      lineHeight: typescaleTokens['bo']['line-height-20'],
      letterSpacing: typescaleTokens['bo']['letter-10'],
      fontFamily: typescaleTokens['bo']['Body'],
    },
  },
  captionMedium: {
    desktop: {
      fontSize: typescaleTokens['desktop']['size-10'],
      fontWeight: typescaleTokens['desktop']['weight-500'],
      lineHeight: typescaleTokens['desktop']['line-height-20'],
      letterSpacing: typescaleTokens['desktop']['letter-10'],
      fontFamily: typescaleTokens['desktop']['Body'],
    },
    mobile: {
      fontSize: typescaleTokens['mobile']['size-10'],
      fontWeight: typescaleTokens['mobile']['weight-500'],
      lineHeight: typescaleTokens['mobile']['line-height-20'],
      letterSpacing: typescaleTokens['mobile']['letter-10'],
      fontFamily: typescaleTokens['mobile']['Body'],
    },
    backoffice: {
      fontSize: typescaleTokens['bo']['size-10'],
      fontWeight: typescaleTokens['bo']['weight-500'],
      lineHeight: typescaleTokens['bo']['line-height-20'],
      letterSpacing: typescaleTokens['bo']['letter-10'],
      fontFamily: typescaleTokens['bo']['Body'],
    },
  },
  input: {
    desktop: {
      fontSize: typescaleTokens['desktop']['size-input'],
      fontWeight: typescaleTokens['desktop']['weight-400'],
      lineHeight: typescaleTokens['desktop']['line-height-30'],
      letterSpacing: typescaleTokens['desktop']['letter-10'],
      fontFamily: typescaleTokens['desktop']['Body'],
    },
    mobile: {
      fontSize: typescaleTokens['mobile']['size-input'],
      fontWeight: typescaleTokens['mobile']['weight-400'],
      lineHeight: typescaleTokens['mobile']['line-height-30'],
      letterSpacing: typescaleTokens['mobile']['letter-10'],
      fontFamily: typescaleTokens['mobile']['Body'],
    },
    backoffice: {
      fontSize: typescaleTokens['bo']['size-input'],
      fontWeight: typescaleTokens['bo']['weight-400'],
      lineHeight: typescaleTokens['bo']['line-height-30'],
      letterSpacing: typescaleTokens['bo']['letter-10'],
      fontFamily: typescaleTokens['bo']['Body'],
    },
  },
  button: {
    desktop: {
      fontSize: typescaleTokens['desktop']['size-20'],
      fontWeight: typescaleTokens['desktop']['weight-500'],
      lineHeight: typescaleTokens['desktop']['line-height-50'],
      letterSpacing: typescaleTokens['desktop']['letter-10'],
      fontFamily: typescaleTokens['desktop']['Button'],
    },
    mobile: {
      fontSize: typescaleTokens['mobile']['size-20'],
      fontWeight: typescaleTokens['mobile']['weight-500'],
      lineHeight: typescaleTokens['mobile']['line-height-50'],
      letterSpacing: typescaleTokens['mobile']['letter-10'],
      fontFamily: typescaleTokens['mobile']['Button'],
    },
    backoffice: {
      fontSize: typescaleTokens['bo']['size-20'],
      fontWeight: typescaleTokens['bo']['weight-500'],
      lineHeight: typescaleTokens['bo']['line-height-50'],
      letterSpacing: typescaleTokens['bo']['letter-10'],
      fontFamily: typescaleTokens['bo']['Button'],
    },
  },
}
