import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { useCallback, type MouseEvent } from 'react'
import { typedMemo } from '../../../code-only/util'
import { AvatarDecorative, type AvatarDecorativeWithIconProps } from '../../../components'
import { borderStroke, colorTokens, spacing, Text } from '../../../foundations'

export type RowV2DynamicButtonProps = {
  value: string
  label: string
  avatarProps: RowV2DynamicButtonAvatarProps
  onItemClicked: (value: string) => void
  ctaText?: string
}

export type RowV2DynamicButtonAvatarProps = Required<Pick<AvatarDecorativeWithIconProps, 'icon' | 'backgroundColor'>>

export const RowV2DynamicButton = typedMemo(function RowV2DynamicButton({
  value,
  label,
  avatarProps,
  onItemClicked,
  ctaText,
}: RowV2DynamicButtonProps) {
  const handleClick = useCallback(
    (event: MouseEvent<HTMLButtonElement>) => {
      onItemClicked(event.currentTarget.value)
    },
    [onItemClicked],
  )

  return (
    <StyledDynamicButton
      type="button"
      onClick={handleClick}
      value={value}
      key={value}
      border={`${borderStroke[20]} solid ${colorTokens['color-stroke-base']}`}
      backgroundColor={colorTokens['color-bg-base-normal']}
    >
      <div
        css={css`
          display: flex;
          align-items: center;
          justify-content: space-between;
        `}
      >
        <div
          css={css`
            display: flex;
            align-items: center;
            gap: ${spacing[50]};
          `}
        >
          <AvatarDecorative
            css={css`
              flex-shrink: 0;
            `}
            size="50"
            {...avatarProps}
            backgroundColor={colorTokens['color-bg-base-normal']}
          />
          <Text className="rowV2ButtonLabel" variant="body2" color={colorTokens['color-text-base-main']}>
            {label}
          </Text>
        </div>
        {ctaText ? (
          <Text
            variant="button"
            color={colorTokens['color-text-base-main']}
            css={css`
              text-decoration: underline;
            `}
          >
            {ctaText}
          </Text>
        ) : undefined}
      </div>
    </StyledDynamicButton>
  )
})

export type StyledDynamicButtonProps = {
  border: string
  backgroundColor: string
}

const StyledDynamicButton = styled.button<{ border: string; backgroundColor: string }>`
  text-align: left;
  background: none;
  cursor: pointer;
  padding: ${spacing[40]} ${spacing[50]};
  border-radius: ${spacing[30]};
  background-color: ${({ backgroundColor }) => backgroundColor};
  border: ${({ border }) => border};

  width: 100%;

  &:hover {
    background-color: ${colorTokens['color-bg-base-focus']};
  }

  &:focus-visible {
    background-color: ${colorTokens['color-bg-base-focus']};
    border: ${borderStroke[30]} solid ${colorTokens['color-stroke-base-selected']};
    outline: none;
  }

  &:active {
    background-color: ${colorTokens['color-bg-base-focus']};
    border: ${borderStroke[20]} solid ${colorTokens['color-stroke-base-selected']};

    .rowV2ButtonLabel {
      color: ${colorTokens['color-text-base-main']};
    }
  }
`
