import { createFileRoute } from '@tanstack/react-router'

import ContractsPage from '../../../components/pages/contracts/ContractsPage'
import { PermissionChecker } from '../../../components/templates/permission-checker'

export const Route = createFileRoute('/_customer/contracts')({
  component: () => (
    <PermissionChecker requiredPermissions={['contracts.read']}>
      <ContractsPage />
    </PermissionChecker>
  ),
})
