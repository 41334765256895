import styled from '@emotion/styled'
import { useRef, type ReactElement } from 'react'
import { useInView } from 'react-intersection-observer'
import { desktopMediaQuery, useUiContext } from '../../../../code-only/hooks'
import { useTranslate } from '../../../../code-only/localization'
import { Button } from '../../../../components'
import { borderRadius, colorTokens, shadow, spacing } from '../../../../foundations'

export const GenericPaymentMethodSubmitButton = function GenericPaymentMethodSubmitButton({
  isDisabled,
  isLoading,
  goBackToPreviousStep,
}: {
  isDisabled: boolean
  isLoading: boolean
  goBackToPreviousStep?: () => void
}): ReactElement {
  const translate = useTranslate()
  const screenType = useUiContext()

  const containerRef = useRef<HTMLDivElement>(null)
  const { ref: triggerRef, inView } = useInView({
    threshold: 1,
    onChange: (inView) => containerRef.current?.classList?.toggle?.('is-pinned', !inView),
  })

  return (
    <>
      <div ref={triggerRef}></div>
      <ButtonsContainer inView={inView}>
        {screenType === 'mobile' && goBackToPreviousStep && (
          <Button
            variant="secondary"
            icon="arrow-left-regular"
            avatarPosition="left"
            onClick={goBackToPreviousStep}
            className="previous-button"
            size="large"
          />
        )}
        <PrimaryButton
          type="submit"
          className="next-button"
          size={screenType === 'desktop' ? 'medium' : 'large'}
          disabled={isDisabled}
          isLoading={isLoading}
        >
          {translate('pay_and_finalize_subscription')}
        </PrimaryButton>
      </ButtonsContainer>
    </>
  )
}

const PrimaryButton = styled(Button)`
  flex-grow: 1;

  ${desktopMediaQuery} {
    flex-grow: 0;
  }
`

const ButtonsContainer = styled.div<{ inView: boolean }>`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  gap: ${spacing[30]};

  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  padding: ${spacing[50]} ${spacing[60]};
  background-color: ${colorTokens['color-bg-base-normal']};
  border-radius: ${borderRadius[30]} ${borderRadius[30]} 0 0;
  box-shadow: ${({ inView }) => (!inView ? shadow.top[20] : 'none')};
  z-index: 1000;

  ${desktopMediaQuery} {
    gap: ${spacing[50]};
    max-width: 760px;
    margin-top: ${spacing[70]};
    margin-bottom: ${spacing[50]};
    position: relative;
    padding: 0;
    background-color: transparent;
    box-shadow: none;
    justify-content: flex-end;
  }
`
