import { createFileRoute, useParams } from '@tanstack/react-router'
import PlatformPendingEndorsementPage from '../../../../../../components/pages/backoffice/platform/platform-endorsement-page/PlatformPendingEndorsementPage'
import { PermissionChecker } from '../../../../../../components/templates/permission-checker'

const PendingEndorsementRoute = () => {
  const { subscriptionId } = useParams({
    strict: false,
  })

  return (
    <PermissionChecker requiredPermissions={['endorsement.create']}>
      <PlatformPendingEndorsementPage key={subscriptionId} />
    </PermissionChecker>
  )
}

export const Route = createFileRoute('/bak/contracts/$subscriptionId/endorsement/$endorsementId/')({
  component: () => <PendingEndorsementRoute />,
})
