import { forwardRef, memo } from 'react'

import { css } from '@emotion/react'
import styled from '@emotion/styled'

import { colorTokens } from '../color-tokens'
import type { CompoundIconName } from './names'
import { PIXELS_PER_ICON_SIZE, type IconSize } from './sizes'
import spriteSvg from './sprite.svg'

export type IconProps = {
  icon: CompoundIconName
  /** Defaults to {@link DEFAULT_SIZE} */
  size?: IconSize

  /** If no color is defined, it inherits the color from the parent CSS */
  color?: string
  /** If no color is defined, defaults to {@link DEFAULT_SECONDARY_COLOR} */
  secondaryColor?: string

  noTransparency?: boolean

  className?: string
}

export const Icon = memo(
  forwardRef<HTMLDivElement, IconProps>(function Icon(
    { icon, size = DEFAULT_SIZE, color, secondaryColor = DEFAULT_SECONDARY_COLOR, className, noTransparency = false },
    ref,
  ) {
    return (
      <IconContainer $size={size} className={className} ref={ref}>
        <svg
          data-icon={icon}
          css={css`
            ${color
              ? css`
                  color: ${color};
                `
              : ``}
            --primary-opacity: 1;
            --secondary-color: ${secondaryColor};
            --secondary-opacity: ${noTransparency ? 1 : 0.4};
            width: 100%;
            height: 100%;
          `}
        >
          <use href={`${spriteSvg}#${icon}`} />
        </svg>
      </IconContainer>
    )
  }),
)

const DEFAULT_SIZE: IconSize = '30'
const DEFAULT_SECONDARY_COLOR = colorTokens['color-bg-base-primary']

const IconContainer = styled.div<{ $size: IconSize }>`
  display: inline-flex;
  ${({ $size }) => css`
    width: ${PIXELS_PER_ICON_SIZE[$size]};
    height: ${PIXELS_PER_ICON_SIZE[$size]};
  `}
`
