import { BaseDimension } from '../abstract-dimension'
import { StringsWithDataEnumDimension } from '../strings-with-data-enum-dimension'

export const rcpaOffers = ['NIVEAU_1', 'NIVEAU_2', 'NIVEAU_3', 'NIVEAU_4'] as const
export type RcpaOffer = (typeof rcpaOffers)[number]

export function isRcpaOffer(value: string): value is RcpaOffer {
  return rcpaOffers.includes(value as RcpaOffer)
}

export const rcpaOfferDimension = new StringsWithDataEnumDimension({
  name: 'rcpaOffer',
  displayValues: { name: "Niveau d'offre RC Pro AIG" },
  tags: ['RCPA'],
  entries: [
    ['NIVEAU_1', { label: 'Réglementaire' }],
    ['NIVEAU_2', { label: 'Confort' }],
    ['NIVEAU_3', { label: 'Premium' }],
    ['NIVEAU_4', { label: 'Premium +' }],
  ],
} as const)

export class RcpaAvailableOffersDimension<NAME extends string> extends BaseDimension<NAME, RcpaOffer[]> {}

export const rcpaAvailableOffersDimension = new RcpaAvailableOffersDimension({
  name: 'rcpaAvailableOffers',
  displayValues: { name: 'Offres disponibles' },
  tags: ['RCPA'],
} as const)
