import type { TypeOfDimension } from './abstract-dimension'
import { StringsWithDataEnumDimension } from './strings-with-data-enum-dimension'

export const latestNomenclatureVersion = '2.0-2025-02-10'

export const nomenclatureVersionDimension = new StringsWithDataEnumDimension({
  name: 'nomenclatureVersion',
  displayValues: { name: 'Version de la nomenclature' },
  entries: [
    ['1.0-2024-11-19', { label: 'Version 1' }],
    ['1.1-2025-01-08', { label: 'Version 1.1' }],
    ['2.0-2025-02-10', { label: 'Version 2 - Mandatory 02/2025 RCDA updates' }],
  ],
} as const)

export type NomenclatureVersion = TypeOfDimension<typeof nomenclatureVersionDimension>
