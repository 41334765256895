import { activityInputType } from '@orus.eu/activity'
import { companyIdNumberType } from '@orus.eu/company-id-number'
import { success, type Result } from '@orus.eu/result'
import { type } from 'arktype'
import {
  AbstractDimension,
  dimensionValidationFailure,
  type DimensionValidationProblem,
  type LooselyTypedValue,
} from '../abstract-dimension'
import { BooleanDimension } from '../boolean-dimension'

export class RcpaAgentsDimension<NAME extends string> extends AbstractDimension<NAME, RcpaAgent[]> {
  override validateData(value: LooselyTypedValue): Result<RcpaAgent[], DimensionValidationProblem> {
    const result = rcpaAgentType.array()(value)
    if (result instanceof type.errors) {
      return dimensionValidationFailure(`Field ${this.name} is not valid : ${result.message}`)
    }
    return success(result)
  }
}

export const rcpaAgentsDimension = new RcpaAgentsDimension({
  name: 'rcpaAgents',
  displayValues: { name: 'Mandataires' },
  tags: ['RCPA'],
})

export const isRcpaAgentsCompletedDimension = new BooleanDimension({
  name: 'isRcpaAgentsCompleted',
  displayValues: { name: 'Mandataires complétés' },
  tags: ['RCPA'],
})

export const isRcpaAgentsAvailableDimension = new BooleanDimension({
  name: 'isRcpaAgentsAvailable',
  displayValues: { name: 'Peut avoir des mandataires' },
  tags: ['RCPA'],
})

export const rcpaAgentType = type({
  id: 'string',
  lastName: 'string|undefined|null',
  firstName: 'string|undefined|null',
  siren: companyIdNumberType.optional().or('undefined').or('null'),
  activities: activityInputType.array(),
  address: type({
    street: 'string|undefined|null',
    postCode: 'string|undefined|null',
    city: 'string|undefined|null',
    country: 'string|undefined|null',
  })
    .or('undefined')
    .or('null'),
})

export type RcpaAgent = typeof rcpaAgentType.infer
