import { css } from '@emotion/react'
import { amountToString, type Amount } from '@orus.eu/amount'
import { memo, type ReactElement } from 'react'

export type AmountTextProps = {
  amount: Amount
  displayDecimals: boolean
  /**
   * String prepended to the amount that should stay with the amount without line break,
   * like for example "+ " to give hints about the meaning of the amount.
   */
  prefix?: string
  strikeThrough?: boolean
  className?: string
}

/**
 * @deprecated use Text from pharaoh in combination with amountToString()
 */
export const AmountText = memo(function AmountText({
  amount,
  displayDecimals,
  prefix,
  strikeThrough,
  className,
}: AmountTextProps): ReactElement {
  const text = `${prefix || ''}${amountToString(amount, {
    displayDecimals: displayDecimals,
    addCurrency: true,
  })}`

  return (
    <span
      className={className}
      css={css`
        white-space: nowrap;
        text-decoration: ${strikeThrough ? 'line-through' : 'initial'};
      `}
    >
      {text}
    </span>
  )
})
