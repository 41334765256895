import { css } from '@emotion/react'
import { memo } from 'react'

import { Avatar } from '../../../components/index.js'
import { type CompoundIconName } from '../../../foundations/icon/names.js'
import { spacing } from '../../../foundations/spacing-tokens.js'

export const PassthroughIcon = memo<{ className?: string; icon: CompoundIconName }>(function PassthroughInfoButton({
  className,
  icon,
}) {
  return (
    <div
      css={css`
        display: flex;
        align-items: center;
        justify-content: center;

        padding: ${spacing[40]};
      `}
      className={className}
    >
      <Avatar size="30" icon={icon} />
    </div>
  )
})
