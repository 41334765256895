import styled from '@emotion/styled'
import { memo, type PropsWithChildren } from 'react'
import { Button, Divider } from '../../components'
import { spacing } from '../../foundations/spacing-tokens.js'
import { Text } from '../../foundations/text/text.js'

export type SidePanelProps = {
  title: string
  isSidePanelOpen: boolean
  setIsSidePanelOpen: (id?: string) => void
  setIsSidePanelClose: () => void
  hasCloseButton?: boolean
}

export const SidePanel = memo<PropsWithChildren<SidePanelProps>>(function SidePanel(props) {
  const { title, isSidePanelOpen, setIsSidePanelOpen, setIsSidePanelClose, hasCloseButton = false, children } = props

  return (
    <StyledSidePanel>
      <Divider orientation="vertical" className="divider" />
      <StyledSidePanelContainer isSidePanelOpen={isSidePanelOpen} hasCloseButton={hasCloseButton}>
        {isSidePanelOpen ? (
          <>
            <StyledSidePanelTitle>
              <Text variant="body1Medium">{title}</Text>
              <Button
                onClick={() => setIsSidePanelClose()}
                icon={hasCloseButton ? 'xmark-regular' : 'arrow-right-to-line-regular'}
                variant="secondary"
                size="small"
              />
            </StyledSidePanelTitle>
            {children}
          </>
        ) : null}
        {!hasCloseButton && !isSidePanelOpen ? (
          <Button
            onClick={() => setIsSidePanelOpen()}
            icon="arrow-left-to-line-regular"
            variant="secondary"
            size="small"
          />
        ) : null}
      </StyledSidePanelContainer>
    </StyledSidePanel>
  )
})

const StyledSidePanel = styled.aside`
  display: flex;
  justify-content: flex-end;
  overflow: hidden;
  height: 100%;
`

const StyledSidePanelContainer = styled.div<{ isSidePanelOpen: boolean; hasCloseButton: boolean }>`
  display: flex;
  flex-direction: column;
  gap: ${({ hasCloseButton }) => (!hasCloseButton ? spacing[60] : '0')};
  padding: ${({ isSidePanelOpen, hasCloseButton }) =>
    !hasCloseButton ? spacing[60] : hasCloseButton && isSidePanelOpen ? `${spacing[60]} ${spacing[60]} 0` : '0'};
  width: ${({ isSidePanelOpen, hasCloseButton }) => (isSidePanelOpen ? '368px' : hasCloseButton ? '0' : 'fit-content')};
  overflow: auto;
  box-shadow: none;
  height: 100%;
`

const StyledSidePanelTitle = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`
