import { css } from '@emotion/react'
import { forwardRef, memo, type DetailedHTMLProps } from 'react'
import { spacing } from '../../foundations/spacing-tokens.js'

type VirtualClickableAreaProps = DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>

/**
 * This component is used to increase the clickable area of an element by 8px on each side.
 * It does not shift the layout of the child component, hence the "virtual area" name.
 * Note that this component wraps the child component in a `div`.
 */
export const VirtualClickableArea = memo(
  forwardRef<HTMLDivElement, VirtualClickableAreaProps>(function VirtualClickableArea(props, ref) {
    return (
      <div
        ref={ref}
        // See https://stackoverflow.com/a/20327676 for explanation
        css={css`
          display: inline-block;
          position: relative;
          z-index: 1;
          padding: ${spacing[30]};
          margin: -${spacing[30]};
          cursor: pointer;
        `}
        {...props}
      />
    )
  }),
)
