import { createFileRoute } from '@tanstack/react-router'

import PaymentMethodNewPage from '../../../../components/pages/payment-method-new-page'
import { PermissionChecker } from '../../../../components/templates/permission-checker'

export const Route = createFileRoute('/_customer/payment-methods/new')({
  component: () => (
    <PermissionChecker requiredPermissions={['paymentAccount.update']}>
      <PaymentMethodNewPage />
    </PermissionChecker>
  ),
})
