import { createFileRoute } from '@tanstack/react-router'

import HealthInsurancePage from '../../../components/pages/health-insurance/health-insurance-page'
import { PermissionChecker } from '../../../components/templates/permission-checker'

export const Route = createFileRoute('/_customer/health-insurance/')({
  component: () => (
    <PermissionChecker requiredPermissions={['contracts.read']}>
      <HealthInsurancePage />
    </PermissionChecker>
  ),
})
