import { financialRateZodSchema, newFinancialRate, ratesEqual, type FinancialRate } from '@orus.eu/amount'
import { TechnicalError } from '@orus.eu/error'
import type { Product } from '@orus.eu/product'
import { success, type Result } from '@orus.eu/result'
import { z } from 'zod'
import {
  AbstractDimension,
  dimensionValidationFailure,
  getParmeterlessKeyTranslations,
  type DimensionValidationProblem,
  type PartialDimensionnedState,
} from './abstract-dimension.js'

const allDiscountCodes = ['partner-disc221220', 'exceptional-20'] as const
export type DiscountCode = (typeof allDiscountCodes)[number]

export const discountDefinitionPerCode: Record<DiscountCode, { rate: FinancialRate; available: boolean }> = {
  'partner-disc221220': { rate: newFinancialRate('0.1'), available: true },
  'exceptional-20': { rate: newFinancialRate('0.2'), available: false },
}

export const discountZodSchema = z.union([
  /**
   * Indicates that no discount is applied
   */
  z.literal('none'),
  z.object({
    code: z.enum(allDiscountCodes),
    rate: financialRateZodSchema,
  }),
  /**
   * Indicates the situation that the discount is in the following edge case that
   * occurs only on multi-products involving RCPH:
   * - The 10% discount has been applied at subscription time
   * - It doesn't apply anymore to RCPH because of discount loss implemented in invoicing
   * - It stills applies to other products because only RCPH implements discount loss
   * -
   */
  z.literal('rcph-edge-case-10'),
  /**
   * Same as above, but with the 20% discount.
   */
  z.literal('rcph-edge-case-20'),
])

export type OrusDiscount = z.infer<typeof discountZodSchema>

export function getDiscountCodeForSubscriptionFunnel(
  discount: OrusDiscount | 'none' | null | undefined,
): DiscountCode | undefined {
  if (!discount || discount === 'none') {
    return undefined
  }
  if (discount === 'rcph-edge-case-10' || discount === 'rcph-edge-case-20') {
    throw new TechnicalError('This discount type was not expected for subscription funnel', {
      context: { discount },
    })
  }
  return discount.code
}

export function getDiscountRateForSubscriptionFunnel(
  discount: OrusDiscount | 'none' | null | undefined,
): FinancialRate | undefined {
  if (!discount || discount === 'none') {
    return undefined
  }
  if (discount === 'rcph-edge-case-10' || discount === 'rcph-edge-case-20') {
    throw new TechnicalError('This discount type was not expected for subscription funnel', {
      context: { discount },
    })
  }
  return discount.rate
}

export function getDiscountRate(
  discount: OrusDiscount | 'none' | null | undefined,
  product: Product,
): FinancialRate | undefined {
  if (!discount || discount === 'none') {
    return undefined
  }
  if (discount === 'rcph-edge-case-10') {
    return product === 'rcph'
      ? // discount already removed by invoicing ad hoc code
        undefined
      : // discount still applicable
        discountDefinitionPerCode['partner-disc221220'].rate
  }
  if (discount === 'rcph-edge-case-20') {
    return product === 'rcph'
      ? // discount already removed by invoicing ad hoc code
        undefined
      : // discount still applicable
        discountDefinitionPerCode['exceptional-20'].rate
  }
  return discount.rate
}

export function getDiscountCode(
  discount: OrusDiscount | 'none' | null | undefined,
  product: Product,
): DiscountCode | undefined {
  if (!discount || discount === 'none') {
    return undefined
  }
  if (discount === 'rcph-edge-case-10') {
    return product === 'rcph'
      ? // discount already removed by invoicing ad hoc code
        undefined
      : // discount still applicable
        'partner-disc221220'
  }
  if (discount === 'rcph-edge-case-20') {
    return product === 'rcph'
      ? // discount already removed by invoicing ad hoc code
        undefined
      : // discount still applicable
        'exceptional-20'
  }
  return discount.code
}

export const discountCodeLabels: { [key in DiscountCode]: string } = {
  'partner-disc221220': 'Discount (approx. -10%)',
  'exceptional-20': 'Exceptionnel 20%',
}

export function getAvailableDiscountByCode(codeString: string): OrusDiscount | undefined {
  if (codeString in discountDefinitionPerCode) {
    const code = codeString as DiscountCode
    const discountDefinition = discountDefinitionPerCode[code]
    return discountDefinition.available ? { code, rate: discountDefinition.rate } : undefined
  }
  return undefined
}

export class DiscountDimension<NAME extends string> extends AbstractDimension<NAME, OrusDiscount> {
  override readonly placeholders = getParmeterlessKeyTranslations('placeholder_no_discount')

  override validateData(value: unknown): Result<OrusDiscount | 'none', DimensionValidationProblem> {
    if (value === 'none') return success('none')

    const orusDiscountResult = discountZodSchema.safeParse(value)

    if (!orusDiscountResult.success) return dimensionValidationFailure(`Field ${this.name} is not a valid discount`)

    const orusDiscount = orusDiscountResult.data

    if (orusDiscount === 'rcph-edge-case-10' || orusDiscount === 'rcph-edge-case-20' || orusDiscount === 'none') {
      return success(orusDiscount)
    }

    const { code, rate } = orusDiscount

    const discountDefinition = discountDefinitionPerCode[code as DiscountCode]

    if (!discountDefinition || !ratesEqual(discountDefinition.rate, rate))
      return dimensionValidationFailure(`Field ${this.name} is not a valid discount`)

    return success({ code, rate })
  }
}

export const discountDimension = new DiscountDimension({
  name: 'discount',
  displayValues: { name: 'Discount' },
} as const)

type DiscountRelatedDimensionnedState = PartialDimensionnedState<[typeof discountDimension]>

type DiscountRelatedSubscriptionState = {
  contract: {
    discount: OrusDiscount | null
    versions: DiscountRelatedContractVersion[]
  } | null
} & DiscountRelatedDimensionnedState

type DiscountRelatedContractVersion = {
  type: 'renewal' | 'signed-version'
  dimensions: DiscountRelatedDimensionnedState
}

export function getSubscriptionStateWithRequiredDiscountEdgeCaseCounterparts<
  T extends DiscountRelatedSubscriptionState,
>(state: T): T {
  if (!state.contract || state.contract.versions.length === 1) {
    return state
  }

  const [firstVersion, ...subsequentVersions] = state.contract.versions

  const { versions: newSubsequentVersions, hasRenewed } = subsequentVersions.reduce<{
    versions: DiscountRelatedContractVersion[]
    hasRenewed: boolean
  }>(
    ({ versions, hasRenewed: hasRenewedBefore }, version) => {
      const versionIsRenewal = version.type === 'renewal'
      const hasRenewed = hasRenewedBefore || versionIsRenewal
      const newVersion = hasRenewed
        ? {
            ...version,
            dimensions: getStateWithDiscountEdgeCaseCounterpart(version.dimensions),
          }
        : version

      return { versions: [...versions, newVersion], hasRenewed }
    },
    { versions: [], hasRenewed: false },
  )

  if (!hasRenewed) {
    return state
  }

  const latestVersion = newSubsequentVersions.at(-1) ?? firstVersion

  return {
    ...getStateWithDiscountEdgeCaseCounterpart(state),
    contract: {
      ...getStateWithDiscountEdgeCaseCounterpart(state.contract),
      firstVersion,
      versions: [firstVersion, ...newSubsequentVersions],
      latestVersion,
    },
  }
}

export function getStateWithDiscountEdgeCaseCounterpart<State extends DiscountRelatedDimensionnedState>(
  state: State,
): State {
  if (
    !state.discount ||
    state.discount === 'none' ||
    state.discount === 'rcph-edge-case-10' ||
    state.discount === 'rcph-edge-case-20'
  ) {
    return state
  }

  switch (state.discount.code) {
    case 'partner-disc221220':
      return { ...state, discount: 'rcph-edge-case-10' }
    case 'exceptional-20':
      return { ...state, discount: 'rcph-edge-case-20' }
  }
}
