import { css } from '@emotion/react'
import { memo } from 'react'
import { Avatar, Button } from '../../../../components/index.js'
import { colorTokens } from '../../../../foundations/color-tokens.js'
import { Text, type TextVariant } from '../../../../foundations/index.js'
import { spacing } from '../../../../foundations/spacing-tokens.js'

import { ButtonInputLabelWrapper, type ButtonInputLabelWrapperProps } from '../../input-label-wrapper/index.js'
export type TextFormFieldProps = {
  button?: { onButtonClick: () => void; buttonLabel: string }
  optionalBlockText?: string
  labelVariant?: TextVariant
} & Omit<ButtonInputLabelWrapperProps, 'leftComponent' | 'rightComponent' | 'rowGap'>

export const TextFormField = memo<TextFormFieldProps>(function TextFormField(props) {
  const { button, optionalBlockText, variant, label, value, infoTooltip, labelVariant } = props
  const rightComponent = button ? (
    <Button
      variant="secondary"
      avatarPosition="left"
      avatar={<Avatar icon="pen-solid" />}
      onClick={button.onButtonClick}
      size="small"
    >
      {button.buttonLabel}
    </Button>
  ) : undefined
  const leftComponent = optionalBlockText ? (
    <div
      css={css`
        height: 36px;
        padding: ${spacing[30]} ${spacing[50]};
        background-color: ${colorTokens['color-bg-neutral-inverse']};
        border-radius: ${spacing[30]};
        margin-right: ${spacing[50]};
      `}
    >
      <Text variant="body2" screenVariant={variant}>
        {optionalBlockText}
      </Text>
    </div>
  ) : undefined
  return (
    <ButtonInputLabelWrapper
      variant={variant}
      label={label}
      value={value}
      infoTooltip={infoTooltip}
      leftComponent={leftComponent}
      rightComponent={rightComponent}
      rowGap="6px"
      labelVariant={labelVariant}
    />
  )
})
