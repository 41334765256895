import { memo, useMemo } from 'react'
import { useTranslate } from '../../../../code-only/localization/language-context.js'
import { useSkin } from '../../../../code-only/skin/hooks.js'
import { Avatar } from '../../../../components/index.js'
import { colorTokens } from '../../../../foundations/color-tokens.js'
import { Card } from '../../../../patterns/index.js'
import { PhoneNumber } from '../../../atoms/phone-number.js'
import type { HelpContactDetails } from '../../quote-v3/types.js'
import { OrusFaceAvatar } from '../orus-face-avatar/index.js'

/**
 * Props for the ContactBanner component
 * @param helpContactDetails - Contact details to display in the banner
 * @param useIllustrations - Whether to show Orus illustrations set
 * @param isManagedByOrus - Whether the subscription is managed by Orus, affects help text
 */
export type ContactBannerProps = {
  helpContactDetails: HelpContactDetails
  useIllustrations?: boolean
  isManagedByOrus?: boolean
}

export const ContactBanner = memo<ContactBannerProps>(function ContactBanner(props) {
  const translate = useTranslate()
  const { helpContactDetails, useIllustrations, isManagedByOrus } = props
  const skin = useSkin()

  const avatar = useMemo(() => {
    if (useIllustrations) return <Avatar illustration="help_big" size="70" />
    if (isManagedByOrus) return <OrusFaceAvatar variant="round" size="60" />
    return undefined
  }, [useIllustrations, isManagedByOrus])

  return (
    <Card
      title={translate('subscription_need_help_title')}
      subtitle={
        isManagedByOrus
          ? translate('subscription_need_help_explanation')
          : translate('subscription_need_help_explanation_broker')
      }
      avatar={avatar}
      size="small"
      variant="decorative"
      backgroundColorDecorative={skin.quoteCardHelperBackgroundColor ?? colorTokens['color-bg-brand']}
      buttons={[
        helpContactDetails?.phone
          ? {
              props: {
                variant: 'primary',
                icon: 'phone-regular',
                avatarPosition: 'left',
                href: `tel:${helpContactDetails.phone}`,
                trackingId: 'call_button',
              },
              content: <PhoneNumber>{helpContactDetails.phone.replace(/^\+33 ?/, '0')}</PhoneNumber>,
            }
          : undefined,
        isManagedByOrus
          ? helpContactDetails.whatsappLink
            ? {
                props: {
                  variant: 'tertiary',
                  avatarPosition: 'left',
                  icon: 'whatsapp-brands',
                  target: '_blank',
                  href: helpContactDetails.whatsappLink,
                },
                content: translate('chat_on_whatsapp'),
              }
            : undefined
          : helpContactDetails.email
            ? {
                props: {
                  variant: 'tertiary',
                  avatarPosition: 'left',
                  icon: 'envelope-regular',
                  target: '_blank',
                  href: `email:${helpContactDetails.email}`,
                },
                content: translate('send_an_email'),
              }
            : undefined,
      ]}
      fullWidth
    />
  )
})
