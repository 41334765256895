import { createTheme } from '@mui/material'

// DO NOT REMOVE THIS LINE
// IntelliJ says it's an unused import, but it's not true !!
import type {} from '@mui/x-date-pickers/themeAugmentation'
import {
  borderRadius,
  borderStroke,
  colorTokens,
  cssPropsPerScreenVariantPerTextVariant,
  shadow,
  spacing,
} from '../foundations'
import { typescaleTokens } from '../foundations/typescale-tokens'

/** @deprecated use colors instead */
export const secondaryColor = colorTokens['color-fg-base-active-inverse']
/** @deprecated use colors instead */
export const primaryColor = colorTokens['color-fg-decorative']
/** @deprecated use colors instead */
export const problemColor = colorTokens['color-fg-danger']

export const mobileSideSpace = spacing[60]
export const mobileSideSmallSpace = spacing[30]
export const maxDesktopColumnContentWidth = '1303px'

declare module '@mui/material/Button' {
  // Interface syntax required for theme extension
  // eslint-disable-next-line @orus.eu/no-interfaces
  interface ButtonPropsVariantOverrides {
    rich: true
    warning: true
    primary: true
    secondary: true
    tertiary: true
    danger: true
  }
}

declare module '@mui/material/Paper' {
  // Interface syntax required for theme extension
  // eslint-disable-next-line @orus.eu/no-interfaces
  interface PaperPropsVariantOverrides {
    flat: true
  }
}

declare module '@mui/material/Typography' {
  // Interface syntax required for theme extension
  // eslint-disable-next-line @orus.eu/no-interfaces
  interface TypographyPropsVariantOverrides {
    body1Medium: true
    body2Medium: true
    captionMedium: true
  }
}

export const stripeControlBorder = (resolvedColorTokens: typeof colorTokens): string =>
  `${borderStroke[20]} solid ${resolvedColorTokens['color-stroke-base-hover']}`
export const controlBorder = (resolvedColorTokens: typeof colorTokens): string =>
  `${borderStroke[20]} solid ${resolvedColorTokens['color-stroke-base']}`
export const controlBorderFocus = (resolvedColorTokens: typeof colorTokens): string =>
  `${borderStroke[20]} solid ${resolvedColorTokens['color-stroke-base-selected']}`

export const controlShadowFocus = (resolvedColorTokens: typeof colorTokens): string =>
  `0 0 0 ${borderStroke[30]} ${resolvedColorTokens['color-stroke-base-highlight']}`
export const borderDashed = (resolvedColorTokens: typeof colorTokens): string =>
  `${borderStroke[20]} dashed ${resolvedColorTokens['color-stroke-base-hover']}`

export const spaceBetweenFormFields = spacing[60]

export const removeBorderRadius = {
  borderTopLeftRadius: 0,
  borderTopRightRadius: 0,
  borderBottomLeftRadius: 0,
  borderBottomRightRadius: 0,
} as const

export const theme = createTheme({
  cssVariables: true,
  typography: {
    fontFamily: typescaleTokens['bo']['Body'],
  },
  // Need to add 2 * 24 px to width in figma because there is 24px padding on the sides
  breakpoints: {
    values: {
      xs: 0,
      sm: 800 + 48,
      md: 1180,
      lg: 1200 + 48,
      xl: 1400 + 48,
    },
  },
  shape: {
    borderRadius: 8,
  },
  // MUI theme does not allow coloring with variables, use hexa codes to replace the 'palette' refs below
  palette: {
    primary: {
      main: '#03001A',
    },
    secondary: {
      main: '#4A37F2',
    },
    background: {
      default: colorTokens['color-background'],
      paper: colorTokens['color-bg-base-normal'],
    },
    action: {
      active: '#03001A',
    },
    text: {
      primary: colorTokens['color-text-base-main'],
    },
  },
  components: {
    MuiTypography: {
      defaultProps: {
        variantMapping: {
          h1: 'h1',
          h2: 'h2',
          h3: 'h2',
          h4: 'h2',
          h5: 'h2',
          h6: 'div',
          subtitle1: 'span',
          subtitle2: 'span',
          body1: 'span',
          body1Medium: 'span',
          body2: 'span',
          body2Medium: 'span',
          caption: 'span',
          captionMedium: 'span',
        },
      },
    },
    MuiToggleButton: {
      styleOverrides: {
        root: {
          textTransform: 'none',
        },
      },
    },
    MuiButtonBase: {
      defaultProps: {
        disableRipple: true,
      },
      styleOverrides: {
        root: {
          textTransform: 'none',
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: borderRadius[20],
          textTransform: 'none',
          whiteSpace: 'nowrap',
          // necessary for links (text is centered by default on buttons)
          textAlign: 'center',
        },
        contained: {
          color: colorTokens['color-text-base-primary'],
          border: 'none',
          backgroundColor: colorTokens['color-bg-base-primary'],
          boxShadow: 'none !important',
          '&:hover': {
            border: 'none',
            backgroundColor: colorTokens['color-bg-base-primary-hover'],
          },
          '&:focus': {
            border: 'none',
            backgroundColor: colorTokens['color-bg-base-primary-active'],
          },
          '&:disabled': {
            color: colorTokens['color-text-base-disable'],
            backgroundColor: colorTokens['color-bg-base-disable'],
          },
          '&.MuiButton-containedError': {
            backgroundColor: colorTokens['color-bg-danger-primary'],
          },
          '&.MuiButton-containedError:hover': {
            border: 'none',
            backgroundColor: colorTokens['color-bg-danger-primary-hover'],
          },
          '&.MuiButton-containedError:focus': {
            border: 'none',
            backgroundColor: colorTokens['color-bg-danger-primary-hover'],
          },
        },
        outlined: {
          color: colorTokens['color-text-base-secondary'],
          border: `${borderStroke[20]} solid ${colorTokens['color-stroke-base']}`,
          backgroundColor: colorTokens['color-bg-base-secondary'],
          '&:hover': {
            border: `${borderStroke[20]} solid ${colorTokens['color-stroke-base-hover']}`,
            backgroundColor: colorTokens['color-bg-base-secondary-hover'],
          },
          '&:focus': {
            border: `${borderStroke[20]} solid ${colorTokens['color-stroke-base-active']}`,
            backgroundColor: colorTokens['color-bg-base-secondary-active'],
          },
          '&:disabled': {
            border: `${borderStroke[20]} solid ${colorTokens['color-stroke-base-disable']}`,
            backgroundColor: colorTokens['color-bg-base-disable'],
            color: colorTokens['color-text-base-disable'],
          },
        },
        text: {
          backgroundColor: 'transparent',
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          '& fieldset': {
            border: `${borderStroke[20]} solid ${colorTokens['color-stroke-base']}`,
          },
          '&:hover fieldset': {
            border: `${borderStroke[20]} solid ${colorTokens['color-stroke-base-hover']} !important`,
          },
          '&.Mui-focused fieldset': {
            border: `${borderStroke[30]} solid ${colorTokens['color-stroke-base-selected']} !important`,
            boxShadow: `0 0 0 ${borderStroke[30]} ${colorTokens['color-stroke-base-highlight']} !important`,
          },
          '&.Mui-disabled fieldset': {
            border: `${borderStroke[20]} solid ${colorTokens['color-stroke-base-disable']} !important`,
          },
          '&.Mui-error fieldset': {
            border: `${borderStroke[30]} solid ${colorTokens['color-stroke-danger']}; !important`,
          },
        },
      },
    },
    MuiLinearProgress: {
      defaultProps: {
        color: 'secondary',
      },
      styleOverrides: {
        root: {
          height: '4px',
          backgroundColor: colorTokens['color-bg-base-active'],
        },
        bar: {
          backgroundColor: colorTokens['color-bg-base-active'],
        },
      },
    },
    MuiTableContainer: {
      styleOverrides: {
        root: {
          borderRadius: borderRadius[20],
          border: controlBorder(colorTokens),
        },
      },
    },
    MuiTable: {
      styleOverrides: {
        root: {
          backgroundColor: colorTokens['color-bg-base-normal'],
        },
      },
    },
    MuiTableHead: {
      styleOverrides: {
        root: {
          borderBottom: '1px solid',
          borderBottomColor: colorTokens['color-stroke-base'],
        },
      },
    },
    MuiTableBody: {
      styleOverrides: {
        root: {
          paddingLeft: spacing[60],
          paddingRight: spacing[60],
        },
      },
    },
    MuiTableRow: {
      styleOverrides: {
        root: {
          position: 'relative',
          // can't do the borders using CSS because the design would require padding or margin
          // on table rows, which is not supported in HTML.
          '&:not(:last-of-type):after': {
            content: '" "',
            position: 'absolute',
            display: 'block',
            bottom: '0',
            height: '1px',
            left: 0,
            right: 0,
            backgroundColor: colorTokens['color-stroke-base'],
          },
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        head: cssPropsPerScreenVariantPerTextVariant['body2']['desktop'],
        root: {
          borderBottom: 'none',
          paddingLeft: spacing[60],
          paddingRight: spacing[60],
          paddingTop: spacing[50],
          paddingBottom: spacing[50],
        },
      },
    },
    MuiCard: {
      styleOverrides: {
        root: {
          backgroundColor: colorTokens['color-bg-base-normal'],
          boxShadow: shadow.bottom[20],
        },
      },
      variants: [
        {
          props: { variant: 'flat' },
          style: {
            backgroundColor: colorTokens['color-bg-base-normal'],
            boxShadow: 'none',
            border: controlBorder(colorTokens),
            borderRadius: borderRadius[20],
          },
        },
      ],
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          ...cssPropsPerScreenVariantPerTextVariant['body1']['desktop'],
          backgroundColor: 'white',
          '&.Mui-disabled': {
            backgroundColor: colorTokens['color-bg-base-disable'],
            color: colorTokens['color-text-base-disable'],
          },
        },
      },
    },
    MuiMenu: {
      styleOverrides: {
        list: {
          backgroundColor: colorTokens['color-bg-base-normal'],
        },
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          whiteSpace: 'normal',
        },
      },
    },
    MuiCheckbox: {
      defaultProps: {
        color: 'secondary',
      },
      styleOverrides: {
        root: {
          color: colorTokens['color-bg-base-normal'],
          '&.Mui-checked': {
            color: colorTokens['color-bg-base-active'],
          },
          '&.Mui-disabled': {
            color: colorTokens['color-bg-base-disable'],
          },
        },
      },
    },
    MuiRadio: {
      styleOverrides: {
        root: {
          color: colorTokens['color-bg-base-normal'],
          '&.Mui-checked': {
            color: colorTokens['color-bg-base-active'],
          },
          '&.Mui-disabled': {
            color: colorTokens['color-bg-base-disable'],
          },
        },
      },
    },
    MuiTooltip: {
      styleOverrides: {
        arrow: {
          color: colorTokens['color-bg-base-inverse'],
        },
        tooltip: {
          backgroundColor: colorTokens['color-bg-base-inverse'],
        },
      },
    },
    MuiDateCalendar: {
      styleOverrides: {
        root: {
          ...cssPropsPerScreenVariantPerTextVariant['body1']['desktop'],
        },
      },
    },
    MuiPickersDay: {
      styleOverrides: {
        root: {
          ...cssPropsPerScreenVariantPerTextVariant['body1']['desktop'],
        },
      },
    },
    MuiAccordion: {
      styleOverrides: {
        root: {
          boxShadow: 'none',
        },
      },
    },
    MuiSlider: {
      styleOverrides: {
        root: {
          '&.MuiSlider-root': {
            height: '20px',
            padding: 0,
            width: '100%',
          },
          '& .MuiSlider-thumb': {
            backgroundColor: colorTokens['color-bg-base-normal'],
          },
          '& .MuiSlider-rail': {
            height: '4px',
            backgroundColor: colorTokens['color-bg-base-tertiary'],
            opacity: 1,
          },
          '& .MuiSlider-track': {
            height: '4px',
            backgroundColor: colorTokens['color-fg-base-active-inverse'],
            border: 0,
          },
          '& .MuiSlider-mark': {
            backgroundColor: colorTokens['color-bg-base-normal'],
            opacity: 1,
          },
          '&.Mui-disabled': {
            backgroundColor: colorTokens['color-stroke-base'],
            color: colorTokens['color-bg-base-active'],
          },
        },
      },
    },
  },
})

export const largeScreenBreakpointName = 'md'
export const sectionDelimitersBorder = `1px solid ${colorTokens['color-stroke-base']}`
