import { success, type Result } from '@orus.eu/result'
import type { ParameterlessTranslationKey } from '@orus.eu/translations'
import {
  dimensionValidationFailure,
  type DimensionOptions,
  type DimensionValidationProblem,
} from './abstract-dimension.js'
import { AbstractNumberDimension, AbstractNumberDimensionWithDefaultOption } from './abstract-number-dimension.js'

export type NumbersDimensionOptions<
  NAME extends string,
  CURRENT_VALUES extends readonly number[],
  SUPPORTED_VALUES extends readonly number[],
> = DimensionOptions<NAME> & {
  values: CURRENT_VALUES
  supportedValues?: SUPPORTED_VALUES
  formatter?: (value: CURRENT_VALUES[number]) => string
}

export class NumbersEnumDimension<
  NAME extends string,
  CURRENT_VALUES extends readonly number[],
  SUPPORTED_VALUES extends readonly number[] = CURRENT_VALUES,
> extends AbstractNumberDimension<NAME, CURRENT_VALUES[number]> {
  readonly type = 'numbers-enum'
  readonly values: CURRENT_VALUES
  readonly supportedValues: SUPPORTED_VALUES | undefined

  constructor(options: NumbersDimensionOptions<NAME, CURRENT_VALUES, SUPPORTED_VALUES>) {
    super(options)
    this.values = options.values
    this.supportedValues = options.supportedValues ?? undefined
  }

  validateNumber(value: number): Result<CURRENT_VALUES[number], DimensionValidationProblem> {
    if ((this.supportedValues ?? this.values).includes(value)) return success(value)
    return dimensionValidationFailure(`Field ${this.name} is a number but has an invalid value`)
  }
}

/**
 * A variant of the numbers enum dimension that allows the "default" string as a valid value.
 * See {@link AbstractNumberDimensionWithDefaultOption} for more details.
 */
export class NumbersEnumDimensionWithDefaultOption<
  NAME extends string,
  CURRENT_VALUES extends readonly number[],
  SUPPORTED_VALUES extends readonly number[] = CURRENT_VALUES,
> extends AbstractNumberDimensionWithDefaultOption<NAME, CURRENT_VALUES[number]> {
  readonly type = 'numbers-enum-with-default-option'
  readonly values: ['default', ...CURRENT_VALUES]
  readonly supportedValues: ['default', ...SUPPORTED_VALUES] | undefined
  readonly defaultValueTranslationKey: ParameterlessTranslationKey

  constructor(
    options: NumbersDimensionOptions<NAME, CURRENT_VALUES, SUPPORTED_VALUES> & {
      defaultValueTranslationKey: ParameterlessTranslationKey
    },
  ) {
    super(options)
    this.values = ['default', ...options.values]
    this.supportedValues = options.supportedValues ? ['default', ...options.supportedValues] : undefined
    this.defaultValueTranslationKey = options.defaultValueTranslationKey
  }

  validateNumber(value: number): Result<CURRENT_VALUES[number], DimensionValidationProblem> {
    if ((this.supportedValues ?? this.values).includes(value)) return success(value)
    return dimensionValidationFailure(`Field ${this.name} is a number but has an invalid value`)
  }
}
