import { createFileRoute, Outlet } from '@tanstack/react-router'
import PartnerPage from '../../components/templates/partner-page'

export const Route = createFileRoute('/partner/$organization')({
  component: RouteComponent,
})

function RouteComponent() {
  const { organization } = Route.useParams()

  return (
    <PartnerPage organization={organization}>
      <Outlet />
    </PartnerPage>
  )
}
