import { NumbersEnumDimension, NumbersEnumDimensionWithDefaultOption } from './numbers-enum-dimension'

export const possiblePaymentDays = [
  1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28,
] as const

export type PaymentDay = (typeof possiblePaymentDays)[number]

const paymentDaysSet: Set<number> = new Set(possiblePaymentDays)
export function isValidPaymentDay(value: number): value is PaymentDay {
  return paymentDaysSet.has(value)
}

/**
 * The payment day that the user prefers, 'default' means that the default payment day will be used.
 */
export const preferredPaymentDayDimension = new NumbersEnumDimensionWithDefaultOption({
  name: 'preferredPaymentDay',
  displayKeys: {
    name: 'dimension_name_preferred_payment_day',
  },
  values: possiblePaymentDays,
  defaultValueTranslationKey: 'dimension_default_preferred_payment_day',
} as const)

/**
 * The payment day that will be used, taking into account the default payment day and the preferred payment day
 */
export const paymentDayDimension = new NumbersEnumDimension({
  name: 'paymentDay',
  displayKeys: {
    name: 'dimension_name_payment_day',
  },
  values: possiblePaymentDays,
} as const)
