import { createFileRoute } from '@tanstack/react-router'

import PlatformContractPage from '../../../../components/pages/backoffice/platform/platform-contract-page/platform-contract-page'
import { PermissionChecker } from '../../../../components/templates/permission-checker'

export const Route = createFileRoute('/bak/contracts/$subscriptionId/')({
  component: () => (
    <PermissionChecker requiredPermissions={['contracts.read']}>
      <PlatformContractPage />
    </PermissionChecker>
  ),
})
