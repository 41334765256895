import { type Language } from '@orus.eu/translations'
import { DateTime } from 'luxon'
import { memo, useEffect, useRef, useState } from 'react'
import { Tooltip } from '../../components/tooltip/tooltip.js'
import { useLanguage } from '../localization/language-context.js'

type TimeAgoProps = {
  date: Date
  /**
   * The locale to use for the time ago.
   * If not provided, the language from the context will be used
   */
  language?: Language
}

export const TimeAgo = memo<TimeAgoProps>(function TimeAgo({ date, language: languageOverride }) {
  const language = useLanguage()
  const resolvedLocale = languageOverride ?? language
  const initialTimeAgo = useRef(formatTimeAgo(resolvedLocale, date))
  const [texts, setTexts] = useState({
    text: initialTimeAgo.current.text,
    absoluteText: initialTimeAgo.current.absoluteText,
  })

  useEffect(() => {
    let timeoutId: number | null = null

    function refresh(refreshDate: Date) {
      timeoutId = window.setTimeout(
        () => {
          const { text, absoluteText, refreshDate } = formatTimeAgo(resolvedLocale, date)
          setTexts({ text, absoluteText })
          if (refreshDate) {
            refresh(refreshDate)
          }
        },
        Math.max(0, refreshDate.getTime() - Date.now()),
      )
    }

    if (initialTimeAgo.current.refreshDate) {
      refresh(initialTimeAgo.current.refreshDate)
    }

    return () => {
      if (timeoutId) {
        window.clearTimeout(timeoutId)
      }
    }
  }, [date, resolvedLocale])

  return (
    <Tooltip showArrow={false} title={texts.absoluteText}>
      <>{texts.text}</>
    </Tooltip>
  )
})

function formatTimeAgo(
  language: Language,
  date: Date,
): {
  text: string
  absoluteText: string
  refreshDate: Date | null
} {
  const nowLuxon = DateTime.now()
  const dateLuxon = DateTime.fromJSDate(date)
  const distance = dateLuxon.diff(nowLuxon, ['milliseconds', 'seconds', 'minutes', 'hours', 'days'])
  const rtf = new Intl.RelativeTimeFormat(language, { numeric: 'auto' })
  const dtfWithSeconds = new Intl.DateTimeFormat(language, { dateStyle: 'long', timeStyle: 'medium' })
  const dtfWithoutSeconds = new Intl.DateTimeFormat(language, { dateStyle: 'long' })

  const absoluteText = dtfWithSeconds.format(date)

  if (distance.days <= -1) {
    return {
      text: `${absoluteDatePrefixPerLanguage[language]} ${dtfWithoutSeconds.format(date)}`,
      absoluteText,
      refreshDate: null,
    }
  }

  if (distance.hours < 0) {
    return {
      text: rtf.format(distance.hours, 'hours'),
      absoluteText,
      refreshDate: dateLuxon.plus({ hour: -distance.hours + 1 }).toJSDate(),
    }
  }

  if (distance.minutes < 0) {
    return {
      text: rtf.format(distance.minutes, 'minutes'),
      absoluteText,
      refreshDate: dateLuxon.plus({ minute: -distance.minutes + 1 }).toJSDate(),
    }
  }

  return {
    text: nowTranslationPerLanguage[language],
    absoluteText,
    refreshDate: dateLuxon.plus({ second: -distance.seconds + 1 }).toJSDate(),
  }
}

const absoluteDatePrefixPerLanguage: Record<Language, string> = {
  fr: 'le',
  es: 'el',
}

const nowTranslationPerLanguage: Record<Language, string> = {
  fr: 'maintenant',
  es: 'ahora',
}
