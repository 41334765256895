import { type } from 'arktype'
import { z } from 'zod'

export function parseCifValue(input: string): string | null {
  const normalizedInput = input.replace(/[\s.-]/g, '').toUpperCase()
  if (cifRegex.test(normalizedInput)) {
    return normalizedInput
  }
  return null
}

export const cifValueZodSchema = z.string().refine(parseCifValue, {
  message: 'Invalid CIF',
})

export const cifValueType = type('string').narrow((s) => parseCifValue(s) !== null)

const cifRegex = /^[A-Z]{1}[0-9]{7}[A-Z0-9]{1}$/
