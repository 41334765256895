import { createFileRoute } from '@tanstack/react-router'

import HealthInsuranceSanteclairPage from '../../../../components/pages/health-insurance/santeclair-page'
import { PermissionChecker } from '../../../../components/templates/permission-checker'

export const Route = createFileRoute('/_customer/health-insurance/santeclair')({
  component: () => (
    <PermissionChecker requiredPermissions={['contracts.read']}>
      <HealthInsuranceSanteclairPage />
    </PermissionChecker>
  ),
})
