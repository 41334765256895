import { createFileRoute } from '@tanstack/react-router'

import PlatformPendingSubscriptionsPage from '../../../components/pages/backoffice/platform/platform-pending-subscriptions-page'
import { PermissionChecker } from '../../../components/templates/permission-checker'

export const Route = createFileRoute('/bak/pending-subscriptions/')({
  component: () => (
    <PermissionChecker requiredPermissions={['subscription.read']}>
      <PlatformPendingSubscriptionsPage />
    </PermissionChecker>
  ),
})
