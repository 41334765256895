/** to be used with the box-shadow prop */
export const shadow = {
  bottom: {
    '05': '0px 1px 2px 0px rgba(21, 19, 24, 0.03), 0px 2px 2px 0px rgba(21, 19, 24, 0.03)',
    10: '0px 21px 6px 0px rgba(21, 19, 24, 0.00), 0px 13px 5px 0px rgba(21, 19, 24, 0.00), 0px 8px 4px 0px rgba(21, 19, 24, 0.02), 0px 3px 3px 0px rgba(21, 19, 24, 0.03), 0px 1px 2px 0px rgba(21, 19, 24, 0.03);',
    20: '0px 24px 16px 0px rgba(21, 19, 24, 0.00), 0px 16px 11px 0px rgba(21, 19, 24, 0.00), 0px 10px 8px 0px rgba(21, 19, 24, 0.02), 0px 4px 5px 0px rgba(21, 19, 24, 0.03), 0px 2px 3px 0px rgba(21, 19, 24, 0.03);',
    30: '0px 28px 18px 0px rgba(21, 19, 24, 0.01), 0px 16px 12px 0px rgba(21, 19, 24, 0.01), 0px 8px 8px 0px rgba(21, 19, 24, 0.03), 0px 4px 6px 0px rgba(21, 19, 24, 0.05), 0px 2px 2px 0px rgba(21, 19, 24, 0.05);',
    40: '0px 36px 28px 0px rgba(21, 19, 24, 0.01), 0px 28px 20px 0px rgba(21, 19, 24, 0.01), 0px 16px 10px 0px rgba(21, 19, 24, 0.03), 0px 6px 8px 0px rgba(21, 19, 24, 0.05), 0px 1px 3px 0px rgba(21, 19, 24, 0.05);',
  },
  top: {
    '05': '0px -2px 2px 0px rgba(21, 19, 24, 0.03), 0px -1px 2px 0px rgba(21, 19, 24, 0.03)',
    10: '0px -21px 6px 0px rgba(21, 19, 24, 0.00), 0px -13px 5px 0px rgba(21, 19, 24, 0.00), 0px -8px 4px 0px rgba(21, 19, 24, 0.02), 0px -3px 3px 0px rgba(21, 19, 24, 0.03), 0px -1px 2px 0px rgba(21, 19, 24, 0.03);',
    20: '0px -24px 16px 0px rgba(21, 19, 24, 0.00), 0px -16px 11px 0px rgba(21, 19, 24, 0.00), 0px -10px 8px 0px rgba(21, 19, 24, 0.02), 0px -4px 5px 0px rgba(21, 19, 24, 0.03), 0px -2px 3px 0px rgba(21, 19, 24, 0.03);',
    30: '0px -28px 18px 0px rgba(21, 19, 24, 0.01), 0px -16px 12px 0px rgba(21, 19, 24, 0.01), 0px -8px 8px 0px rgba(21, 19, 24, 0.03), 0px -4px 6px 0px rgba(21, 19, 24, 0.05), 0px -2px 2px 0px rgba(21, 19, 24, 0.05);',
    40: '0px -36px 28px 0px rgba(21, 19, 24, 0.01), 0px -28px 20px 0px rgba(21, 19, 24, 0.01), 0px -16px 10px 0px rgba(21, 19, 24, 0.03), 0px -6px 8px 0px rgba(21, 19, 24, 0.05), 0px -1px 3px 0px rgba(21, 19, 24, 0.05);',
  },
}
