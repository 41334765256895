import { css } from '@emotion/react'
import { memo, type ReactNode } from 'react'
import { spacing } from '../../foundations/spacing-tokens'

type ButtonGroupProps = {
  children: ReactNode
  orientation?: 'column' | 'row'
}

export const ButtonGroup = memo<ButtonGroupProps>(function Switch(props) {
  const { children, orientation = 'row' } = props

  return (
    <div
      css={css`
        display: flex;
        flex-direction: ${orientation};
        align-items: center;
        gap: ${spacing['30']};
      `}
    >
      {children}
    </div>
  )
})
