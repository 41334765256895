import { createFileRoute } from '@tanstack/react-router'
import DetailedSheetPage from '../../../../../../../components/pages/backoffice/detailed-sheet-page/DetailedSheetPage'
import { PermissionChecker } from '../../../../../../../components/templates/permission-checker'

export const Route = createFileRoute('/bak/contracts/$subscriptionId/detailed-sheet/versions/$versionId')({
  component: () => (
    <PermissionChecker requiredPermissions={['contracts.read']}>
      <DetailedSheetPage />
    </PermissionChecker>
  ),
})
