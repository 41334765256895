import { createFileRoute } from '@tanstack/react-router'
import BackofficeNewSubscriptionPage from '../../../../components/pages/backoffice/common/backoffice-new-subscription-page'
import { PermissionChecker } from '../../../../components/templates/permission-checker'

export const Route = createFileRoute('/partner/$organization/new-subscription')({
  component: () => (
    <PermissionChecker requiredPermissions={['subscription.create']}>
      <BackofficeNewSubscriptionPage />
    </PermissionChecker>
  ),
})
