import styled from '@emotion/styled'
import { memo, type ReactElement } from 'react'
import { spacing } from '../foundations/spacing-tokens'
import type { FormRow } from './form-row'

export type FormProps = {
  children: ReactElement<typeof FormRow> | ReactElement<typeof FormRow>[]
}

export const Form = memo<FormProps>(function Form(props: FormProps) {
  const { children } = props

  return <FormContainer>{children}</FormContainer>
})

const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${spacing[50]};
  flex-grow: 1;
`
