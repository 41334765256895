import { createFileRoute } from '@tanstack/react-router'

import AccountPage from '../../../components/pages/account/AccountPage'
import { PermissionChecker } from '../../../components/templates/permission-checker'

export const Route = createFileRoute('/_customer/account')({
  component: () => (
    <PermissionChecker requiredPermissions={['users.read']}>
      <AccountPage />
    </PermissionChecker>
  ),
})
