import { createFileRoute } from '@tanstack/react-router'

import GuaranteesPage from '../../../components/pages/guarantees-page'
import { PermissionChecker } from '../../../components/templates/permission-checker'

export const Route = createFileRoute('/_customer/contract-guarantees')({
  component: () => (
    <PermissionChecker requiredPermissions={['contracts.read']}>
      <GuaranteesPage />
    </PermissionChecker>
  ),
})
