import { css } from '@emotion/react'
import { memo, useCallback, type ReactNode } from 'react'

import { mobileMediaQuery } from '../../../../code-only/hooks/use-screen-type.js'
import type { UiContext } from '../../../../code-only/hooks/use-screen-variant.js'
import { useTranslate } from '../../../../code-only/localization/language-context.js'
import { Avatar, Tooltip } from '../../../../components/index.js'
import { colorTokens } from '../../../../foundations/color-tokens.js'
import { Text } from '../../../../foundations/index.js'
import { spacing } from '../../../../foundations/spacing-tokens.js'
import { ListWrapper } from '../list-wrapper/index.js'
import type { Guarantee } from '../types.js'

type OnClick = (offer: Guarantee) => void

export type GuaranteeWithDeductibleListProps = {
  guarantees: (Guarantee & { button: ReactNode; onClick: () => void })[]
  variant: UiContext
  className?: string
  quotePageType: 'dynamic' | 'static'
  isMuta: boolean
}

export const GuaranteesWithDeductibleList = memo<GuaranteeWithDeductibleListProps>(
  function GuaranteesWithDeductibleList(props) {
    const { guarantees, variant, className, quotePageType, isMuta } = props

    return (
      <ListWrapper
        className={className}
        css={css`
          margin-top: ${spacing[50]};
        `}
      >
        {guarantees.map((guarantee) => (
          <GuaranteeWithDeductible
            key={guarantee.type}
            guarantee={guarantee}
            variant={variant}
            onClick={guarantee.onClick}
            button={guarantee.button}
            quotePageType={quotePageType}
            isMuta={isMuta}
          />
        ))}
      </ListWrapper>
    )
  },
)

const GuaranteeWithDeductible = memo<{
  guarantee: Guarantee
  variant: UiContext
  onClick: OnClick
  button: ReactNode
  quotePageType: 'dynamic' | 'static'
  isMuta: boolean
}>(function Guarantee({ guarantee, variant, onClick, button, quotePageType, isMuta }) {
  const translate = useTranslate()
  const handleClick = useCallback(
    (event: React.MouseEvent) => {
      event.stopPropagation()
      onClick(guarantee)
    },
    [onClick, guarantee],
  )

  return (
    <div
      onClick={handleClick}
      css={css`
        width: 100%;

        display: grid;
        grid-template-columns: auto 1fr 1fr auto;
        grid-template-rows: auto auto auto;
        grid-template-areas:
          'avatar description monetary info'
          'avatar description monetary info'
          'avatar description monetary info';
        gap: ${spacing[60]};
        padding: ${spacing[30]} ${spacing[50]};

        ${mobileMediaQuery} {
          gap: ${spacing[30]};
          padding: ${spacing[50]} ${spacing[50]};
          grid-template-areas:
            'avatar      .           .           info'
            'description description description description'
            'monetary    monetary    monetary    monetary';
        }

        padding: ${spacing[50]} ${variant !== 'desktop' ? spacing[50] : spacing[30]} ${spacing[50]};

        cursor: pointer;

        &:hover {
          background-color: ${colorTokens['color-bg-base-hover']};
        }
      `}
    >
      <div
        css={css`
          grid-area: avatar;

          display: flex;
          align-items: center;
        `}
      >
        <Avatar src={guarantee.avatar} size="40" />
      </div>
      <div
        css={css`
          grid-area: description;

          overflow: hidden;
          overflow-wrap: break-word;
          hyphens: auto;
        `}
      >
        <Text variant="body1Medium">{guarantee.name}</Text>
        <Text
          variant="body2"
          color={colorTokens['color-text-base-basic']}
          css={css`
            margin-top: ${spacing[20]};
          `}
        >
          {guarantee.shortText}
        </Text>
      </div>
      <div
        css={css`
          grid-area: monetary;

          display: flex;
          align-items: center;

          margin-top: ${variant !== 'desktop' ? spacing[50] : '0px'};
          margin-left: ${variant !== 'desktop' ? '0px' : spacing[60]};
        `}
      >
        {variant !== 'desktop' || quotePageType === 'static' ? (
          <></>
        ) : (
          <Tooltip title={guarantee.ceiling.locked ? 'Plafond fixe' : 'Plafond ajustable'}>
            <Avatar
              size="10"
              icon={guarantee.ceiling.locked ? 'lock-regular' : 'arrows-left-right-regular'}
              color={colorTokens['color-fg-base']}
              css={css`
                margin-right: ${spacing[50]};
              `}
            />
          </Tooltip>
        )}
        <div
          css={css`
            flex: 1;
          `}
        >
          <Text variant="body2Medium">{guarantee.ceiling.amount}</Text>
          <Text
            variant="caption"
            color={colorTokens['color-text-base-basic']}
            css={css`
              margin-top: ${spacing[20]};
            `}
          >
            {translate('limit')}
          </Text>
        </div>
        <div
          css={css`
            flex: 1;
            margin-left: ${variant !== 'desktop' ? '0px' : spacing[60]};
          `}
        >
          <Text variant="body2Medium" color={guarantee.deductible ? undefined : 'green'}>
            {guarantee.deductible ?? translate('deductible_none')}
          </Text>
          <Text
            variant="caption"
            color={colorTokens['color-text-base-basic']}
            css={css`
              margin-top: ${spacing[20]};
            `}
          >
            {translate('deductible')}
          </Text>
        </div>
      </div>
      {!isMuta ? (
        <div
          css={css`
            grid-area: info;

            display: flex;
            align-items: center;
            justify-content: flex-end;
          `}
        >
          {button}
        </div>
      ) : (
        <></>
      )}
    </div>
  )
})
