import styled from '@emotion/styled'
import { memo, useMemo, type ReactElement, type ReactNode } from 'react'
import { Divider } from '../components/divider/divider'
import type { TabProps } from '../components/tabbar/tab'
import { Tabbar } from '../components/tabbar/tabbar'
import { Text } from '../foundations'
import { spacing } from '../foundations/spacing-tokens'

export type TabWithContent = {
  tab: ReactElement<TabProps>
  content: ReactNode
  isFullBleed: boolean
}

export type LayoutTabbedProps = {
  header: ReactNode
  sidePanel?: ReactNode
  tabs: TabWithContent[]
  activeTabId: string
}

export const LayoutTabbed = memo<LayoutTabbedProps>(function LayoutTabbed(props: LayoutTabbedProps) {
  const { header, sidePanel, tabs, activeTabId } = props

  const fullBleedTabIds = useMemo(() => {
    return new Set(tabs.filter((tab) => tab.isFullBleed).map((tab) => tab.tab.props.tabId))
  }, [tabs])

  return (
    <LayoutTabbedContainer>
      <LayoutContainer>
        <HeaderContainer>
          {header}
          <Tabbar size="medium" activeTabId={activeTabId}>
            {tabs.map((tab) => tab.tab)}
          </Tabbar>
        </HeaderContainer>
        <Divider orientation="horizontal" />
        <BodyContainer>
          <ContentContainer role="tabpanel">
            {tabs.map((tab) => (
              <ContentWrapper
                key={tab.tab.props.tabId}
                className={tab.tab.props.tabId !== activeTabId ? 'hidden' : undefined}
                isFullBleed={tab.isFullBleed}
              >
                {tab.content}
              </ContentWrapper>
            ))}
          </ContentContainer>
          {!fullBleedTabIds.has(activeTabId) ? sidePanel : null}
        </BodyContainer>
      </LayoutContainer>
    </LayoutTabbedContainer>
  )
})

const LayoutTabbedContainer = styled.div`
  height: 100vh;
  width: 100%;
  display: flex;
`

const LayoutContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
`

const BodyContainer = styled.div`
  flex-grow: 1;
  justify-content: flex-end;
  display: flex;
  overflow: hidden;
`

const ContentContainer = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  overflow: auto;
  box-shadow: none;

  .hidden {
    display: none;
  }
`

const ContentWrapper = styled.div<{ isFullBleed: boolean }>`
  padding: ${({ isFullBleed }) => (isFullBleed ? 0 : spacing[60])};
  padding-bottom: ${({ isFullBleed }) => (isFullBleed ? 0 : spacing[100])};
  max-width: ${({ isFullBleed }) => (isFullBleed ? '100%' : '1440px')};
  flex-grow: 1;
  display: flex;
  flex-direction: column;
`

export const HeaderPlaceHolder = memo(function HeaderPlaceHolder() {
  return (
    <HeaderPlaceHolderContainer>
      <Text variant="h6">Header</Text>
    </HeaderPlaceHolderContainer>
  )
})

const HeaderPlaceHolderContainer = styled.div`
  padding: ${spacing[50]} 0;
`

const HeaderContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${spacing[60]};
  padding: ${spacing[60]};
  padding-bottom: 0;
`
