import styled from '@emotion/styled'
import { memo } from 'react'

import { Button } from '../../../components/index.js'
import { borderRadius } from '../../../foundations/border-radius-tokens.js'
import { colorTokens } from '../../../foundations/color-tokens.js'
import { spacing } from '../../../foundations/spacing-tokens.js'
import { Text, TextContainer } from '../../../foundations/text/text.js'
import { PhoneNumber } from '../../atoms/index.js'

export type ClaimCardProps = {
  avatar: React.ReactNode
  title: string
  description: string
  phoneNumber?: string
  email?: string
}

export const ClaimCard = memo<ClaimCardProps>(function ClaimCard(props) {
  const { phoneNumber, email, title, description, avatar } = props

  return (
    <Container>
      {avatar}
      <TextWrapper>
        <Text variant="h6" color={colorTokens['color-text-base-main']}>
          {title}
        </Text>
        <Text variant="body2" color={colorTokens['color-text-base-basic']}>
          {description}
        </Text>
      </TextWrapper>

      {phoneNumber || email ? (
        <ButtonsContainer>
          {phoneNumber ? (
            <Button
              variant="secondary"
              size={'large'}
              fullWidth
              icon="phone-regular"
              avatarPosition="left"
              trackingId="call_button"
              href={`tel:${encodeURIComponent(phoneNumber)}`}
            >
              <PhoneNumber>{phoneNumber}</PhoneNumber>
            </Button>
          ) : null}

          {email ? (
            <Button variant="secondary" size={'large'} fullWidth href={`mailto:${encodeURIComponent(email)}`}>
              <Text variant="button">{email}</Text>
            </Button>
          ) : null}
        </ButtonsContainer>
      ) : null}
    </Container>
  )
})

const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: ${spacing[70]};
  background: ${colorTokens['color-bg-base-normal']};
  border: ${colorTokens['color-stroke-base']} 1px solid;
  border-radius: ${borderRadius[30]};
`

const TextWrapper = styled(TextContainer)`
  margin-top: ${spacing[40]};
  display: flex;
  flex-direction: column;
  gap: ${spacing[30]};
`

const ButtonsContainer = styled.div`
  flex-grow: 1;
  justify-content: flex-end;
  margin-top: ${spacing[90]};
  display: flex;
  flex-direction: column;
  gap: ${spacing[40]};
`
