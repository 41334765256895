import { css } from '@emotion/react'
import { memo, type ReactElement, type ReactNode } from 'react'

export type PhoneNumberProps = {
  children: ReactNode
  className?: string
}

export const PhoneNumber = memo(function PhoneNumber({ children, className }: PhoneNumberProps): ReactElement {
  return (
    <span
      className={className}
      css={css`
        white-space: nowrap;
      `}
    >
      {children}
    </span>
  )
})
