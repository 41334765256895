import { css } from '@emotion/react'
import { ToggleButton, ToggleButtonGroup } from '@mui/material'
import { memo, useCallback, type MouseEvent } from 'react'
import { borderRadius, borderStroke, colorTokens, spacing, Text } from '../../foundations'

export type ToggleButtonsSize = 'small' | 'large'

export type ToggleButtonsProps = {
  value?: string | null
  values: readonly string[]
  labels: { [key: string]: string }
  onChange: (newValue?: string | undefined) => void
  disabled?: boolean
  size: ToggleButtonsSize
  className?: string
}

const buttonCss = css`
  background-color: ${colorTokens['color-bg-base-normal']};
  border-radius: ${borderRadius[20]};
  border: ${borderStroke[20]} solid ${colorTokens['color-stroke-base']}!important;
  padding: ${spacing[30]} ${spacing[60]};

  &.Mui-selected {
    background-color: ${colorTokens['color-bg-base-active']}!important;
    color: ${colorTokens['color-bg-base-normal']};
  }

  &.Mui-focusVisible {
    outline: ${borderStroke[30]} solid ${colorTokens['color-bg-base-tertiary-active']};
  }

  &:first-of-type {
    padding-left: ${spacing[60]};
  }

  &:last-of-type {
    padding-left: ${spacing[60]};
  }
`

export const ToggleButtons = memo<ToggleButtonsProps>(function ToggleButtons(props) {
  const { value, values, labels, onChange, className } = props

  const handleChange = (_event: MouseEvent<HTMLElement>, value: string) => {
    // prevent deselection of the currently selected option
    if (value) {
      onChange(value)
    }
  }

  return (
    <ToggleButtonGroup
      className={className}
      value={value}
      exclusive
      onChange={handleChange}
      aria-label="text alignment"
    >
      {values.map((itemValue) => {
        return (
          <ToggleButton key={itemValue} value={itemValue} aria-label={labels[itemValue]} css={buttonCss}>
            <Text variant="body2Medium">{labels[itemValue]}</Text>
          </ToggleButton>
        )
      })}
    </ToggleButtonGroup>
  )
})

export type YesNoToggleButtonsProps = {
  value?: boolean | null
  onChange: (newValue?: boolean) => void
  disabled?: boolean
  size: ToggleButtonsSize
  className?: string
}
export const YesNoToggleButtons = memo<YesNoToggleButtonsProps>(function YesNoToggleButtons(props) {
  const handleChange = useCallback(
    (newValue?: string) => {
      const booleanValue = getBooleanFromYesNoValue(newValue)
      props.onChange(booleanValue)
    },
    [props],
  )
  const stringValue = getYesNoValueFromBoolean(props.value)
  return (
    <ToggleButtons
      className={props.className}
      value={stringValue}
      values={yesNoValues}
      labels={yesNoLabels}
      onChange={handleChange}
      size={'small'}
    />
  )
})

const yesNoValues = ['yes', 'no']

const yesNoLabels = { yes: 'OUI', no: 'NON' }

function getYesNoValueFromBoolean(value: boolean | null | undefined) {
  return value === true ? 'yes' : value === false ? 'no' : undefined
}

function getBooleanFromYesNoValue(value?: string) {
  return value === 'yes' ? true : value === 'no' ? false : undefined
}
