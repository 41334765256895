import type { OperatingZone } from '@orus.eu/operating-zone'
import type { Siret } from '@orus.eu/siret'
import { translate, type Language } from '@orus.eu/translations'
import { type } from 'arktype'
import { z } from 'zod'
import { cifValueType, cifValueZodSchema } from './cif'
import { nifValueType, nifValueZodSchema } from './nif'
import { formatSirenValue, parseSirenValue, sirenValueType, sirenValueZodSchema } from './siren'

export const companyIdNumberZodSchema = z.union([
  /**
   * @deprecated Use "siren" instead
   */
  z.object({ type: z.literal('provided'), siren: sirenValueZodSchema }),
  /**
   * @deprecated Use "siren" instead
   */
  z.object({ type: z.literal('inferred'), siren: sirenValueZodSchema }),
  z.object({ type: z.literal('siren'), siren: sirenValueZodSchema }),
  /**
   * Spanish individual tax identification number
   */
  z.object({ type: z.literal('nif'), nif: nifValueZodSchema }),
  /**
   * Spanish company tax identification number
   */
  z.object({ type: z.literal('cif'), cif: cifValueZodSchema }),
  z.object({ type: z.literal('later') }),
])

export const companyIdNumberType = type({
  /**
   * @deprecated Use "siren" instead
   */
  type: "'provided'",
  siren: sirenValueType,
})
  .or({
    /**
     * @deprecated Use "siren" instead
     */
    type: "'inferred'",
    siren: sirenValueType,
  })
  .or({
    type: "'siren'",
    siren: sirenValueType,
  })
  .or({
    type: "'nif'",
    nif: nifValueType,
  })
  .or({
    type: "'cif'",
    cif: cifValueType,
  })
  .or({
    type: "'later'",
  })

export type CompanyIdNumber = typeof companyIdNumberType.infer

/**
 * Extract a string that is guaranteed to be a SIREN from a CompanyIdNumber.
 * Returns undefined if the CompanyIdNumber is not a siren, or if the wrapped value is invalid.
 */
export function getCompanyIdNumberSirenValue(data?: CompanyIdNumber | null | undefined): string | undefined {
  if (!data) return undefined
  switch (data.type) {
    case 'provided':
    case 'inferred':
    case 'siren':
      return parseSirenValue(data.siren) ?? undefined
    case 'nif':
    case 'cif':
    case 'later':
      return undefined
  }
}

/**
 * Extract a the string value from a company id number
 */
export function getCompanyIdNumberValue(data?: CompanyIdNumber | null | undefined): string | undefined {
  if (!data) return undefined
  switch (data.type) {
    case 'provided':
    case 'inferred':
    case 'siren':
      return data.siren
    case 'nif':
      return data.nif
    case 'cif':
      return data.cif
    case 'later':
      return undefined
  }
}

export type CompanyIdNumberType = CompanyIdNumber['type']

/**
 * Non-deprecated CompanyIdNumberType
 */
export type CanonicalCompanyIdNumberType = Exclude<CompanyIdNumberType, 'inferred' | 'provided'>

/**
 * Extract a the string value from a company id number
 */
export function getCompanyIdNumberCanonicalType(
  data?: CompanyIdNumber | null,
): CanonicalCompanyIdNumberType | undefined {
  if (!data) return undefined
  switch (data.type) {
    case 'provided':
    case 'inferred':
    case 'siren':
      return 'siren'
    case 'nif':
    case 'cif':
    case 'later':
      return data.type
  }
}

export const laterCompanyIdNumber = { type: 'later' } as const

export function inferCompanyIdNumberFromSiret(siret: Siret): CompanyIdNumber {
  const siren = siret.substring(0, 9)
  return { type: 'siren', siren }
}

export function formatCompanyIdNumber(companyIdNumber: CompanyIdNumber, language: Language): string {
  switch (companyIdNumber.type) {
    case 'provided':
    case 'inferred':
    case 'siren':
      return formatSirenValue(companyIdNumber.siren)
    case 'nif':
      return companyIdNumber.nif
    case 'cif':
      return companyIdNumber.cif
    case 'later':
      return translate('waiting_for_pending_registration_process', language)
  }
}

export const companyIdNumberLabel: Record<OperatingZone, string> = {
  fr: 'N° SIREN',
  es: 'NIF/CIF',
}
