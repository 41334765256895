import { createFileRoute } from '@tanstack/react-router'

import PlatformMigrationPage from '../../../components/pages/backoffice/platform/platform-migration-page'
import { PermissionChecker } from '../../../components/templates/permission-checker'

export const Route = createFileRoute('/bak/migration')({
  component: () => (
    <PermissionChecker requiredPermissions={['contracts.update', 'users.update']}>
      <PlatformMigrationPage />
    </PermissionChecker>
  ),
})
