import type { MutaSocialSecurityRegimeType } from '@orus.eu/dimensions'
import { failure, success } from '@orus.eu/result'
import type { ValidatedTypeMapper } from '../../../../legacy/atoms/validated-text-field'

export function validateSocialSecurityOrganismNumber(
  text: string,
  mutaRegime?: MutaSocialSecurityRegimeType,
):
  | {
      ok: true
      value: string
    }
  | {
      ok: false
      hint: string
    } {
  if (!/[0-9]{9}/.test(text)) {
    return { ok: false, hint: "Ce numéro d'organisme social n'est pas valide" }
  }

  const textRegimeTrunk = text.slice(0, 2)

  if (
    ((mutaRegime === 'GENERAL' || mutaRegime === 'GENERAL-TNS') && textRegimeTrunk != '01') ||
    (mutaRegime === 'AGRICOLE' && textRegimeTrunk != '02')
  ) {
    return { ok: false, hint: "Ce numéro d'organisme social n'est pas applicable à votre régime" }
  }

  return { ok: true, value: text }
}

export function getSocialSecurityOrganismNumberMapper(
  mutaRegime?: MutaSocialSecurityRegimeType,
): ValidatedTypeMapper<string> {
  return {
    inputType: 'text',
    format: (value) => value.trim().replaceAll('_', ''),
    formatPlaceholder: (value) => value.trim().replaceAll('_', ' '),
    parse: (text) => {
      const socialSecurityOrganismNumber = validateSocialSecurityOrganismNumber(text, mutaRegime)
      return socialSecurityOrganismNumber.ok
        ? success(socialSecurityOrganismNumber.value)
        : failure(socialSecurityOrganismNumber.hint)
    },
  }
}
