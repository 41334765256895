import { createFileRoute } from '@tanstack/react-router'

import PartnerContractPage from '../../../../../components/pages/backoffice/partners/partners-contract-page/partners-contract-page'
import { PermissionChecker } from '../../../../../components/templates/permission-checker'

export const Route = createFileRoute('/partner/$organization/contracts/$subscriptionId')({
  component: () => (
    <PermissionChecker requiredPermissions={['contracts.read']}>
      <PartnerContractPage />
    </PermissionChecker>
  ),
})
