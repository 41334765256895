import { createFileRoute } from '@tanstack/react-router'

import { PlatformNegativeInvoiceGroupsList } from '../../../../components/pages/backoffice/platform/platform-invoicing-negative-invoices-list'
import { PermissionChecker } from '../../../../components/templates/permission-checker'

export const Route = createFileRoute('/bak/invoicing/negative-invoices-list')({
  component: () => (
    <PermissionChecker requiredPermissions={['invoices.configuration']}>
      <PlatformNegativeInvoiceGroupsList />
    </PermissionChecker>
  ),
})
