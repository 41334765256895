import styled from '@emotion/styled'
import type { ReactElement } from 'react'
import { memo } from 'react'
import { colorTokens } from '../../foundations/color-tokens.js'

export const Divider = memo(function Divider(props: {
  orientation: 'horizontal' | 'vertical'
  className?: string
}): ReactElement {
  const { orientation, ...otherProps } = props
  return orientation === 'horizontal' ? <HorizontalDivider {...otherProps} /> : <VerticalDivider {...otherProps} />
})

export const VerticalDivider = styled('div', {
  shouldForwardProp: (propName) => !['width', 'backgroundColor'].includes(propName),
})<{
  width?: string
  backgroundColor?: string
}>`
  flex-shrink: 0;
  width: ${({ width = '1px' }) => width} !important;
  height: auto;
  background-color: ${({ backgroundColor = colorTokens['color-stroke-base'] }) => backgroundColor};
  box-sizing: border-box;
`

export const HorizontalDivider = styled('div', {
  shouldForwardProp: (propName) => !['height', 'backgroundColor'].includes(propName),
})<{
  height?: string
  backgroundColor?: string
}>`
  flex-shrink: 0;
  height: ${({ height = '1px' }) => height} !important;
  width: auto;
  background-color: ${({ backgroundColor = colorTokens['color-stroke-base'] }) => backgroundColor};
  box-sizing: border-box;
`
