import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { amountToString, rateGt, X0, type Amount, type FinancialRate } from '@orus.eu/amount'
import { globalMonthlyCommitmentPenaltyTenPercentRate, type ProductAttributes } from '@orus.eu/dimensions'
import { memo } from 'react'

import {
  productAttributeValueToString,
  projectAttributeInSummaryUnderQuote,
  useProductsAttributesProjection,
} from '../../../../code-only/hooks/use-product-attributes-projection.js'
import { desktopMediaQuery } from '../../../../code-only/hooks/use-screen-type.js'
import { useLanguage, useTranslate } from '../../../../code-only/localization/language-context.js'
import { Button, Chip } from '../../../../components/index.js'
import { colorTokens } from '../../../../foundations/color-tokens.js'
import { shadow } from '../../../../foundations/shadow-tokens.js'
import { spacing } from '../../../../foundations/spacing-tokens.js'
import { Text } from '../../../../foundations/text'
import { DownloadButton } from '../../../../legacy/molecules/download-button.js'

type Variant = 'mobile' | 'desktop'

export type PricingBlockProps = {
  variant: Variant

  paymentRecurrence: 'monthly' | 'yearly'
  forbiddenMonthlyPayment: boolean
  monthlyPrice: Amount
  yearlyPrice: Amount
  explanationsAboveSubscriptionButton: string[]
  discount: FinancialRate
  yearlyCommitmentHasDiscount: boolean
  monthlyWithoutCommitment?: boolean
  productsAttributes: ProductAttributes[]
  onSeeMoreDetailsClick: () => void
  onSubscribeClick?: () => void
  className?: string
  handleDownload: () => void
  downloadInProgress: boolean
}

export const PricingBlock = memo<PricingBlockProps>(function PricingBlock(props) {
  const language = useLanguage()
  const translate = useTranslate()
  const {
    variant,
    paymentRecurrence,
    forbiddenMonthlyPayment,
    monthlyPrice,
    yearlyPrice,
    explanationsAboveSubscriptionButton,
    discount,
    productsAttributes,
    yearlyCommitmentHasDiscount,
    monthlyWithoutCommitment,
    onSeeMoreDetailsClick,
    onSubscribeClick,
    className,
    handleDownload,
    downloadInProgress,
  } = props

  const currentRecurrencePrice = paymentRecurrence === 'monthly' ? monthlyPrice : yearlyPrice
  const formattedCurrentRecurrencePrice = amountToString(currentRecurrencePrice, AMOUNT_TO_STRING_OPTIONS)
  const otherRecurrencePrice = paymentRecurrence === 'monthly' ? yearlyPrice : monthlyPrice
  const otherRecurrence = paymentRecurrence === 'monthly' ? 'yearly' : 'monthly'

  const showWithoutCommitmentChip =
    paymentRecurrence === 'monthly' && monthlyWithoutCommitment && !forbiddenMonthlyPayment
  const showYearlyDiscountChip =
    paymentRecurrence === 'yearly' && !!yearlyCommitmentHasDiscount && !forbiddenMonthlyPayment

  const detailLines = useProductsAttributesProjection(productsAttributes, projectAttributeInSummaryUnderQuote)

  const isDesktop = variant === 'desktop'

  return (
    <Container variant={variant} className={className}>
      <Text variant="h3">{formattedCurrentRecurrencePrice}</Text>
      <Text variant="captionMedium">{translate(`per_time_unit_taxes_included_${paymentRecurrence}`)}</Text>
      {!!(showYearlyDiscountChip || showWithoutCommitmentChip || discount) && (
        <div
          css={css`
            display: flex;
            flex-wrap: wrap;
            gap: ${spacing[30]};
            justify-content: ${variant === 'mobile' ? 'center' : 'flex-start'};

            margin-top: ${spacing[50]};
          `}
        >
          {showWithoutCommitmentChip && (
            <Chip
              size="large"
              backgroundColor={colorTokens['color-bg-decorative-3']}
              textColor={colorTokens['color-text-decorative-main']}
            >
              {translate('subscription_funnel_without_commitment_badge')}
            </Chip>
          )}

          {showYearlyDiscountChip && (
            <Chip
              size="large"
              backgroundColor={colorTokens['color-bg-decorative-2']}
              textColor={colorTokens['color-text-decorative-main']}
            >
              {translate('subscription_funnel_yearly_discount_badge', {
                rate: globalMonthlyCommitmentPenaltyTenPercentRate,
              })}
            </Chip>
          )}

          {rateGt(discount, X0) && (
            <Chip
              size="large"
              backgroundColor={colorTokens['color-bg-decorative-4']}
              textColor={colorTokens['color-text-decorative-main']}
            >
              {translate('subscription_funnel_discount_badge', { rate: discount })}
            </Chip>
          )}
        </div>
      )}
      {!forbiddenMonthlyPayment && (
        <Text
          variant="body2Medium"
          color={colorTokens['color-text-base-main']}
          css={css`
            margin-top: ${spacing[30]};
          `}
        >
          {translate(`or_amount_per_time_unit_${otherRecurrence}`, { amount: otherRecurrencePrice })}
        </Text>
      )}
      <Button
        variant="text"
        icon="calculator-regular"
        avatarPosition="left"
        onClick={onSeeMoreDetailsClick}
        css={css`
          align-self: ${variant === 'mobile' ? 'center' : 'flex-start'};
          margin-top: ${spacing[60]};
        `}
      >
        {translate('subscription_funnel_see_detail')}
      </Button>
      {!!detailLines?.length && (
        <div>
          {detailLines.map((detailLine, index) => (
            <div
              key={index}
              css={css`
                margin-top: ${spacing[60]};
              `}
            >
              <Text
                variant="body2Medium"
                css={css`
                  text-align: left;
                `}
              >
                {detailLine.productName}
              </Text>
              {detailLine.attributes.map((attribute) => (
                <div
                  key={attribute.label}
                  css={css`
                    display: flex;
                    justify-content: space-between;
                  `}
                >
                  <Text
                    variant="body2"
                    css={css`
                      text-align: left;
                    `}
                  >
                    {attribute.label}
                  </Text>
                  <Text
                    variant="body2"
                    css={css`
                      text-align: right;
                    `}
                    color={colorTokens['color-text-base-main']}
                  >
                    {productAttributeValueToString(attribute, language)}
                  </Text>
                </div>
              ))}
            </div>
          ))}
        </div>
      )}
      {onSubscribeClick && (
        <>
          {explanationsAboveSubscriptionButton.length > 0 ? (
            <div
              css={css`
                margin-top: ${spacing[70]};
                text-align: center;
              `}
            >
              {explanationsAboveSubscriptionButton.map((explanation, index) => (
                <Text key={index} variant="body2">
                  {explanation}
                </Text>
              ))}
            </div>
          ) : (
            <></>
          )}
          {isDesktop && (
            <Button
              size="medium"
              onClick={onSubscribeClick}
              css={css`
                margin-top: ${spacing[70]};
              `}
            >
              {translate('subscription_funnel_finalize_for', { amount: currentRecurrencePrice })}
            </Button>
          )}
        </>
      )}

      <DownloadButton
        css={css`
          margin-top: ${variant === 'mobile' ? spacing[70] : spacing[40]};
        `}
        size={variant === 'mobile' ? 'large' : 'medium'}
        icon="arrow-down-to-line-solid"
        iconColor={colorTokens['color-fg-base-secondary']}
        avatarPosition="left"
        variant="secondary"
        downloadInProgress={downloadInProgress}
        onClick={handleDownload}
      >
        {translate('subscription_funnel_download_quote_pdf')}
      </DownloadButton>
    </Container>
  )
})

const Container = styled.div<{ variant: Variant }>`
  display: flex;
  flex-direction: column;

  padding: ${spacing[70]};

  text-align: ${(props) => (props.variant === 'mobile' ? 'center' : 'left')};

  background-color: ${colorTokens['color-bg-base-normal']};
  border: ${(props) =>
    props.variant === 'mobile'
      ? 'none'
      : `
    1px solid ${colorTokens['color-stroke-base']};
  `};
  border-radius: ${(props) => (props.variant === 'mobile' ? '0px' : '12px')};
  box-shadow: ${(props) => (props.variant === 'mobile' ? 'none' : shadow.bottom[30])};

  ${desktopMediaQuery} {
    min-width: 375px;
  }
`
const AMOUNT_TO_STRING_OPTIONS = { addCurrency: true }
