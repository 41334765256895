import { Box } from '@mui/material'
import type { ReactElement, ReactNode } from 'react'

export type CenterProps = {
  children: ReactNode
  minHeight?: string
}

export function Center({ children, minHeight }: CenterProps): ReactElement {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        textAlign: 'center',
        justifyContent: 'center',
        minHeight,
        height: '100%',
        width: '100%',
      }}
    >
      {children}
    </Box>
  )
}
