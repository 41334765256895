/* eslint-disable */

// @ts-nocheck

// noinspection JSUnusedGlobalSymbols

// This file was automatically generated by TanStack Router.
// You should NOT make any changes in this file as it will be overwritten.
// Additionally, you should also exclude this file from your linter and/or formatter to prevent it from being checked or modified.

// Import Routes

import { Route as rootRoute } from './routes/__root'
import { Route as BakImport } from './routes/bak'
import { Route as CustomerImport } from './routes/_customer'
import { Route as UnsubscribeRouteImport } from './routes/unsubscribe/route'
import { Route as SubscribeNoPossibleStepRouteImport } from './routes/subscribe-no-possible-step/route'
import { Route as SearchRouteImport } from './routes/search/route'
import { Route as ReceiveQuoteRouteImport } from './routes/receive-quote/route'
import { Route as PaymentMethodUpdateRouteImport } from './routes/payment-method-update/route'
import { Route as LoginRouteImport } from './routes/login/route'
import { Route as ImpersonateRouteImport } from './routes/impersonate/route'
import { Route as IndexImport } from './routes/index'
import { Route as PartnerOrganizationImport } from './routes/partner/$organization'
import { Route as SubscribeLandingRouteImport } from './routes/subscribe/landing/route'
import { Route as SubscribeExclusionSubscriptionIdRouteImport } from './routes/subscribe-exclusion/$subscriptionId/route'
import { Route as PaymentAuthenticationRouteImport } from './routes/payment/authentication/route'
import { Route as BakTrackingDocRouteImport } from './routes/bak/tracking-doc/route'
import { Route as BakSearchRouteImport } from './routes/bak/search/route'
import { Route as BakOrganizationsRouteImport } from './routes/bak/organizations/route'
import { Route as BakNewSubscriptionRouteImport } from './routes/bak/new-subscription/route'
import { Route as BakMigrationRouteImport } from './routes/bak/migration/route'
import { Route as BakMembershipsRouteImport } from './routes/bak/memberships/route'
import { Route as BakHomeRouteImport } from './routes/bak/home/route'
import { Route as BakEphemeralJobsRouteImport } from './routes/bak/ephemeral-jobs/route'
import { Route as BakActivitiesRouteImport } from './routes/bak/activities/route'
import { Route as CustomerReportClaimRouteImport } from './routes/_customer/report-claim/route'
import { Route as CustomerInvoicesRouteImport } from './routes/_customer/invoices/route'
import { Route as CustomerHomeRouteImport } from './routes/_customer/home/route'
import { Route as CustomerContractsRouteImport } from './routes/_customer/contracts/route'
import { Route as CustomerContractGuaranteesRouteImport } from './routes/_customer/contract-guarantees/route'
import { Route as CustomerAccountRouteImport } from './routes/_customer/account/route'
import { Route as BakUsersIndexImport } from './routes/bak/users/index'
import { Route as BakPendingSubscriptionsIndexImport } from './routes/bak/pending-subscriptions/index'
import { Route as BakInvoicingIndexImport } from './routes/bak/invoicing/index'
import { Route as BakDocumentationIndexImport } from './routes/bak/documentation/index'
import { Route as BakAdminIndexImport } from './routes/bak/admin/index'
import { Route as CustomerHealthInsuranceIndexImport } from './routes/_customer/health-insurance/index'
import { Route as SubscribeRestaurantsV2IdentifyRouteImport } from './routes/subscribe/restaurants-v2/identify/route'
import { Route as SQTokenRouteImport } from './routes/s/q/$token/route'
import { Route as SEV2TokenRouteImport } from './routes/s/e-v2/$token/route'
import { Route as SCTokenRouteImport } from './routes/s/c/$token/route'
import { Route as PartnerOrganizationSearchRouteImport } from './routes/partner/$organization/search/route'
import { Route as PartnerOrganizationNewSubscriptionRouteImport } from './routes/partner/$organization/new-subscription/route'
import { Route as PartnerOrganizationHomeRouteImport } from './routes/partner/$organization/home/route'
import { Route as BakUsersUserIdRouteImport } from './routes/bak/users/$userId/route'
import { Route as BakInvoicingNegativeInvoicesListRouteImport } from './routes/bak/invoicing/negative-invoices-list/route'
import { Route as BakDocumentationSubscriptionFunnelTextsRouteImport } from './routes/bak/documentation/subscription-funnel-texts/route'
import { Route as BakDocumentationRolesRouteImport } from './routes/bak/documentation/roles/route'
import { Route as BakDocumentationGeneralTermsRouteImport } from './routes/bak/documentation/general-terms/route'
import { Route as BakDocumentationExclusionsRouteImport } from './routes/bak/documentation/exclusions/route'
import { Route as BakDocumentationActivitySpecificQuestionsRouteImport } from './routes/bak/documentation/activity-specific-questions/route'
import { Route as BakAdminJobsRouteImport } from './routes/bak/admin/jobs/route'
import { Route as BakAdminImaRouteImport } from './routes/bak/admin/ima/route'
import { Route as BakAdminCpmsRouteImport } from './routes/bak/admin/cpms/route'
import { Route as CustomerPaymentMethodsUpdateRouteImport } from './routes/_customer/payment-methods/update/route'
import { Route as CustomerPaymentMethodsNewRouteImport } from './routes/_customer/payment-methods/new/route'
import { Route as CustomerInsuranceCertificateGeneratorRealEstateSalesAgentsSubscriptionIdRouteImport } from './routes/_customer/insurance-certificate-generator-real-estate-sales-agents/$subscriptionId/route'
import { Route as CustomerInsuranceCertificateGeneratorRealEstateAgentsSubscriptionIdRouteImport } from './routes/_customer/insurance-certificate-generator-real-estate-agents/$subscriptionId/route'
import { Route as CustomerHealthInsuranceSanteclairRouteImport } from './routes/_customer/health-insurance/santeclair/route'
import { Route as CustomerHealthInsuranceCpmsRouteImport } from './routes/_customer/health-insurance/cpms/route'
import { Route as CustomerHealthInsuranceBeneficiariesRouteImport } from './routes/_customer/health-insurance/beneficiaries/route'
import { Route as CustomerContractSubscriptionIdRouteImport } from './routes/_customer/contract/$subscriptionId/route'
import { Route as SubscribeSubscriptionIdStepIdIndexImport } from './routes/subscribe/$subscriptionId/$stepId/index'
import { Route as PartnerOrganizationUsersIndexImport } from './routes/partner/$organization/users/index'
import { Route as PartnerOrganizationPendingSubscriptionsIndexImport } from './routes/partner/$organization/pending-subscriptions/index'
import { Route as BakV2PendingSubscriptionsSubscriptionIdIndexImport } from './routes/bak/v2-pending-subscriptions/$subscriptionId/index'
import { Route as BakContractsSubscriptionIdIndexImport } from './routes/bak/contracts/$subscriptionId/index'
import { Route as PartnerOrganizationV2PendingSubscriptionsSubscriptionIdRouteImport } from './routes/partner/$organization/v2-pending-subscriptions/$subscriptionId/route'
import { Route as PartnerOrganizationUsersUserIdRouteImport } from './routes/partner/$organization/users/$userId/route'
import { Route as PartnerOrganizationContractsSubscriptionIdRouteImport } from './routes/partner/$organization/contracts/$subscriptionId/route'
import { Route as BakV2PendingSubscriptionsSubscriptionIdDetailedSheetRouteImport } from './routes/bak/v2-pending-subscriptions/$subscriptionId/detailed-sheet/route'
import { Route as BakTroubleshootingSessionSessionIdRouteImport } from './routes/bak/troubleshooting/session/$sessionId/route'
import { Route as BakPendingSubscriptionsSubscriptionIdPaymentRouteImport } from './routes/bak/pending-subscriptions/$subscriptionId/payment/route'
import { Route as BakPendingSubscriptionsSubscriptionIdHistoryRouteImport } from './routes/bak/pending-subscriptions/$subscriptionId/history/route'
import { Route as BakContractsSubscriptionIdStatusRouteImport } from './routes/bak/contracts/$subscriptionId/status/route'
import { Route as BakContractsSubscriptionIdNewInvoiceRouteImport } from './routes/bak/contracts/$subscriptionId/new-invoice/route'
import { Route as BakContractsSubscriptionIdInvoicingRouteImport } from './routes/bak/contracts/$subscriptionId/invoicing/route'
import { Route as CustomerSRTokenRouteImport } from './routes/_customer/s/r/$token/route'
import { Route as CustomerProductGuaranteesSubscriptionIdProductNameRouteImport } from './routes/_customer/product-guarantees/$subscriptionId/$productName/route'
import { Route as SubscribeSubscriptionIdStepIdDetailTypeDetailIdRouteImport } from './routes/subscribe/$subscriptionId/$stepId/$detailType/$detailId/route'
import { Route as PartnerOrganizationPendingSubscriptionsSubscriptionIdPaymentRouteImport } from './routes/partner/$organization/pending-subscriptions/$subscriptionId/payment/route'
import { Route as BakContractsSubscriptionIdRenewalEndorsementIdRouteImport } from './routes/bak/contracts/$subscriptionId/renewal/$endorsementId/route'
import { Route as BakContractsSubscriptionIdEndorsementEndorsementIdIndexImport } from './routes/bak/contracts/$subscriptionId/endorsement/$endorsementId/index'
import { Route as BakContractsSubscriptionIdEndorsementEndorsementIdDetailedSheetRouteImport } from './routes/bak/contracts/$subscriptionId/endorsement/$endorsementId/detailed-sheet/route'
import { Route as BakContractsSubscriptionIdDetailedSheetVersionsVersionIdRouteImport } from './routes/bak/contracts/$subscriptionId/detailed-sheet/versions/$versionId/route'

// Create/Update Routes

const BakRoute = BakImport.update({
  id: '/bak',
  path: '/bak',
  getParentRoute: () => rootRoute,
} as any)

const CustomerRoute = CustomerImport.update({
  id: '/_customer',
  getParentRoute: () => rootRoute,
} as any)

const UnsubscribeRouteRoute = UnsubscribeRouteImport.update({
  id: '/unsubscribe',
  path: '/unsubscribe',
  getParentRoute: () => rootRoute,
} as any)

const SubscribeNoPossibleStepRouteRoute =
  SubscribeNoPossibleStepRouteImport.update({
    id: '/subscribe-no-possible-step',
    path: '/subscribe-no-possible-step',
    getParentRoute: () => rootRoute,
  } as any)

const SearchRouteRoute = SearchRouteImport.update({
  id: '/search',
  path: '/search',
  getParentRoute: () => rootRoute,
} as any)

const ReceiveQuoteRouteRoute = ReceiveQuoteRouteImport.update({
  id: '/receive-quote',
  path: '/receive-quote',
  getParentRoute: () => rootRoute,
} as any)

const PaymentMethodUpdateRouteRoute = PaymentMethodUpdateRouteImport.update({
  id: '/payment-method-update',
  path: '/payment-method-update',
  getParentRoute: () => rootRoute,
} as any)

const LoginRouteRoute = LoginRouteImport.update({
  id: '/login',
  path: '/login',
  getParentRoute: () => rootRoute,
} as any)

const ImpersonateRouteRoute = ImpersonateRouteImport.update({
  id: '/impersonate',
  path: '/impersonate',
  getParentRoute: () => rootRoute,
} as any)

const IndexRoute = IndexImport.update({
  id: '/',
  path: '/',
  getParentRoute: () => rootRoute,
} as any)

const PartnerOrganizationRoute = PartnerOrganizationImport.update({
  id: '/partner/$organization',
  path: '/partner/$organization',
  getParentRoute: () => rootRoute,
} as any)

const SubscribeLandingRouteRoute = SubscribeLandingRouteImport.update({
  id: '/subscribe/landing',
  path: '/subscribe/landing',
  getParentRoute: () => rootRoute,
} as any)

const SubscribeExclusionSubscriptionIdRouteRoute =
  SubscribeExclusionSubscriptionIdRouteImport.update({
    id: '/subscribe-exclusion/$subscriptionId',
    path: '/subscribe-exclusion/$subscriptionId',
    getParentRoute: () => rootRoute,
  } as any)

const PaymentAuthenticationRouteRoute = PaymentAuthenticationRouteImport.update(
  {
    id: '/payment/authentication',
    path: '/payment/authentication',
    getParentRoute: () => rootRoute,
  } as any,
)

const BakTrackingDocRouteRoute = BakTrackingDocRouteImport.update({
  id: '/tracking-doc',
  path: '/tracking-doc',
  getParentRoute: () => BakRoute,
} as any)

const BakSearchRouteRoute = BakSearchRouteImport.update({
  id: '/search',
  path: '/search',
  getParentRoute: () => BakRoute,
} as any)

const BakOrganizationsRouteRoute = BakOrganizationsRouteImport.update({
  id: '/organizations',
  path: '/organizations',
  getParentRoute: () => BakRoute,
} as any)

const BakNewSubscriptionRouteRoute = BakNewSubscriptionRouteImport.update({
  id: '/new-subscription',
  path: '/new-subscription',
  getParentRoute: () => BakRoute,
} as any)

const BakMigrationRouteRoute = BakMigrationRouteImport.update({
  id: '/migration',
  path: '/migration',
  getParentRoute: () => BakRoute,
} as any)

const BakMembershipsRouteRoute = BakMembershipsRouteImport.update({
  id: '/memberships',
  path: '/memberships',
  getParentRoute: () => BakRoute,
} as any)

const BakHomeRouteRoute = BakHomeRouteImport.update({
  id: '/home',
  path: '/home',
  getParentRoute: () => BakRoute,
} as any)

const BakEphemeralJobsRouteRoute = BakEphemeralJobsRouteImport.update({
  id: '/ephemeral-jobs',
  path: '/ephemeral-jobs',
  getParentRoute: () => BakRoute,
} as any)

const BakActivitiesRouteRoute = BakActivitiesRouteImport.update({
  id: '/activities',
  path: '/activities',
  getParentRoute: () => BakRoute,
} as any)

const CustomerReportClaimRouteRoute = CustomerReportClaimRouteImport.update({
  id: '/report-claim',
  path: '/report-claim',
  getParentRoute: () => CustomerRoute,
} as any)

const CustomerInvoicesRouteRoute = CustomerInvoicesRouteImport.update({
  id: '/invoices',
  path: '/invoices',
  getParentRoute: () => CustomerRoute,
} as any)

const CustomerHomeRouteRoute = CustomerHomeRouteImport.update({
  id: '/home',
  path: '/home',
  getParentRoute: () => CustomerRoute,
} as any)

const CustomerContractsRouteRoute = CustomerContractsRouteImport.update({
  id: '/contracts',
  path: '/contracts',
  getParentRoute: () => CustomerRoute,
} as any)

const CustomerContractGuaranteesRouteRoute =
  CustomerContractGuaranteesRouteImport.update({
    id: '/contract-guarantees',
    path: '/contract-guarantees',
    getParentRoute: () => CustomerRoute,
  } as any)

const CustomerAccountRouteRoute = CustomerAccountRouteImport.update({
  id: '/account',
  path: '/account',
  getParentRoute: () => CustomerRoute,
} as any)

const BakUsersIndexRoute = BakUsersIndexImport.update({
  id: '/users/',
  path: '/users/',
  getParentRoute: () => BakRoute,
} as any)

const BakPendingSubscriptionsIndexRoute =
  BakPendingSubscriptionsIndexImport.update({
    id: '/pending-subscriptions/',
    path: '/pending-subscriptions/',
    getParentRoute: () => BakRoute,
  } as any)

const BakInvoicingIndexRoute = BakInvoicingIndexImport.update({
  id: '/invoicing/',
  path: '/invoicing/',
  getParentRoute: () => BakRoute,
} as any)

const BakDocumentationIndexRoute = BakDocumentationIndexImport.update({
  id: '/documentation/',
  path: '/documentation/',
  getParentRoute: () => BakRoute,
} as any)

const BakAdminIndexRoute = BakAdminIndexImport.update({
  id: '/admin/',
  path: '/admin/',
  getParentRoute: () => BakRoute,
} as any)

const CustomerHealthInsuranceIndexRoute =
  CustomerHealthInsuranceIndexImport.update({
    id: '/health-insurance/',
    path: '/health-insurance/',
    getParentRoute: () => CustomerRoute,
  } as any)

const SubscribeRestaurantsV2IdentifyRouteRoute =
  SubscribeRestaurantsV2IdentifyRouteImport.update({
    id: '/subscribe/restaurants-v2/identify',
    path: '/subscribe/restaurants-v2/identify',
    getParentRoute: () => rootRoute,
  } as any)

const SQTokenRouteRoute = SQTokenRouteImport.update({
  id: '/s/q/$token',
  path: '/s/q/$token',
  getParentRoute: () => rootRoute,
} as any)

const SEV2TokenRouteRoute = SEV2TokenRouteImport.update({
  id: '/s/e-v2/$token',
  path: '/s/e-v2/$token',
  getParentRoute: () => rootRoute,
} as any)

const SCTokenRouteRoute = SCTokenRouteImport.update({
  id: '/s/c/$token',
  path: '/s/c/$token',
  getParentRoute: () => rootRoute,
} as any)

const PartnerOrganizationSearchRouteRoute =
  PartnerOrganizationSearchRouteImport.update({
    id: '/search',
    path: '/search',
    getParentRoute: () => PartnerOrganizationRoute,
  } as any)

const PartnerOrganizationNewSubscriptionRouteRoute =
  PartnerOrganizationNewSubscriptionRouteImport.update({
    id: '/new-subscription',
    path: '/new-subscription',
    getParentRoute: () => PartnerOrganizationRoute,
  } as any)

const PartnerOrganizationHomeRouteRoute =
  PartnerOrganizationHomeRouteImport.update({
    id: '/home',
    path: '/home',
    getParentRoute: () => PartnerOrganizationRoute,
  } as any)

const BakUsersUserIdRouteRoute = BakUsersUserIdRouteImport.update({
  id: '/users/$userId',
  path: '/users/$userId',
  getParentRoute: () => BakRoute,
} as any)

const BakInvoicingNegativeInvoicesListRouteRoute =
  BakInvoicingNegativeInvoicesListRouteImport.update({
    id: '/invoicing/negative-invoices-list',
    path: '/invoicing/negative-invoices-list',
    getParentRoute: () => BakRoute,
  } as any)

const BakDocumentationSubscriptionFunnelTextsRouteRoute =
  BakDocumentationSubscriptionFunnelTextsRouteImport.update({
    id: '/documentation/subscription-funnel-texts',
    path: '/documentation/subscription-funnel-texts',
    getParentRoute: () => BakRoute,
  } as any)

const BakDocumentationRolesRouteRoute = BakDocumentationRolesRouteImport.update(
  {
    id: '/documentation/roles',
    path: '/documentation/roles',
    getParentRoute: () => BakRoute,
  } as any,
)

const BakDocumentationGeneralTermsRouteRoute =
  BakDocumentationGeneralTermsRouteImport.update({
    id: '/documentation/general-terms',
    path: '/documentation/general-terms',
    getParentRoute: () => BakRoute,
  } as any)

const BakDocumentationExclusionsRouteRoute =
  BakDocumentationExclusionsRouteImport.update({
    id: '/documentation/exclusions',
    path: '/documentation/exclusions',
    getParentRoute: () => BakRoute,
  } as any)

const BakDocumentationActivitySpecificQuestionsRouteRoute =
  BakDocumentationActivitySpecificQuestionsRouteImport.update({
    id: '/documentation/activity-specific-questions',
    path: '/documentation/activity-specific-questions',
    getParentRoute: () => BakRoute,
  } as any)

const BakAdminJobsRouteRoute = BakAdminJobsRouteImport.update({
  id: '/admin/jobs',
  path: '/admin/jobs',
  getParentRoute: () => BakRoute,
} as any)

const BakAdminImaRouteRoute = BakAdminImaRouteImport.update({
  id: '/admin/ima',
  path: '/admin/ima',
  getParentRoute: () => BakRoute,
} as any)

const BakAdminCpmsRouteRoute = BakAdminCpmsRouteImport.update({
  id: '/admin/cpms',
  path: '/admin/cpms',
  getParentRoute: () => BakRoute,
} as any)

const CustomerPaymentMethodsUpdateRouteRoute =
  CustomerPaymentMethodsUpdateRouteImport.update({
    id: '/payment-methods/update',
    path: '/payment-methods/update',
    getParentRoute: () => CustomerRoute,
  } as any)

const CustomerPaymentMethodsNewRouteRoute =
  CustomerPaymentMethodsNewRouteImport.update({
    id: '/payment-methods/new',
    path: '/payment-methods/new',
    getParentRoute: () => CustomerRoute,
  } as any)

const CustomerInsuranceCertificateGeneratorRealEstateSalesAgentsSubscriptionIdRouteRoute =
  CustomerInsuranceCertificateGeneratorRealEstateSalesAgentsSubscriptionIdRouteImport.update(
    {
      id: '/insurance-certificate-generator-real-estate-sales-agents/$subscriptionId',
      path: '/insurance-certificate-generator-real-estate-sales-agents/$subscriptionId',
      getParentRoute: () => CustomerRoute,
    } as any,
  )

const CustomerInsuranceCertificateGeneratorRealEstateAgentsSubscriptionIdRouteRoute =
  CustomerInsuranceCertificateGeneratorRealEstateAgentsSubscriptionIdRouteImport.update(
    {
      id: '/insurance-certificate-generator-real-estate-agents/$subscriptionId',
      path: '/insurance-certificate-generator-real-estate-agents/$subscriptionId',
      getParentRoute: () => CustomerRoute,
    } as any,
  )

const CustomerHealthInsuranceSanteclairRouteRoute =
  CustomerHealthInsuranceSanteclairRouteImport.update({
    id: '/health-insurance/santeclair',
    path: '/health-insurance/santeclair',
    getParentRoute: () => CustomerRoute,
  } as any)

const CustomerHealthInsuranceCpmsRouteRoute =
  CustomerHealthInsuranceCpmsRouteImport.update({
    id: '/health-insurance/cpms',
    path: '/health-insurance/cpms',
    getParentRoute: () => CustomerRoute,
  } as any)

const CustomerHealthInsuranceBeneficiariesRouteRoute =
  CustomerHealthInsuranceBeneficiariesRouteImport.update({
    id: '/health-insurance/beneficiaries',
    path: '/health-insurance/beneficiaries',
    getParentRoute: () => CustomerRoute,
  } as any)

const CustomerContractSubscriptionIdRouteRoute =
  CustomerContractSubscriptionIdRouteImport.update({
    id: '/contract/$subscriptionId',
    path: '/contract/$subscriptionId',
    getParentRoute: () => CustomerRoute,
  } as any)

const SubscribeSubscriptionIdStepIdIndexRoute =
  SubscribeSubscriptionIdStepIdIndexImport.update({
    id: '/subscribe/$subscriptionId/$stepId/',
    path: '/subscribe/$subscriptionId/$stepId/',
    getParentRoute: () => rootRoute,
  } as any)

const PartnerOrganizationUsersIndexRoute =
  PartnerOrganizationUsersIndexImport.update({
    id: '/users/',
    path: '/users/',
    getParentRoute: () => PartnerOrganizationRoute,
  } as any)

const PartnerOrganizationPendingSubscriptionsIndexRoute =
  PartnerOrganizationPendingSubscriptionsIndexImport.update({
    id: '/pending-subscriptions/',
    path: '/pending-subscriptions/',
    getParentRoute: () => PartnerOrganizationRoute,
  } as any)

const BakV2PendingSubscriptionsSubscriptionIdIndexRoute =
  BakV2PendingSubscriptionsSubscriptionIdIndexImport.update({
    id: '/v2-pending-subscriptions/$subscriptionId/',
    path: '/v2-pending-subscriptions/$subscriptionId/',
    getParentRoute: () => BakRoute,
  } as any)

const BakContractsSubscriptionIdIndexRoute =
  BakContractsSubscriptionIdIndexImport.update({
    id: '/contracts/$subscriptionId/',
    path: '/contracts/$subscriptionId/',
    getParentRoute: () => BakRoute,
  } as any)

const PartnerOrganizationV2PendingSubscriptionsSubscriptionIdRouteRoute =
  PartnerOrganizationV2PendingSubscriptionsSubscriptionIdRouteImport.update({
    id: '/v2-pending-subscriptions/$subscriptionId',
    path: '/v2-pending-subscriptions/$subscriptionId',
    getParentRoute: () => PartnerOrganizationRoute,
  } as any)

const PartnerOrganizationUsersUserIdRouteRoute =
  PartnerOrganizationUsersUserIdRouteImport.update({
    id: '/users/$userId',
    path: '/users/$userId',
    getParentRoute: () => PartnerOrganizationRoute,
  } as any)

const PartnerOrganizationContractsSubscriptionIdRouteRoute =
  PartnerOrganizationContractsSubscriptionIdRouteImport.update({
    id: '/contracts/$subscriptionId',
    path: '/contracts/$subscriptionId',
    getParentRoute: () => PartnerOrganizationRoute,
  } as any)

const BakV2PendingSubscriptionsSubscriptionIdDetailedSheetRouteRoute =
  BakV2PendingSubscriptionsSubscriptionIdDetailedSheetRouteImport.update({
    id: '/v2-pending-subscriptions/$subscriptionId/detailed-sheet',
    path: '/v2-pending-subscriptions/$subscriptionId/detailed-sheet',
    getParentRoute: () => BakRoute,
  } as any)

const BakTroubleshootingSessionSessionIdRouteRoute =
  BakTroubleshootingSessionSessionIdRouteImport.update({
    id: '/troubleshooting/session/$sessionId',
    path: '/troubleshooting/session/$sessionId',
    getParentRoute: () => BakRoute,
  } as any)

const BakPendingSubscriptionsSubscriptionIdPaymentRouteRoute =
  BakPendingSubscriptionsSubscriptionIdPaymentRouteImport.update({
    id: '/pending-subscriptions/$subscriptionId/payment',
    path: '/pending-subscriptions/$subscriptionId/payment',
    getParentRoute: () => BakRoute,
  } as any)

const BakPendingSubscriptionsSubscriptionIdHistoryRouteRoute =
  BakPendingSubscriptionsSubscriptionIdHistoryRouteImport.update({
    id: '/pending-subscriptions/$subscriptionId/history',
    path: '/pending-subscriptions/$subscriptionId/history',
    getParentRoute: () => BakRoute,
  } as any)

const BakContractsSubscriptionIdStatusRouteRoute =
  BakContractsSubscriptionIdStatusRouteImport.update({
    id: '/contracts/$subscriptionId/status',
    path: '/contracts/$subscriptionId/status',
    getParentRoute: () => BakRoute,
  } as any)

const BakContractsSubscriptionIdNewInvoiceRouteRoute =
  BakContractsSubscriptionIdNewInvoiceRouteImport.update({
    id: '/contracts/$subscriptionId/new-invoice',
    path: '/contracts/$subscriptionId/new-invoice',
    getParentRoute: () => BakRoute,
  } as any)

const BakContractsSubscriptionIdInvoicingRouteRoute =
  BakContractsSubscriptionIdInvoicingRouteImport.update({
    id: '/contracts/$subscriptionId/invoicing',
    path: '/contracts/$subscriptionId/invoicing',
    getParentRoute: () => BakRoute,
  } as any)

const CustomerSRTokenRouteRoute = CustomerSRTokenRouteImport.update({
  id: '/s/r/$token',
  path: '/s/r/$token',
  getParentRoute: () => CustomerRoute,
} as any)

const CustomerProductGuaranteesSubscriptionIdProductNameRouteRoute =
  CustomerProductGuaranteesSubscriptionIdProductNameRouteImport.update({
    id: '/product-guarantees/$subscriptionId/$productName',
    path: '/product-guarantees/$subscriptionId/$productName',
    getParentRoute: () => CustomerRoute,
  } as any)

const SubscribeSubscriptionIdStepIdDetailTypeDetailIdRouteRoute =
  SubscribeSubscriptionIdStepIdDetailTypeDetailIdRouteImport.update({
    id: '/subscribe/$subscriptionId/$stepId/$detailType/$detailId',
    path: '/subscribe/$subscriptionId/$stepId/$detailType/$detailId',
    getParentRoute: () => rootRoute,
  } as any)

const PartnerOrganizationPendingSubscriptionsSubscriptionIdPaymentRouteRoute =
  PartnerOrganizationPendingSubscriptionsSubscriptionIdPaymentRouteImport.update(
    {
      id: '/pending-subscriptions/$subscriptionId/payment',
      path: '/pending-subscriptions/$subscriptionId/payment',
      getParentRoute: () => PartnerOrganizationRoute,
    } as any,
  )

const BakContractsSubscriptionIdRenewalEndorsementIdRouteRoute =
  BakContractsSubscriptionIdRenewalEndorsementIdRouteImport.update({
    id: '/contracts/$subscriptionId/renewal/$endorsementId',
    path: '/contracts/$subscriptionId/renewal/$endorsementId',
    getParentRoute: () => BakRoute,
  } as any)

const BakContractsSubscriptionIdEndorsementEndorsementIdIndexRoute =
  BakContractsSubscriptionIdEndorsementEndorsementIdIndexImport.update({
    id: '/contracts/$subscriptionId/endorsement/$endorsementId/',
    path: '/contracts/$subscriptionId/endorsement/$endorsementId/',
    getParentRoute: () => BakRoute,
  } as any)

const BakContractsSubscriptionIdEndorsementEndorsementIdDetailedSheetRouteRoute =
  BakContractsSubscriptionIdEndorsementEndorsementIdDetailedSheetRouteImport.update(
    {
      id: '/contracts/$subscriptionId/endorsement/$endorsementId/detailed-sheet',
      path: '/contracts/$subscriptionId/endorsement/$endorsementId/detailed-sheet',
      getParentRoute: () => BakRoute,
    } as any,
  )

const BakContractsSubscriptionIdDetailedSheetVersionsVersionIdRouteRoute =
  BakContractsSubscriptionIdDetailedSheetVersionsVersionIdRouteImport.update({
    id: '/contracts/$subscriptionId/detailed-sheet/versions/$versionId',
    path: '/contracts/$subscriptionId/detailed-sheet/versions/$versionId',
    getParentRoute: () => BakRoute,
  } as any)

// Populate the FileRoutesByPath interface

declare module '@tanstack/react-router' {
  interface FileRoutesByPath {
    '/': {
      id: '/'
      path: '/'
      fullPath: '/'
      preLoaderRoute: typeof IndexImport
      parentRoute: typeof rootRoute
    }
    '/impersonate': {
      id: '/impersonate'
      path: '/impersonate'
      fullPath: '/impersonate'
      preLoaderRoute: typeof ImpersonateRouteImport
      parentRoute: typeof rootRoute
    }
    '/login': {
      id: '/login'
      path: '/login'
      fullPath: '/login'
      preLoaderRoute: typeof LoginRouteImport
      parentRoute: typeof rootRoute
    }
    '/payment-method-update': {
      id: '/payment-method-update'
      path: '/payment-method-update'
      fullPath: '/payment-method-update'
      preLoaderRoute: typeof PaymentMethodUpdateRouteImport
      parentRoute: typeof rootRoute
    }
    '/receive-quote': {
      id: '/receive-quote'
      path: '/receive-quote'
      fullPath: '/receive-quote'
      preLoaderRoute: typeof ReceiveQuoteRouteImport
      parentRoute: typeof rootRoute
    }
    '/search': {
      id: '/search'
      path: '/search'
      fullPath: '/search'
      preLoaderRoute: typeof SearchRouteImport
      parentRoute: typeof rootRoute
    }
    '/subscribe-no-possible-step': {
      id: '/subscribe-no-possible-step'
      path: '/subscribe-no-possible-step'
      fullPath: '/subscribe-no-possible-step'
      preLoaderRoute: typeof SubscribeNoPossibleStepRouteImport
      parentRoute: typeof rootRoute
    }
    '/unsubscribe': {
      id: '/unsubscribe'
      path: '/unsubscribe'
      fullPath: '/unsubscribe'
      preLoaderRoute: typeof UnsubscribeRouteImport
      parentRoute: typeof rootRoute
    }
    '/_customer': {
      id: '/_customer'
      path: ''
      fullPath: ''
      preLoaderRoute: typeof CustomerImport
      parentRoute: typeof rootRoute
    }
    '/bak': {
      id: '/bak'
      path: '/bak'
      fullPath: '/bak'
      preLoaderRoute: typeof BakImport
      parentRoute: typeof rootRoute
    }
    '/_customer/account': {
      id: '/_customer/account'
      path: '/account'
      fullPath: '/account'
      preLoaderRoute: typeof CustomerAccountRouteImport
      parentRoute: typeof CustomerImport
    }
    '/_customer/contract-guarantees': {
      id: '/_customer/contract-guarantees'
      path: '/contract-guarantees'
      fullPath: '/contract-guarantees'
      preLoaderRoute: typeof CustomerContractGuaranteesRouteImport
      parentRoute: typeof CustomerImport
    }
    '/_customer/contracts': {
      id: '/_customer/contracts'
      path: '/contracts'
      fullPath: '/contracts'
      preLoaderRoute: typeof CustomerContractsRouteImport
      parentRoute: typeof CustomerImport
    }
    '/_customer/home': {
      id: '/_customer/home'
      path: '/home'
      fullPath: '/home'
      preLoaderRoute: typeof CustomerHomeRouteImport
      parentRoute: typeof CustomerImport
    }
    '/_customer/invoices': {
      id: '/_customer/invoices'
      path: '/invoices'
      fullPath: '/invoices'
      preLoaderRoute: typeof CustomerInvoicesRouteImport
      parentRoute: typeof CustomerImport
    }
    '/_customer/report-claim': {
      id: '/_customer/report-claim'
      path: '/report-claim'
      fullPath: '/report-claim'
      preLoaderRoute: typeof CustomerReportClaimRouteImport
      parentRoute: typeof CustomerImport
    }
    '/bak/activities': {
      id: '/bak/activities'
      path: '/activities'
      fullPath: '/bak/activities'
      preLoaderRoute: typeof BakActivitiesRouteImport
      parentRoute: typeof BakImport
    }
    '/bak/ephemeral-jobs': {
      id: '/bak/ephemeral-jobs'
      path: '/ephemeral-jobs'
      fullPath: '/bak/ephemeral-jobs'
      preLoaderRoute: typeof BakEphemeralJobsRouteImport
      parentRoute: typeof BakImport
    }
    '/bak/home': {
      id: '/bak/home'
      path: '/home'
      fullPath: '/bak/home'
      preLoaderRoute: typeof BakHomeRouteImport
      parentRoute: typeof BakImport
    }
    '/bak/memberships': {
      id: '/bak/memberships'
      path: '/memberships'
      fullPath: '/bak/memberships'
      preLoaderRoute: typeof BakMembershipsRouteImport
      parentRoute: typeof BakImport
    }
    '/bak/migration': {
      id: '/bak/migration'
      path: '/migration'
      fullPath: '/bak/migration'
      preLoaderRoute: typeof BakMigrationRouteImport
      parentRoute: typeof BakImport
    }
    '/bak/new-subscription': {
      id: '/bak/new-subscription'
      path: '/new-subscription'
      fullPath: '/bak/new-subscription'
      preLoaderRoute: typeof BakNewSubscriptionRouteImport
      parentRoute: typeof BakImport
    }
    '/bak/organizations': {
      id: '/bak/organizations'
      path: '/organizations'
      fullPath: '/bak/organizations'
      preLoaderRoute: typeof BakOrganizationsRouteImport
      parentRoute: typeof BakImport
    }
    '/bak/search': {
      id: '/bak/search'
      path: '/search'
      fullPath: '/bak/search'
      preLoaderRoute: typeof BakSearchRouteImport
      parentRoute: typeof BakImport
    }
    '/bak/tracking-doc': {
      id: '/bak/tracking-doc'
      path: '/tracking-doc'
      fullPath: '/bak/tracking-doc'
      preLoaderRoute: typeof BakTrackingDocRouteImport
      parentRoute: typeof BakImport
    }
    '/payment/authentication': {
      id: '/payment/authentication'
      path: '/payment/authentication'
      fullPath: '/payment/authentication'
      preLoaderRoute: typeof PaymentAuthenticationRouteImport
      parentRoute: typeof rootRoute
    }
    '/subscribe-exclusion/$subscriptionId': {
      id: '/subscribe-exclusion/$subscriptionId'
      path: '/subscribe-exclusion/$subscriptionId'
      fullPath: '/subscribe-exclusion/$subscriptionId'
      preLoaderRoute: typeof SubscribeExclusionSubscriptionIdRouteImport
      parentRoute: typeof rootRoute
    }
    '/subscribe/landing': {
      id: '/subscribe/landing'
      path: '/subscribe/landing'
      fullPath: '/subscribe/landing'
      preLoaderRoute: typeof SubscribeLandingRouteImport
      parentRoute: typeof rootRoute
    }
    '/partner/$organization': {
      id: '/partner/$organization'
      path: '/partner/$organization'
      fullPath: '/partner/$organization'
      preLoaderRoute: typeof PartnerOrganizationImport
      parentRoute: typeof rootRoute
    }
    '/_customer/contract/$subscriptionId': {
      id: '/_customer/contract/$subscriptionId'
      path: '/contract/$subscriptionId'
      fullPath: '/contract/$subscriptionId'
      preLoaderRoute: typeof CustomerContractSubscriptionIdRouteImport
      parentRoute: typeof CustomerImport
    }
    '/_customer/health-insurance/beneficiaries': {
      id: '/_customer/health-insurance/beneficiaries'
      path: '/health-insurance/beneficiaries'
      fullPath: '/health-insurance/beneficiaries'
      preLoaderRoute: typeof CustomerHealthInsuranceBeneficiariesRouteImport
      parentRoute: typeof CustomerImport
    }
    '/_customer/health-insurance/cpms': {
      id: '/_customer/health-insurance/cpms'
      path: '/health-insurance/cpms'
      fullPath: '/health-insurance/cpms'
      preLoaderRoute: typeof CustomerHealthInsuranceCpmsRouteImport
      parentRoute: typeof CustomerImport
    }
    '/_customer/health-insurance/santeclair': {
      id: '/_customer/health-insurance/santeclair'
      path: '/health-insurance/santeclair'
      fullPath: '/health-insurance/santeclair'
      preLoaderRoute: typeof CustomerHealthInsuranceSanteclairRouteImport
      parentRoute: typeof CustomerImport
    }
    '/_customer/insurance-certificate-generator-real-estate-agents/$subscriptionId': {
      id: '/_customer/insurance-certificate-generator-real-estate-agents/$subscriptionId'
      path: '/insurance-certificate-generator-real-estate-agents/$subscriptionId'
      fullPath: '/insurance-certificate-generator-real-estate-agents/$subscriptionId'
      preLoaderRoute: typeof CustomerInsuranceCertificateGeneratorRealEstateAgentsSubscriptionIdRouteImport
      parentRoute: typeof CustomerImport
    }
    '/_customer/insurance-certificate-generator-real-estate-sales-agents/$subscriptionId': {
      id: '/_customer/insurance-certificate-generator-real-estate-sales-agents/$subscriptionId'
      path: '/insurance-certificate-generator-real-estate-sales-agents/$subscriptionId'
      fullPath: '/insurance-certificate-generator-real-estate-sales-agents/$subscriptionId'
      preLoaderRoute: typeof CustomerInsuranceCertificateGeneratorRealEstateSalesAgentsSubscriptionIdRouteImport
      parentRoute: typeof CustomerImport
    }
    '/_customer/payment-methods/new': {
      id: '/_customer/payment-methods/new'
      path: '/payment-methods/new'
      fullPath: '/payment-methods/new'
      preLoaderRoute: typeof CustomerPaymentMethodsNewRouteImport
      parentRoute: typeof CustomerImport
    }
    '/_customer/payment-methods/update': {
      id: '/_customer/payment-methods/update'
      path: '/payment-methods/update'
      fullPath: '/payment-methods/update'
      preLoaderRoute: typeof CustomerPaymentMethodsUpdateRouteImport
      parentRoute: typeof CustomerImport
    }
    '/bak/admin/cpms': {
      id: '/bak/admin/cpms'
      path: '/admin/cpms'
      fullPath: '/bak/admin/cpms'
      preLoaderRoute: typeof BakAdminCpmsRouteImport
      parentRoute: typeof BakImport
    }
    '/bak/admin/ima': {
      id: '/bak/admin/ima'
      path: '/admin/ima'
      fullPath: '/bak/admin/ima'
      preLoaderRoute: typeof BakAdminImaRouteImport
      parentRoute: typeof BakImport
    }
    '/bak/admin/jobs': {
      id: '/bak/admin/jobs'
      path: '/admin/jobs'
      fullPath: '/bak/admin/jobs'
      preLoaderRoute: typeof BakAdminJobsRouteImport
      parentRoute: typeof BakImport
    }
    '/bak/documentation/activity-specific-questions': {
      id: '/bak/documentation/activity-specific-questions'
      path: '/documentation/activity-specific-questions'
      fullPath: '/bak/documentation/activity-specific-questions'
      preLoaderRoute: typeof BakDocumentationActivitySpecificQuestionsRouteImport
      parentRoute: typeof BakImport
    }
    '/bak/documentation/exclusions': {
      id: '/bak/documentation/exclusions'
      path: '/documentation/exclusions'
      fullPath: '/bak/documentation/exclusions'
      preLoaderRoute: typeof BakDocumentationExclusionsRouteImport
      parentRoute: typeof BakImport
    }
    '/bak/documentation/general-terms': {
      id: '/bak/documentation/general-terms'
      path: '/documentation/general-terms'
      fullPath: '/bak/documentation/general-terms'
      preLoaderRoute: typeof BakDocumentationGeneralTermsRouteImport
      parentRoute: typeof BakImport
    }
    '/bak/documentation/roles': {
      id: '/bak/documentation/roles'
      path: '/documentation/roles'
      fullPath: '/bak/documentation/roles'
      preLoaderRoute: typeof BakDocumentationRolesRouteImport
      parentRoute: typeof BakImport
    }
    '/bak/documentation/subscription-funnel-texts': {
      id: '/bak/documentation/subscription-funnel-texts'
      path: '/documentation/subscription-funnel-texts'
      fullPath: '/bak/documentation/subscription-funnel-texts'
      preLoaderRoute: typeof BakDocumentationSubscriptionFunnelTextsRouteImport
      parentRoute: typeof BakImport
    }
    '/bak/invoicing/negative-invoices-list': {
      id: '/bak/invoicing/negative-invoices-list'
      path: '/invoicing/negative-invoices-list'
      fullPath: '/bak/invoicing/negative-invoices-list'
      preLoaderRoute: typeof BakInvoicingNegativeInvoicesListRouteImport
      parentRoute: typeof BakImport
    }
    '/bak/users/$userId': {
      id: '/bak/users/$userId'
      path: '/users/$userId'
      fullPath: '/bak/users/$userId'
      preLoaderRoute: typeof BakUsersUserIdRouteImport
      parentRoute: typeof BakImport
    }
    '/partner/$organization/home': {
      id: '/partner/$organization/home'
      path: '/home'
      fullPath: '/partner/$organization/home'
      preLoaderRoute: typeof PartnerOrganizationHomeRouteImport
      parentRoute: typeof PartnerOrganizationImport
    }
    '/partner/$organization/new-subscription': {
      id: '/partner/$organization/new-subscription'
      path: '/new-subscription'
      fullPath: '/partner/$organization/new-subscription'
      preLoaderRoute: typeof PartnerOrganizationNewSubscriptionRouteImport
      parentRoute: typeof PartnerOrganizationImport
    }
    '/partner/$organization/search': {
      id: '/partner/$organization/search'
      path: '/search'
      fullPath: '/partner/$organization/search'
      preLoaderRoute: typeof PartnerOrganizationSearchRouteImport
      parentRoute: typeof PartnerOrganizationImport
    }
    '/s/c/$token': {
      id: '/s/c/$token'
      path: '/s/c/$token'
      fullPath: '/s/c/$token'
      preLoaderRoute: typeof SCTokenRouteImport
      parentRoute: typeof rootRoute
    }
    '/s/e-v2/$token': {
      id: '/s/e-v2/$token'
      path: '/s/e-v2/$token'
      fullPath: '/s/e-v2/$token'
      preLoaderRoute: typeof SEV2TokenRouteImport
      parentRoute: typeof rootRoute
    }
    '/s/q/$token': {
      id: '/s/q/$token'
      path: '/s/q/$token'
      fullPath: '/s/q/$token'
      preLoaderRoute: typeof SQTokenRouteImport
      parentRoute: typeof rootRoute
    }
    '/subscribe/restaurants-v2/identify': {
      id: '/subscribe/restaurants-v2/identify'
      path: '/subscribe/restaurants-v2/identify'
      fullPath: '/subscribe/restaurants-v2/identify'
      preLoaderRoute: typeof SubscribeRestaurantsV2IdentifyRouteImport
      parentRoute: typeof rootRoute
    }
    '/_customer/health-insurance/': {
      id: '/_customer/health-insurance/'
      path: '/health-insurance'
      fullPath: '/health-insurance'
      preLoaderRoute: typeof CustomerHealthInsuranceIndexImport
      parentRoute: typeof CustomerImport
    }
    '/bak/admin/': {
      id: '/bak/admin/'
      path: '/admin'
      fullPath: '/bak/admin'
      preLoaderRoute: typeof BakAdminIndexImport
      parentRoute: typeof BakImport
    }
    '/bak/documentation/': {
      id: '/bak/documentation/'
      path: '/documentation'
      fullPath: '/bak/documentation'
      preLoaderRoute: typeof BakDocumentationIndexImport
      parentRoute: typeof BakImport
    }
    '/bak/invoicing/': {
      id: '/bak/invoicing/'
      path: '/invoicing'
      fullPath: '/bak/invoicing'
      preLoaderRoute: typeof BakInvoicingIndexImport
      parentRoute: typeof BakImport
    }
    '/bak/pending-subscriptions/': {
      id: '/bak/pending-subscriptions/'
      path: '/pending-subscriptions'
      fullPath: '/bak/pending-subscriptions'
      preLoaderRoute: typeof BakPendingSubscriptionsIndexImport
      parentRoute: typeof BakImport
    }
    '/bak/users/': {
      id: '/bak/users/'
      path: '/users'
      fullPath: '/bak/users'
      preLoaderRoute: typeof BakUsersIndexImport
      parentRoute: typeof BakImport
    }
    '/_customer/product-guarantees/$subscriptionId/$productName': {
      id: '/_customer/product-guarantees/$subscriptionId/$productName'
      path: '/product-guarantees/$subscriptionId/$productName'
      fullPath: '/product-guarantees/$subscriptionId/$productName'
      preLoaderRoute: typeof CustomerProductGuaranteesSubscriptionIdProductNameRouteImport
      parentRoute: typeof CustomerImport
    }
    '/_customer/s/r/$token': {
      id: '/_customer/s/r/$token'
      path: '/s/r/$token'
      fullPath: '/s/r/$token'
      preLoaderRoute: typeof CustomerSRTokenRouteImport
      parentRoute: typeof CustomerImport
    }
    '/bak/contracts/$subscriptionId/invoicing': {
      id: '/bak/contracts/$subscriptionId/invoicing'
      path: '/contracts/$subscriptionId/invoicing'
      fullPath: '/bak/contracts/$subscriptionId/invoicing'
      preLoaderRoute: typeof BakContractsSubscriptionIdInvoicingRouteImport
      parentRoute: typeof BakImport
    }
    '/bak/contracts/$subscriptionId/new-invoice': {
      id: '/bak/contracts/$subscriptionId/new-invoice'
      path: '/contracts/$subscriptionId/new-invoice'
      fullPath: '/bak/contracts/$subscriptionId/new-invoice'
      preLoaderRoute: typeof BakContractsSubscriptionIdNewInvoiceRouteImport
      parentRoute: typeof BakImport
    }
    '/bak/contracts/$subscriptionId/status': {
      id: '/bak/contracts/$subscriptionId/status'
      path: '/contracts/$subscriptionId/status'
      fullPath: '/bak/contracts/$subscriptionId/status'
      preLoaderRoute: typeof BakContractsSubscriptionIdStatusRouteImport
      parentRoute: typeof BakImport
    }
    '/bak/pending-subscriptions/$subscriptionId/history': {
      id: '/bak/pending-subscriptions/$subscriptionId/history'
      path: '/pending-subscriptions/$subscriptionId/history'
      fullPath: '/bak/pending-subscriptions/$subscriptionId/history'
      preLoaderRoute: typeof BakPendingSubscriptionsSubscriptionIdHistoryRouteImport
      parentRoute: typeof BakImport
    }
    '/bak/pending-subscriptions/$subscriptionId/payment': {
      id: '/bak/pending-subscriptions/$subscriptionId/payment'
      path: '/pending-subscriptions/$subscriptionId/payment'
      fullPath: '/bak/pending-subscriptions/$subscriptionId/payment'
      preLoaderRoute: typeof BakPendingSubscriptionsSubscriptionIdPaymentRouteImport
      parentRoute: typeof BakImport
    }
    '/bak/troubleshooting/session/$sessionId': {
      id: '/bak/troubleshooting/session/$sessionId'
      path: '/troubleshooting/session/$sessionId'
      fullPath: '/bak/troubleshooting/session/$sessionId'
      preLoaderRoute: typeof BakTroubleshootingSessionSessionIdRouteImport
      parentRoute: typeof BakImport
    }
    '/bak/v2-pending-subscriptions/$subscriptionId/detailed-sheet': {
      id: '/bak/v2-pending-subscriptions/$subscriptionId/detailed-sheet'
      path: '/v2-pending-subscriptions/$subscriptionId/detailed-sheet'
      fullPath: '/bak/v2-pending-subscriptions/$subscriptionId/detailed-sheet'
      preLoaderRoute: typeof BakV2PendingSubscriptionsSubscriptionIdDetailedSheetRouteImport
      parentRoute: typeof BakImport
    }
    '/partner/$organization/contracts/$subscriptionId': {
      id: '/partner/$organization/contracts/$subscriptionId'
      path: '/contracts/$subscriptionId'
      fullPath: '/partner/$organization/contracts/$subscriptionId'
      preLoaderRoute: typeof PartnerOrganizationContractsSubscriptionIdRouteImport
      parentRoute: typeof PartnerOrganizationImport
    }
    '/partner/$organization/users/$userId': {
      id: '/partner/$organization/users/$userId'
      path: '/users/$userId'
      fullPath: '/partner/$organization/users/$userId'
      preLoaderRoute: typeof PartnerOrganizationUsersUserIdRouteImport
      parentRoute: typeof PartnerOrganizationImport
    }
    '/partner/$organization/v2-pending-subscriptions/$subscriptionId': {
      id: '/partner/$organization/v2-pending-subscriptions/$subscriptionId'
      path: '/v2-pending-subscriptions/$subscriptionId'
      fullPath: '/partner/$organization/v2-pending-subscriptions/$subscriptionId'
      preLoaderRoute: typeof PartnerOrganizationV2PendingSubscriptionsSubscriptionIdRouteImport
      parentRoute: typeof PartnerOrganizationImport
    }
    '/bak/contracts/$subscriptionId/': {
      id: '/bak/contracts/$subscriptionId/'
      path: '/contracts/$subscriptionId'
      fullPath: '/bak/contracts/$subscriptionId'
      preLoaderRoute: typeof BakContractsSubscriptionIdIndexImport
      parentRoute: typeof BakImport
    }
    '/bak/v2-pending-subscriptions/$subscriptionId/': {
      id: '/bak/v2-pending-subscriptions/$subscriptionId/'
      path: '/v2-pending-subscriptions/$subscriptionId'
      fullPath: '/bak/v2-pending-subscriptions/$subscriptionId'
      preLoaderRoute: typeof BakV2PendingSubscriptionsSubscriptionIdIndexImport
      parentRoute: typeof BakImport
    }
    '/partner/$organization/pending-subscriptions/': {
      id: '/partner/$organization/pending-subscriptions/'
      path: '/pending-subscriptions'
      fullPath: '/partner/$organization/pending-subscriptions'
      preLoaderRoute: typeof PartnerOrganizationPendingSubscriptionsIndexImport
      parentRoute: typeof PartnerOrganizationImport
    }
    '/partner/$organization/users/': {
      id: '/partner/$organization/users/'
      path: '/users'
      fullPath: '/partner/$organization/users'
      preLoaderRoute: typeof PartnerOrganizationUsersIndexImport
      parentRoute: typeof PartnerOrganizationImport
    }
    '/subscribe/$subscriptionId/$stepId/': {
      id: '/subscribe/$subscriptionId/$stepId/'
      path: '/subscribe/$subscriptionId/$stepId'
      fullPath: '/subscribe/$subscriptionId/$stepId'
      preLoaderRoute: typeof SubscribeSubscriptionIdStepIdIndexImport
      parentRoute: typeof rootRoute
    }
    '/bak/contracts/$subscriptionId/renewal/$endorsementId': {
      id: '/bak/contracts/$subscriptionId/renewal/$endorsementId'
      path: '/contracts/$subscriptionId/renewal/$endorsementId'
      fullPath: '/bak/contracts/$subscriptionId/renewal/$endorsementId'
      preLoaderRoute: typeof BakContractsSubscriptionIdRenewalEndorsementIdRouteImport
      parentRoute: typeof BakImport
    }
    '/partner/$organization/pending-subscriptions/$subscriptionId/payment': {
      id: '/partner/$organization/pending-subscriptions/$subscriptionId/payment'
      path: '/pending-subscriptions/$subscriptionId/payment'
      fullPath: '/partner/$organization/pending-subscriptions/$subscriptionId/payment'
      preLoaderRoute: typeof PartnerOrganizationPendingSubscriptionsSubscriptionIdPaymentRouteImport
      parentRoute: typeof PartnerOrganizationImport
    }
    '/subscribe/$subscriptionId/$stepId/$detailType/$detailId': {
      id: '/subscribe/$subscriptionId/$stepId/$detailType/$detailId'
      path: '/subscribe/$subscriptionId/$stepId/$detailType/$detailId'
      fullPath: '/subscribe/$subscriptionId/$stepId/$detailType/$detailId'
      preLoaderRoute: typeof SubscribeSubscriptionIdStepIdDetailTypeDetailIdRouteImport
      parentRoute: typeof rootRoute
    }
    '/bak/contracts/$subscriptionId/detailed-sheet/versions/$versionId': {
      id: '/bak/contracts/$subscriptionId/detailed-sheet/versions/$versionId'
      path: '/contracts/$subscriptionId/detailed-sheet/versions/$versionId'
      fullPath: '/bak/contracts/$subscriptionId/detailed-sheet/versions/$versionId'
      preLoaderRoute: typeof BakContractsSubscriptionIdDetailedSheetVersionsVersionIdRouteImport
      parentRoute: typeof BakImport
    }
    '/bak/contracts/$subscriptionId/endorsement/$endorsementId/detailed-sheet': {
      id: '/bak/contracts/$subscriptionId/endorsement/$endorsementId/detailed-sheet'
      path: '/contracts/$subscriptionId/endorsement/$endorsementId/detailed-sheet'
      fullPath: '/bak/contracts/$subscriptionId/endorsement/$endorsementId/detailed-sheet'
      preLoaderRoute: typeof BakContractsSubscriptionIdEndorsementEndorsementIdDetailedSheetRouteImport
      parentRoute: typeof BakImport
    }
    '/bak/contracts/$subscriptionId/endorsement/$endorsementId/': {
      id: '/bak/contracts/$subscriptionId/endorsement/$endorsementId/'
      path: '/contracts/$subscriptionId/endorsement/$endorsementId'
      fullPath: '/bak/contracts/$subscriptionId/endorsement/$endorsementId'
      preLoaderRoute: typeof BakContractsSubscriptionIdEndorsementEndorsementIdIndexImport
      parentRoute: typeof BakImport
    }
  }
}

// Create and export the route tree

interface CustomerRouteChildren {
  CustomerAccountRouteRoute: typeof CustomerAccountRouteRoute
  CustomerContractGuaranteesRouteRoute: typeof CustomerContractGuaranteesRouteRoute
  CustomerContractsRouteRoute: typeof CustomerContractsRouteRoute
  CustomerHomeRouteRoute: typeof CustomerHomeRouteRoute
  CustomerInvoicesRouteRoute: typeof CustomerInvoicesRouteRoute
  CustomerReportClaimRouteRoute: typeof CustomerReportClaimRouteRoute
  CustomerContractSubscriptionIdRouteRoute: typeof CustomerContractSubscriptionIdRouteRoute
  CustomerHealthInsuranceBeneficiariesRouteRoute: typeof CustomerHealthInsuranceBeneficiariesRouteRoute
  CustomerHealthInsuranceCpmsRouteRoute: typeof CustomerHealthInsuranceCpmsRouteRoute
  CustomerHealthInsuranceSanteclairRouteRoute: typeof CustomerHealthInsuranceSanteclairRouteRoute
  CustomerInsuranceCertificateGeneratorRealEstateAgentsSubscriptionIdRouteRoute: typeof CustomerInsuranceCertificateGeneratorRealEstateAgentsSubscriptionIdRouteRoute
  CustomerInsuranceCertificateGeneratorRealEstateSalesAgentsSubscriptionIdRouteRoute: typeof CustomerInsuranceCertificateGeneratorRealEstateSalesAgentsSubscriptionIdRouteRoute
  CustomerPaymentMethodsNewRouteRoute: typeof CustomerPaymentMethodsNewRouteRoute
  CustomerPaymentMethodsUpdateRouteRoute: typeof CustomerPaymentMethodsUpdateRouteRoute
  CustomerHealthInsuranceIndexRoute: typeof CustomerHealthInsuranceIndexRoute
  CustomerProductGuaranteesSubscriptionIdProductNameRouteRoute: typeof CustomerProductGuaranteesSubscriptionIdProductNameRouteRoute
  CustomerSRTokenRouteRoute: typeof CustomerSRTokenRouteRoute
}

const CustomerRouteChildren: CustomerRouteChildren = {
  CustomerAccountRouteRoute: CustomerAccountRouteRoute,
  CustomerContractGuaranteesRouteRoute: CustomerContractGuaranteesRouteRoute,
  CustomerContractsRouteRoute: CustomerContractsRouteRoute,
  CustomerHomeRouteRoute: CustomerHomeRouteRoute,
  CustomerInvoicesRouteRoute: CustomerInvoicesRouteRoute,
  CustomerReportClaimRouteRoute: CustomerReportClaimRouteRoute,
  CustomerContractSubscriptionIdRouteRoute:
    CustomerContractSubscriptionIdRouteRoute,
  CustomerHealthInsuranceBeneficiariesRouteRoute:
    CustomerHealthInsuranceBeneficiariesRouteRoute,
  CustomerHealthInsuranceCpmsRouteRoute: CustomerHealthInsuranceCpmsRouteRoute,
  CustomerHealthInsuranceSanteclairRouteRoute:
    CustomerHealthInsuranceSanteclairRouteRoute,
  CustomerInsuranceCertificateGeneratorRealEstateAgentsSubscriptionIdRouteRoute:
    CustomerInsuranceCertificateGeneratorRealEstateAgentsSubscriptionIdRouteRoute,
  CustomerInsuranceCertificateGeneratorRealEstateSalesAgentsSubscriptionIdRouteRoute:
    CustomerInsuranceCertificateGeneratorRealEstateSalesAgentsSubscriptionIdRouteRoute,
  CustomerPaymentMethodsNewRouteRoute: CustomerPaymentMethodsNewRouteRoute,
  CustomerPaymentMethodsUpdateRouteRoute:
    CustomerPaymentMethodsUpdateRouteRoute,
  CustomerHealthInsuranceIndexRoute: CustomerHealthInsuranceIndexRoute,
  CustomerProductGuaranteesSubscriptionIdProductNameRouteRoute:
    CustomerProductGuaranteesSubscriptionIdProductNameRouteRoute,
  CustomerSRTokenRouteRoute: CustomerSRTokenRouteRoute,
}

const CustomerRouteWithChildren = CustomerRoute._addFileChildren(
  CustomerRouteChildren,
)

interface BakRouteChildren {
  BakActivitiesRouteRoute: typeof BakActivitiesRouteRoute
  BakEphemeralJobsRouteRoute: typeof BakEphemeralJobsRouteRoute
  BakHomeRouteRoute: typeof BakHomeRouteRoute
  BakMembershipsRouteRoute: typeof BakMembershipsRouteRoute
  BakMigrationRouteRoute: typeof BakMigrationRouteRoute
  BakNewSubscriptionRouteRoute: typeof BakNewSubscriptionRouteRoute
  BakOrganizationsRouteRoute: typeof BakOrganizationsRouteRoute
  BakSearchRouteRoute: typeof BakSearchRouteRoute
  BakTrackingDocRouteRoute: typeof BakTrackingDocRouteRoute
  BakAdminCpmsRouteRoute: typeof BakAdminCpmsRouteRoute
  BakAdminImaRouteRoute: typeof BakAdminImaRouteRoute
  BakAdminJobsRouteRoute: typeof BakAdminJobsRouteRoute
  BakDocumentationActivitySpecificQuestionsRouteRoute: typeof BakDocumentationActivitySpecificQuestionsRouteRoute
  BakDocumentationExclusionsRouteRoute: typeof BakDocumentationExclusionsRouteRoute
  BakDocumentationGeneralTermsRouteRoute: typeof BakDocumentationGeneralTermsRouteRoute
  BakDocumentationRolesRouteRoute: typeof BakDocumentationRolesRouteRoute
  BakDocumentationSubscriptionFunnelTextsRouteRoute: typeof BakDocumentationSubscriptionFunnelTextsRouteRoute
  BakInvoicingNegativeInvoicesListRouteRoute: typeof BakInvoicingNegativeInvoicesListRouteRoute
  BakUsersUserIdRouteRoute: typeof BakUsersUserIdRouteRoute
  BakAdminIndexRoute: typeof BakAdminIndexRoute
  BakDocumentationIndexRoute: typeof BakDocumentationIndexRoute
  BakInvoicingIndexRoute: typeof BakInvoicingIndexRoute
  BakPendingSubscriptionsIndexRoute: typeof BakPendingSubscriptionsIndexRoute
  BakUsersIndexRoute: typeof BakUsersIndexRoute
  BakContractsSubscriptionIdInvoicingRouteRoute: typeof BakContractsSubscriptionIdInvoicingRouteRoute
  BakContractsSubscriptionIdNewInvoiceRouteRoute: typeof BakContractsSubscriptionIdNewInvoiceRouteRoute
  BakContractsSubscriptionIdStatusRouteRoute: typeof BakContractsSubscriptionIdStatusRouteRoute
  BakPendingSubscriptionsSubscriptionIdHistoryRouteRoute: typeof BakPendingSubscriptionsSubscriptionIdHistoryRouteRoute
  BakPendingSubscriptionsSubscriptionIdPaymentRouteRoute: typeof BakPendingSubscriptionsSubscriptionIdPaymentRouteRoute
  BakTroubleshootingSessionSessionIdRouteRoute: typeof BakTroubleshootingSessionSessionIdRouteRoute
  BakV2PendingSubscriptionsSubscriptionIdDetailedSheetRouteRoute: typeof BakV2PendingSubscriptionsSubscriptionIdDetailedSheetRouteRoute
  BakContractsSubscriptionIdIndexRoute: typeof BakContractsSubscriptionIdIndexRoute
  BakV2PendingSubscriptionsSubscriptionIdIndexRoute: typeof BakV2PendingSubscriptionsSubscriptionIdIndexRoute
  BakContractsSubscriptionIdRenewalEndorsementIdRouteRoute: typeof BakContractsSubscriptionIdRenewalEndorsementIdRouteRoute
  BakContractsSubscriptionIdDetailedSheetVersionsVersionIdRouteRoute: typeof BakContractsSubscriptionIdDetailedSheetVersionsVersionIdRouteRoute
  BakContractsSubscriptionIdEndorsementEndorsementIdDetailedSheetRouteRoute: typeof BakContractsSubscriptionIdEndorsementEndorsementIdDetailedSheetRouteRoute
  BakContractsSubscriptionIdEndorsementEndorsementIdIndexRoute: typeof BakContractsSubscriptionIdEndorsementEndorsementIdIndexRoute
}

const BakRouteChildren: BakRouteChildren = {
  BakActivitiesRouteRoute: BakActivitiesRouteRoute,
  BakEphemeralJobsRouteRoute: BakEphemeralJobsRouteRoute,
  BakHomeRouteRoute: BakHomeRouteRoute,
  BakMembershipsRouteRoute: BakMembershipsRouteRoute,
  BakMigrationRouteRoute: BakMigrationRouteRoute,
  BakNewSubscriptionRouteRoute: BakNewSubscriptionRouteRoute,
  BakOrganizationsRouteRoute: BakOrganizationsRouteRoute,
  BakSearchRouteRoute: BakSearchRouteRoute,
  BakTrackingDocRouteRoute: BakTrackingDocRouteRoute,
  BakAdminCpmsRouteRoute: BakAdminCpmsRouteRoute,
  BakAdminImaRouteRoute: BakAdminImaRouteRoute,
  BakAdminJobsRouteRoute: BakAdminJobsRouteRoute,
  BakDocumentationActivitySpecificQuestionsRouteRoute:
    BakDocumentationActivitySpecificQuestionsRouteRoute,
  BakDocumentationExclusionsRouteRoute: BakDocumentationExclusionsRouteRoute,
  BakDocumentationGeneralTermsRouteRoute:
    BakDocumentationGeneralTermsRouteRoute,
  BakDocumentationRolesRouteRoute: BakDocumentationRolesRouteRoute,
  BakDocumentationSubscriptionFunnelTextsRouteRoute:
    BakDocumentationSubscriptionFunnelTextsRouteRoute,
  BakInvoicingNegativeInvoicesListRouteRoute:
    BakInvoicingNegativeInvoicesListRouteRoute,
  BakUsersUserIdRouteRoute: BakUsersUserIdRouteRoute,
  BakAdminIndexRoute: BakAdminIndexRoute,
  BakDocumentationIndexRoute: BakDocumentationIndexRoute,
  BakInvoicingIndexRoute: BakInvoicingIndexRoute,
  BakPendingSubscriptionsIndexRoute: BakPendingSubscriptionsIndexRoute,
  BakUsersIndexRoute: BakUsersIndexRoute,
  BakContractsSubscriptionIdInvoicingRouteRoute:
    BakContractsSubscriptionIdInvoicingRouteRoute,
  BakContractsSubscriptionIdNewInvoiceRouteRoute:
    BakContractsSubscriptionIdNewInvoiceRouteRoute,
  BakContractsSubscriptionIdStatusRouteRoute:
    BakContractsSubscriptionIdStatusRouteRoute,
  BakPendingSubscriptionsSubscriptionIdHistoryRouteRoute:
    BakPendingSubscriptionsSubscriptionIdHistoryRouteRoute,
  BakPendingSubscriptionsSubscriptionIdPaymentRouteRoute:
    BakPendingSubscriptionsSubscriptionIdPaymentRouteRoute,
  BakTroubleshootingSessionSessionIdRouteRoute:
    BakTroubleshootingSessionSessionIdRouteRoute,
  BakV2PendingSubscriptionsSubscriptionIdDetailedSheetRouteRoute:
    BakV2PendingSubscriptionsSubscriptionIdDetailedSheetRouteRoute,
  BakContractsSubscriptionIdIndexRoute: BakContractsSubscriptionIdIndexRoute,
  BakV2PendingSubscriptionsSubscriptionIdIndexRoute:
    BakV2PendingSubscriptionsSubscriptionIdIndexRoute,
  BakContractsSubscriptionIdRenewalEndorsementIdRouteRoute:
    BakContractsSubscriptionIdRenewalEndorsementIdRouteRoute,
  BakContractsSubscriptionIdDetailedSheetVersionsVersionIdRouteRoute:
    BakContractsSubscriptionIdDetailedSheetVersionsVersionIdRouteRoute,
  BakContractsSubscriptionIdEndorsementEndorsementIdDetailedSheetRouteRoute:
    BakContractsSubscriptionIdEndorsementEndorsementIdDetailedSheetRouteRoute,
  BakContractsSubscriptionIdEndorsementEndorsementIdIndexRoute:
    BakContractsSubscriptionIdEndorsementEndorsementIdIndexRoute,
}

const BakRouteWithChildren = BakRoute._addFileChildren(BakRouteChildren)

interface PartnerOrganizationRouteChildren {
  PartnerOrganizationHomeRouteRoute: typeof PartnerOrganizationHomeRouteRoute
  PartnerOrganizationNewSubscriptionRouteRoute: typeof PartnerOrganizationNewSubscriptionRouteRoute
  PartnerOrganizationSearchRouteRoute: typeof PartnerOrganizationSearchRouteRoute
  PartnerOrganizationContractsSubscriptionIdRouteRoute: typeof PartnerOrganizationContractsSubscriptionIdRouteRoute
  PartnerOrganizationUsersUserIdRouteRoute: typeof PartnerOrganizationUsersUserIdRouteRoute
  PartnerOrganizationV2PendingSubscriptionsSubscriptionIdRouteRoute: typeof PartnerOrganizationV2PendingSubscriptionsSubscriptionIdRouteRoute
  PartnerOrganizationPendingSubscriptionsIndexRoute: typeof PartnerOrganizationPendingSubscriptionsIndexRoute
  PartnerOrganizationUsersIndexRoute: typeof PartnerOrganizationUsersIndexRoute
  PartnerOrganizationPendingSubscriptionsSubscriptionIdPaymentRouteRoute: typeof PartnerOrganizationPendingSubscriptionsSubscriptionIdPaymentRouteRoute
}

const PartnerOrganizationRouteChildren: PartnerOrganizationRouteChildren = {
  PartnerOrganizationHomeRouteRoute: PartnerOrganizationHomeRouteRoute,
  PartnerOrganizationNewSubscriptionRouteRoute:
    PartnerOrganizationNewSubscriptionRouteRoute,
  PartnerOrganizationSearchRouteRoute: PartnerOrganizationSearchRouteRoute,
  PartnerOrganizationContractsSubscriptionIdRouteRoute:
    PartnerOrganizationContractsSubscriptionIdRouteRoute,
  PartnerOrganizationUsersUserIdRouteRoute:
    PartnerOrganizationUsersUserIdRouteRoute,
  PartnerOrganizationV2PendingSubscriptionsSubscriptionIdRouteRoute:
    PartnerOrganizationV2PendingSubscriptionsSubscriptionIdRouteRoute,
  PartnerOrganizationPendingSubscriptionsIndexRoute:
    PartnerOrganizationPendingSubscriptionsIndexRoute,
  PartnerOrganizationUsersIndexRoute: PartnerOrganizationUsersIndexRoute,
  PartnerOrganizationPendingSubscriptionsSubscriptionIdPaymentRouteRoute:
    PartnerOrganizationPendingSubscriptionsSubscriptionIdPaymentRouteRoute,
}

const PartnerOrganizationRouteWithChildren =
  PartnerOrganizationRoute._addFileChildren(PartnerOrganizationRouteChildren)

export interface FileRoutesByFullPath {
  '/': typeof IndexRoute
  '/impersonate': typeof ImpersonateRouteRoute
  '/login': typeof LoginRouteRoute
  '/payment-method-update': typeof PaymentMethodUpdateRouteRoute
  '/receive-quote': typeof ReceiveQuoteRouteRoute
  '/search': typeof SearchRouteRoute
  '/subscribe-no-possible-step': typeof SubscribeNoPossibleStepRouteRoute
  '/unsubscribe': typeof UnsubscribeRouteRoute
  '': typeof CustomerRouteWithChildren
  '/bak': typeof BakRouteWithChildren
  '/account': typeof CustomerAccountRouteRoute
  '/contract-guarantees': typeof CustomerContractGuaranteesRouteRoute
  '/contracts': typeof CustomerContractsRouteRoute
  '/home': typeof CustomerHomeRouteRoute
  '/invoices': typeof CustomerInvoicesRouteRoute
  '/report-claim': typeof CustomerReportClaimRouteRoute
  '/bak/activities': typeof BakActivitiesRouteRoute
  '/bak/ephemeral-jobs': typeof BakEphemeralJobsRouteRoute
  '/bak/home': typeof BakHomeRouteRoute
  '/bak/memberships': typeof BakMembershipsRouteRoute
  '/bak/migration': typeof BakMigrationRouteRoute
  '/bak/new-subscription': typeof BakNewSubscriptionRouteRoute
  '/bak/organizations': typeof BakOrganizationsRouteRoute
  '/bak/search': typeof BakSearchRouteRoute
  '/bak/tracking-doc': typeof BakTrackingDocRouteRoute
  '/payment/authentication': typeof PaymentAuthenticationRouteRoute
  '/subscribe-exclusion/$subscriptionId': typeof SubscribeExclusionSubscriptionIdRouteRoute
  '/subscribe/landing': typeof SubscribeLandingRouteRoute
  '/partner/$organization': typeof PartnerOrganizationRouteWithChildren
  '/contract/$subscriptionId': typeof CustomerContractSubscriptionIdRouteRoute
  '/health-insurance/beneficiaries': typeof CustomerHealthInsuranceBeneficiariesRouteRoute
  '/health-insurance/cpms': typeof CustomerHealthInsuranceCpmsRouteRoute
  '/health-insurance/santeclair': typeof CustomerHealthInsuranceSanteclairRouteRoute
  '/insurance-certificate-generator-real-estate-agents/$subscriptionId': typeof CustomerInsuranceCertificateGeneratorRealEstateAgentsSubscriptionIdRouteRoute
  '/insurance-certificate-generator-real-estate-sales-agents/$subscriptionId': typeof CustomerInsuranceCertificateGeneratorRealEstateSalesAgentsSubscriptionIdRouteRoute
  '/payment-methods/new': typeof CustomerPaymentMethodsNewRouteRoute
  '/payment-methods/update': typeof CustomerPaymentMethodsUpdateRouteRoute
  '/bak/admin/cpms': typeof BakAdminCpmsRouteRoute
  '/bak/admin/ima': typeof BakAdminImaRouteRoute
  '/bak/admin/jobs': typeof BakAdminJobsRouteRoute
  '/bak/documentation/activity-specific-questions': typeof BakDocumentationActivitySpecificQuestionsRouteRoute
  '/bak/documentation/exclusions': typeof BakDocumentationExclusionsRouteRoute
  '/bak/documentation/general-terms': typeof BakDocumentationGeneralTermsRouteRoute
  '/bak/documentation/roles': typeof BakDocumentationRolesRouteRoute
  '/bak/documentation/subscription-funnel-texts': typeof BakDocumentationSubscriptionFunnelTextsRouteRoute
  '/bak/invoicing/negative-invoices-list': typeof BakInvoicingNegativeInvoicesListRouteRoute
  '/bak/users/$userId': typeof BakUsersUserIdRouteRoute
  '/partner/$organization/home': typeof PartnerOrganizationHomeRouteRoute
  '/partner/$organization/new-subscription': typeof PartnerOrganizationNewSubscriptionRouteRoute
  '/partner/$organization/search': typeof PartnerOrganizationSearchRouteRoute
  '/s/c/$token': typeof SCTokenRouteRoute
  '/s/e-v2/$token': typeof SEV2TokenRouteRoute
  '/s/q/$token': typeof SQTokenRouteRoute
  '/subscribe/restaurants-v2/identify': typeof SubscribeRestaurantsV2IdentifyRouteRoute
  '/health-insurance': typeof CustomerHealthInsuranceIndexRoute
  '/bak/admin': typeof BakAdminIndexRoute
  '/bak/documentation': typeof BakDocumentationIndexRoute
  '/bak/invoicing': typeof BakInvoicingIndexRoute
  '/bak/pending-subscriptions': typeof BakPendingSubscriptionsIndexRoute
  '/bak/users': typeof BakUsersIndexRoute
  '/product-guarantees/$subscriptionId/$productName': typeof CustomerProductGuaranteesSubscriptionIdProductNameRouteRoute
  '/s/r/$token': typeof CustomerSRTokenRouteRoute
  '/bak/contracts/$subscriptionId/invoicing': typeof BakContractsSubscriptionIdInvoicingRouteRoute
  '/bak/contracts/$subscriptionId/new-invoice': typeof BakContractsSubscriptionIdNewInvoiceRouteRoute
  '/bak/contracts/$subscriptionId/status': typeof BakContractsSubscriptionIdStatusRouteRoute
  '/bak/pending-subscriptions/$subscriptionId/history': typeof BakPendingSubscriptionsSubscriptionIdHistoryRouteRoute
  '/bak/pending-subscriptions/$subscriptionId/payment': typeof BakPendingSubscriptionsSubscriptionIdPaymentRouteRoute
  '/bak/troubleshooting/session/$sessionId': typeof BakTroubleshootingSessionSessionIdRouteRoute
  '/bak/v2-pending-subscriptions/$subscriptionId/detailed-sheet': typeof BakV2PendingSubscriptionsSubscriptionIdDetailedSheetRouteRoute
  '/partner/$organization/contracts/$subscriptionId': typeof PartnerOrganizationContractsSubscriptionIdRouteRoute
  '/partner/$organization/users/$userId': typeof PartnerOrganizationUsersUserIdRouteRoute
  '/partner/$organization/v2-pending-subscriptions/$subscriptionId': typeof PartnerOrganizationV2PendingSubscriptionsSubscriptionIdRouteRoute
  '/bak/contracts/$subscriptionId': typeof BakContractsSubscriptionIdIndexRoute
  '/bak/v2-pending-subscriptions/$subscriptionId': typeof BakV2PendingSubscriptionsSubscriptionIdIndexRoute
  '/partner/$organization/pending-subscriptions': typeof PartnerOrganizationPendingSubscriptionsIndexRoute
  '/partner/$organization/users': typeof PartnerOrganizationUsersIndexRoute
  '/subscribe/$subscriptionId/$stepId': typeof SubscribeSubscriptionIdStepIdIndexRoute
  '/bak/contracts/$subscriptionId/renewal/$endorsementId': typeof BakContractsSubscriptionIdRenewalEndorsementIdRouteRoute
  '/partner/$organization/pending-subscriptions/$subscriptionId/payment': typeof PartnerOrganizationPendingSubscriptionsSubscriptionIdPaymentRouteRoute
  '/subscribe/$subscriptionId/$stepId/$detailType/$detailId': typeof SubscribeSubscriptionIdStepIdDetailTypeDetailIdRouteRoute
  '/bak/contracts/$subscriptionId/detailed-sheet/versions/$versionId': typeof BakContractsSubscriptionIdDetailedSheetVersionsVersionIdRouteRoute
  '/bak/contracts/$subscriptionId/endorsement/$endorsementId/detailed-sheet': typeof BakContractsSubscriptionIdEndorsementEndorsementIdDetailedSheetRouteRoute
  '/bak/contracts/$subscriptionId/endorsement/$endorsementId': typeof BakContractsSubscriptionIdEndorsementEndorsementIdIndexRoute
}

export interface FileRoutesByTo {
  '/': typeof IndexRoute
  '/impersonate': typeof ImpersonateRouteRoute
  '/login': typeof LoginRouteRoute
  '/payment-method-update': typeof PaymentMethodUpdateRouteRoute
  '/receive-quote': typeof ReceiveQuoteRouteRoute
  '/search': typeof SearchRouteRoute
  '/subscribe-no-possible-step': typeof SubscribeNoPossibleStepRouteRoute
  '/unsubscribe': typeof UnsubscribeRouteRoute
  '': typeof CustomerRouteWithChildren
  '/bak': typeof BakRouteWithChildren
  '/account': typeof CustomerAccountRouteRoute
  '/contract-guarantees': typeof CustomerContractGuaranteesRouteRoute
  '/contracts': typeof CustomerContractsRouteRoute
  '/home': typeof CustomerHomeRouteRoute
  '/invoices': typeof CustomerInvoicesRouteRoute
  '/report-claim': typeof CustomerReportClaimRouteRoute
  '/bak/activities': typeof BakActivitiesRouteRoute
  '/bak/ephemeral-jobs': typeof BakEphemeralJobsRouteRoute
  '/bak/home': typeof BakHomeRouteRoute
  '/bak/memberships': typeof BakMembershipsRouteRoute
  '/bak/migration': typeof BakMigrationRouteRoute
  '/bak/new-subscription': typeof BakNewSubscriptionRouteRoute
  '/bak/organizations': typeof BakOrganizationsRouteRoute
  '/bak/search': typeof BakSearchRouteRoute
  '/bak/tracking-doc': typeof BakTrackingDocRouteRoute
  '/payment/authentication': typeof PaymentAuthenticationRouteRoute
  '/subscribe-exclusion/$subscriptionId': typeof SubscribeExclusionSubscriptionIdRouteRoute
  '/subscribe/landing': typeof SubscribeLandingRouteRoute
  '/partner/$organization': typeof PartnerOrganizationRouteWithChildren
  '/contract/$subscriptionId': typeof CustomerContractSubscriptionIdRouteRoute
  '/health-insurance/beneficiaries': typeof CustomerHealthInsuranceBeneficiariesRouteRoute
  '/health-insurance/cpms': typeof CustomerHealthInsuranceCpmsRouteRoute
  '/health-insurance/santeclair': typeof CustomerHealthInsuranceSanteclairRouteRoute
  '/insurance-certificate-generator-real-estate-agents/$subscriptionId': typeof CustomerInsuranceCertificateGeneratorRealEstateAgentsSubscriptionIdRouteRoute
  '/insurance-certificate-generator-real-estate-sales-agents/$subscriptionId': typeof CustomerInsuranceCertificateGeneratorRealEstateSalesAgentsSubscriptionIdRouteRoute
  '/payment-methods/new': typeof CustomerPaymentMethodsNewRouteRoute
  '/payment-methods/update': typeof CustomerPaymentMethodsUpdateRouteRoute
  '/bak/admin/cpms': typeof BakAdminCpmsRouteRoute
  '/bak/admin/ima': typeof BakAdminImaRouteRoute
  '/bak/admin/jobs': typeof BakAdminJobsRouteRoute
  '/bak/documentation/activity-specific-questions': typeof BakDocumentationActivitySpecificQuestionsRouteRoute
  '/bak/documentation/exclusions': typeof BakDocumentationExclusionsRouteRoute
  '/bak/documentation/general-terms': typeof BakDocumentationGeneralTermsRouteRoute
  '/bak/documentation/roles': typeof BakDocumentationRolesRouteRoute
  '/bak/documentation/subscription-funnel-texts': typeof BakDocumentationSubscriptionFunnelTextsRouteRoute
  '/bak/invoicing/negative-invoices-list': typeof BakInvoicingNegativeInvoicesListRouteRoute
  '/bak/users/$userId': typeof BakUsersUserIdRouteRoute
  '/partner/$organization/home': typeof PartnerOrganizationHomeRouteRoute
  '/partner/$organization/new-subscription': typeof PartnerOrganizationNewSubscriptionRouteRoute
  '/partner/$organization/search': typeof PartnerOrganizationSearchRouteRoute
  '/s/c/$token': typeof SCTokenRouteRoute
  '/s/e-v2/$token': typeof SEV2TokenRouteRoute
  '/s/q/$token': typeof SQTokenRouteRoute
  '/subscribe/restaurants-v2/identify': typeof SubscribeRestaurantsV2IdentifyRouteRoute
  '/health-insurance': typeof CustomerHealthInsuranceIndexRoute
  '/bak/admin': typeof BakAdminIndexRoute
  '/bak/documentation': typeof BakDocumentationIndexRoute
  '/bak/invoicing': typeof BakInvoicingIndexRoute
  '/bak/pending-subscriptions': typeof BakPendingSubscriptionsIndexRoute
  '/bak/users': typeof BakUsersIndexRoute
  '/product-guarantees/$subscriptionId/$productName': typeof CustomerProductGuaranteesSubscriptionIdProductNameRouteRoute
  '/s/r/$token': typeof CustomerSRTokenRouteRoute
  '/bak/contracts/$subscriptionId/invoicing': typeof BakContractsSubscriptionIdInvoicingRouteRoute
  '/bak/contracts/$subscriptionId/new-invoice': typeof BakContractsSubscriptionIdNewInvoiceRouteRoute
  '/bak/contracts/$subscriptionId/status': typeof BakContractsSubscriptionIdStatusRouteRoute
  '/bak/pending-subscriptions/$subscriptionId/history': typeof BakPendingSubscriptionsSubscriptionIdHistoryRouteRoute
  '/bak/pending-subscriptions/$subscriptionId/payment': typeof BakPendingSubscriptionsSubscriptionIdPaymentRouteRoute
  '/bak/troubleshooting/session/$sessionId': typeof BakTroubleshootingSessionSessionIdRouteRoute
  '/bak/v2-pending-subscriptions/$subscriptionId/detailed-sheet': typeof BakV2PendingSubscriptionsSubscriptionIdDetailedSheetRouteRoute
  '/partner/$organization/contracts/$subscriptionId': typeof PartnerOrganizationContractsSubscriptionIdRouteRoute
  '/partner/$organization/users/$userId': typeof PartnerOrganizationUsersUserIdRouteRoute
  '/partner/$organization/v2-pending-subscriptions/$subscriptionId': typeof PartnerOrganizationV2PendingSubscriptionsSubscriptionIdRouteRoute
  '/bak/contracts/$subscriptionId': typeof BakContractsSubscriptionIdIndexRoute
  '/bak/v2-pending-subscriptions/$subscriptionId': typeof BakV2PendingSubscriptionsSubscriptionIdIndexRoute
  '/partner/$organization/pending-subscriptions': typeof PartnerOrganizationPendingSubscriptionsIndexRoute
  '/partner/$organization/users': typeof PartnerOrganizationUsersIndexRoute
  '/subscribe/$subscriptionId/$stepId': typeof SubscribeSubscriptionIdStepIdIndexRoute
  '/bak/contracts/$subscriptionId/renewal/$endorsementId': typeof BakContractsSubscriptionIdRenewalEndorsementIdRouteRoute
  '/partner/$organization/pending-subscriptions/$subscriptionId/payment': typeof PartnerOrganizationPendingSubscriptionsSubscriptionIdPaymentRouteRoute
  '/subscribe/$subscriptionId/$stepId/$detailType/$detailId': typeof SubscribeSubscriptionIdStepIdDetailTypeDetailIdRouteRoute
  '/bak/contracts/$subscriptionId/detailed-sheet/versions/$versionId': typeof BakContractsSubscriptionIdDetailedSheetVersionsVersionIdRouteRoute
  '/bak/contracts/$subscriptionId/endorsement/$endorsementId/detailed-sheet': typeof BakContractsSubscriptionIdEndorsementEndorsementIdDetailedSheetRouteRoute
  '/bak/contracts/$subscriptionId/endorsement/$endorsementId': typeof BakContractsSubscriptionIdEndorsementEndorsementIdIndexRoute
}

export interface FileRoutesById {
  __root__: typeof rootRoute
  '/': typeof IndexRoute
  '/impersonate': typeof ImpersonateRouteRoute
  '/login': typeof LoginRouteRoute
  '/payment-method-update': typeof PaymentMethodUpdateRouteRoute
  '/receive-quote': typeof ReceiveQuoteRouteRoute
  '/search': typeof SearchRouteRoute
  '/subscribe-no-possible-step': typeof SubscribeNoPossibleStepRouteRoute
  '/unsubscribe': typeof UnsubscribeRouteRoute
  '/_customer': typeof CustomerRouteWithChildren
  '/bak': typeof BakRouteWithChildren
  '/_customer/account': typeof CustomerAccountRouteRoute
  '/_customer/contract-guarantees': typeof CustomerContractGuaranteesRouteRoute
  '/_customer/contracts': typeof CustomerContractsRouteRoute
  '/_customer/home': typeof CustomerHomeRouteRoute
  '/_customer/invoices': typeof CustomerInvoicesRouteRoute
  '/_customer/report-claim': typeof CustomerReportClaimRouteRoute
  '/bak/activities': typeof BakActivitiesRouteRoute
  '/bak/ephemeral-jobs': typeof BakEphemeralJobsRouteRoute
  '/bak/home': typeof BakHomeRouteRoute
  '/bak/memberships': typeof BakMembershipsRouteRoute
  '/bak/migration': typeof BakMigrationRouteRoute
  '/bak/new-subscription': typeof BakNewSubscriptionRouteRoute
  '/bak/organizations': typeof BakOrganizationsRouteRoute
  '/bak/search': typeof BakSearchRouteRoute
  '/bak/tracking-doc': typeof BakTrackingDocRouteRoute
  '/payment/authentication': typeof PaymentAuthenticationRouteRoute
  '/subscribe-exclusion/$subscriptionId': typeof SubscribeExclusionSubscriptionIdRouteRoute
  '/subscribe/landing': typeof SubscribeLandingRouteRoute
  '/partner/$organization': typeof PartnerOrganizationRouteWithChildren
  '/_customer/contract/$subscriptionId': typeof CustomerContractSubscriptionIdRouteRoute
  '/_customer/health-insurance/beneficiaries': typeof CustomerHealthInsuranceBeneficiariesRouteRoute
  '/_customer/health-insurance/cpms': typeof CustomerHealthInsuranceCpmsRouteRoute
  '/_customer/health-insurance/santeclair': typeof CustomerHealthInsuranceSanteclairRouteRoute
  '/_customer/insurance-certificate-generator-real-estate-agents/$subscriptionId': typeof CustomerInsuranceCertificateGeneratorRealEstateAgentsSubscriptionIdRouteRoute
  '/_customer/insurance-certificate-generator-real-estate-sales-agents/$subscriptionId': typeof CustomerInsuranceCertificateGeneratorRealEstateSalesAgentsSubscriptionIdRouteRoute
  '/_customer/payment-methods/new': typeof CustomerPaymentMethodsNewRouteRoute
  '/_customer/payment-methods/update': typeof CustomerPaymentMethodsUpdateRouteRoute
  '/bak/admin/cpms': typeof BakAdminCpmsRouteRoute
  '/bak/admin/ima': typeof BakAdminImaRouteRoute
  '/bak/admin/jobs': typeof BakAdminJobsRouteRoute
  '/bak/documentation/activity-specific-questions': typeof BakDocumentationActivitySpecificQuestionsRouteRoute
  '/bak/documentation/exclusions': typeof BakDocumentationExclusionsRouteRoute
  '/bak/documentation/general-terms': typeof BakDocumentationGeneralTermsRouteRoute
  '/bak/documentation/roles': typeof BakDocumentationRolesRouteRoute
  '/bak/documentation/subscription-funnel-texts': typeof BakDocumentationSubscriptionFunnelTextsRouteRoute
  '/bak/invoicing/negative-invoices-list': typeof BakInvoicingNegativeInvoicesListRouteRoute
  '/bak/users/$userId': typeof BakUsersUserIdRouteRoute
  '/partner/$organization/home': typeof PartnerOrganizationHomeRouteRoute
  '/partner/$organization/new-subscription': typeof PartnerOrganizationNewSubscriptionRouteRoute
  '/partner/$organization/search': typeof PartnerOrganizationSearchRouteRoute
  '/s/c/$token': typeof SCTokenRouteRoute
  '/s/e-v2/$token': typeof SEV2TokenRouteRoute
  '/s/q/$token': typeof SQTokenRouteRoute
  '/subscribe/restaurants-v2/identify': typeof SubscribeRestaurantsV2IdentifyRouteRoute
  '/_customer/health-insurance/': typeof CustomerHealthInsuranceIndexRoute
  '/bak/admin/': typeof BakAdminIndexRoute
  '/bak/documentation/': typeof BakDocumentationIndexRoute
  '/bak/invoicing/': typeof BakInvoicingIndexRoute
  '/bak/pending-subscriptions/': typeof BakPendingSubscriptionsIndexRoute
  '/bak/users/': typeof BakUsersIndexRoute
  '/_customer/product-guarantees/$subscriptionId/$productName': typeof CustomerProductGuaranteesSubscriptionIdProductNameRouteRoute
  '/_customer/s/r/$token': typeof CustomerSRTokenRouteRoute
  '/bak/contracts/$subscriptionId/invoicing': typeof BakContractsSubscriptionIdInvoicingRouteRoute
  '/bak/contracts/$subscriptionId/new-invoice': typeof BakContractsSubscriptionIdNewInvoiceRouteRoute
  '/bak/contracts/$subscriptionId/status': typeof BakContractsSubscriptionIdStatusRouteRoute
  '/bak/pending-subscriptions/$subscriptionId/history': typeof BakPendingSubscriptionsSubscriptionIdHistoryRouteRoute
  '/bak/pending-subscriptions/$subscriptionId/payment': typeof BakPendingSubscriptionsSubscriptionIdPaymentRouteRoute
  '/bak/troubleshooting/session/$sessionId': typeof BakTroubleshootingSessionSessionIdRouteRoute
  '/bak/v2-pending-subscriptions/$subscriptionId/detailed-sheet': typeof BakV2PendingSubscriptionsSubscriptionIdDetailedSheetRouteRoute
  '/partner/$organization/contracts/$subscriptionId': typeof PartnerOrganizationContractsSubscriptionIdRouteRoute
  '/partner/$organization/users/$userId': typeof PartnerOrganizationUsersUserIdRouteRoute
  '/partner/$organization/v2-pending-subscriptions/$subscriptionId': typeof PartnerOrganizationV2PendingSubscriptionsSubscriptionIdRouteRoute
  '/bak/contracts/$subscriptionId/': typeof BakContractsSubscriptionIdIndexRoute
  '/bak/v2-pending-subscriptions/$subscriptionId/': typeof BakV2PendingSubscriptionsSubscriptionIdIndexRoute
  '/partner/$organization/pending-subscriptions/': typeof PartnerOrganizationPendingSubscriptionsIndexRoute
  '/partner/$organization/users/': typeof PartnerOrganizationUsersIndexRoute
  '/subscribe/$subscriptionId/$stepId/': typeof SubscribeSubscriptionIdStepIdIndexRoute
  '/bak/contracts/$subscriptionId/renewal/$endorsementId': typeof BakContractsSubscriptionIdRenewalEndorsementIdRouteRoute
  '/partner/$organization/pending-subscriptions/$subscriptionId/payment': typeof PartnerOrganizationPendingSubscriptionsSubscriptionIdPaymentRouteRoute
  '/subscribe/$subscriptionId/$stepId/$detailType/$detailId': typeof SubscribeSubscriptionIdStepIdDetailTypeDetailIdRouteRoute
  '/bak/contracts/$subscriptionId/detailed-sheet/versions/$versionId': typeof BakContractsSubscriptionIdDetailedSheetVersionsVersionIdRouteRoute
  '/bak/contracts/$subscriptionId/endorsement/$endorsementId/detailed-sheet': typeof BakContractsSubscriptionIdEndorsementEndorsementIdDetailedSheetRouteRoute
  '/bak/contracts/$subscriptionId/endorsement/$endorsementId/': typeof BakContractsSubscriptionIdEndorsementEndorsementIdIndexRoute
}

export interface FileRouteTypes {
  fileRoutesByFullPath: FileRoutesByFullPath
  fullPaths:
    | '/'
    | '/impersonate'
    | '/login'
    | '/payment-method-update'
    | '/receive-quote'
    | '/search'
    | '/subscribe-no-possible-step'
    | '/unsubscribe'
    | ''
    | '/bak'
    | '/account'
    | '/contract-guarantees'
    | '/contracts'
    | '/home'
    | '/invoices'
    | '/report-claim'
    | '/bak/activities'
    | '/bak/ephemeral-jobs'
    | '/bak/home'
    | '/bak/memberships'
    | '/bak/migration'
    | '/bak/new-subscription'
    | '/bak/organizations'
    | '/bak/search'
    | '/bak/tracking-doc'
    | '/payment/authentication'
    | '/subscribe-exclusion/$subscriptionId'
    | '/subscribe/landing'
    | '/partner/$organization'
    | '/contract/$subscriptionId'
    | '/health-insurance/beneficiaries'
    | '/health-insurance/cpms'
    | '/health-insurance/santeclair'
    | '/insurance-certificate-generator-real-estate-agents/$subscriptionId'
    | '/insurance-certificate-generator-real-estate-sales-agents/$subscriptionId'
    | '/payment-methods/new'
    | '/payment-methods/update'
    | '/bak/admin/cpms'
    | '/bak/admin/ima'
    | '/bak/admin/jobs'
    | '/bak/documentation/activity-specific-questions'
    | '/bak/documentation/exclusions'
    | '/bak/documentation/general-terms'
    | '/bak/documentation/roles'
    | '/bak/documentation/subscription-funnel-texts'
    | '/bak/invoicing/negative-invoices-list'
    | '/bak/users/$userId'
    | '/partner/$organization/home'
    | '/partner/$organization/new-subscription'
    | '/partner/$organization/search'
    | '/s/c/$token'
    | '/s/e-v2/$token'
    | '/s/q/$token'
    | '/subscribe/restaurants-v2/identify'
    | '/health-insurance'
    | '/bak/admin'
    | '/bak/documentation'
    | '/bak/invoicing'
    | '/bak/pending-subscriptions'
    | '/bak/users'
    | '/product-guarantees/$subscriptionId/$productName'
    | '/s/r/$token'
    | '/bak/contracts/$subscriptionId/invoicing'
    | '/bak/contracts/$subscriptionId/new-invoice'
    | '/bak/contracts/$subscriptionId/status'
    | '/bak/pending-subscriptions/$subscriptionId/history'
    | '/bak/pending-subscriptions/$subscriptionId/payment'
    | '/bak/troubleshooting/session/$sessionId'
    | '/bak/v2-pending-subscriptions/$subscriptionId/detailed-sheet'
    | '/partner/$organization/contracts/$subscriptionId'
    | '/partner/$organization/users/$userId'
    | '/partner/$organization/v2-pending-subscriptions/$subscriptionId'
    | '/bak/contracts/$subscriptionId'
    | '/bak/v2-pending-subscriptions/$subscriptionId'
    | '/partner/$organization/pending-subscriptions'
    | '/partner/$organization/users'
    | '/subscribe/$subscriptionId/$stepId'
    | '/bak/contracts/$subscriptionId/renewal/$endorsementId'
    | '/partner/$organization/pending-subscriptions/$subscriptionId/payment'
    | '/subscribe/$subscriptionId/$stepId/$detailType/$detailId'
    | '/bak/contracts/$subscriptionId/detailed-sheet/versions/$versionId'
    | '/bak/contracts/$subscriptionId/endorsement/$endorsementId/detailed-sheet'
    | '/bak/contracts/$subscriptionId/endorsement/$endorsementId'
  fileRoutesByTo: FileRoutesByTo
  to:
    | '/'
    | '/impersonate'
    | '/login'
    | '/payment-method-update'
    | '/receive-quote'
    | '/search'
    | '/subscribe-no-possible-step'
    | '/unsubscribe'
    | ''
    | '/bak'
    | '/account'
    | '/contract-guarantees'
    | '/contracts'
    | '/home'
    | '/invoices'
    | '/report-claim'
    | '/bak/activities'
    | '/bak/ephemeral-jobs'
    | '/bak/home'
    | '/bak/memberships'
    | '/bak/migration'
    | '/bak/new-subscription'
    | '/bak/organizations'
    | '/bak/search'
    | '/bak/tracking-doc'
    | '/payment/authentication'
    | '/subscribe-exclusion/$subscriptionId'
    | '/subscribe/landing'
    | '/partner/$organization'
    | '/contract/$subscriptionId'
    | '/health-insurance/beneficiaries'
    | '/health-insurance/cpms'
    | '/health-insurance/santeclair'
    | '/insurance-certificate-generator-real-estate-agents/$subscriptionId'
    | '/insurance-certificate-generator-real-estate-sales-agents/$subscriptionId'
    | '/payment-methods/new'
    | '/payment-methods/update'
    | '/bak/admin/cpms'
    | '/bak/admin/ima'
    | '/bak/admin/jobs'
    | '/bak/documentation/activity-specific-questions'
    | '/bak/documentation/exclusions'
    | '/bak/documentation/general-terms'
    | '/bak/documentation/roles'
    | '/bak/documentation/subscription-funnel-texts'
    | '/bak/invoicing/negative-invoices-list'
    | '/bak/users/$userId'
    | '/partner/$organization/home'
    | '/partner/$organization/new-subscription'
    | '/partner/$organization/search'
    | '/s/c/$token'
    | '/s/e-v2/$token'
    | '/s/q/$token'
    | '/subscribe/restaurants-v2/identify'
    | '/health-insurance'
    | '/bak/admin'
    | '/bak/documentation'
    | '/bak/invoicing'
    | '/bak/pending-subscriptions'
    | '/bak/users'
    | '/product-guarantees/$subscriptionId/$productName'
    | '/s/r/$token'
    | '/bak/contracts/$subscriptionId/invoicing'
    | '/bak/contracts/$subscriptionId/new-invoice'
    | '/bak/contracts/$subscriptionId/status'
    | '/bak/pending-subscriptions/$subscriptionId/history'
    | '/bak/pending-subscriptions/$subscriptionId/payment'
    | '/bak/troubleshooting/session/$sessionId'
    | '/bak/v2-pending-subscriptions/$subscriptionId/detailed-sheet'
    | '/partner/$organization/contracts/$subscriptionId'
    | '/partner/$organization/users/$userId'
    | '/partner/$organization/v2-pending-subscriptions/$subscriptionId'
    | '/bak/contracts/$subscriptionId'
    | '/bak/v2-pending-subscriptions/$subscriptionId'
    | '/partner/$organization/pending-subscriptions'
    | '/partner/$organization/users'
    | '/subscribe/$subscriptionId/$stepId'
    | '/bak/contracts/$subscriptionId/renewal/$endorsementId'
    | '/partner/$organization/pending-subscriptions/$subscriptionId/payment'
    | '/subscribe/$subscriptionId/$stepId/$detailType/$detailId'
    | '/bak/contracts/$subscriptionId/detailed-sheet/versions/$versionId'
    | '/bak/contracts/$subscriptionId/endorsement/$endorsementId/detailed-sheet'
    | '/bak/contracts/$subscriptionId/endorsement/$endorsementId'
  id:
    | '__root__'
    | '/'
    | '/impersonate'
    | '/login'
    | '/payment-method-update'
    | '/receive-quote'
    | '/search'
    | '/subscribe-no-possible-step'
    | '/unsubscribe'
    | '/_customer'
    | '/bak'
    | '/_customer/account'
    | '/_customer/contract-guarantees'
    | '/_customer/contracts'
    | '/_customer/home'
    | '/_customer/invoices'
    | '/_customer/report-claim'
    | '/bak/activities'
    | '/bak/ephemeral-jobs'
    | '/bak/home'
    | '/bak/memberships'
    | '/bak/migration'
    | '/bak/new-subscription'
    | '/bak/organizations'
    | '/bak/search'
    | '/bak/tracking-doc'
    | '/payment/authentication'
    | '/subscribe-exclusion/$subscriptionId'
    | '/subscribe/landing'
    | '/partner/$organization'
    | '/_customer/contract/$subscriptionId'
    | '/_customer/health-insurance/beneficiaries'
    | '/_customer/health-insurance/cpms'
    | '/_customer/health-insurance/santeclair'
    | '/_customer/insurance-certificate-generator-real-estate-agents/$subscriptionId'
    | '/_customer/insurance-certificate-generator-real-estate-sales-agents/$subscriptionId'
    | '/_customer/payment-methods/new'
    | '/_customer/payment-methods/update'
    | '/bak/admin/cpms'
    | '/bak/admin/ima'
    | '/bak/admin/jobs'
    | '/bak/documentation/activity-specific-questions'
    | '/bak/documentation/exclusions'
    | '/bak/documentation/general-terms'
    | '/bak/documentation/roles'
    | '/bak/documentation/subscription-funnel-texts'
    | '/bak/invoicing/negative-invoices-list'
    | '/bak/users/$userId'
    | '/partner/$organization/home'
    | '/partner/$organization/new-subscription'
    | '/partner/$organization/search'
    | '/s/c/$token'
    | '/s/e-v2/$token'
    | '/s/q/$token'
    | '/subscribe/restaurants-v2/identify'
    | '/_customer/health-insurance/'
    | '/bak/admin/'
    | '/bak/documentation/'
    | '/bak/invoicing/'
    | '/bak/pending-subscriptions/'
    | '/bak/users/'
    | '/_customer/product-guarantees/$subscriptionId/$productName'
    | '/_customer/s/r/$token'
    | '/bak/contracts/$subscriptionId/invoicing'
    | '/bak/contracts/$subscriptionId/new-invoice'
    | '/bak/contracts/$subscriptionId/status'
    | '/bak/pending-subscriptions/$subscriptionId/history'
    | '/bak/pending-subscriptions/$subscriptionId/payment'
    | '/bak/troubleshooting/session/$sessionId'
    | '/bak/v2-pending-subscriptions/$subscriptionId/detailed-sheet'
    | '/partner/$organization/contracts/$subscriptionId'
    | '/partner/$organization/users/$userId'
    | '/partner/$organization/v2-pending-subscriptions/$subscriptionId'
    | '/bak/contracts/$subscriptionId/'
    | '/bak/v2-pending-subscriptions/$subscriptionId/'
    | '/partner/$organization/pending-subscriptions/'
    | '/partner/$organization/users/'
    | '/subscribe/$subscriptionId/$stepId/'
    | '/bak/contracts/$subscriptionId/renewal/$endorsementId'
    | '/partner/$organization/pending-subscriptions/$subscriptionId/payment'
    | '/subscribe/$subscriptionId/$stepId/$detailType/$detailId'
    | '/bak/contracts/$subscriptionId/detailed-sheet/versions/$versionId'
    | '/bak/contracts/$subscriptionId/endorsement/$endorsementId/detailed-sheet'
    | '/bak/contracts/$subscriptionId/endorsement/$endorsementId/'
  fileRoutesById: FileRoutesById
}

export interface RootRouteChildren {
  IndexRoute: typeof IndexRoute
  ImpersonateRouteRoute: typeof ImpersonateRouteRoute
  LoginRouteRoute: typeof LoginRouteRoute
  PaymentMethodUpdateRouteRoute: typeof PaymentMethodUpdateRouteRoute
  ReceiveQuoteRouteRoute: typeof ReceiveQuoteRouteRoute
  SearchRouteRoute: typeof SearchRouteRoute
  SubscribeNoPossibleStepRouteRoute: typeof SubscribeNoPossibleStepRouteRoute
  UnsubscribeRouteRoute: typeof UnsubscribeRouteRoute
  CustomerRoute: typeof CustomerRouteWithChildren
  BakRoute: typeof BakRouteWithChildren
  PaymentAuthenticationRouteRoute: typeof PaymentAuthenticationRouteRoute
  SubscribeExclusionSubscriptionIdRouteRoute: typeof SubscribeExclusionSubscriptionIdRouteRoute
  SubscribeLandingRouteRoute: typeof SubscribeLandingRouteRoute
  PartnerOrganizationRoute: typeof PartnerOrganizationRouteWithChildren
  SCTokenRouteRoute: typeof SCTokenRouteRoute
  SEV2TokenRouteRoute: typeof SEV2TokenRouteRoute
  SQTokenRouteRoute: typeof SQTokenRouteRoute
  SubscribeRestaurantsV2IdentifyRouteRoute: typeof SubscribeRestaurantsV2IdentifyRouteRoute
  SubscribeSubscriptionIdStepIdIndexRoute: typeof SubscribeSubscriptionIdStepIdIndexRoute
  SubscribeSubscriptionIdStepIdDetailTypeDetailIdRouteRoute: typeof SubscribeSubscriptionIdStepIdDetailTypeDetailIdRouteRoute
}

const rootRouteChildren: RootRouteChildren = {
  IndexRoute: IndexRoute,
  ImpersonateRouteRoute: ImpersonateRouteRoute,
  LoginRouteRoute: LoginRouteRoute,
  PaymentMethodUpdateRouteRoute: PaymentMethodUpdateRouteRoute,
  ReceiveQuoteRouteRoute: ReceiveQuoteRouteRoute,
  SearchRouteRoute: SearchRouteRoute,
  SubscribeNoPossibleStepRouteRoute: SubscribeNoPossibleStepRouteRoute,
  UnsubscribeRouteRoute: UnsubscribeRouteRoute,
  CustomerRoute: CustomerRouteWithChildren,
  BakRoute: BakRouteWithChildren,
  PaymentAuthenticationRouteRoute: PaymentAuthenticationRouteRoute,
  SubscribeExclusionSubscriptionIdRouteRoute:
    SubscribeExclusionSubscriptionIdRouteRoute,
  SubscribeLandingRouteRoute: SubscribeLandingRouteRoute,
  PartnerOrganizationRoute: PartnerOrganizationRouteWithChildren,
  SCTokenRouteRoute: SCTokenRouteRoute,
  SEV2TokenRouteRoute: SEV2TokenRouteRoute,
  SQTokenRouteRoute: SQTokenRouteRoute,
  SubscribeRestaurantsV2IdentifyRouteRoute:
    SubscribeRestaurantsV2IdentifyRouteRoute,
  SubscribeSubscriptionIdStepIdIndexRoute:
    SubscribeSubscriptionIdStepIdIndexRoute,
  SubscribeSubscriptionIdStepIdDetailTypeDetailIdRouteRoute:
    SubscribeSubscriptionIdStepIdDetailTypeDetailIdRouteRoute,
}

export const routeTree = rootRoute
  ._addFileChildren(rootRouteChildren)
  ._addFileTypes<FileRouteTypes>()

/* ROUTE_MANIFEST_START
{
  "routes": {
    "__root__": {
      "filePath": "__root.tsx",
      "children": [
        "/",
        "/impersonate",
        "/login",
        "/payment-method-update",
        "/receive-quote",
        "/search",
        "/subscribe-no-possible-step",
        "/unsubscribe",
        "/_customer",
        "/bak",
        "/payment/authentication",
        "/subscribe-exclusion/$subscriptionId",
        "/subscribe/landing",
        "/partner/$organization",
        "/s/c/$token",
        "/s/e-v2/$token",
        "/s/q/$token",
        "/subscribe/restaurants-v2/identify",
        "/subscribe/$subscriptionId/$stepId/",
        "/subscribe/$subscriptionId/$stepId/$detailType/$detailId"
      ]
    },
    "/": {
      "filePath": "index.tsx"
    },
    "/impersonate": {
      "filePath": "impersonate/route.tsx"
    },
    "/login": {
      "filePath": "login/route.tsx"
    },
    "/payment-method-update": {
      "filePath": "payment-method-update/route.tsx"
    },
    "/receive-quote": {
      "filePath": "receive-quote/route.tsx"
    },
    "/search": {
      "filePath": "search/route.tsx"
    },
    "/subscribe-no-possible-step": {
      "filePath": "subscribe-no-possible-step/route.tsx"
    },
    "/unsubscribe": {
      "filePath": "unsubscribe/route.tsx"
    },
    "/_customer": {
      "filePath": "_customer.tsx",
      "children": [
        "/_customer/account",
        "/_customer/contract-guarantees",
        "/_customer/contracts",
        "/_customer/home",
        "/_customer/invoices",
        "/_customer/report-claim",
        "/_customer/contract/$subscriptionId",
        "/_customer/health-insurance/beneficiaries",
        "/_customer/health-insurance/cpms",
        "/_customer/health-insurance/santeclair",
        "/_customer/insurance-certificate-generator-real-estate-agents/$subscriptionId",
        "/_customer/insurance-certificate-generator-real-estate-sales-agents/$subscriptionId",
        "/_customer/payment-methods/new",
        "/_customer/payment-methods/update",
        "/_customer/health-insurance/",
        "/_customer/product-guarantees/$subscriptionId/$productName",
        "/_customer/s/r/$token"
      ]
    },
    "/bak": {
      "filePath": "bak.tsx",
      "children": [
        "/bak/activities",
        "/bak/ephemeral-jobs",
        "/bak/home",
        "/bak/memberships",
        "/bak/migration",
        "/bak/new-subscription",
        "/bak/organizations",
        "/bak/search",
        "/bak/tracking-doc",
        "/bak/admin/cpms",
        "/bak/admin/ima",
        "/bak/admin/jobs",
        "/bak/documentation/activity-specific-questions",
        "/bak/documentation/exclusions",
        "/bak/documentation/general-terms",
        "/bak/documentation/roles",
        "/bak/documentation/subscription-funnel-texts",
        "/bak/invoicing/negative-invoices-list",
        "/bak/users/$userId",
        "/bak/admin/",
        "/bak/documentation/",
        "/bak/invoicing/",
        "/bak/pending-subscriptions/",
        "/bak/users/",
        "/bak/contracts/$subscriptionId/invoicing",
        "/bak/contracts/$subscriptionId/new-invoice",
        "/bak/contracts/$subscriptionId/status",
        "/bak/pending-subscriptions/$subscriptionId/history",
        "/bak/pending-subscriptions/$subscriptionId/payment",
        "/bak/troubleshooting/session/$sessionId",
        "/bak/v2-pending-subscriptions/$subscriptionId/detailed-sheet",
        "/bak/contracts/$subscriptionId/",
        "/bak/v2-pending-subscriptions/$subscriptionId/",
        "/bak/contracts/$subscriptionId/renewal/$endorsementId",
        "/bak/contracts/$subscriptionId/detailed-sheet/versions/$versionId",
        "/bak/contracts/$subscriptionId/endorsement/$endorsementId/detailed-sheet",
        "/bak/contracts/$subscriptionId/endorsement/$endorsementId/"
      ]
    },
    "/_customer/account": {
      "filePath": "_customer/account/route.tsx",
      "parent": "/_customer"
    },
    "/_customer/contract-guarantees": {
      "filePath": "_customer/contract-guarantees/route.tsx",
      "parent": "/_customer"
    },
    "/_customer/contracts": {
      "filePath": "_customer/contracts/route.tsx",
      "parent": "/_customer"
    },
    "/_customer/home": {
      "filePath": "_customer/home/route.tsx",
      "parent": "/_customer"
    },
    "/_customer/invoices": {
      "filePath": "_customer/invoices/route.tsx",
      "parent": "/_customer"
    },
    "/_customer/report-claim": {
      "filePath": "_customer/report-claim/route.tsx",
      "parent": "/_customer"
    },
    "/bak/activities": {
      "filePath": "bak/activities/route.tsx",
      "parent": "/bak"
    },
    "/bak/ephemeral-jobs": {
      "filePath": "bak/ephemeral-jobs/route.tsx",
      "parent": "/bak"
    },
    "/bak/home": {
      "filePath": "bak/home/route.tsx",
      "parent": "/bak"
    },
    "/bak/memberships": {
      "filePath": "bak/memberships/route.tsx",
      "parent": "/bak"
    },
    "/bak/migration": {
      "filePath": "bak/migration/route.tsx",
      "parent": "/bak"
    },
    "/bak/new-subscription": {
      "filePath": "bak/new-subscription/route.tsx",
      "parent": "/bak"
    },
    "/bak/organizations": {
      "filePath": "bak/organizations/route.tsx",
      "parent": "/bak"
    },
    "/bak/search": {
      "filePath": "bak/search/route.tsx",
      "parent": "/bak"
    },
    "/bak/tracking-doc": {
      "filePath": "bak/tracking-doc/route.tsx",
      "parent": "/bak"
    },
    "/payment/authentication": {
      "filePath": "payment/authentication/route.tsx"
    },
    "/subscribe-exclusion/$subscriptionId": {
      "filePath": "subscribe-exclusion/$subscriptionId/route.tsx"
    },
    "/subscribe/landing": {
      "filePath": "subscribe/landing/route.tsx"
    },
    "/partner/$organization": {
      "filePath": "partner/$organization.tsx",
      "children": [
        "/partner/$organization/home",
        "/partner/$organization/new-subscription",
        "/partner/$organization/search",
        "/partner/$organization/contracts/$subscriptionId",
        "/partner/$organization/users/$userId",
        "/partner/$organization/v2-pending-subscriptions/$subscriptionId",
        "/partner/$organization/pending-subscriptions/",
        "/partner/$organization/users/",
        "/partner/$organization/pending-subscriptions/$subscriptionId/payment"
      ]
    },
    "/_customer/contract/$subscriptionId": {
      "filePath": "_customer/contract/$subscriptionId/route.tsx",
      "parent": "/_customer"
    },
    "/_customer/health-insurance/beneficiaries": {
      "filePath": "_customer/health-insurance/beneficiaries/route.tsx",
      "parent": "/_customer"
    },
    "/_customer/health-insurance/cpms": {
      "filePath": "_customer/health-insurance/cpms/route.tsx",
      "parent": "/_customer"
    },
    "/_customer/health-insurance/santeclair": {
      "filePath": "_customer/health-insurance/santeclair/route.tsx",
      "parent": "/_customer"
    },
    "/_customer/insurance-certificate-generator-real-estate-agents/$subscriptionId": {
      "filePath": "_customer/insurance-certificate-generator-real-estate-agents/$subscriptionId/route.tsx",
      "parent": "/_customer"
    },
    "/_customer/insurance-certificate-generator-real-estate-sales-agents/$subscriptionId": {
      "filePath": "_customer/insurance-certificate-generator-real-estate-sales-agents/$subscriptionId/route.tsx",
      "parent": "/_customer"
    },
    "/_customer/payment-methods/new": {
      "filePath": "_customer/payment-methods/new/route.tsx",
      "parent": "/_customer"
    },
    "/_customer/payment-methods/update": {
      "filePath": "_customer/payment-methods/update/route.tsx",
      "parent": "/_customer"
    },
    "/bak/admin/cpms": {
      "filePath": "bak/admin/cpms/route.tsx",
      "parent": "/bak"
    },
    "/bak/admin/ima": {
      "filePath": "bak/admin/ima/route.tsx",
      "parent": "/bak"
    },
    "/bak/admin/jobs": {
      "filePath": "bak/admin/jobs/route.tsx",
      "parent": "/bak"
    },
    "/bak/documentation/activity-specific-questions": {
      "filePath": "bak/documentation/activity-specific-questions/route.tsx",
      "parent": "/bak"
    },
    "/bak/documentation/exclusions": {
      "filePath": "bak/documentation/exclusions/route.tsx",
      "parent": "/bak"
    },
    "/bak/documentation/general-terms": {
      "filePath": "bak/documentation/general-terms/route.tsx",
      "parent": "/bak"
    },
    "/bak/documentation/roles": {
      "filePath": "bak/documentation/roles/route.tsx",
      "parent": "/bak"
    },
    "/bak/documentation/subscription-funnel-texts": {
      "filePath": "bak/documentation/subscription-funnel-texts/route.tsx",
      "parent": "/bak"
    },
    "/bak/invoicing/negative-invoices-list": {
      "filePath": "bak/invoicing/negative-invoices-list/route.tsx",
      "parent": "/bak"
    },
    "/bak/users/$userId": {
      "filePath": "bak/users/$userId/route.tsx",
      "parent": "/bak"
    },
    "/partner/$organization/home": {
      "filePath": "partner/$organization/home/route.tsx",
      "parent": "/partner/$organization"
    },
    "/partner/$organization/new-subscription": {
      "filePath": "partner/$organization/new-subscription/route.tsx",
      "parent": "/partner/$organization"
    },
    "/partner/$organization/search": {
      "filePath": "partner/$organization/search/route.tsx",
      "parent": "/partner/$organization"
    },
    "/s/c/$token": {
      "filePath": "s/c/$token/route.tsx"
    },
    "/s/e-v2/$token": {
      "filePath": "s/e-v2/$token/route.tsx"
    },
    "/s/q/$token": {
      "filePath": "s/q/$token/route.tsx"
    },
    "/subscribe/restaurants-v2/identify": {
      "filePath": "subscribe/restaurants-v2/identify/route.tsx"
    },
    "/_customer/health-insurance/": {
      "filePath": "_customer/health-insurance/index.tsx",
      "parent": "/_customer"
    },
    "/bak/admin/": {
      "filePath": "bak/admin/index.tsx",
      "parent": "/bak"
    },
    "/bak/documentation/": {
      "filePath": "bak/documentation/index.tsx",
      "parent": "/bak"
    },
    "/bak/invoicing/": {
      "filePath": "bak/invoicing/index.tsx",
      "parent": "/bak"
    },
    "/bak/pending-subscriptions/": {
      "filePath": "bak/pending-subscriptions/index.tsx",
      "parent": "/bak"
    },
    "/bak/users/": {
      "filePath": "bak/users/index.tsx",
      "parent": "/bak"
    },
    "/_customer/product-guarantees/$subscriptionId/$productName": {
      "filePath": "_customer/product-guarantees/$subscriptionId/$productName/route.tsx",
      "parent": "/_customer"
    },
    "/_customer/s/r/$token": {
      "filePath": "_customer/s/r/$token/route.tsx",
      "parent": "/_customer"
    },
    "/bak/contracts/$subscriptionId/invoicing": {
      "filePath": "bak/contracts/$subscriptionId/invoicing/route.tsx",
      "parent": "/bak"
    },
    "/bak/contracts/$subscriptionId/new-invoice": {
      "filePath": "bak/contracts/$subscriptionId/new-invoice/route.tsx",
      "parent": "/bak"
    },
    "/bak/contracts/$subscriptionId/status": {
      "filePath": "bak/contracts/$subscriptionId/status/route.tsx",
      "parent": "/bak"
    },
    "/bak/pending-subscriptions/$subscriptionId/history": {
      "filePath": "bak/pending-subscriptions/$subscriptionId/history/route.tsx",
      "parent": "/bak"
    },
    "/bak/pending-subscriptions/$subscriptionId/payment": {
      "filePath": "bak/pending-subscriptions/$subscriptionId/payment/route.tsx",
      "parent": "/bak"
    },
    "/bak/troubleshooting/session/$sessionId": {
      "filePath": "bak/troubleshooting/session/$sessionId/route.tsx",
      "parent": "/bak"
    },
    "/bak/v2-pending-subscriptions/$subscriptionId/detailed-sheet": {
      "filePath": "bak/v2-pending-subscriptions/$subscriptionId/detailed-sheet/route.tsx",
      "parent": "/bak"
    },
    "/partner/$organization/contracts/$subscriptionId": {
      "filePath": "partner/$organization/contracts/$subscriptionId/route.tsx",
      "parent": "/partner/$organization"
    },
    "/partner/$organization/users/$userId": {
      "filePath": "partner/$organization/users/$userId/route.tsx",
      "parent": "/partner/$organization"
    },
    "/partner/$organization/v2-pending-subscriptions/$subscriptionId": {
      "filePath": "partner/$organization/v2-pending-subscriptions/$subscriptionId/route.tsx",
      "parent": "/partner/$organization"
    },
    "/bak/contracts/$subscriptionId/": {
      "filePath": "bak/contracts/$subscriptionId/index.tsx",
      "parent": "/bak"
    },
    "/bak/v2-pending-subscriptions/$subscriptionId/": {
      "filePath": "bak/v2-pending-subscriptions/$subscriptionId/index.tsx",
      "parent": "/bak"
    },
    "/partner/$organization/pending-subscriptions/": {
      "filePath": "partner/$organization/pending-subscriptions/index.tsx",
      "parent": "/partner/$organization"
    },
    "/partner/$organization/users/": {
      "filePath": "partner/$organization/users/index.tsx",
      "parent": "/partner/$organization"
    },
    "/subscribe/$subscriptionId/$stepId/": {
      "filePath": "subscribe/$subscriptionId/$stepId/index.tsx"
    },
    "/bak/contracts/$subscriptionId/renewal/$endorsementId": {
      "filePath": "bak/contracts/$subscriptionId/renewal/$endorsementId/route.tsx",
      "parent": "/bak"
    },
    "/partner/$organization/pending-subscriptions/$subscriptionId/payment": {
      "filePath": "partner/$organization/pending-subscriptions/$subscriptionId/payment/route.tsx",
      "parent": "/partner/$organization"
    },
    "/subscribe/$subscriptionId/$stepId/$detailType/$detailId": {
      "filePath": "subscribe/$subscriptionId/$stepId/$detailType/$detailId/route.tsx"
    },
    "/bak/contracts/$subscriptionId/detailed-sheet/versions/$versionId": {
      "filePath": "bak/contracts/$subscriptionId/detailed-sheet/versions/$versionId/route.tsx",
      "parent": "/bak"
    },
    "/bak/contracts/$subscriptionId/endorsement/$endorsementId/detailed-sheet": {
      "filePath": "bak/contracts/$subscriptionId/endorsement/$endorsementId/detailed-sheet/route.tsx",
      "parent": "/bak"
    },
    "/bak/contracts/$subscriptionId/endorsement/$endorsementId/": {
      "filePath": "bak/contracts/$subscriptionId/endorsement/$endorsementId/index.tsx",
      "parent": "/bak"
    }
  }
}
ROUTE_MANIFEST_END */
