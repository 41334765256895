import { LanguageProvider } from '@orus.eu/pharaoh/src/code-only/localization/language-context'
import { memo } from 'react'
import { useSession } from '../../lib/session'

export const SessionLanguageProvider = memo<{ children: React.ReactNode }>(function SessionLanguageProvider({
  children,
}) {
  const session = useSession()

  const language = session.user?.language ?? undefined

  return <LanguageProvider value={language}>{children}</LanguageProvider>
})
