import { css } from '@emotion/react'
import { Dialog } from '@mui/material'
import { memo, useCallback, useEffect, useMemo, useState } from 'react'
import { useDownloadFile, useScreenType } from '../../../../code-only/hooks'
import { useTranslate } from '../../../../code-only/localization/language-context.js'
import { secondaryColor } from '../../../../code-only/theme.js'
import { getFileDisplayName } from '../../../../code-only/util.js'
import { Avatar, Button, Spinner } from '../../../../components/index.js'
import { spacing } from '../../../../foundations/spacing-tokens.js'
import { Text } from '../../../../foundations/text'
import { PdfViewer } from '../../../../legacy/molecules/pdf-viewer.js'
type PdfDocumentDialogProps = {
  fileName: string
  /** The PDF byte array. If `undefined` or not set, the dialog is considered loading */
  pdfBytes?: Uint8Array
  /** The PDF to download when clicking on the download button. If `undefined` or not set, the button is disabled */
  downloadablePdfBytes?: Uint8Array

  onClose: () => void
}

export const PdfDocumentDialog = memo<PdfDocumentDialogProps>(function PdfDocumentDialog(props) {
  const translate = useTranslate()
  const { fileName, pdfBytes, downloadablePdfBytes, onClose } = props
  const screenType = useScreenType()
  const [isDownloadTriggered, triggerDownload] = useState<boolean>(false)
  const { downloadFile } = useDownloadFile(downloadablePdfBytes ?? '', fileName)

  const download = useCallback(() => {
    triggerDownload(true)
    if (downloadablePdfBytes) {
      downloadFile()
      triggerDownload(false)
    }
  }, [downloadFile, downloadablePdfBytes])

  useEffect(() => {
    if (downloadablePdfBytes && isDownloadTriggered) {
      downloadFile()
      triggerDownload(false)
    }
  }, [downloadFile, downloadablePdfBytes, isDownloadTriggered])

  const loader = useMemo(() => <Loader />, [])

  return (
    <Dialog
      open
      fullWidth
      fullScreen={screenType === 'mobile'}
      maxWidth="md"
      onClose={onClose}
      PaperProps={{ style: { borderRadius: screenType === 'desktop' ? spacing[50] : 'none' } }}
    >
      <div
        css={css`
          display: flex;
          justify-content: space-between;
          align-items: center;

          padding-inline: ${screenType === 'mobile' ? spacing[60] : spacing[70]};
          padding-block: ${screenType === 'mobile' ? spacing[50] : spacing[30]};
        `}
      >
        <Text
          variant="subtitle"
          css={css`
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          `}
        >
          {getFileDisplayName(fileName)}
        </Text>
        <div
          css={css`
            display: flex;
            align-items: center;

            margin-left: ${spacing[70]};
          `}
        >
          {screenType === 'desktop' ? (
            <Button
              css={css`
                margin-right: ${spacing[70]};
              `}
              variant="tertiary"
              icon={!isDownloadTriggered ? 'arrow-down-to-line-solid' : undefined}
              avatar={isDownloadTriggered ? <Spinner strokeColor={secondaryColor} size="30" /> : undefined}
              avatarPosition="left"
              onClick={download}
            >
              {translate('download')}
            </Button>
          ) : (
            <></>
          )}

          <Button variant="text" avatarPosition="left" onClick={onClose}>
            <Avatar size="40" icon="xmark-solid" />
          </Button>
        </div>
      </div>

      {pdfBytes ? (
        <PdfViewer
          css={css`
            /* account for the fixed download button */
            padding-bottom: ${screenType === 'mobile' ? '56px' : '0px'};
            background-color: #f2f3f7;
          `}
          screenType={screenType}
          urlOrBytes={pdfBytes}
          loader={loader}
        />
      ) : (
        loader
      )}

      {screenType === 'mobile' ? (
        <Button
          css={css`
            margin-right: ${spacing[70]};
          `}
          variant="tertiary"
          icon={!isDownloadTriggered ? 'arrow-down-to-line-solid' : undefined}
          avatar={isDownloadTriggered ? <Spinner strokeColor={secondaryColor} /> : undefined}
          avatarPosition="left"
          onClick={download}
        >
          {translate('download')}
        </Button>
      ) : (
        <></>
      )}
    </Dialog>
  )
})

const Loader = memo(function Loader() {
  const translate = useTranslate()

  return (
    <div
      css={css`
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        padding: ${spacing[70]};
      `}
    >
      <Spinner />
      <Text
        variant="h5"
        css={css`
          margin-top: ${spacing[70]};
        `}
      >
        {translate('just_a_little_moment')}
      </Text>
    </div>
  )
})
