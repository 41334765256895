import { createFileRoute } from '@tanstack/react-router'

import PlatformSearchPage from '../../../components/pages/backoffice/platform/platform-search-page'
import { PermissionChecker } from '../../../components/templates/permission-checker'

export const Route = createFileRoute('/bak/search')({
  component: () => (
    <PermissionChecker requiredPermissions={['search']}>
      <PlatformSearchPage />
    </PermissionChecker>
  ),
})
