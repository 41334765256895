import { memo, useCallback } from 'react'

import { useTranslate } from '../../../code-only/localization/language-context.js'
import { Avatar, AvatarDecorative } from '../../../components/index.js'
import { DropdownMenu } from '../../molecules/menu-dropdown/index.js'

export const UserDropdown = memo<{
  firstName: string
  lastName: string
  onLogout: () => void
  onOpenAccountPage: () => void
  onOpenChat: () => void
}>(function UserDropdown({ firstName, lastName, onLogout, onOpenAccountPage, onOpenChat }) {
  const translate = useTranslate()

  const handleChange = useCallback(
    (value: string | null) => {
      switch (value) {
        case 'ACCOUNT_PAGE':
          onOpenAccountPage()
          return
        case 'CONTACT_CARE':
          onOpenChat()
          return
        case 'LOGOUT':
          onLogout()
          return
        case null:
          return
      }
    },
    [onLogout, onOpenAccountPage, onOpenChat],
  )

  return (
    <DropdownMenu
      buttonLabel={[firstName, lastName].join(' ')}
      buttonAccessoryLeft={<AvatarDecorative icon={'circle-user-light'} size="40" backgroundColor="periwinkle" />}
      buttonAccessoryRight={<Avatar icon={'angles-up-down-solid'} size="10" />}
      buttonMaxWidth={'240px'}
      menuItems={[
        { icon: 'arrow-up-right-from-square-regular', label: translate('open_my_customer_app'), value: 'ACCOUNT_PAGE' },
        { icon: 'comment-light', label: translate('contact_customer_support'), value: 'CONTACT_CARE' },
        { icon: 'arrow-right-from-bracket-regular', label: translate('disconnect'), value: 'LOGOUT' },
      ]}
      onChange={handleChange}
    />
  )
})
