import styled from '@emotion/styled'
import { memo, type ReactNode } from 'react'
import { Avatar } from '../../components'
import { colorTokens, spacing, Text, type CompoundIconName } from '../../foundations'
import type { IllustrationName } from '../../foundations/illustration'
type EmptyStateStyle = 'default' | 'neutral'

type EmptyStateProps = {
  subtitle: string
  secondaryText?: string
  style?: EmptyStateStyle
  icon?: CompoundIconName
  illustration?: IllustrationName
  height?: string
  children?: ReactNode
}

export const EmptyState = memo(function SectionProduct(props: EmptyStateProps) {
  const { icon, subtitle, height, children, secondaryText, style = 'default', illustration } = props

  return (
    <EmptyStateContainer height={height} emptyStateStyle={style}>
      {icon ? <Avatar icon={icon} size="40" color={colorTokens['color-fg-base']} /> : undefined}
      {illustration ? <Avatar size="70" illustration={illustration} /> : undefined}
      <TextCenter variant="body2" color={colorTokens['color-text-base-basic']}>
        {subtitle}
        {secondaryText ? (
          <>
            <br />
            {secondaryText}
          </>
        ) : undefined}
      </TextCenter>
      {children}
    </EmptyStateContainer>
  )
})

const EmptyStateContainer = styled.div<{ height?: string; emptyStateStyle?: EmptyStateStyle }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  padding: ${spacing[60]} ${spacing[80]};
  gap: ${spacing[50]};

  background-color: ${({ emptyStateStyle }) =>
    emptyStateStyle === 'default' ? colorTokens['color-bg-base-normal'] : colorTokens['color-bg-neutral-inverse']};
  border-radius: ${spacing[30]};

  height: ${({ height }) => (height ? height : 'auto')};
`

const TextCenter = styled(Text)`
  text-align: center;
`
