import { createFileRoute } from '@tanstack/react-router'

import PlatformAdminPage from '../../../components/pages/backoffice/platform/platform-admin-page'
import { PermissionChecker } from '../../../components/templates/permission-checker'

export const Route = createFileRoute('/bak/admin/')({
  component: () => (
    <PermissionChecker requiredPermissions={['tech.configurations']}>
      <PlatformAdminPage />
    </PermissionChecker>
  ),
})
