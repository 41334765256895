import { css } from '@emotion/react'
import { memo } from 'react'
import { spacing } from '../../../../foundations/spacing-tokens.js'
import { Text } from '../../../../foundations/text'
import { LegacyDialog } from '../../../templates/index.js'
import { RelatedActivitiesList } from '../related-activities-list/index.js'

type ActivityCoverageDialogProps = {
  activity: string
  mainActivities: string[]
  secondaryActivities: string[]
  forbiddenActivities: string[]
  onClose: () => void
}

export const ActivityCoverageDialog = memo<ActivityCoverageDialogProps>(function ActivityCoverageDialog(props) {
  const { activity, mainActivities, secondaryActivities, forbiddenActivities, onClose } = props

  const isSupported = mainActivities.length > 0 || secondaryActivities.length > 0 || forbiddenActivities.length > 0

  return (
    <LegacyDialog
      fullWidth
      onClose={onClose}
      header={
        <div>
          <Text variant="h3">{activity}</Text>
        </div>
      }
    >
      {isSupported ? (
        <>
          {mainActivities.length > 0 && (
            <RelatedActivitiesList
              activities={mainActivities}
              variant="mainActivities"
              css={css`
                margin-top: ${spacing[100]};
              `}
            />
          )}

          {secondaryActivities.length > 0 && (
            <RelatedActivitiesList activities={secondaryActivities} variant="secondaryActivities" />
          )}

          {forbiddenActivities.length > 0 && (
            <RelatedActivitiesList activities={forbiddenActivities} variant="forbiddenActivities" />
          )}
        </>
      ) : (
        <Text>Cette activité ne peut pas être couverte.</Text>
      )}
    </LegacyDialog>
  )
})
