import { createFileRoute } from '@tanstack/react-router'
import DetailedSheetPage from '../../../../../../../components/pages/backoffice/detailed-sheet-page/DetailedSheetPage'
import { PermissionChecker } from '../../../../../../../components/templates/permission-checker'

export const Route = createFileRoute('/bak/contracts/$subscriptionId/endorsement/$endorsementId/detailed-sheet')({
  component: () => (
    <PermissionChecker requiredPermissions={['endorsement.create']}>
      <DetailedSheetPage />
    </PermissionChecker>
  ),
})
