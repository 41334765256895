import styled from '@emotion/styled'
import {
  localizeAmount,
  localizeRecurrence,
  type GetAmountLabel,
  type PaymentRecurrence,
  type ProductInformation,
} from '@orus.eu/dimensions'
import { memo, type ReactNode } from 'react'
import { useUiContext, type UiContext } from '../../code-only/hooks'
import {
  projectAttributeInProductBlock,
  useProductAttributesProjection,
} from '../../code-only/hooks/use-product-attributes-projection'
import { useLanguage, useTranslate } from '../../code-only/localization'
import { AvatarDecorative, Badge } from '../../components'
import { Button, ButtonLink } from '../../components/button'
import { borderStroke, colorTokens, shadow, spacing } from '../../foundations'
import { Text } from '../../foundations/text'

import { productsAvatarConfigurations } from './helpers/products-avatar-configurations'
import { ProductCardAttribute } from './product-card-attribute'

type ProductCard = {
  productInformation: ProductInformation
  priceAndRecurrence?: GetAmountLabel
  recurrence: PaymentRecurrence | 'one-time' | undefined | null
  onLearnMoreClick?: () => void
  onShowGuaranteesClick?: () => void
  showGuaranteesInNewPage?: boolean
  hasLearnMoreButton?: boolean
  hasShowGuaranteesButton?: boolean
  children?: ReactNode
  useIllustrations: boolean
}

export const ProductCard = memo<ProductCard>(function ProductCard(props) {
  const {
    productInformation,
    priceAndRecurrence,
    recurrence,
    onLearnMoreClick,
    onShowGuaranteesClick,
    showGuaranteesInNewPage,
    hasLearnMoreButton,
    hasShowGuaranteesButton,
    children,
    useIllustrations,
  } = props

  const translate = useTranslate()
  const language = useLanguage()
  const screenVariant = useUiContext()
  const size = ['desktop', 'backoffice'].includes(screenVariant) ? 'small' : 'large'

  const productAttributes = useProductAttributesProjection(
    productInformation.attributes,
    projectAttributeInProductBlock,
  )

  const productPrice = productInformation.attributes.find((attribute) => attribute.type === 'amount-and-recurrence')

  return (
    <StyledProductCard screenVariant={screenVariant}>
      <StyledProductCardHeader screenVariant={screenVariant}>
        <AvatarDecorative
          size={useIllustrations ? '70' : '60'}
          {...productsAvatarConfigurations[productInformation.product][useIllustrations ? 'illustration' : 'avatar']}
        />
        <StyledProductCardHeaderTitle variant="subtitle">{productInformation.name}</StyledProductCardHeaderTitle>
        {productPrice && productPrice.value ? (
          <StyledProductCardHeaderPrice>
            <Text variant="body2Medium">{localizeAmount(productPrice?.value, language)}</Text>
            {productPrice.inProductHeaderContext?.showRecurrence && recurrence ? (
              <Badge>{localizeRecurrence(recurrence, language)}</Badge>
            ) : null}
          </StyledProductCardHeaderPrice>
        ) : priceAndRecurrence ? (
          <StyledProductCardHeaderPrice>
            <Text variant="body2Medium">{priceAndRecurrence.price}</Text>
            {priceAndRecurrence.recurrence ? <Badge>{priceAndRecurrence.recurrence}</Badge> : null}
          </StyledProductCardHeaderPrice>
        ) : (
          <></>
        )}
      </StyledProductCardHeader>
      <StyledProductCardContent>
        <div>
          {productAttributes.map((productAttribute) => (
            <StyledProductCardContentAttributesItem key={productAttribute.label}>
              <Text variant="body2">{productAttribute.label}</Text>
              <ProductCardAttribute productAttribute={productAttribute} />
            </StyledProductCardContentAttributesItem>
          ))}
        </div>
        {hasLearnMoreButton || hasShowGuaranteesButton ? (
          <StyledProductCardFooter showGuaranteesInNewPage={showGuaranteesInNewPage} screenVariant={screenVariant}>
            {hasLearnMoreButton ? (
              <Button variant={showGuaranteesInNewPage ? 'text' : 'secondary'} size={size} onClick={onLearnMoreClick}>
                {translate('subscription_funnel_product_indicator_learn_more')}
              </Button>
            ) : null}

            {hasShowGuaranteesButton ? (
              showGuaranteesInNewPage ? (
                <ButtonLink
                  ariaLabel={translate('see_product_guarantees', { productName: productInformation.name })}
                  variant="secondary"
                  from="/subscribe/$subscriptionId/$stepId"
                  to="/subscribe/$subscriptionId/$stepId/$detailType/$detailId"
                  params={{ detailType: 'guarantees', detailId: productInformation.name }}
                  size={['desktop', 'backoffice'].includes(screenVariant) ? 'medium' : 'large'}
                >
                  {translate('see_guarantees')}
                </ButtonLink>
              ) : (
                <Button
                  variant="secondary"
                  size={size}
                  onClick={onShowGuaranteesClick}
                  ariaLabel={`${translate('see_guarantees')} ${productInformation.name}`}
                >
                  {translate('see_guarantees')}
                </Button>
              )
            ) : null}
          </StyledProductCardFooter>
        ) : null}
      </StyledProductCardContent>
      {children ? <StyledProductCardChildren>{children}</StyledProductCardChildren> : null}
    </StyledProductCard>
  )
})

const StyledProductCard = styled.div<{ screenVariant: UiContext }>`
  border-radius: ${spacing[40]};
  border: ${borderStroke[20]} solid ${colorTokens['color-stroke-base']};
  padding: ${({ screenVariant }) => (['desktop', 'backoffice'].includes(screenVariant) ? spacing[60] : spacing[70])};
  background: ${colorTokens['color-bg-base-normal']};
  box-shadow: ${shadow.bottom[20]};
`

const StyledProductCardHeader = styled.div<{ screenVariant: UiContext }>`
  display: flex;
  gap: ${spacing[50]};
  align-items: ${({ screenVariant }) => (['desktop', 'backoffice'].includes(screenVariant) ? 'center' : 'column')};
  justify-content: space-between;
  flex: 1 1;
  flex-flow: ${({ screenVariant }) => (['desktop', 'backoffice'].includes(screenVariant) ? 'row wrap' : 'column wrap')};
`

const StyledProductCardHeaderTitle = styled(Text)`
  flex: 1 1;
`

const StyledProductCardHeaderPrice = styled.div`
  display: flex;
  gap: ${spacing[30]};
  align-items: center;
`

const StyledProductCardContent = styled.div`
  margin-top: ${spacing[60]};
`

const StyledProductCardContentAttributesItem = styled.div`
  margin-bottom: ${spacing[30]};
  display: flex;
  justify-content: space-between;
`

const StyledProductCardFooter = styled.div<{ showGuaranteesInNewPage?: boolean; screenVariant: UiContext }>`
  margin-top: ${spacing[50]};
  display: flex;
  flex-flow: ${({ screenVariant }) =>
    ['desktop', 'backoffice'].includes(screenVariant) ? 'row wrap' : 'column-reverse wrap'};
  gap: ${({ showGuaranteesInNewPage }) => (showGuaranteesInNewPage ? spacing[50] : spacing[30])};
  justify-content: flex-end;
`

const StyledProductCardChildren = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${spacing[60]};
  margin-top: ${spacing[60]};
`
