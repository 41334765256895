import { useCallback, useState } from 'react'
import { useEnqueueTemporaryNotificationAlert } from '../../components/callout-notification/notification/alert/use-enqueue-alert.js'
import { useTranslate } from '../localization'

type DownloadFileStatus = 'idle' | 'downloading' | 'error'

export function useDownloadFile(
  urlOrBytes: string | Uint8Array,
  fileName: string,
): { downloadFile: () => void; downloadStatus: DownloadFileStatus } {
  const translate = useTranslate()
  const [status, setStatus] = useState<DownloadFileStatus>('idle')

  const { enqueueTemporaryNotificationAlert } = useEnqueueTemporaryNotificationAlert()

  const downloadFile = useCallback(() => {
    setStatus('downloading')
    ;(async () => {
      const blob =
        typeof urlOrBytes === 'string'
          ? await (
              await fetch(urlOrBytes, {
                headers: new Headers({
                  Accept: 'application/octet-stream',
                }),
              })
            ).blob()
          : new Blob([urlOrBytes], { type: 'application/octet-stream' })

      const url = window.URL.createObjectURL(blob)
      const a = document.createElement('a')
      a.href = url
      a.download = fileName
      a.style.display = 'none'
      document.body.appendChild(a)
      a.click()
      a.remove()
      window.URL.revokeObjectURL(url)
      enqueueTemporaryNotificationAlert(translate('download_successful'), {
        variant: 'success',
        autoHideDuration: 3000,
      })
      setStatus('idle')
    })().catch(() => {
      enqueueTemporaryNotificationAlert('Échec du téléchargement du fichier.', { variant: 'danger' })
      setStatus('error')
    })
  }, [urlOrBytes, fileName, enqueueTemporaryNotificationAlert, translate])

  return { downloadFile, downloadStatus: status }
}
