import { createFileRoute } from '@tanstack/react-router'

import PlatformOrganizationMembershipsPage from '../../../components/pages/backoffice/platform/platform-membership-page/platform-memberships-page'
import { PermissionChecker } from '../../../components/templates/permission-checker'

export const Route = createFileRoute('/bak/memberships')({
  component: () => (
    <PermissionChecker requiredPermissions={['organization.read']}>
      <PlatformOrganizationMembershipsPage />
    </PermissionChecker>
  ),
})
