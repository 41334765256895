import { BaseDimension } from '../abstract-dimension'
import { NumbersEnumDimension } from '../numbers-enum-dimension'

export const ALL_ES_RCPH_DEDUCTIBLES = [300, 500, 600, 1_000, 1_500] as const satisfies number[]
export type EsRcphDeductible = (typeof ALL_ES_RCPH_DEDUCTIBLES)[number]

export const esRcphAllowedDeductiblesDimension = new BaseDimension<'esRcphAllowedDeductibles', EsRcphDeductible[]>({
  name: 'esRcphAllowedDeductibles',
  displayKeys: {
    name: 'dimension_name_es_rcph_allowed_deductibles',
  },
  tags: ['ES-RCPH'],
} as const)

export const esRcphSelectedDeductibleDimension = new NumbersEnumDimension({
  name: 'esRcphSelectedDeductible',
  displayKeys: {
    name: 'dimension_name_es_rcph_selected_deductible',
  },
  values: ALL_ES_RCPH_DEDUCTIBLES,
  tags: ['ES-RCPH'],
} as const)

export function isEsRcphDeductible(value: number): value is EsRcphDeductible {
  return (ALL_ES_RCPH_DEDUCTIBLES as readonly number[]).includes(value)
}
