import { addAmounts, multiplyByNumber, type Amount, type FinancialRate } from '@orus.eu/amount'

import type { Result } from '@orus.eu/result'
import { AbstractDimension } from '../abstract-dimension.js'
import type { MrpwPricingVersion } from '../pricing-version-dimensions.js'
import type { FloodRiskProblem, TemporaryWakamProblem } from './wakam-util.js'

export const mrpwProtectionEntries = [
  ['1', { label: 'Niveau 1' }],
  ['2', { label: 'Niveau 2' }],
  ['3', { label: 'Niveau 3' }],
  ['4', { label: 'Niveau 4' }],
] as const

export const mrpwProtectionLevels = ['1', '2', '3', '4'] as const satisfies ReadonlyArray<
  (typeof mrpwProtectionEntries)[number][0]
>

export type MrpwProtectionLevel = (typeof mrpwProtectionLevels)[number]

export type MrpwQuoteV2 = {
  version: 2
  wakamQuoteReference: string

  /**
   * Yearly base premium when payment recurrence is yearly
   */
  yearlyBasePremium: Amount

  /**
   * Yearly assistance base premium when payment recurrence is yearly
   */
  yearlyAssistanceBasePremium?: Amount

  /**
   * Yearly assistance taxes when payment recurrence is yearly
   */
  yearlyAssistanceTaxes?: Amount

  /**
   * Part of the yearlyBasePremium that is the Orus fee
   */
  yearlyOrusFee: Amount

  /**
   * Yearly taxes when payment recurrence is yearly, including terrorism tax if any
   */
  yearlyTaxes: Amount

  /**
   * Yearly total amount to pay when payment recurrence is yearly
   */
  yearlyTotalPremium: Amount

  /**
   * Monthly base premium when payment recurrence is monthly
   */
  monthlyBasePremium: Amount

  /**
   * Monthly assistance base premium when payment recurrence is monthly
   */
  monthlyAssistanceBasePremium?: Amount

  /**
   * Monthly assistance taxes when payment recurrence is monthly
   */
  monthlyAssistanceTaxes?: Amount

  /**
   * Part of the monthlyBasePremium that is the Orus fee
   */
  monthlyOrusFee: Amount

  /**
   * Monthly taxes when payment recurrence is monthly, excluding terrorism tax
   */
  monthlyTaxes: Amount

  /**
   * Monthly total amount to pay specific to the first month (including terrorism tax if any for ex.), when payment recurrence is monthly
   */
  firstMonthTotalPremium: Amount

  /**
   * Monthly total amount to pay excl. first month, when payment recurrence is monthly
   */
  subsequentMonthsTotalPremium: Amount

  /**
   * Terrorism taxes, already included
   */
  terrorismTax: Amount
  requiredProtectionLevel: MrpwProtectionLevel
  guarantees: MrpwQuoteGuaranteesV2
  optionsGuarantees?: MrpwQuoteOptionGuaranteesV2
  monthlyOrusFeeRate: FinancialRate
  yearlyOrusFeeRate: FinancialRate
  partnerManagementFeeRate?: FinancialRate | undefined
  partnerDiscountRate?: FinancialRate
  partnerCommissionRate?: FinancialRate
  /**
   * Monthly total amount for partner management fee
   */
  monthlyPartnerManagementFeePremium: Amount | undefined
  /**
   * Yearly total amount for partner management fee
   */
  yearlyPartnerManagementFeePremium: Amount | undefined
  /**
   * Yearly total amount for partner application fee
   */
  partnerApplicationFeePremium: Amount | undefined

  /**
   * Pricing version used to compute the subscription
   */
  pricingVersion?: MrpwPricingVersion | undefined
}

export type MrpwQuoteV1 = Omit<
  MrpwQuoteV2,
  'version' | 'monthlyOrusFeeRate' | 'yearlyOrusFeeRate' | 'optionsGuarantees' | 'guarantees'
> & {
  orusFeeRate: FinancialRate
  optionsGuarantees?: MrpwQuoteOptionGuarantees
  guarantees: MrpwQuoteGuarantees
}

export type MrpwQuote = MrpwQuoteV1 | MrpwQuoteV2

export function getNormalizedMrpwQuote(quote: MrpwQuote): MrpwQuoteV2 {
  if (!('version' in quote)) {
    const guarantees = Object.fromEntries(
      wakamCodeGaranties.map((guaranteeCode) => [
        guaranteeCode,
        {
          yearlyBasePremium: quote.yearlyBasePremium,
          yearlyOrusFee: quote.yearlyOrusFee,
          yearlyTaxes: quote.yearlyTaxes,
          yearlyTotalPremium: quote.yearlyTotalPremium,

          monthlyBasePremium: transformYearlyAmountToMonthly(quote.yearlyBasePremium),
          monthlyOrusFee: transformYearlyAmountToMonthly(quote.yearlyOrusFee),
          monthlyTaxes: transformYearlyAmountToMonthly(quote.yearlyTaxes),
          monthlyTotalPremium: transformYearlyAmountToMonthly(quote.yearlyTotalPremium),
        },
      ]),
    )

    const optionsGuarantees = Object.fromEntries(
      wakamCodeGarantiesOptionWithPriceDisplayedInSelfonboarding.map((guaranteeCode) => [
        guaranteeCode,
        {
          yearlyBasePremium: quote.yearlyBasePremium,
          yearlyOrusFee: quote.yearlyOrusFee,
          yearlyTaxes: quote.yearlyTaxes,
          yearlyTotalPremium: quote.yearlyTotalPremium,

          monthlyBasePremium: transformYearlyAmountToMonthly(quote.yearlyBasePremium),
          monthlyOrusFee: transformYearlyAmountToMonthly(quote.yearlyOrusFee),
          monthlyTaxes: transformYearlyAmountToMonthly(quote.yearlyTaxes),
          monthlyTotalPremium: transformYearlyAmountToMonthly(quote.yearlyTotalPremium),
        },
      ]),
    )

    return {
      ...quote,
      version: 2,
      monthlyOrusFeeRate: quote.orusFeeRate,
      yearlyOrusFeeRate: quote.orusFeeRate,
      guarantees,
      optionsGuarantees,
    }
  }
  return quote
}

export type MrpwQuoteOptionGuarantees = {
  [key in (typeof wakamCodeGarantiesOptionWithPriceDisplayedInSelfonboarding)[number]]?: MrpwQuoteGuarantee
}

export type MrpwQuoteOptionGuaranteesV2 = {
  [key in (typeof wakamCodeGarantiesOptionWithPriceDisplayedInSelfonboarding)[number]]?: MrpwQuoteGuaranteeV2
}

export type MrpwQuoteGuarantees = {
  [code: string]: MrpwQuoteGuarantee
}

export type MrpwQuoteGuaranteesV2 = {
  [code: string]: MrpwQuoteGuaranteeV2
}

export type MrpwQuoteGuarantee = {
  yearlyBasePremium: Amount
  yearlyOrusFee: Amount
  yearlyTaxes: Amount
  yearlyTotalPremium: Amount
}

export type MrpwQuoteGuaranteeV2 = MrpwQuoteGuarantee & {
  monthlyBasePremium: Amount | undefined
  monthlyOrusFee: Amount | undefined
  monthlyTaxes: Amount | undefined
  monthlyTotalPremium: Amount | undefined
}

export function addMrpwQuoteAmounts(main: MrpwQuoteV2, addition: MrpwQuoteV2): MrpwQuoteV2 {
  const guarantees = Object.entries(main.guarantees)
    .concat(Object.entries(addition.guarantees))
    .reduce<MrpwQuoteGuaranteesV2>(
      (guarantees, [code, guarantee]) => addGuaranteesAmounts(guarantees, code, guarantee),
      {},
    )

  const quote: MrpwQuoteV2 = {
    version: 2,
    pricingVersion: main.pricingVersion,
    wakamQuoteReference: main.wakamQuoteReference,
    monthlyOrusFeeRate: main.monthlyOrusFeeRate,
    yearlyOrusFeeRate: main.yearlyOrusFeeRate,
    yearlyBasePremium: addAmounts(main.yearlyBasePremium, addition.yearlyBasePremium),
    yearlyOrusFee: addAmounts(main.yearlyOrusFee, addition.yearlyOrusFee),
    yearlyTaxes: addAmounts(main.yearlyTaxes, addition.yearlyTaxes),
    yearlyTotalPremium: addAmounts(main.yearlyTotalPremium, addition.yearlyTotalPremium),
    monthlyBasePremium: addAmounts(main.monthlyBasePremium, addition.monthlyBasePremium),
    monthlyOrusFee: addAmounts(main.monthlyOrusFee, addition.monthlyOrusFee),
    monthlyTaxes: addAmounts(main.monthlyTaxes, addition.monthlyTaxes),
    firstMonthTotalPremium: addAmounts(main.firstMonthTotalPremium, addition.firstMonthTotalPremium),
    subsequentMonthsTotalPremium: addAmounts(main.subsequentMonthsTotalPremium, addition.subsequentMonthsTotalPremium),
    terrorismTax: addAmounts(main.terrorismTax, addition.terrorismTax),
    requiredProtectionLevel: main.requiredProtectionLevel,
    guarantees,
    monthlyPartnerManagementFeePremium:
      main.monthlyPartnerManagementFeePremium && addition.monthlyPartnerManagementFeePremium
        ? addAmounts(main.monthlyPartnerManagementFeePremium, addition.monthlyPartnerManagementFeePremium)
        : undefined,
    yearlyPartnerManagementFeePremium:
      main.yearlyPartnerManagementFeePremium && addition.yearlyPartnerManagementFeePremium
        ? addAmounts(main.yearlyPartnerManagementFeePremium, addition.yearlyPartnerManagementFeePremium)
        : undefined,
    partnerApplicationFeePremium: main.partnerApplicationFeePremium,
  }
  if (main.optionsGuarantees) quote.optionsGuarantees = main.optionsGuarantees
  return quote
}

function addGuaranteesAmounts(
  guarantees: MrpwQuoteGuaranteesV2,
  code: string,
  addedGuarantee: MrpwQuoteGuaranteeV2,
): MrpwQuoteGuaranteesV2 {
  const existingGuarantee = guarantees[code]
  const upsertedGuarantee: MrpwQuoteGuaranteeV2 = existingGuarantee
    ? {
        yearlyBasePremium: addAmounts(existingGuarantee.yearlyBasePremium, addedGuarantee.yearlyBasePremium),
        yearlyOrusFee: addAmounts(existingGuarantee.yearlyOrusFee, addedGuarantee.yearlyOrusFee),
        yearlyTaxes: addAmounts(existingGuarantee.yearlyTaxes, addedGuarantee.yearlyTaxes),
        yearlyTotalPremium: addAmounts(existingGuarantee.yearlyTotalPremium, addedGuarantee.yearlyTotalPremium),
        monthlyBasePremium:
          existingGuarantee.monthlyBasePremium && addedGuarantee.monthlyBasePremium
            ? addAmounts(existingGuarantee.monthlyBasePremium, addedGuarantee.monthlyBasePremium)
            : undefined,
        monthlyOrusFee:
          existingGuarantee.monthlyOrusFee && addedGuarantee.monthlyOrusFee
            ? addAmounts(existingGuarantee.monthlyOrusFee, addedGuarantee.monthlyOrusFee)
            : undefined,
        monthlyTaxes:
          existingGuarantee.monthlyTaxes && addedGuarantee.monthlyTaxes
            ? addAmounts(existingGuarantee.monthlyTaxes, addedGuarantee.monthlyTaxes)
            : undefined,
        monthlyTotalPremium:
          existingGuarantee.monthlyTotalPremium && addedGuarantee.monthlyTotalPremium
            ? addAmounts(existingGuarantee.monthlyTotalPremium, addedGuarantee.monthlyTotalPremium)
            : undefined,
      }
    : addedGuarantee

  return { ...guarantees, [code]: upsertedGuarantee }
}

export type MrpwQuoteResult = Result<MrpwQuoteV2, TemporaryWakamProblem | FloodRiskProblem>

export class MrpwQuoteDimension<NAME extends string> extends AbstractDimension<NAME, MrpwQuoteResult> {}

/**
 * Subset of the wakamCodeGaranties which correspond to options which price is displayed in self onboarding.
 * We need this list to make a quote only to compute the price of these options
 */
export const wakamCodeGarantiesOptionWithPriceDisplayedInSelfonboarding = ['BDG', 'PE', 'PJ', 'PVF'] as const

/**
 * Wakam codes, defintions here : https://www.notion.so/Wakam-CodeGarantie-mapping-22d008b89483411c92b3d35eab77e5e8
 */
export const wakamCodeGaranties = [
  'INC',
  'ATTENP',
  'TEMP',
  'DEGEAU',
  'VLXFV',
  'DOME',
  'BDMACH',
  'RCX',
  'DRX',
  'MARR',
  ...wakamCodeGarantiesOptionWithPriceDisplayedInSelfonboarding,
  'CATNAT',
  'ATTEN',
  /**
   * This guarantee has been removed from newer products. It is not relevant for professional
   * insurance, and we included because of a mistake of wakam. We keep it for backward
   * compatibility with existing contracts, and
   */
  'CATECH',
] as const

export type WakamCodeGarantie = (typeof wakamCodeGaranties)[number]

export function transformYearlyAmountToMonthly(amount: Amount): Amount {
  return multiplyByNumber(amount, 1 / 12)
}
