import type { ReactElement } from 'react'
import { useOpenChat } from '../../code-only/lib'
import { useTranslate } from '../../code-only/localization'
import { Avatar, Button } from '../../components'
import { colorTokens } from '../../foundations'

export function HeaderContactButton(): ReactElement {
  const openChat = useOpenChat()
  const translate = useTranslate()
  return (
    <Button
      variant="secondary"
      onClick={openChat}
      avatar={
        <Avatar
          icon="comment-regular"
          color={colorTokens['color-fg-base-active-inverse']}
          secondaryColor={colorTokens['color-fg-base-active-inverse']}
        />
      }
      size="medium"
      avatarPosition="left"
      trackingId="contact_button"
    >
      {translate('contact_customer_support')}
    </Button>
  )
}
