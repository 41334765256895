import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { memo } from 'react'
import { borderStroke } from '../../../foundations/border-stroke-tokens.js'
import { colorTokens } from '../../../foundations/color-tokens.js'
import { spacing } from '../../../foundations/spacing-tokens.js'
import { Text } from '../../../foundations/text'
import type { TextVariant } from '../../../foundations/text/variant.js'

const borderRadius = '8px'

type TabBarSize = 'small' | 'large'

type TabBarProps = {
  size: TabBarSize
  fullWidth?: boolean

  tabs: Record<string, string>
  selectedTabId: string
  onTabChange: (tabId: string) => void
  padding?: string
  className?: string
}

export const TabBar = memo<TabBarProps>(function TabBar(props) {
  const { size, fullWidth, tabs, selectedTabId, onTabChange, className, padding } = props

  const { textVariant, height } = sizesPerTabBarSize[size]

  return (
    <div
      className={className}
      css={css`
        display: ${fullWidth ? 'flex' : 'inline-flex'};
        border-radius: ${borderRadius};
        border: ${borderStroke[20]} solid ${colorTokens['color-stroke-base']};
      `}
    >
      {Object.entries(tabs).map(([id, label]) => (
        <TabButton
          type="button"
          key={id}
          height={height}
          active={id === selectedTabId}
          onClick={() => onTabChange(id)}
          padding={padding}
        >
          <Text
            variant={textVariant}
            css={css`
              white-space: nowrap;
              text-overflow: ellipsis;
              overflow: hidden;
            `}
          >
            {label}
          </Text>
        </TabButton>
      ))}
    </div>
  )
})

const TabButton = styled.button<{ height: string; active: boolean; padding?: string }>`
  display: flex;
  justify-content: center;
  align-items: center;

  flex: 1;
  height: ${({ height }) => height};

  padding: ${({ padding }) => padding || `0 ${spacing[60]} 0 ${spacing[60]}`};

  color: ${({ active }) => (active ? colorTokens['color-text-base-primary'] : colorTokens['color-text-base-main'])};

  transition: all 0.3s;
  cursor: pointer;
  background-color: ${({ active }) =>
    active ? colorTokens['color-bg-base-active'] : colorTokens['color-bg-base-normal']};
  border: 1px solid transparent;

  &:hover {
    background-color: ${({ active }) =>
      active ? colorTokens['color-bg-base-active'] : colorTokens['color-bg-base-hover']};
  }

  &:active {
    background-color: ${({ active }) =>
      active ? colorTokens['color-bg-base-active'] : colorTokens['color-bg-base-hover']};
  }

  &:focus-visible {
    background-color: ${({ active }) =>
      active ? colorTokens['color-bg-base-active'] : colorTokens['color-bg-base-hover']};
    border: ${borderStroke[20]} solid ${colorTokens['color-stroke-base-focus']};
    outline: none;
  }

  &:first-child {
    border-top-left-radius: ${borderRadius};
    border-bottom-left-radius: ${borderRadius};
  }

  &:last-child {
    border-top-right-radius: ${borderRadius};
    border-bottom-right-radius: ${borderRadius};
  }
`

const sizesPerTabBarSize: {
  [type in TabBarSize]: {
    textVariant: TextVariant
    height: string
  }
} = {
  small: { textVariant: 'captionMedium', height: '32px' },
  large: { textVariant: 'body2Medium', height: '40px' },
}
