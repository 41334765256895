import { createFileRoute, Outlet } from '@tanstack/react-router'
import CustomerPage from '../components/templates/customer-page'

export const Route = createFileRoute('/_customer')({
  component: RouteComponent,
})

function RouteComponent() {
  return (
    <CustomerPage>
      <Outlet />
    </CustomerPage>
  )
}
